import { currentToastAtom, hideToastActionAtom } from "@shared/frontend/stores/Toast";
import { useAtomValue, useSetAtom } from "jotai";
import React, { useCallback, useEffect, useRef, useState } from "react";
import Text from "../../atoms/Text";
import Toast from "./index";

const ANIMATION_DURATION = 150;

function ToastWrapper() {
  const toastData = useAtomValue(currentToastAtom);
  const [internalToast, setInternalToast] = useState(toastData);
  const hideToast = useSetAtom(hideToastActionAtom);

  const [isAnimatingOut, setIsAnimatingOut] = useState(false);
  const wasPreviouslyVisible = useRef(false);
  const timer = useRef<NodeJS.Timeout | number | null>(null);

  const startExitAnimation = useCallback(() => {
    setIsAnimatingOut(true);
    timer.current = setTimeout(() => {
      wasPreviouslyVisible.current = false;
      setIsAnimatingOut(false);
      setInternalToast(null);
    }, ANIMATION_DURATION - 50);
  }, [setInternalToast, setIsAnimatingOut]);

  const hideToastImmediately = useCallback(() => {
    if (internalToast?.showCloseButton) hideToast();
    setIsAnimatingOut(false);
    setInternalToast(null);
    if (timer.current) {
      clearTimeout(timer.current);
    }
    wasPreviouslyVisible.current = false;
  }, [internalToast, hideToast]);

  const handleClickAction = useCallback(() => {
    if (internalToast?.onClickAction) {
      internalToast.onClickAction();
    }
    hideToastImmediately();
  }, [internalToast, hideToastImmediately]);

  const handleAnimationEnd = useCallback(() => {
    setIsAnimatingOut(false);
    setInternalToast(null);
  }, [setInternalToast, setIsAnimatingOut]);

  useEffect(
    function startExitAnimationOnNullToast() {
      if (toastData && !wasPreviouslyVisible.current) {
        setInternalToast(toastData);
        wasPreviouslyVisible.current = true;
      } else if (!toastData && wasPreviouslyVisible.current) {
        startExitAnimation();
      }
    },
    [toastData, setInternalToast, startExitAnimation]
  );

  if (!internalToast) {
    return null;
  }

  return (
    <Toast
      {...internalToast}
      onClickClose={internalToast.showCloseButton ? hideToastImmediately : undefined}
      onClickAction={internalToast.onClickAction ? handleClickAction : undefined}
      isAnimatingOut={isAnimatingOut}
      onAnimationEnd={handleAnimationEnd}
      animationDuration={ANIMATION_DURATION}
    >
      <Text color="invert">{internalToast.message}</Text>
    </Toast>
  );
}

export default ToastWrapper;
