import type { z } from "zod";

import { IDittoClient } from "./DittoClient";
import * as ApiRoutes from "./routes/api.schema";
import * as ChangesRoutes from "./routes/changes.schema";
import * as CommentsRoutes from "./routes/comments.schema";
import * as CompRoutes from "./routes/comp.schema";
import * as ComponentFolderRoutes from "./routes/componentFolder.schema";
import * as ConnectionsRoutes from "./routes/connections.schema";
import * as DittoProjectRoutes from "./routes/dittoProject.schema";
import * as DocRoutes from "./routes/doc.schema";
import * as FigmaRoutes from "./routes/figma.schema";
import * as InviteRoutes from "./routes/invite.schema";
import * as JobsRoutes from "./routes/jobs.schema";
import * as Library from "./routes/library.schema";
import * as LibraryComponentRoutes from "./routes/libraryComponent.schema";
import * as LibraryComponentFolderRoutes from "./routes/libraryComponentFolder.schema";
import * as ProjectRoutes from "./routes/project.schema";
import * as SlackRoutes from "./routes/slack.schema";
import * as TextItemRoutes from "./routes/textItem.schema";
import * as TrustedRoutes from "./routes/trusted.schema";
import * as UserRoutes from "./routes/user.schema";
import * as VariableRoutes from "./routes/variable.schema";
import * as VariableFolderRoutes from "./routes/variableFolder.schema";
import * as VariantRoutes from "./routes/variant.schema";
import * as VariantFolderRoutes from "./routes/variantFolder.schema";
import * as WebhookLogsRoutes from "./routes/webhookLogs.schema";
import * as WorkspaceRoutes from "./routes/workspace.schema";
import * as WritingRoutes from "./routes/writing.schema";
import * as WSCompRoutes from "./routes/ws_comp.schema";

const routeImports = {
  api: ApiRoutes,
  changes: ChangesRoutes,
  comments: CommentsRoutes,
  comp: CompRoutes,
  componentFolder: ComponentFolderRoutes,
  connections: ConnectionsRoutes,
  dittoProject: DittoProjectRoutes,
  doc: DocRoutes,
  figma: FigmaRoutes,
  invite: InviteRoutes,
  jobs: JobsRoutes,
  library: Library,
  libraryComponent: LibraryComponentRoutes,
  libraryComponentFolder: LibraryComponentFolderRoutes,
  project: ProjectRoutes,
  slack: SlackRoutes,
  textItem: TextItemRoutes,
  trusted: TrustedRoutes,
  user: UserRoutes,
  variable: VariableRoutes,
  variableFolder: VariableFolderRoutes,
  variant: VariantRoutes,
  variantFolder: VariantFolderRoutes,
  webhookLogs: WebhookLogsRoutes,
  workspace: WorkspaceRoutes,
  writing: WritingRoutes,
  ws_comp: WSCompRoutes,
};

export type RequestExecutor = (args: { method: string; url: string; data: Record<string, unknown> }) => Promise<any>;

function deriveKeys(routeKey: string) {
  const capitalized = routeKey.charAt(0).toUpperCase() + routeKey.slice(1);
  return {
    methodKey: `${capitalized}Method`,
    pathKey: `${capitalized}Path`,
    zodKey: `Z${capitalized}Request`,
  };
}

function makeRouteFunction(
  requestFn: RequestExecutor,
  method: string,
  pathTemplate: string,
  zodSchema: Record<string, z.ZodTypeAny>
) {
  return (args: Record<string, unknown> = {}) => {
    const parsedParams = zodSchema.params ? zodSchema.params.parse(args) : {};
    const parsedQuery = zodSchema.query ? zodSchema.query.parse(args) : {};
    const parsedBody = zodSchema.body ? zodSchema.body.parse(args) : {};

    let finalPath = pathTemplate;

    // First pass: handle optional parameters
    const optionalParamRegex = /\/:([\w]+)\?/g;
    let match;
    while ((match = optionalParamRegex.exec(pathTemplate)) !== null) {
      const [fullMatch, paramName] = match;
      const value = parsedParams[paramName];
      if (!value || String(value).length === 0) {
        finalPath = finalPath.replace(fullMatch, "");
      } else {
        finalPath = finalPath.replace(fullMatch, `/${String(value)}`);
      }
    }

    // Second pass: handle required parameters
    for (const key of Object.keys(parsedParams)) {
      const pattern = `:${key}`;
      if (finalPath.includes(pattern)) {
        finalPath = finalPath.replace(pattern, String(parsedParams[key]));
      }
    }

    const queryKeys = Object.keys(parsedQuery);
    if (queryKeys.length > 0) {
      const searchParams = new URLSearchParams();

      for (const key of queryKeys) {
        const value = parsedQuery[key];

        if (value == null) continue;

        if (Array.isArray(value)) {
          value.forEach((item) => {
            if (item !== "") {
              searchParams.append(`${key}[]`, String(item));
            }
          });
        } else {
          if (value === "") {
            continue;
          }

          searchParams.append(key, String(value));
        }
      }

      const qs = searchParams.toString();
      if (qs) {
        finalPath += `?${qs}`;
      }
    }

    return requestFn({
      method,
      url: finalPath,
      data: parsedBody,
    });
  };
}

/**
 * The Ditto client is built up implicitly from the values exported from the files
 * in ./routes/*.schema.ts
 *
 * Each route should have four exports in its corresponding schema file. For a route
 * with name "exampleRoute":
 *   - ZExampleRouteRequest
 *   - ZExampleRouteResponse
 *   - ExampleRouteMethod
 *   - ExampleRoutePath
 *
 * These are used on both the value and type levels to construct the shared client.
 */
export function buildDittoClient(requestFn: RequestExecutor): IDittoClient {
  const resolvedModules = Object.entries(routeImports).map(([groupName, mod]) => {
    const groupProxy = new Proxy(
      {},
      {
        get(_target, routeKey: string) {
          const { methodKey, pathKey, zodKey } = deriveKeys(routeKey);

          const method = (mod as any)[methodKey];
          const path = (mod as any)[pathKey];
          const zodSchema = (mod as any)[zodKey];

          if (!method || !path || !zodSchema) {
            throw new Error(
              `Could not find method/path/Zod schema for "${String(routeKey)}" in "${groupName}" module.`
            );
          }

          return makeRouteFunction(requestFn, method, path, zodSchema);
        },
      }
    );

    return [groupName, groupProxy] as const;
  });

  const clientObject = resolvedModules.reduce((acc, [groupName, proxy]) => {
    acc[groupName] = proxy;
    return acc;
  }, {} as Record<string, any>);

  const topLevelProxy = new Proxy(clientObject, {
    get(target, prop: string) {
      if (!(prop in target)) {
        throw new Error(`No route group "${String(prop)}" found`);
      }
      return target[prop];
    },
  });

  return topLevelProxy as IDittoClient;
}
