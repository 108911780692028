import ProjectCard from "@/components/ProjectCard/ProjectCard";
import { IProjectSummary } from "@shared/types/http/project";
import classnames from "classnames";
import React from "react";
import { Draggable } from "react-beautiful-dnd";
import style from "./style.module.css";

interface IDraggableProjectCardProps {
  project: IProjectSummary;
  index: number;
  isDragDisabled: boolean;
  canRemoveFromFolder: boolean;
  handleRemoveFromFolder: (folderId: string, docId: string) => void;
}

function DraggableProjectCard(props: IDraggableProjectCardProps) {
  return (
    <Draggable draggableId={props.project._id} index={props.index} isDragDisabled={props.isDragDisabled}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className={classnames(style.fullCardHeight, style.responsiveCol, {
            [style.activeDrag]: snapshot.isDragging,
          })}
        >
          <ProjectCard
            onClick={(projectLink, e) => {
              // don't redirect if the card is being dragged
              if (snapshot.isDragging) {
                e.preventDefault();
                return;
              }
            }}
            className={style.fullHeight}
            options={{
              hideFolder: true,
              canRemoveFromFolder: props.canRemoveFromFolder,
              handleRemoveFromFolder: props.handleRemoveFromFolder,
            }}
            project={props.project}
          />
        </div>
      )}
    </Draggable>
  );
}

export default DraggableProjectCard;
