import Button from "@ds/atoms/Button";
import Text from "@ds/atoms/Text";
import TextInput from "@ds/atoms/TextInput";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import FolderIcon from "@mui/icons-material/FolderOpenOutlined";
import { useCallback } from "react";
import LockedFolderIcon from "../../../icons/LockedFolderIcon";
import ErrorMessage from "../ErrorMessage";
import style from "./style.module.css";

interface TitleProps {
  folder: { _id: string; name: string; invite_only: boolean } | null;
}
const Title = ({ folder }: TitleProps) => (
  <div className={style.title}>
    <DescriptionOutlinedIcon />
    Start a project with the new Ditto beta{folder ? " in " : ""}
    {folder && (
      <div className={style.folderIconWrapper}>
        {!folder.invite_only ? <FolderIcon className={style.icon} /> : <LockedFolderIcon className={style.icon} />}
        <div>{folder.name}</div>
      </div>
    )}
  </div>
);

interface BodyProps {
  projectName: string;
  importError: string;
  setProjectName: (val: string) => void;
  onHide: () => void;
  goToAccountUser: () => void;
  createDittoProject: () => void;
}
const Body = ({ projectName, setProjectName, importError, onHide, goToAccountUser, createDittoProject }: BodyProps) => {
  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === "Enter") {
        createDittoProject();
      }
    },
    [createDittoProject]
  );

  return (
    <div className={style.container}>
      <Text weight="strong">Project Title</Text>
      <TextInput
        autoFocus
        placeholder="Enter a name for your project…"
        value={projectName}
        onKeyDown={handleKeyDown}
        onChange={setProjectName}
      />
      {importError && (
        <ErrorMessage
          error={importError}
          onHide={onHide}
          goToAccountUser={goToAccountUser}
          showAdvancedBranchingModal={() => null}
        />
      )}
    </div>
  );
};

interface FooterProps {
  projectName: string;
  goBackToStart: () => void;
  createDittoProject: () => void;
  importError: string;
  isLoading: boolean;
}
const Footer = ({ projectName, goBackToStart, createDittoProject, importError, isLoading }: FooterProps) => (
  <div className={style.buttonWrapper}>
    <Button level="subtleAction" onClick={goBackToStart}>
      {"<-"} Back
    </Button>
    <Button disabled={projectName.length === 0 || Boolean(importError) || isLoading} onClick={createDittoProject}>
      Create project
    </Button>
  </div>
);
const NewDraftProject = ({ children }: { children: React.ReactNode }) => <>{children}</>;
NewDraftProject.Title = Title;
NewDraftProject.Body = Body;
NewDraftProject.Footer = Footer;
export default NewDraftProject;
