import React from "react";

export default function TextIcon(props: { style?: React.CSSProperties; className?: string }) {
  return (
    <svg
      style={props.style}
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 4H11.9995V4.79943H12V6.39932H11.2001V4.79995H8.39997V11.199H7.60003V4.79995H4.79995V6.39932H4V4.79995V4.79943V4ZM6.39958 11.2001H9.59938V12H6.39958V11.2001Z"
        fill="#A3A3A3"
      />
    </svg>
  );
}
