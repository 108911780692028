import classNames from "classnames";
import React, { useCallback, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import Badge, { BadgeProps } from "../../atoms/Badge";
import Tooltip from "../Tooltip";
import style from "./index.module.css";

interface CopyableBadgeProps extends Omit<BadgeProps, "children" | "className"> {
  /**
   * This text will be rendered in the badge and copied to the clipboard when the badge is clicked.
   */
  text: string;
  /**
   * A css class to be supplied to the Tooltip container
   */
  containerClassName?: string;
  /**
   * A css class to be supplied to the Badge component
   */
  badgeClassName?: string;
  /**
   * A callback function to run when the text is copied to the clipboard.
   */
  onCopy?: () => void;
}

export function CopyableBadge(props: CopyableBadgeProps) {
  const [copiedText, setCopiedText] = useState(false);
  const { text, containerClassName, badgeClassName, onCopy, ...badgeProps } = props;

  const handleCopied = useCallback(() => {
    setCopiedText(true);
    onCopy?.();
    setTimeout(() => {
      setCopiedText(false);
    }, 3000);
  }, [onCopy]);

  return (
    <Tooltip
      type="invert"
      content={copiedText ? "Copied!" : "Click to copy"}
      stayOpenOnTriggerClick
      stopPropagationOnTriggerClick
      triggerClassName={classNames(style.tooltipContainer, containerClassName)}
    >
      <CopyToClipboard text={text} onCopy={handleCopied}>
        <Badge className={classNames(style.badge, badgeClassName)} {...badgeProps}>
          {text}
        </Badge>
      </CopyToClipboard>
    </Tooltip>
  );
}

export default CopyableBadge;
