import { projectIdAtom } from "@/stores/Project";
import { useAtom } from "jotai";
import "jotai-devtools/styles.css";
import { Suspense } from "react";
import FilterBar from "./components/FilterBar";
import DialogueModalContainer from "./components/Modals/DialogueModalContainer";
import NavBar from "./components/NavBar";
import ProjectContent from "./components/ProjectContent";
import { WebsocketsHandler } from "./components/WebsocketsHandler";
import style from "./index.module.css";

function ProjectPage() {
  const [projectId, setProjectId] = useAtom(projectIdAtom);

  if (!projectId) return null;
  return (
    <div className={style.wrapper}>
      <WebsocketsHandler projectId={projectId} />
      <NavBar />
      <Suspense fallback={<FilterBar.Fallback />}>
        <FilterBar />
      </Suspense>
      <ProjectContent />
      <DialogueModalContainer />
    </div>
  );
}

export default ProjectPage;
