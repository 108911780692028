import * as WorkspaceService from "@/http/workspaceNew";
import client from "@shared/frontend/http/httpClient";
import { userHasSomeResourceAccess } from "@shared/frontend/userPermissionContext";
import { useEffect, useState } from "react";

interface Tag {
  name: string;
  count: number;
}

export default function useTagsPanel() {
  const [tags, setTags] = useState<Tag[]>([]);

  useEffect(function initialize() {
    fetchWorkspaceTags();
  }, []);

  const fetchWorkspaceTags = async () => {
    const data = await client.workspace.listWorkspaceTags();
    setTags(
      data.map((tag) => ({
        name: tag._id,
        count: tag.total,
      }))
    );
  };

  const canEditTags =
    userHasSomeResourceAccess("project_folder", "edit") || userHasSomeResourceAccess("component_folder", "edit");

  const editTag = async (currentTagName: string, newTagName: string) => {
    await WorkspaceService.editTag({
      currentTagName,
      newTagName,
    })[0];
  };

  const deleteTag = async (tagName: string) => {
    await WorkspaceService.deleteTag({ tagName })[0];
    setTags(tags.filter((t) => t.name !== tagName));
  };

  return {
    tags,
    canEditTags,
    editTag,
    deleteTag,
    fetchWorkspaceTags,
  };
}
