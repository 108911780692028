import useSegment from "@/hooks/useSegment";
import BetaButton from "@ds/atoms/BetaButton";
import Button from "@ds/atoms/Button";
import { DropdownMenuItemType } from "@ds/molecules/DropdownMenu";
import { Tooltip } from "@ds/molecules/Tooltip";
import { LEARN_MORE_URL } from "@shared/frontend/learnMoreLinks";
import { NS_BETA_INTEREST_GENERAL } from "@shared/segment-event-names";
import { useCallback, useMemo } from "react";
import LibraryBreadcrumbs from "./LibraryBreadcrumbs";
import style from "./style.module.css";

function LibraryNavBar() {
  const { track } = useSegment();

  const handleBetaButtonClick = useCallback(() => {
    window.open(LEARN_MORE_URL.BETA_BUTTON, "_blank");
    track({ event: NS_BETA_INTEREST_GENERAL, properties: { version: "NS" } });
  }, [track]);

  const dropdownMenuItems: DropdownMenuItemType[] = useMemo(() => {
    return [];
  }, []);

  return (
    <div className={style.navBarContainer}>
      <LibraryBreadcrumbs />
      <div className={style.rightSection}>
        <BetaButton leftText="BETA" rightText="Learn more" hoverRight onClick={handleBetaButtonClick} />
        <div className={style.rightSubgroup}>
          <Tooltip forceText={true} side="bottom" type="invert" content={"Coming soon..."}>
            <Button size="small" level="primary" disabled>
              Import
            </Button>
          </Tooltip>
        </div>
      </div>
    </div>
  );
}

export default LibraryNavBar;
