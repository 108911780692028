import { IBaseDeveloperIdConfig } from "@shared/types/Workspace";

export type ValidateDeveloperIdResult =
  | { isValid: true }
  | { isValid: false; type: "empty"; message: string }
  | { isValid: false; type: "maxLength"; message: string; length: number }
  | { isValid: false; type: "invalidChars"; message: string; invalidChars: string[] };

/**
 * Validates a potential developer id string based on the provided config.
 * @returns If valid: { isValid: true }
 * @returns If invalid: { isValid: false, type: string; message: string } plus possible additional metadata
 * @param developerId - The developer ID to validate.
 * @param config - The configuration object containing validation rules.
 */
export const validateDeveloperId = (developerId: string, config: IBaseDeveloperIdConfig): ValidateDeveloperIdResult => {
  if (!developerId) {
    return { isValid: false, type: "empty", message: "may not be empty" };
  }
  if (config.maxLength && developerId.length > config.maxLength) {
    return {
      isValid: false,
      type: "maxLength",
      message: `may not exceed ${config.maxLength} characters`,
      length: developerId.length,
    };
  }
  if (config.acceptedCharsPattern) {
    const disallowedCharsRegex = new RegExp(`[^${config.acceptedCharsPattern}]`, "g");
    const invalidMatches = developerId.match(disallowedCharsRegex);
    if (invalidMatches) {
      const invalidChars: string[] = [];
      const dedupedMatches = Array.from(new Set(invalidMatches));
      dedupedMatches.forEach((char) => {
        switch (char) {
          case " ":
            invalidChars.push("spaces");
            break;
          case ",":
            invalidChars.push("commas");
            break;
          case ".":
            invalidChars.push("periods");
            break;
          default:
            invalidChars.push(char);
        }
      });
      return {
        isValid: false,
        type: "invalidChars",
        invalidChars,
        message: `may not contain ${arrayToString(invalidChars, { conjunction: "or" })}`,
      };
    }
  }
  return { isValid: true };
};

/**
 * Takes an array of strings and converts it to a human-readable string.
 * @param arr - The array of strings to convert.
 * @param options.conjunction - Whether to use "and" or "or" between the last two items (default: "and").
 * @param options.oxfordComma - Whether to use the Oxford comma before the last item (default: false).
 *
 * Examples:
 * - `arrayToString(["apple", "banana"])` returns "apple and banana"
 * - `arrayToString(["apple", "banana", "orange"], { conjunction: "or" })` returns "apple, banana or orange"
 * - `arrayToString(["apple", "banana", "orange"], { oxfordComma: true })` returns "apple, banana, and orange"
 */
export const arrayToString = (
  arr: string[],
  { conjunction = "and", oxfordComma = false }: { conjunction?: "and" | "or"; oxfordComma?: boolean } = {}
): string => {
  if (arr.length === 0) return "";
  if (arr.length === 1) return arr[0];
  if (arr.length === 2) return `${arr[0]} ${conjunction} ${arr[1]}`;
  return `${arr.slice(0, -1).join(", ")}${oxfordComma ? ", " : " "}${conjunction} ${arr[arr.length - 1]}`;
};
