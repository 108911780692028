import classNames from "classnames";
import React from "react";
import { useNativeProps } from "../../useNativeProps";
import { Dot } from "../Dot";
import Icon, { IProps as IconProps } from "../Icon";
import Text from "../Text";
import style from "./index.module.css";

export interface IProps {
  style?: React.CSSProperties;
  className?: string;
  defaultValue?: string;
  value?: string;
  autoFocus?: boolean;

  /**
   * Optional ref for the input.
   */
  inputRef?: React.RefObject<HTMLInputElement>;

  /**
   * Optional style presets.
   */
  fontStyle?: "default" | "code";

  /**
   * You can pass in an icon like Icon={\<CheckIcon \/>}.
   * You can also pass in a straight-up SVG element like Icon={\<svg>...\</svg>}.
   * The icon will inherit the color of the label, but can be overridden by passing in the iconColor prop.
   */
  leadingIcon?: React.ReactNode;
  trailingIcon?: React.ReactNode;
  iconColor?: string;
  placeholder?: string;
  size?: "base" | "small";
  iconSize?: IconProps["size"];
  disabled?: boolean;
  expand?: "inline" | "block";
  /**
   * Determines the color of error message styling (outline and message color).
   * Defaults to "danger".
   */
  errorLevel?: "danger" | "warning";
  /**
   * A string to display as an error or warning message, below the input.
   */
  errorMessage?: string;
  /**
   * If included with an errorMessage, will render the error as {{message}} * {{errorMessageAction}}
   */
  errorMessageAction?: {
    text: string;
    onClick: (event?: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
  };

  onChange?: (val: string) => void;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
}

export function TextInput({
  value,
  size,
  leadingIcon,
  trailingIcon,
  iconColor,
  expand,
  inputRef,
  fontStyle = "default",
  iconSize: iconSizeProp,
  errorLevel = "danger",
  errorMessage,
  errorMessageAction,
  className,
  onChange,
  onBlur,
  onKeyDown,
  ...props
}: IProps) {
  // normal input gets a small icon; small input gets an xs icon
  // can be overriden explicitly via prop
  const iconSize = iconSizeProp ?? (size === "small" ? "xs" : "small");
  const errorSize = size === "small" ? "micro" : "small";

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    onChange?.(e.target.value);
  }

  function handleKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.defaultPrevented === false) {
      // When we're in the Figma plugin, certain keyboard shortcuts are broken. The KeyboardEvent still comes
      // through, but something about the sandbox environment is blocking the default behavior.
      if (e.metaKey || e.ctrlKey) {
        switch (e.key) {
          case "a":
            e.preventDefault();
            e.currentTarget.select();
            break;
          case "z":
            e.preventDefault();
            if (e.shiftKey) {
              e.currentTarget.focus();
              document.execCommand("redo");
            } else {
              e.currentTarget.focus();
              document.execCommand("undo");
            }
            break;
        }
      }
    }

    onKeyDown?.(e);
  }

  const nativeProps = useNativeProps<HTMLInputElement, typeof props>(props, {
    placeholder: true,
    disabled: true,
  });

  return (
    <>
      <div
        style={props.style}
        className={classNames(style.inputWrapper, {
          [style.block]: expand === "block",
          [style[`size-${size}`]]: size,
        })}
      >
        {leadingIcon && (
          <Icon
            Icon={leadingIcon}
            className={classNames(style.icon, style.leading)}
            size={iconSize}
            style={{ color: iconColor || undefined }}
          />
        )}

        <input
          className={classNames(style.TextInputWrapper, className, {
            [style.hasLeadingIcon]: !!leadingIcon,
            [style.hasTrailingIcon]: !!trailingIcon,
            [style[`error-${errorLevel}`]]: !!errorMessage,
            [style.disabled]: props.disabled ?? false,
            [style[`fontStyle-${fontStyle}`]]: true,
          })}
          disabled={props.disabled ?? false}
          data-testid="text-input"
          {...nativeProps}
          value={value}
          onBlur={onBlur}
          onKeyDown={handleKeyDown}
          onChange={handleChange}
          ref={inputRef}
          placeholder={props.placeholder}
          autoFocus={props.autoFocus}
        />

        {trailingIcon && (
          <Icon
            Icon={trailingIcon}
            className={classNames(style.icon, style.trailing)}
            size={iconSize}
            style={{ color: iconColor || undefined }}
          />
        )}
      </div>
      {errorMessage && (
        <div className={style[`errorMessageContainer-${errorSize}`]}>
          <Text color={errorLevel} size={errorSize}>
            {errorMessage}
          </Text>

          {errorMessageAction && (
            <>
              <Dot size={3} color="icon-secondary" className={style.errorMessageDot} />
              <Text color="action" size={errorSize} asLink onClick={errorMessageAction.onClick}>
                {errorMessageAction.text}
              </Text>
            </>
          )}
        </div>
      )}
    </>
  );
}

export default TextInput;
