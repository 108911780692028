import { INavMessageItem } from "@/stores/Project";
import { ITextProps } from "@ds/atoms/Text";
import NavItem from "@ds/molecules/NavItem";

export default function MessageNavItem(props: {
  item: INavMessageItem;
  isNested?: boolean;
  labelOptions?: Partial<ITextProps>;
}) {
  return (
    <NavItem
      id={props.item._id}
      type="item"
      label={props.item.message}
      labelOptions={props.labelOptions}
      isNested={props.isNested}
    />
  );
}
