import { z } from "zod";
import { ZLibraryItem } from "../../types/Library";

export const ZGetLibraryStructureRequest = {
  params: z.object({
    folderId: z.string().optional(),
  }),
  query: z.object({
    statuses: z.array(z.string()).optional(),
    assignees: z.array(z.string()).optional(),
    tags: z.array(z.string()).optional(),
    search: z.string().optional(),
  }),
};
export const ZGetLibraryStructureResponse = z.array(ZLibraryItem);
export const GetLibraryStructureMethod = "GET";
export const GetLibraryStructurePath = "/library/:folderId?";
