import useAutoScroll from "@/hooks/useAutoScroll";
import { libraryComponentFamilyAtom } from "@/stores/Components";
import {
  componentIsSelectedAtomFamily,
  draggableItemsForComponentItemAtom,
  handleComponentClickActionAtom,
  libraryDraggedSelectionAtom,
  reorderLibraryComponentsActionAtom,
  scrollToComponentIdActionAtom,
} from "@/stores/Library";
import { DragLocation } from "@ds/atoms/DragAndDroppable";
import LibraryComponentIcon from "@ds/icons/LibraryComponentIcon";
import NavItem from "@ds/molecules/NavItem";
import { ILibraryComponentItem } from "@shared/types/Library";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { useAtomCallback } from "jotai/utils";
import { memo, useCallback } from "react";
import { DragStartEvent, DragTypes } from "react-aria";
import { z } from "zod";
import { libraryComponentListScrollRefAtom } from "../LibraryItemsList";
import { libraryLeftSidebarScrollRefAtom } from "../LibraryLeftSidebar";
import style from "./style.module.css";

const allowedItemKeys = { "ditto/componentItem": z.string() };

const LibraryComponentNavItem = memo(function LibraryComponentNavItem(props: {
  item: ILibraryComponentItem;
  disableCustomPreview?: boolean;
}) {
  const component = useAtomValue(libraryComponentFamilyAtom(props.item._id));
  const isSelected = useAtomValue(componentIsSelectedAtomFamily(props.item._id));

  const libraryLeftSideBarScrollRef = useAtomValue(libraryLeftSidebarScrollRefAtom);
  const libraryComponentListScrollRef = useAtomValue(libraryComponentListScrollRefAtom);

  const [libraryDraggedSelection, setLibraryDraggedSelection] = useAtom(libraryDraggedSelectionAtom);

  const handleComponentClickAction = useSetAtom(handleComponentClickActionAtom);
  const reorderLibraryComponentsAction = useSetAtom(reorderLibraryComponentsActionAtom);
  const setScrollToComponentIdAction = useSetAtom(scrollToComponentIdActionAtom);
  const scrollProps = useAutoScroll(libraryLeftSideBarScrollRef, libraryComponentListScrollRef);

  const handleDrop = useCallback(
    (componentIds: string[], dragLocation: DragLocation) => {
      reorderLibraryComponentsAction([
        {
          componentIds,
          before: dragLocation === "above" ? props.item._id : undefined,
          after: dragLocation === "below" ? props.item._id : undefined,
          folderId: component?.folderId,
        },
      ]);

      // request animation frame to ensure we scroll to newly updated component location
      requestAnimationFrame(() => setScrollToComponentIdAction(componentIds[0]));
    },
    [component?.folderId, props.item._id, reorderLibraryComponentsAction, setScrollToComponentIdAction]
  );

  const getDraggableItems = useAtomCallback(
    useCallback((get) => get(draggableItemsForComponentItemAtom(props.item._id)), [props.item._id])
  );

  function getDropOperation(types: DragTypes) {
    return types.has("ditto/componentItem") ? "move" : "cancel";
  }

  const handleComponentClick = useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      // Must stop event propagation to prevent global deselect handler from clearing selection
      event.stopPropagation();

      handleComponentClickAction({
        event,
        componentId: props.item._id,
        skipInlineEditing: true,
      });
    },
    [handleComponentClickAction, props.item._id]
  );

  const handleDragStart = useCallback(
    function _handleDragStart(event: DragStartEvent) {
      if (!isSelected) {
        handleComponentClickAction({
          event,
          componentId: props.item._id,
          skipInlineEditing: true,
        });
      }

      setLibraryDraggedSelection({ _id: props.item._id, type: "component", origin: "left-sidebar" });
    },
    [handleComponentClickAction, setLibraryDraggedSelection, isSelected, props.item._id]
  );

  const handleDragEnd = useCallback(() => setLibraryDraggedSelection(null), [setLibraryDraggedSelection]);

  if (!component) return null;

  return (
    <NavItem
      id={props.item._id}
      type="item"
      label={component.name}
      labelOptions={{ color: "purple" }}
      icon={<LibraryComponentIcon className={style.componentIcon} />}
      dragAndDrop={{
        ...scrollProps,
        allowedItemKeys,
        selectionType: libraryDraggedSelection?.type ?? "none",
        getDraggableItems,
        onDrop: handleDrop,
        onDragStart: handleDragStart,
        onDragEnd: handleDragEnd,
        getDropOperation,
      }}
      selected={isSelected}
      onClick={handleComponentClick}
      showDropIndicators
      disableCustomPreview={props.disableCustomPreview}
      dropTargetType="component"
    />
  );
});

export default LibraryComponentNavItem;
