import ButtonPrimary from "@/components/button/buttonprimary";
import ModalInfoAlert from "@/components/modal-info-alert";
import CloseIcon from "@mui/icons-material/Close";
import { VariantSelect } from "@shared/frontend/VariantSelect";
import { ALL_VARIANTS_FOLDER_ID } from "@shared/frontend/useVariantSelect";
import classNames from "classnames";
import React, { useState } from "react";
import Alert from "react-bootstrap/Alert";
import BootstrapModal from "react-bootstrap/Modal";
import style from "./style.module.css";

const VARIANT_NAME_TAKEN_ERROR_MSG = "Variant name is already taken.";

const VariantCreateModal = ({
  selectedVariantFolder,
  folders,
  onChange,
  onHide,
  uniqueVariantNames,
  handleCreateNewVariant,
}) => {
  const [name, setName] = useState("");
  function onNameChange(e) {
    const value = e.target.value;
    setName(value);
    setErrorMessage("");
  }

  const [description, setDescription] = useState("");
  function onDescriptionChange(e) {
    const value = e.target.value;
    setDescription(value);
  }

  const [errorMessage, setErrorMessage] = useState("");
  const [isSaving, setIsSaving] = useState(false);

  const addNewVariant = async () => {
    setIsSaving(true);

    const res = await handleCreateNewVariant(
      { name, description },
      selectedVariantFolder._id === ALL_VARIANTS_FOLDER_ID ? null : selectedVariantFolder._id
    );

    if (res !== "success") {
      setErrorMessage(res);
      setIsSaving(false);
      return;
    }

    onHide();
  };

  const hasValidFolderLoaded = (folders) => {
    const filteredFolders = folders.filter((folder) => !folder.isSample);
    return !(filteredFolders.length === 1 && filteredFolders[0]._id === "all-variants");
  };

  return (
    <div>
      <BootstrapModal show onHide={onHide} centered>
        <BootstrapModal.Header className={style.modalHeader}>
          <BootstrapModal.Title className={style.title}>Create Variant</BootstrapModal.Title>
          <CloseIcon className={style.close} onClick={onHide} />
        </BootstrapModal.Header>
        <BootstrapModal.Body className={style.body}>
          <ModalInfoAlert
            className={style.infoAlert}
            text="Want tips on how you can use variants in Ditto for explorations, user groups, or localization?"
            linkUrl="https://www.dittowords.com/docs/variants"
          />
          <p className={style.createNewMessage}>Create a new variant to use in your team&apos;s projects.</p>
          <label className={style.label}>Variant</label>
          <input placeholder="Insert variant name" value={name} onChange={onNameChange} />
          {errorMessage && (
            <Alert variant="danger" className={style.duplicateVariantNameAlert}>
              {errorMessage}
            </Alert>
          )}
          <>
            <label className={classNames([style.label, style.descriptionLabel])}>Description</label>
            <input placeholder="Optional" value={description} onChange={onDescriptionChange} />
          </>
          <div className={style.createButtonWrapper}>
            {hasValidFolderLoaded(folders) && (
              <VariantSelect
                selectedVariantFolder={selectedVariantFolder}
                options={folders
                  .filter((f) => !f.isSample)
                  .map((folder) => ({
                    id: folder._id,
                    name: folder.name,
                  }))}
                onChange={onChange}
                className={style.variantSelect}
                text="Creating in"
              />
            )}
            <ButtonPrimary
              disabled={errorMessage || !name || isSaving}
              text={!isSaving ? "Create Variant" : "Creating Variant..."}
              onClick={() => addNewVariant()}
            />
          </div>
        </BootstrapModal.Body>
      </BootstrapModal>
    </div>
  );
};

export default VariantCreateModal;
