import { projectIdAtom, projectNameAtom } from "@/stores/Project";
import Button from "@ds/atoms/Button";
import Text from "@ds/atoms/Text";
import TextInput from "@ds/atoms/TextInput";
import Modal from "@ds/molecules/Modal";
import client from "@shared/frontend/http/httpClient";
import { showToastActionAtom } from "@shared/frontend/stores/Toast";
import logger from "@shared/utils/logger";
import { getDefaultStore, useAtomValue, useSetAtom } from "jotai";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import style from "./style.module.css";

interface IDeleteProjectModalProps {
  open: boolean;
  onOpenChange: (isOpen: boolean) => void;
}

function DeleteProjectModal({ open, onOpenChange }: IDeleteProjectModalProps) {
  const projectId = useAtomValue(projectIdAtom);
  const projectName = useAtomValue(projectNameAtom);
  const [inputValue, setInputValue] = useState("");
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const showProjectToast = useSetAtom(showToastActionAtom);
  const showGlobalToast = useSetAtom(showToastActionAtom, { store: getDefaultStore() });
  const inputRef = useRef<HTMLInputElement>(null);
  const history = useHistory();

  const canDelete = useMemo(() => {
    return !isDeleting && inputValue === projectName;
  }, [inputValue, isDeleting, projectName]);

  const closeAndClearModal = useCallback(() => {
    onOpenChange(false);
    setInputValue("");
    setIsDeleting(false);
  }, [onOpenChange]);

  const handleOpenChange = useCallback(
    (isOpen: boolean) => {
      if (isOpen) {
        onOpenChange(true);
        inputRef.current?.focus();
      } else {
        closeAndClearModal();
      }
    },
    [onOpenChange, closeAndClearModal]
  );

  const handleDeleteProject = useCallback(async () => {
    if (!canDelete || !projectId) return;

    try {
      setIsDeleting(true);
      await client.dittoProject.deleteProject({ projectId });
      closeAndClearModal();
      history.push("/projects");
      // Need to explicitly set the toast on the global store, so it can show outside the project provider after redirecting
      showGlobalToast({ message: `Project "${projectName}" successfully deleted 🎉` });
    } catch (error) {
      logger.error("Error deleting project", { context: { projectId } }, error);
      showProjectToast({ message: "Something went wrong, please try again later." });
    }
    setIsDeleting(false);
  }, [canDelete, projectId, closeAndClearModal, history, showGlobalToast, projectName, showProjectToast]);

  const onInputChange = useCallback((value: string) => {
    setInputValue(value);
  }, []);

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Enter" && canDelete) {
        handleDeleteProject();
      }
    },
    [canDelete, handleDeleteProject]
  );

  return (
    <Modal
      headline="Delete project?"
      description="Permanently delete this project"
      open={open}
      onOpenChange={handleOpenChange}
    >
      <div className={style.contentContainer}>
        <Text color="secondary" inline>
          This will permanently delete the{" "}
          <Text color="primary" weight="medium" inline>
            {projectName}
          </Text>{" "}
          project, and all of its text items, comments, and history. Workspace components and variables will not be
          affected. This can’t be undone.
        </Text>
        <div className={style.inputSection}>
          <Text color="primary" size="small" weight="strong">
            Enter "{projectName}" to continue
          </Text>
          <TextInput
            inputRef={inputRef}
            value={inputValue}
            onChange={onInputChange}
            onKeyDown={handleKeyDown}
            disabled={isDeleting}
          />
        </div>
        <div className={style.footer}>
          <Button level="outline" onClick={closeAndClearModal}>
            Cancel
          </Button>
          <Button level="danger" disabled={!canDelete} onClick={handleDeleteProject}>
            Delete project
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default DeleteProjectModal;
