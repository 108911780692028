import TextareaAutosize from "@mui/material/TextareaAutosize";
import classNames from "classnames";
import React, { useCallback } from "react";
import style from "./index.module.css";

interface IProps {
  content?: string;
  placeholder?: string;
  disabled?: boolean;
  minRows?: number;
  /** If set to true, hitting Enter (but not shift+enter) will blur the input area */
  blurOnEnter?: boolean;
  onChange?: (value: string) => void;
  onFocus?: React.FocusEventHandler<HTMLTextAreaElement>;
  onBlur?: React.FocusEventHandler<HTMLTextAreaElement>;
  size?: "base" | "small";
  className?: string;
  style?: React.CSSProperties;
}

export function TextAreaInput(props: IProps) {
  const { onChange, onFocus, onBlur } = props;

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      if (onChange) onChange(e.target.value);
    },
    [onChange]
  );

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (props.blurOnEnter && e.key === "Enter" && !e.shiftKey) {
        e.currentTarget.blur();
      }
    },
    [props.blurOnEnter]
  );

  return (
    <TextareaAutosize
      value={props.content}
      className={classNames(style.textAreaInput, props.className, {
        [style[`size-${props.size}`]]: props.size,
      })}
      placeholder={props.placeholder}
      onChange={handleChange}
      onFocus={onFocus}
      onBlur={onBlur}
      onKeyDown={handleKeyDown}
      disabled={props.disabled}
      minRows={props.minRows}
    />
  );
}

export default TextAreaInput;
