import useSegment from "@/hooks/useSegment";
import {
  libraryComponentFolderFamilyAtom,
  libraryComponentFoldersBreadcrumbsAtomFamily,
  libraryComponentFoldersListAtom,
  refreshLibraryComponentFoldersListAtom,
} from "@/stores/ComponentFolders";
import { linkComponentActionAtom, unwrappedSuggestedComponentIdsAtom } from "@/stores/ComponentLinkingFlow";
import { libraryComponentFamilyAtom } from "@/stores/Components";
import { locationAtom } from "@/stores/Location";
import { derivedSelectedTextItemsAtom } from "@/stores/ProjectSelection";
import { allComponentTagsAtom, projectsAtom, usersByIdAtom } from "@/stores/Workspace";
import Text from "@ds/atoms/Text";
import { CompactTextEntityList, useCompactTextEntityList } from "@ds/organisms/CompactTextEntityList";
import { createComponentEntityListAtom } from "@ds/organisms/CompactTextEntityList/data";
import useStableRef from "@shared/frontend/hooks/useStableRef";
import * as SegmentEvents from "@shared/segment-event-names";
import { Atom, atom, useAtomValue, useSetAtom } from "jotai";
import { soon } from "jotai-derive";
import { useCallback } from "react";
import { useHistory } from "react-router-dom";
import style from "./style.module.css";

export const InTheLibraryLink = (props: { onClick: () => void }) => {
  return (
    <Text inline color="action" size="small" onClick={props.onClick}>
      in the library.
    </Text>
  );
};

export default function LinkComponent() {
  const { track } = useSegment();
  const history = useHistory();
  const setLocation = useSetAtom(locationAtom);
  const selectedTextItems = useAtomValue(derivedSelectedTextItemsAtom);

  const { current: getEntityListAtom } = useStableRef(() =>
    createComponentEntityListAtom({
      libraryComponentFamilyAtom,
      usersByIdAtom,
    })
  );

  const onOpenLibraryClick = useCallback(() => {
    history.push("/library");
    setLocation({ pathname: "/library" }, { replace: true });
  }, [history, setLocation]);

  const getFoldersListAtom = useCallback(
    (dependencyAtoms: { selectedFolderIdAtom: Atom<string | null> }) =>
      atom(
        (get) =>
          soon(get(libraryComponentFoldersListAtom), (folders) => {
            const selectedFolderId = get(dependencyAtoms.selectedFolderIdAtom);
            return folders.filter((folder) => folder.parentId === selectedFolderId);
          }),
        (_get, set) => set(refreshLibraryComponentFoldersListAtom)
      ),
    []
  );

  const props = useCompactTextEntityList({
    searchPlaceholder: "Search in the library...",
    emptyStates: {
      whenFiltering: "No components found for the selected filters",
      whenInFolder: (
        <span>
          This folder has no components yet. Publish a text item from a project or create a new component{" "}
          <InTheLibraryLink onClick={onOpenLibraryClick} />
        </span>
      ),
      default: (
        <span>
          Your library has no components yet. Publish a text item from a project or create a new component{" "}
          <InTheLibraryLink onClick={onOpenLibraryClick} />
        </span>
      ),
    },
    getEntityListAtom,
    getFoldersListAtom,
    suggestedItemIdsAtom: unwrappedSuggestedComponentIdsAtom,
    selectedTextItems,
    itemActionClickAtom: linkComponentActionAtom,
    actionText: "Link",
    tagsAtom: allComponentTagsAtom,
    listItemFolderFamilyAtom: libraryComponentFolderFamilyAtom,
    libraryComponentFamilyAtom,
    usersByIdAtom,
    projectsAtom,
    libraryComponentFoldersBreadcrumbsAtomFamily,
    onComponentActionClick: (componentId: string) => {
      track({
        event: SegmentEvents.COMPONENT_LINKING_SUGGESTION_ACCEPTED,
        properties: {
          version: "NS",
          for_imported_text: true,
          num_instances: selectedTextItems[0]?.integrations.figmaV2?.instances?.length ?? 0,
        },
      });
    },
  });

  return (
    <div className={style.linkComponentContainer}>
      <CompactTextEntityList {...props} />
    </div>
  );
}

export function LinkComponentFallback() {
  return (
    <div className={style.linkComponentContainer}>
      <CompactTextEntityList.Fallback />
    </div>
  );
}

LinkComponent.Fallback = LinkComponentFallback;
