import { ITextItem } from "@shared/types/TextItem";
import { atom } from "jotai";
import type { SetRequired } from "type-fest";
import { buildDittoClient } from "../../client/buildDittoClient";
import batchedAsyncAtomFamily from "./batchedAsyncAtomFamily";

export function createTextItemsStore(client: ReturnType<typeof buildDittoClient>) {
  /**
   * The source of truth for all text items.
   */
  const { familyAtom: textItemFamilyAtom, resetAtom: resetTextItemFamilyAtomActionAtom } =
    batchedAsyncAtomFamily<ITextItem>({
      asyncFetchRequest: async (get, ids) => {
        const response = await client.textItem.getTextItems({
          ids,
        });

        return response.textItems;
      },
      getId: (item) => item._id,
      debugPrefix: "Text Item",
    });

  /**
   * Simple action that updates the text item family atom with the new data.
   * Useful when you don't know the text item id where you're calling `useSetAtom`
   * @param updatedData the values to update. Must include `_id` to identify the text item.
   */
  const updateTextItemFamilyActionAtom = atom(
    null,
    async (get, set, updatedData: SetRequired<Partial<ITextItem>, "_id">) => {
      set(textItemFamilyAtom(updatedData._id), (prev) => ({ ...prev, ...updatedData }));
    }
  );

  return {
    textItemFamilyAtom,
    resetTextItemFamilyAtomActionAtom,
    updateTextItemFamilyActionAtom,
  };
}
