import Icon from "@ds/atoms/Icon";
import LibraryComponentIcon from "@ds/icons/LibraryComponentIcon";
import DescriptionIcon from "@mui/icons-material/Description";
import ImportContactsIcon from "@mui/icons-material/ImportContacts";
import classNames from "classnames";
import React from "react";
import { ISelectedVariant } from "./EditVariant";
import style from "./VariantInstance.module.css";

interface IProps {
  className?: string;
  instance: ISelectedVariant["instances"][number];
  onClickProjectInstance: (projectId: string, version: 1 | 2) => void;
  onClickComponentInstance: (componentId: string) => void;
  onClickLibraryComponentInstance: (folderId: string | null, libraryComponentId: string) => void;
}

export const VariantInstance = (props: IProps) => {
  return (
    <div className={classNames([style.wrapper, props.className])}>
      <VariantInstanceContent {...props} />
    </div>
  );
};

const VariantInstanceContent = (props: IProps) => {
  if (props.instance.type === "project") {
    const projectId = props.instance.projectId;
    return (
      <div className={style.projectInstance} onClick={() => props.onClickProjectInstance(projectId, 1)}>
        <div className={style.left}>
          <DescriptionIcon className={style.projectIcon} />
          <span className={style.projectName}>{props.instance.projectName}</span>
        </div>
        <div className={classNames([style.right, style.projectGroups])}>
          ({props.instance.groupsAttached}/{props.instance.groupsTotal} groups)
        </div>
      </div>
    );
  }

  if (props.instance.type === "NSproject") {
    const projectId = props.instance.projectId;
    return (
      <div className={style.projectInstance} onClick={() => props.onClickProjectInstance(projectId, 2)}>
        <div className={style.left}>
          <DescriptionIcon className={style.projectIcon} />
          <span className={style.projectName}>{props.instance.projectName}</span>
        </div>
      </div>
    );
  }

  if (props.instance.type === "component") {
    const componentId = props.instance.componentId;
    return (
      <div className={style.componentInstance} onClick={() => props.onClickComponentInstance(componentId)}>
        <ImportContactsIcon className={style.componentIcon} />
        <span className={style.componentName}>{props.instance.componentName}</span>
      </div>
    );
  }

  if (props.instance.type === "libraryComponent") {
    const libraryComponentId = props.instance._id;
    const folderId = props.instance.folderId;
    return (
      <div
        className={style.componentInstance}
        onClick={() => props.onClickLibraryComponentInstance(folderId, libraryComponentId)}
      >
        <Icon Icon={<LibraryComponentIcon />} className={style.libraryComponentIcon} />
        <span className={style.componentName}>{props.instance.name}</span>
      </div>
    );
  }

  throw new Error("Invalid instance type");
};
