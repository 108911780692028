import Add from "@mui/icons-material/Add";
import React from "react";
import Text from "../../atoms/Text";
import DropdownCombobox from "../../molecules/DropdownCombobox";
import style from "./index.module.css";

interface IOption<ValueType extends string> {
  value: ValueType;
  label: string;
}

interface IProps<T extends string> {
  options: IOption<T>[];
  selectedOptions: IOption<T>[];
  setSelectedOptions: (options: IOption<T>[]) => void;
  removeFilter?: () => void;

  size?: "base" | "small";
  Icon?: React.ReactNode;
  className?: string;
  popoverClassName?: string;
  style?: React.CSSProperties;
  hideToggledState?: boolean;
  alignPopover?: "start" | "end" | "center";
}

export function AddFiltersDropdown<T extends string>(props: IProps<T>) {
  return (
    <DropdownCombobox
      className={props.className}
      size={props.size}
      asToggle
      hideToggledState={props.hideToggledState}
      forceToggledDisplay={props.selectedOptions.length > 0}
      multiple
      showTextInput={false}
      options={props.options}
      selectedOptions={props.selectedOptions}
      onChange={props.setSelectedOptions}
      OptionComponent={OptionComponent}
      TriggerComponent={TriggerComponent}
      onRemoveFilter={props.removeFilter}
      triggerLeadingIcon={props.Icon ?? <Add />}
      closeOnSelect
      alignPopover={props.alignPopover}
      popoverClassName={props.popoverClassName}
    />
  );
}

interface ITriggerProps<T extends string> {
  selectedOptions: IOption<T>[];
  size?: "base" | "small";
  hideToggledState?: boolean;
}
function TriggerComponent<T extends string>(props: ITriggerProps<T>) {
  if (props.hideToggledState) {
    return (
      <Text size={props.size === "small" ? "micro" : "small"} color="primary">
        Filter
      </Text>
    );
  }

  return (
    <Text size={props.size === "small" ? "micro" : "small"} color="primary">
      {props.selectedOptions.length === 0 && "Filter"}
      {props.selectedOptions.length > 0 &&
        `${props.selectedOptions.length} filter${props.selectedOptions.length === 1 ? "" : "s"} applied`}
    </Text>
  );
}

interface IOptionProps<T extends string> {
  option: IOption<T>;
  query: string;
  selected: boolean;
}
function OptionComponent<T extends string>(props: IOptionProps<T>) {
  return (
    <Text color="primary" size="small" className={style.label}>
      <span>{props.option.label}</span>
    </Text>
  );
}

export default AddFiltersDropdown;
