import React from "react";
import { IUser } from "../../../../shared/types/User";
import Button from "../../atoms/Button";
import Text from "../../atoms/Text";
import CommentEditor from "../CommentEditor";
import style from "./index.module.css";

/**
 * Renders a comment section for use in the Edit panel:
 * - Provides the ability to create a new comment.
 * - Renders the most recent unresolved comment, if one exists.
 *
 * Currently, always renders a horizontal border below the section. This can be updated to be conditional if needed.
 */

interface IProps {
  selectedItemId: string; // Id of the item being commented on
  mostRecentThreadId: string | null; // Id of the most recent unresolved comment thread on this item, if one exists
  mentionableUsers: IUser[]; // The list of workspace users that can be mentioned in a comment
  CommentThreadComponent: React.FC<{ commentThreadId: string }>; // The React Component to use for rendering the most recent comment thread
  onTypeComment?: (contentToSave: string, mentionedUsersInfo: IUser[]) => void; // Optionally, a handler for the comment editor's onChange event
  handlePostComment: (commentText: string, mentionedUsers: IUser[], resetEditorState: () => void) => Promise<void>; // Handler for posting the comment
  handleShowAll: () => void; // Handler for the show all comments button
}

export function CommentSection({
  selectedItemId,
  mostRecentThreadId,
  mentionableUsers,
  CommentThreadComponent,
  onTypeComment,
  handlePostComment,
  handleShowAll,
}: IProps) {
  if (!selectedItemId) {
    return <></>;
  }

  return (
    <div className={style.commentsContainer}>
      <div className={style.commentEditorContainer}>
        <Text size="small" color="primary" weight="strong">
          Comment
        </Text>
        <CommentEditor
          key={selectedItemId} // Resets the editor when selectedItemId changes
          placeholderText="Add a comment..."
          submitButtonText="Add comment"
          onChange={onTypeComment}
          onSave={handlePostComment}
          mentionableUsers={mentionableUsers}
        />
      </div>
      {!!mostRecentThreadId && (
        <div className={style.mostRecentContainer}>
          <div className={style.mostRecentHeader}>
            <Text size="small" weight="medium" color="secondary">
              Most recent
            </Text>
            <Button size="micro" level="subtle" onClick={handleShowAll}>
              See all comments
            </Button>
          </div>
          <CommentThreadComponent commentThreadId={mostRecentThreadId} />
        </div>
      )}
    </div>
  );
}

export default CommentSection;
