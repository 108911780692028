import { getUsers, getVariants } from "@/http/workspaceNew";
import client from "@shared/frontend/http/httpClient";
import asyncMutableDerivedAtom from "@shared/frontend/stores/asyncMutableDerivedAtom";
import { IUser } from "@shared/types/User";
import { BASE_VARIANT_ID } from "@shared/types/Variant";
import { atom } from "jotai";
import { derive } from "jotai-derive";
import { atomFamily, atomWithRefresh, unwrap } from "jotai/utils";

export const usersAtom = atomWithRefresh(() => getUsers()[0].then((r) => r.data));
export const deferredUsersAtom = derive([usersAtom], (users) => users);
deferredUsersAtom.debugLabel = "deferredUsersAtom";

/**
 * user referenced by their _id (ObjectId)
 */
export const usersByIdAtom = derive([usersAtom], (usersArr) => {
  return usersArr.reduce((acc, user) => {
    acc[user._id] = user;
    return acc;
  }, {} as Record<string, IUser>);
});

/**
 * user referenced by their userId (sub)
 */
export const usersByUserIdAtom = derive([usersAtom], (usersArr) => {
  return usersArr.reduce((acc, user) => {
    acc[user.userId] = user;
    return acc;
  }, {} as Record<string, IUser>);
});

/**
 * Just the _id, name, email and role of each user in the workspace
 */
export const usersBasicInfoAtom = derive([usersAtom], (users) => {
  return users.map((user) => ({ _id: user._id, name: user.name, email: user.email, role: user.role }));
});

export const { valueAtom: allTagsAtom } = asyncMutableDerivedAtom({
  loadData: () => client.workspace.listWorkspaceTags(),
  debugLabel: "All Tags",
});
export const deferredAllTagsAtom = derive([allTagsAtom], (tags) => tags);
deferredAllTagsAtom.debugLabel = "deferredAllTagsAtom";

export const { valueAtom: _allComponentTagsAtom, refreshAtom: refreshAllComponentTagsAtom } = asyncMutableDerivedAtom({
  loadData: async () => {
    const response = await client.libraryComponent.getAllComponentTags({} as never);
    return response.tags;
  },
  debugLabel: "All Component Tags",
});
export const allComponentTagsAtom = derive([_allComponentTagsAtom], (tags) => tags);

export const unwrappedAllTagsAtom = unwrap(allTagsAtom, (prev) => prev ?? []);

export const createNewTagActionAtom = atom(null, async (get, set, newTag: string) => {
  const tags = await get(allTagsAtom);
  const newTags = [...tags];
  newTags.push({
    _id: newTag,
    total: 0,
  });

  set(allTagsAtom, newTags);

  // This timeout SEEMS like a hack, but it's not that bad. Tags are computed
  // by aggregating usage across text items and components; this action atom
  // is executed when a new tag is created, but it's done so as a side effect that
  // doesn't await the actual edit to the component / text item.
  //
  // By adding a timeout to refresh component tags, it all but guarantees that the
  // component tag filter will become refreshed with the latest tag information after
  // the component update has already persisted to the database
  //
  // This is a non-urgent update, so we don't care about the long delay
  setTimeout(() => set(refreshAllComponentTagsAtom), 1000);
});

/**
 * Atom for all the variants in the workspace.
 */
export const { valueAtom: variantsAtom, refreshAtom: refreshVariantsAtom } = asyncMutableDerivedAtom({
  loadData: async () => {
    const variants = (await getVariants()[0]).data;
    return variants;
  },
  debugLabel: "All Variants",
});

/**
 * Derived atom for all the variants in the workspace.
 */
export const deferredVariantsAtom = derive([variantsAtom], (variants) => variants);

/**
 * Derived atom for a map of variant names to their IDs.
 */
export const deferredVariantsNamesMapAtom = derive([variantsAtom], (variants) => {
  return variants.reduce((acc, variant) => {
    acc[variant._id] = variant.name;
    return acc;
  }, {} as Record<string, string>);
});

/**
 * Atom family that returns a variant's name given its ID.
 * Returns undefined if the variant is not found.
 * @param variantId - The ID of the variant to get the name for.
 */
export const variantNameFamilyAtom = atomFamily((variantId: string) => {
  const variantNameAtom = derive([deferredVariantsAtom], (variants): string => {
    if (variantId === BASE_VARIANT_ID) return "Base";
    return variants.find((v) => v._id === variantId)?.name ?? "";
  });
  variantNameAtom.debugLabel = `Variant Name (${variantId})`;

  return variantNameAtom;
});

export const { valueAtom: projectsAtom, refreshAtom: refreshProjectsAtom } = asyncMutableDerivedAtom({
  loadData: async () => (await client.dittoProject.getProjects()).projects,
});
