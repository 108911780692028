import {
  fetchNextLibraryCommentsPageActionAtom,
  hasMoreLibraryCommentsAtom,
  libraryCommentsAtom,
  libraryCommentsLoadingAtom,
} from "@/stores/Comments";
import CommentThreadWrapper from "@/views/NS/components/CommentThreadWrapper";
import LoadMore from "@ds/atoms/LoadMore";
import PanelEmptyState from "@ds/molecules/PanelEmptyState";
import Scrollbar from "@ds/molecules/Scrollbar";
import { useAtomValue, useSetAtom } from "jotai";
import React from "react";
import style from "./style.module.css";

function LibraryGeneralCommentsPanel() {
  const allComments = useAtomValue(libraryCommentsAtom);
  const isLoadingMore = useAtomValue(libraryCommentsLoadingAtom);
  const hasMore = useAtomValue(hasMoreLibraryCommentsAtom);
  const fetchMore = useSetAtom(fetchNextLibraryCommentsPageActionAtom);

  if (!allComments.length) {
    return (
      <PanelEmptyState
        data-testid="comments-panel"
        header="No comments"
        description="Comments and suggested edits will appear here"
      />
    );
  }

  return (
    <Scrollbar className={style.scrollArea} data-test-id="comments-panel">
      {allComments.map((commentThreadId, index) => (
        <React.Fragment key={commentThreadId}>
          {index !== 0 && <hr className={style.divider} />}
          <CommentThreadWrapper className={style.commentThread} commentThreadId={commentThreadId} showGoToText />
        </React.Fragment>
      ))}
      <LoadMore hasMore={hasMore} isLoadingMore={isLoadingMore} fetchMore={fetchMore} />
    </Scrollbar>
  );
}

export default LibraryGeneralCommentsPanel;
