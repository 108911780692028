import { ZObjectId } from "@shared/types/lib";
import { z } from "zod";

export const ZLibraryComponentFolder = z.object({
  _id: ZObjectId,
  name: z.string(),
  apiId: z.string(),
  parentId: ZObjectId.nullable(),
  workspaceId: ZObjectId,
  sortKey: z.string(),
});

export type ILibraryComponentFolder = z.infer<typeof ZLibraryComponentFolder>;

export const ZReorderLibraryComponentFolderAction = z.object({
  folderId: ZObjectId,
  before: ZObjectId.nullable(),
  after: ZObjectId.nullable(),
});

export type IReorderLibraryComponentFolderAction = z.infer<typeof ZReorderLibraryComponentFolderAction>;
