import { UpdateDeveloperIdResponse } from "@ds/organisms/EditableDeveloperId";
import { NS_BETA_URL_PATH_NAME, SELECTED_TEXT_IDS_URL_PARAM } from "@shared/common/constants";
import { WEB_BASE_URL } from "@shared/common/parseDittoURL";
import client from "@shared/frontend/http/httpClient";
import {
  getMergedAssignee,
  getMergedNotes,
  getMergedStatus,
  getMergedTags,
  getRemovedTags,
} from "@shared/frontend/lib/metadataHelpers";
import { REFRESH_SILENTLY } from "@shared/frontend/stores/symbols";
import { isDiffRichText } from "@shared/lib/text";
import { ILibraryComponentUpdate, ITextItemUpdate } from "@shared/types/DittoProject";
import { ITextItem, ITextItemStatus } from "@shared/types/TextItem";
import logger from "@shared/utils/logger";
import { atom } from "jotai";
import { soon, soonAll } from "jotai-derive";
import { atomWithDefault } from "jotai/utils";
import { ITipTapRichText } from "../../shared/types/TextItem";
import { updateLibraryComponentActionAtom } from "./Components";
import { stopInlineEditingActionAtom } from "./Editing";
import {
  derivedOnlySelectedComponentAtom,
  derivedSelectedComponentsAtom,
  libraryInstanceCountsFamilyAtom,
} from "./Library";
import { projectIdAtom, refreshAllTagsInProjectAtom } from "./Project";
import { derivedOnlySelectedTextItemAtom, derivedSelectedTextItemsAtom } from "./ProjectSelection";
import { textItemFamilyAtom, updateTextItemFamilyActionAtom } from "./TextItem";
import { usersByIdAtom } from "./Workspace";

// #region Text Items ---------------------------------------------------

// Using atomWithDefault here to avoid a not initialized error, probably caused by a circular dependency
const selectedTextItemsAtom = atomWithDefault((get) => soon(get(derivedSelectedTextItemsAtom), (items) => items));
const onlySelectedTextItemAtom = atomWithDefault((get) => soon(get(derivedOnlySelectedTextItemAtom), (item) => item));

/**
 * An action to update text items in a project.
 * This takes an update payload to pass into the API.
 * This will catch and log errors, but does not perform optimistic updates,
 * as we are currently using local state to optimistically update the UI.
 * Returns true if successful, false if an error occurred.
 *
 * Note: This is not currently exported, preferring each of the specific property atoms below.
 * However, if a flow to update multiple properties at once is added, this can be exported and used.
 */
const updateTextItemsActionAtom = atom(null, async (get, set, update: ITextItemUpdate) => {
  const projectId = get(projectIdAtom);

  try {
    if (!projectId) {
      throw new Error("Project ID not found");
    }

    // Nothing to update
    if (!update.textItemIds.length || Object.keys(update).length <= 1) {
      return true;
    }

    const result = await client.dittoProject.updateTextItems({
      projectId,
      updates: [update],
    });

    const updatedTextItems: ITextItem[] = Object.values(result.updatedTextItems);
    const updatedLibraryComponents = Object.values(result.updatedLibraryComponents);

    // Update text items
    for (const updatedTextItem of updatedTextItems) {
      set(textItemFamilyAtom(updatedTextItem._id), updatedTextItem);
    }

    // Update library components
    for (const updatedLibraryComponent of updatedLibraryComponents) {
      set(updateLibraryComponentActionAtom, {
        _id: updatedLibraryComponent._id,
        update: updatedLibraryComponent,
      });
    }
    set(stopInlineEditingActionAtom, { skipConfirmation: true, skipFocus: true });

    if (update.tags || update.tagsToDelete) {
      set(refreshAllTagsInProjectAtom, REFRESH_SILENTLY);
    }
    return true;
  } catch (e) {
    logger.error("Error updating text items:", { context: { projectId, update } }, e);
    return false;
  }
});

/**
 * This atom provides the following functionality related to text item status:
 * - The `get` function returns the merged status for the current text item selection
 * - The `set` function updates the status of all selected text items to the new status, in jotai and mongo
 */
export const selectedTextItemsStatusAtom = atom(
  (get) => soon(get(selectedTextItemsAtom), (items) => getMergedStatus(items)),
  async (get, set, newStatus: ITextItemStatus) => {
    const selectedTextItems = await get(selectedTextItemsAtom);
    const textItemIds: string[] = []; // The ids of the text items that need to be updated
    for (const textItem of selectedTextItems) {
      if (textItem.status !== newStatus) {
        textItemIds.push(textItem._id);
      }
    }

    const updatePayload: ITextItemUpdate = {
      textItemIds,
      status: newStatus,
    };

    const success = await set(updateTextItemsActionAtom, updatePayload);
    return success;
  }
);

/**
 * This atom provides the following functionality related to text item assignee:
 * - The `get` function returns the merged assignee for the current text item selection
 * - The `set` function updates the assignee of all selected text items to the new assignee, in jotai and mongo
 */
export const selectedTextItemsAssigneeAtom = atom(
  (get) =>
    soon(soonAll([get(selectedTextItemsAtom), get(usersByIdAtom)]), ([items, usersById]) =>
      getMergedAssignee(items, usersById)
    ),
  async (get, set, newAssignee: string | null) => {
    const selectedTextItems = await get(selectedTextItemsAtom);
    const textItemIds: string[] = []; // The ids of the text items that need to be updated
    for (const textItem of selectedTextItems) {
      if (textItem.assignee !== newAssignee) {
        textItemIds.push(textItem._id);
      }
    }

    const updatePayload: ITextItemUpdate = {
      textItemIds,
      assignee: newAssignee,
    };

    const success = await set(updateTextItemsActionAtom, updatePayload);
    return success;
  }
);

/**
 * This atom provides the following functionality related to text item tags:
 * - The `get` function returns the merged tags for the current text item selection
 * - The `set` function updates the tags of all selected text items to the new tags, in jotai and mongo
 */
export const selectedTextItemsTagsAtom = atom(
  (get) => soon(get(selectedTextItemsAtom), (items) => getMergedTags(items)),
  async (get, set, newTags: string[]) => {
    const selectedTextItems = await get(selectedTextItemsAtom);
    const textItemIds = selectedTextItems.map((textItem) => textItem._id);
    const tagsToDelete = getRemovedTags(
      selectedTextItems.map((textItem) => textItem.tags),
      newTags
    );

    if (!newTags.length && !tagsToDelete.length) {
      return true;
    }

    const updatePayload: ITextItemUpdate = {
      textItemIds,
      ...(newTags.length > 0 && { tags: newTags }),
      ...(tagsToDelete.length > 0 && { tagsToDelete }),
    };

    const success = await set(updateTextItemsActionAtom, updatePayload);
    return success;
  }
);

/**
 * This atom provides the following functionality related to text item notes:
 * - The `get` function returns the merged notes for the current text item selection
 * - The `set` function updates the notes of all selected text items to the new notes, in jotai and mongo
 */
export const selectedTextItemsNotesAtom = atom(
  (get) => soon(get(selectedTextItemsAtom), (items) => getMergedNotes(items)),
  async (get, set, newNotes: string) => {
    const selectedTextItems = await get(selectedTextItemsAtom);
    const textItemIds: string[] = []; // The ids of the text items that need to be updated
    for (const textItem of selectedTextItems) {
      if (textItem.notes !== newNotes) {
        textItemIds.push(textItem._id);
      }
    }

    const updatePayload: ITextItemUpdate = {
      textItemIds,
      notes: newNotes,
    };

    const success = await set(updateTextItemsActionAtom, updatePayload);
    return success;
  }
);

/**
 * This atom provides the following functionality related to text item rich text:
 * - The `get` function returns the rich text for the selected text item, if exactly one text item is selected
 * - The `set` function updates the rich text of the single-selected text item to the new text value, in jotai and mongo
 * Note: As of now, this only supports updating the rich text of a single text item at a time.
 * If multiple text items are selected, the merged value is null and the set function will return false.
 */
export const selectedTextItemsRichTextAtom = atom(
  (get) => soon(get(onlySelectedTextItemAtom), (item) => item?.rich_text ?? null),
  async (get, set, newValue: ITipTapRichText) => {
    const onlySelectedTextItem = await get(onlySelectedTextItemAtom);
    if (!onlySelectedTextItem) {
      logger.error("Cannot update rich text if there is not exactly one text item selected", { context: { newValue } });
      return false;
    }

    // Nothing to update
    if (!isDiffRichText(onlySelectedTextItem.rich_text, newValue)) {
      return true;
    }

    const updatePayload: ITextItemUpdate = {
      textItemIds: [onlySelectedTextItem._id],
      richText: newValue,
    };

    const success = await set(updateTextItemsActionAtom, updatePayload);
    return success;
  }
);

/**
 * If exactly one text item is selected, and it is connected to a library component,
 * returns the instance count and project count for that library component.
 * Otherwise, returns null.
 */
export const selectedTextItemLibraryInstanceDataAtom = atom((get) => {
  return soon(get(onlySelectedTextItemAtom), (textItem) => {
    if (!textItem?.ws_comp) {
      return null;
    }

    return soon(get(libraryInstanceCountsFamilyAtom(textItem.ws_comp)), (instances) => instances);
  });
});

/**
 * This atom can be used to get or set the developerId of the selected text item, if exactly one is selected.
 * Calling the setter will update the text item in jotai and the backend.
 */
export const selectedTextItemDeveloperIdAtom = atom(
  (get) => soon(get(onlySelectedTextItemAtom), (item) => item?.developerId ?? ""),
  async (get, set, newValue: string): Promise<UpdateDeveloperIdResponse> => {
    const onlySelectedTextItem = await get(onlySelectedTextItemAtom);
    if (!onlySelectedTextItem) {
      logger.error("Cannot update text item developer id if there is not exactly one text item selected", {
        context: { newValue },
      });
      return { success: false, errorMessage: "An unexpected error occurred" };
    }

    // Nothing to update
    if (onlySelectedTextItem.developerId === newValue) {
      return { success: true };
    }

    const projectId = get(projectIdAtom);
    if (!projectId) {
      return { success: false, errorMessage: "An unexpected error occurred" };
    }
    const textItemId = onlySelectedTextItem._id;

    try {
      const { updatedTextItem } = await client.dittoProject.updateTextItemDeveloperId({
        projectId,
        textItemId,
        developerId: newValue,
      });
      set(updateTextItemFamilyActionAtom, { _id: textItemId, developerId: updatedTextItem.developerId });
      return { success: true };
    } catch (e) {
      if (e.response?.data) {
        if (e.response.data.type === "exists" && e.response.data.projectId && e.response.data.textItemId) {
          return {
            success: false,
            errorMessage: "Dev ID already exists in workspace",
            errorMessageAction: {
              text: "View",
              onClick: () => {
                window.open(
                  `${WEB_BASE_URL}/${NS_BETA_URL_PATH_NAME}/${e.response.data.projectId}?${SELECTED_TEXT_IDS_URL_PARAM}=${e.response.data.textItemId}`,
                  "_blank"
                );
              },
            },
          };
        }
        // This should have been caught by frontend validation, so logging as an error
        logger.error(
          "Update Text Item Developer Id: Validation error",
          { context: { textItemId, newValue, errorMessage: e.response.data.message } },
          e
        );
        return {
          success: false,
          errorMessage: e.response.data.message,
        };
      }

      // Some other unexpected error
      logger.error("Error updating text item developer id", { context: { textItemId, newValue } }, e);
      return {
        success: false,
        errorMessage: "An unexpected error occurred",
      };
    }
  }
);

// #endregion Text Items ---------------------------------------------------

// #region Library components ---------------------------------------------------

// Using atomWithDefault here to avoid a not initialized error, possibly caused by a circular dependency
const selectedLibraryComponentsAtom = atomWithDefault((get) =>
  soon(get(derivedSelectedComponentsAtom), (items) => items)
);
const onlySelectedLibraryComponentAtom = atomWithDefault((get) =>
  soon(get(derivedOnlySelectedComponentAtom), (item) => item)
);

/**
 * An action to update library components in jotai and the backend.
 * This takes an update payload to pass into the API.
 * This will catch and log errors, but does not perform optimistic updates,
 * as we are currently using local state to optimistically update the UI.
 * Returns true if successful, false if an error occurred.
 */
export const updateLibraryComponentsActionAtom = atom(null, async (get, set, update: ILibraryComponentUpdate) => {
  try {
    // Nothing to update
    if (!update.libraryComponentIds.length || Object.keys(update).length <= 1) {
      return true;
    }

    const result = await client.libraryComponent.updateLibraryComponents({
      updates: [update],
    });

    const updatedLibraryComponents = Object.values(result.updatedLibraryComponents);

    for (const updatedLibraryComponent of updatedLibraryComponents) {
      set(updateLibraryComponentActionAtom, {
        _id: updatedLibraryComponent._id,
        update: updatedLibraryComponent,
      });
    }

    if (update.tags || update.tagsToDelete) {
      set(refreshAllTagsInProjectAtom, REFRESH_SILENTLY);
    }
    return true;
  } catch (e) {
    logger.error("Error updating library components:", { context: { update } }, e);
    return false;
  }
});

/**
 * This atom provides the following functionality related to library component status:
 * - The `get` function returns the merged status for the current library component selection
 * - The `set` function updates the status of all selected library components to the new status, in jotai and mongo
 */
export const selectedLibraryComponentsStatusAtom = atom(
  (get) => soon(get(selectedLibraryComponentsAtom), (items) => getMergedStatus(items)),
  async (get, set, newStatus: ITextItemStatus) => {
    const selectedLibraryComponents = await get(selectedLibraryComponentsAtom);
    const libraryComponentIds: string[] = []; // The ids of the library components that need to be updated
    for (const libraryComponent of selectedLibraryComponents) {
      if (libraryComponent.status !== newStatus) {
        libraryComponentIds.push(libraryComponent._id);
      }
    }

    const updatePayload: ILibraryComponentUpdate = {
      libraryComponentIds,
      status: newStatus,
    };

    const success = await set(updateLibraryComponentsActionAtom, updatePayload);
    return success;
  }
);

/**
 * This atom provides the following functionality related to library component assignee:
 * - The `get` function returns the merged assignee for the current library component selection
 * - The `set` function updates the assignee of all selected library components to the new assignee, in jotai and mongo
 */
export const selectedLibraryComponentsAssigneeAtom = atom(
  (get) =>
    soon(soonAll([get(selectedLibraryComponentsAtom), get(usersByIdAtom)]), ([items, usersById]) =>
      getMergedAssignee(items, usersById)
    ),
  async (get, set, newAssignee: string | null) => {
    const selectedLibraryComponents = await get(selectedLibraryComponentsAtom);
    const libraryComponentIds: string[] = []; // The ids of the library components that need to be updated
    for (const libraryComponent of selectedLibraryComponents) {
      if (libraryComponent.assignee !== newAssignee) {
        libraryComponentIds.push(libraryComponent._id);
      }
    }

    const updatePayload: ILibraryComponentUpdate = {
      libraryComponentIds,
      assignee: newAssignee,
    };

    const success = await set(updateLibraryComponentsActionAtom, updatePayload);
    return success;
  }
);

/**
 * This atom provides the following functionality related to library component tags:
 * - The `get` function returns the merged tags for the current library component selection
 * - The `set` function updates the tags of all selected library components to the new tags, in jotai and mongo
 */
export const selectedLibraryComponentsTagsAtom = atom(
  (get) => soon(get(selectedLibraryComponentsAtom), (items) => getMergedTags(items)),
  async (get, set, newTags: string[]) => {
    const selectedLibraryComponents = await get(selectedLibraryComponentsAtom);
    const libraryComponentIds = selectedLibraryComponents.map((libraryComponent) => libraryComponent._id);
    const tagsToDelete = getRemovedTags(
      selectedLibraryComponents.map((libraryComponent) => libraryComponent.tags),
      newTags
    );

    if (!newTags.length && !tagsToDelete.length) {
      return true;
    }

    const updatePayload: ILibraryComponentUpdate = {
      libraryComponentIds,
      ...(newTags.length > 0 && { tags: newTags }),
      ...(tagsToDelete.length > 0 && { tagsToDelete }),
    };

    const success = await set(updateLibraryComponentsActionAtom, updatePayload);
    return success;
  }
);

/**
 * This atom provides the following functionality related to library component notes:
 * - The `get` function returns the merged notes for the current library component selection
 * - The `set` function updates the notes of all selected library components to the new notes, in jotai and mongo
 */
export const selectedLibraryComponentsNotesAtom = atom(
  (get) => soon(get(selectedLibraryComponentsAtom), (items) => getMergedNotes(items)),
  async (get, set, newNotes: string) => {
    const selectedLibraryComponents = await get(selectedLibraryComponentsAtom);
    const libraryComponentIds: string[] = []; // The ids of the library components that need to be updated
    for (const libraryComponent of selectedLibraryComponents) {
      if (libraryComponent.notes !== newNotes) {
        libraryComponentIds.push(libraryComponent._id);
      }
    }

    const updatePayload: ILibraryComponentUpdate = {
      libraryComponentIds,
      notes: newNotes,
    };

    const success = await set(updateLibraryComponentsActionAtom, updatePayload);
    return success;
  }
);

/**
 * This atom provides the following functionality related to component rich text:
 * - The `get` function returns the rich text for the currently selected component, if exactly one component is selected
 * - The `set` function updates the rich text of the single-selected component to the new text value, in jotai and mongo
 * Note: As of now, this only supports updating the rich text of a single component at a time.
 * If multiple components are selected, the merged value is null and the set function will return false.
 */
export const selectedLibraryComponentsRichTextAtom = atom(
  (get) => soon(get(onlySelectedLibraryComponentAtom), (item) => item?.rich_text ?? null),
  async (get, set, newValue: ITipTapRichText) => {
    const onlySelectedLibraryComponent = await get(onlySelectedLibraryComponentAtom);
    if (!onlySelectedLibraryComponent) {
      logger.error("Cannot update rich text if there is not exactly one component selected", { context: { newValue } });
      return false;
    }

    // Nothing to update
    if (!isDiffRichText(onlySelectedLibraryComponent.rich_text, newValue)) {
      return true;
    }

    const updatePayload: ILibraryComponentUpdate = {
      libraryComponentIds: [onlySelectedLibraryComponent._id],
      richText: newValue,
    };

    const success = await set(updateLibraryComponentsActionAtom, updatePayload);
    return success;
  }
);

/**
 * This atom provides the following functionality related to component name:
 * - The `get` function returns the name of the currently selected component, if exactly one component is selected
 * - The `set` function updates the nae of the single-selected component to the new name value, in jotai and mongo
 * Note: As of now, this only supports updating the name of a single component at a time.
 * If multiple components are selected, the merged value is null and the set function will return false.
 */
export const selectedLibraryComponentsNameAtom = atom(
  (get) => soon(get(onlySelectedLibraryComponentAtom), (item) => item?.name ?? null),
  async (get, set, newName: string) => {
    const onlySelectedLibraryComponent = await get(onlySelectedLibraryComponentAtom);
    if (!onlySelectedLibraryComponent) {
      logger.error("Cannot update component name if there is not exactly one component selected", {
        context: { newName },
      });
      return false;
    }

    // Nothing to update
    if (onlySelectedLibraryComponent.name === newName) {
      return true;
    }

    const updatePayload: ILibraryComponentUpdate = {
      libraryComponentIds: [onlySelectedLibraryComponent._id],
      name: newName,
    };

    const success = await set(updateLibraryComponentsActionAtom, updatePayload);
    return success;
  }
);

// #endregion Library components ---------------------------------------------------
