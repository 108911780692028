import React from "react";
import Text from "../../atoms/Text";
import style from "./index.module.css";

interface IProps {
  componentCounts: { instanceCount: number; projectCount: number } | null | undefined;
}
function InstancesNotice(props: IProps) {
  if (!props.componentCounts) {
    return null;
  }

  const { instanceCount, projectCount } = props.componentCounts;

  if (instanceCount > 1) {
    return (
      <div className={style.instanceWarning}>
        <Text color="purple" size="micro" inline>
          <Text color="purple" weight="strong" size="micro" inline>
            Heads-up
          </Text>{" "}
          - Changes to this component will sync across {projectCount} project
          {projectCount === 1 ? "" : "s"} and {instanceCount} instance
          {instanceCount === 1 ? "" : "s"}.
        </Text>
      </div>
    );
  }

  return null;
}

export default InstancesNotice;
