import { locationAtom } from "@/stores/Location";
import { useSetAtom } from "jotai";
import { useCallback } from "react";
import { useHistory } from "react-router-dom";

/**
 * This hook should be used to change url from a
 * non-NS page to a NS page. It updates both the
 * React Router history and the Jotai location atom.
 * This is required because our routing isn't hooked
 * up to the Jotai location atom.
 */
function useRedirectToNSPage() {
  const setLocation = useSetAtom(locationAtom);
  const history = useHistory();

  const handleRedirectToNSPage = useCallback((pathname: string, searchParams = new URLSearchParams()) => {
    setLocation({ pathname, searchParams });
    history.replace({ pathname, search: searchParams.toString() });
  }, []);

  return handleRedirectToNSPage;
}

export default useRedirectToNSPage;
