import { useAtom, useAtomValue } from "jotai";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { EditMetadataAtom } from ".";
import { IListWorkspaceTagsResponse } from "../../../../shared/client/routes/workspace.schema";
import { MergedTagsMetadata } from "../../../../shared/frontend/lib/metadataHelpers";
import { SoonAtom } from "../../../../shared/frontend/stores/types";
import LabelWrapper from "../../molecules/LabelWrapper";
import MultiCombobox from "../../molecules/MultiCombobox";
import { TagIcon } from "./icons";
import style from "./index.module.css";

interface IProps {
  tagsAtom: EditMetadataAtom<MergedTagsMetadata, string[]>;
  allTagsAtom: SoonAtom<IListWorkspaceTagsResponse>;
  onCreateNewTag: (value: string) => void;
  inputSize: "base" | "small";
  labelHeight: number;
}

function EditTags(props: IProps) {
  const [persistedTags, savePersistedTags] = useAtom(props.tagsAtom);
  const [localTags, setLocalTags] = useState(persistedTags);
  const allTags = useAtomValue(props.allTagsAtom);

  const hasTagsList = Array.isArray(localTags);

  const onFocus = useCallback(() => {
    if (!hasTagsList) {
      setLocalTags([]);
    }
  }, [hasTagsList]);

  const options = useMemo(() => allTags.map((tag) => ({ value: tag._id, label: tag._id })), [allTags]);

  const selectedItems = useMemo(
    () => (hasTagsList ? localTags.map((tag) => ({ value: tag, label: tag })) : []),
    [hasTagsList, localTags]
  );

  const setSelectedItems = useCallback(
    (items: { value: string; label: string }[]) => {
      const newTags = items.map((item) => item.value);
      setLocalTags(newTags); // Optimistic local UI update
      savePersistedTags(newTags); // Alert the parent to the change
    },
    [savePersistedTags]
  );

  useEffect(
    function updateLocalStateOnPropChange() {
      setLocalTags(persistedTags);
    },
    [persistedTags]
  );

  return (
    <LabelWrapper
      className={style.labelWrapper}
      labelFlexStart
      labelHeight={props.labelHeight}
      label="Tags"
      leadingIcon={<TagIcon />}
    >
      <div className={style.inputWidthWrapper}>
        <MultiCombobox
          onFocus={onFocus}
          size={props.inputSize}
          focusedPlaceholder={hasTagsList ? undefined : "Mixed tags"}
          placeholder={"Add tag..."}
          options={options}
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          onCreateNew={props.onCreateNewTag}
          disallowCaseDuplicates={true}
        />
      </div>
    </LabelWrapper>
  );
}

export default EditTags;
