import classNames from "classnames";
import React, { ForwardedRef, forwardRef, RefObject, useEffect, useRef, useState } from "react";
import {
  DragMoveEvent,
  DragPreviewRenderer,
  DragStartEvent,
  DragTypes,
  DropOperation,
  useDrag,
  useDrop,
} from "react-aria";
import { ZodTypeAny } from "zod";
import style from "./index.module.css";

export type DragLocation = "above" | "below" | "above-edge" | "below-edge" | null;

export type SelectionType = "text" | "block" | "component" | "folder" | "none";

interface IDraggableProps<DataType> {
  getDraggableItems: () => Record<string, DataType>[];
  isDragDisabled?: boolean;
  onDragStart?: (e: DragStartEvent) => void;
  onDragMove?: (e: DragMoveEvent) => void;
  onDragCancel?: () => void;
  onDragEnd?: () => void;
  preview?: RefObject<DragPreviewRenderer | null>;
}

interface IDroppableProps<DataType> {
  // Droppable Props
  allowedItemKeys: Record<string, ZodTypeAny>;
  /**
   * Note: if dropping different types of items, the dropKey will be the key of the first item.
   *
   * @param items The items that were dropped.
   * @param dragLocation The location of the draggable item relative to the droppable item.
   * @param dropKey The key of the item that was dropped.
   */
  onDrop?: (items: DataType[], dragLocation: DragLocation, dropKey: string) => void;
  /**
   * If provided, the edge threshold from which to disambiguate "above-edge" vs. "above" and
   * "below-edge" vs. "below". If not provided, only "above" and "below" will be used.
   */
  edgeThreshold?: number;
  /**
   * Use this function to determine what kinds of elements can be dropped on the droppable item.
   *
   * e.g. getDropOperation(types) {return types.has("ditto/componentItem") ? "move" : "cancel"}
   */
  getDropOperation?: (types: DragTypes) => DropOperation;
}

export interface IDragAndDroppableProps<DraggableDataType, DroppableDataType>
  extends IDraggableProps<DraggableDataType>,
    IDroppableProps<DroppableDataType> {
  children:
    | React.ReactNode[]
    | ((props: { isDragging: boolean; isDropTarget: boolean; dragLocation: DragLocation }) => React.ReactNode);
  className?: string;
  selectionType: SelectionType;
  showDraggedContent?: boolean;
}

/**
 * Given an object, return a new object with each value stringified.
 */
function stringifyEachEntry(object: Record<string, unknown>): Record<string, string> {
  return Object.entries(object).reduce<Record<string, string>>((acc, [key, value]) => {
    acc[key] = JSON.stringify(value);
    return acc;
  }, {});
}

export const DragAndDroppable = forwardRef(function DragAndDroppable<DraggableDataType, DroppableDataType>(
  props: IDragAndDroppableProps<DraggableDataType, DroppableDataType>,
  ref: ForwardedRef<unknown>
) {
  let dropRef = useRef<HTMLDivElement | null>(null);

  // Merge internal ref with optional external ref
  function setRefs(node: HTMLDivElement | null) {
    dropRef.current = node;

    if (ref) {
      (ref as React.MutableRefObject<HTMLDivElement | null>).current = node;
    }
  }

  const [dragLocation, setDragLocation] = useState<DragLocation>(null);
  const [halfHeight, setHalfHeight] = useState(0);

  useEffect(
    // if this component is ever causing performance issues, it's possible that the calls
    // to setHalfHeight are the problem! It's possible this effect is running too frequently.
    function calculateContentHeight() {
      // We record the height of the nudge content so that we can animate it in and out.
      if (dropRef.current) {
        const clientHeight = dropRef.current.clientHeight;
        dropRef.current.style.setProperty("--content-height", `${clientHeight}px`);
        setHalfHeight((clientHeight ?? 0) / 2);
      }
    },
    [dropRef.current?.clientHeight]
  );

  let { dragProps, isDragging } = useDrag({
    getItems: () => props.getDraggableItems().map(stringifyEachEntry),
    isDisabled: props.isDragDisabled ?? false,
    onDragStart: props.onDragStart,
    onDragMove: props.onDragMove,
    onDragEnd: props.onDragEnd,
    preview: props.preview,
  });

  const calculateDragLocation = (y: number): DragLocation => {
    if (props.edgeThreshold === undefined) {
      return y >= halfHeight ? "below" : "above";
    } else {
      const height = halfHeight * 2;

      if (y <= props.edgeThreshold * height) {
        return "above-edge";
      } else if (y >= height - props.edgeThreshold * height) {
        return "below-edge";
      } else {
        return y >= halfHeight ? "below" : "above";
      }
    }
  };

  let { dropProps, isDropTarget } = useDrop({
    ref: dropRef,
    getDropOperation: props.getDropOperation,
    async onDrop(e) {
      let dropKey: string | undefined;
      let itemPromises: Promise<DroppableDataType | undefined>[] = [];
      const textMimeItems = e.items.filter((item) => item.kind === "text");
      for (const item of textMimeItems) {
        for (const [key, zodValidator] of Object.entries(props.allowedItemKeys)) {
          if (item.types.has(key)) {
            if (!dropKey) {
              dropKey = key;
            }
            itemPromises.push(
              item.getText(key).then((text) => {
                const parsedJSON = JSON.parse(text);
                const validatedJSON = zodValidator.safeParse(parsedJSON);
                if (validatedJSON.success) {
                  return validatedJSON.data as DroppableDataType;
                }
              })
            );
          }
        }
      }

      const items: DroppableDataType[] = (await Promise.all(itemPromises)).filter(Boolean) as DroppableDataType[];
      if (items.length > 0) props.onDrop?.(items, dragLocation, dropKey!);
    },
    onDropEnter(e) {
      setDragLocation(calculateDragLocation(e.y));
    },
    onDropMove(e) {
      setDragLocation(calculateDragLocation(e.y));
    },
    onDropExit(e) {
      setDragLocation(null);
    },
  });

  return (
    <div
      className={classNames(props.className, { [style.draggableContent]: !props.showDraggedContent })}
      ref={setRefs}
      {...dragProps}
      {...dropProps}
      data-isdragging={isDragging}
      data-isdroptarget={isDropTarget}
      data-draglocation={dragLocation}
      data-state={isDragging ? "dragging" : "idle"}
      data-selectiontype={props.selectionType}
    >
      {typeof props.children === "function"
        ? props.children({ isDragging, isDropTarget, dragLocation })
        : props.children}
    </div>
  );
});

export default DragAndDroppable;
