// feature flag variables go here until we eventually
// implement a more robust system

/**
 * This file is shared between the FE and BE codebases. Since
 * localStorage doesn't exist on the BE, we need to mock it
 * so the BE doesn't throw the error.
 */
let ls;
try {
  ls = localStorage;
} catch (err) {
  ls = { getItem: () => "false" };
}

export const SUBERSCRIPT_ENABLED_WORKSPACES = [
  // suberscript test workspace
  "63b87312eb5b8c23dc77d182",
  // Claire's ActualWorkspace
  "6291236524dcd447c2c17565",
  // PRI
  "63b4b71831048c00cdf3866a",
  // LegalZoom
  "5ee30713a698ee003a70951a",
  // Red Ventures
  "624f3f9a01b1c000f64dda0c",
];

export const DUPLICATE_DRAFT_PROJECT_ENABLED_WORKSPACES = [
  // Lutron
  "64d2785a24069aa3de4d7990",
  // Brendan enterprise WS
  "66a94683872709cb69435333",
  // Buzz brothers
  "66c33c96e660f9bebe4c950b",
];

export const SAMPLE_DATA_DEBUG = ls.getItem("SAMPLE_DATA_DEBUG") === "true";

export const VARIANT_STATUSES_ENABLED = true;
export const HIDE_JOTAI_DEVTOOLS = ls.getItem("HIDE_JOTAI_DEVTOOLS") === "true";
export const DEV_INTEGRATIONS_V2 = ls.getItem("DEV_INTEGRATIONS_V2") === "true";
