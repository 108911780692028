import { designPreviewToggledAtom } from "@/stores/Location";
import {
  clearSelectionActionAtom,
  setSelectedBlockIdsActionAtom,
  setSelectedTextIdsActionAtom,
} from "@/stores/ProjectSelection";
import DesignPreviews from "@/views/NS/ProjectNS/components/DesignPreviews";
import { useAtom, useSetAtom } from "jotai";
import { Suspense, useEffect } from "react";
import DetailsPanel from "../DetailsPanel";
import LeftSidebar from "../LeftSidebar";
import TextItemList from "../TextItemList";
import style from "./style.module.css";

function ProjectContent() {
  const setSelectedTextItemIds = useSetAtom(setSelectedTextIdsActionAtom);
  const setSelectedBlockIds = useSetAtom(setSelectedBlockIdsActionAtom);
  const clearSelectionAction = useSetAtom(clearSelectionActionAtom);
  const [designPreviewToggled, setDesignPreviewToggled] = useAtom(designPreviewToggledAtom);

  useEffect(
    function handleEscape() {
      const keyboardEventListener = function (event: KeyboardEvent) {
        if (event.key === "Escape") {
          clearSelectionAction();
        }
      };

      document.addEventListener("keydown", keyboardEventListener);

      return function cleanup() {
        document.removeEventListener("keydown", keyboardEventListener);
      };
    },
    [clearSelectionAction]
  );

  return (
    <div className={style.projectContainer}>
      <Suspense fallback={<></>}>
        <LeftSidebar />
      </Suspense>
      <Suspense fallback={<></>}>
        <TextItemList />
      </Suspense>
      {designPreviewToggled && (
        // TODO: nicer loading state
        // [DIT-8475: Loading & error states for preview images](https://linear.app/dittowords/issue/DIT-8475/loading-and-error-states-for-preview-images)
        <Suspense fallback={<DesignPreviews.Fallback />}>
          <DesignPreviews />
        </Suspense>
      )}

      <Suspense fallback={<></>}>
        <DetailsPanel />
      </Suspense>
    </div>
  );
}

export default ProjectContent;
