import { routes } from "@/defs";
import { default as useRedirectToNSPage } from "@/hooks/useRedirectToNSPage";
import { unlinkComponentActionAtom } from "@/stores/ComponentLinkingFlow";
import {
  editableTextItemVariantChangesAtom,
  resetAddVariantFormChangesActionAtom,
  setAddVariantFormChangesActionAtom,
} from "@/stores/Editing";
import { removeVariantFromTextItemsActionAtom, workspaceIdAtom } from "@/stores/Project";
import {
  derivedOnlySelectedTextItemAtom,
  derivedOnlySelectedTextItemVariantsAtom,
  detailsPanelPropsAtom,
  detailsPanelSelectionStateAtom,
  editPanelCloseActionAtom,
  editPanelTitleAtom,
  libraryInteractionViewAtom,
  selectedBlockIdAtom,
  selectionTypeAtom,
  singleSelectedComponentAtom,
  singleSelectedComponentNameAtom,
  textItemVariantsFamilyAtom,
} from "@/stores/ProjectSelection";
import {
  attachVariantActionAtom,
  blockSelectedVariantIdFamilyAtom,
  updateTextItemVariantActionAtom,
} from "@/stores/Variants";
import { deferredVariantsAtom } from "@/stores/Workspace";
import BlockDetails from "@/views/NS/ProjectNS/components/DetailsPanel/BlockDetails";
import LinkComponent from "@/views/NS/ProjectNS/components/DetailsPanel/LinkComponent";
import TextItemDetails from "@/views/NS/ProjectNS/components/DetailsPanel/TextItemDetails";
import ComponentActions from "@ds/molecules/ComponentActions";
import Scrollbar from "@ds/molecules/Scrollbar";
import TitleRow from "@ds/organisms/DetailsPanelTitleRow";
import VariantsPanel from "@ds/organisms/VariantsPanel";
import { useAtomValue, useSetAtom } from "jotai";
import { Suspense, useCallback } from "react";
import EditTabBar from "../EditTabBar";
import PublishToLibraryWrapper from "../PublishToLibraryWrapper";
import TextItemActivity from "../TextItemActivity";
import TextItemComments from "../TextItemComments";
import style from "./style.module.css";

function EditDetailsPanel() {
  const editPanelCloseAction = useSetAtom(editPanelCloseActionAtom);
  const title = useAtomValue(editPanelTitleAtom);
  const closeAction = useCallback(() => {
    editPanelCloseAction();
  }, [editPanelCloseAction]);

  return (
    <>
      <TitleRow title={title} closeAction={closeAction} />
      <Suspense fallback={<ComponentActions.Fallback />}>
        <ComponentActionsComponent />
      </Suspense>
      <EditTabBarComponent />
      <EditDetailsPanelContent />
    </>
  );
}

function ComponentActionsComponent() {
  const selectedComponentName = useAtomValue(singleSelectedComponentNameAtom);
  const singleSelectedComponent = useAtomValue(singleSelectedComponentAtom);
  const unlinkComponentAction = useSetAtom(unlinkComponentActionAtom);
  const handleRedirectToNSPage = useRedirectToNSPage();

  const handleLinkOut = useCallback(() => {
    if (!singleSelectedComponent) return;

    const { pathname, searchParams } = routes.nonNavRoutes.library.getUrlParts({
      folderId: singleSelectedComponent.folderId,
      selectedComponentIds: [singleSelectedComponent._id],
    });
    handleRedirectToNSPage(pathname, searchParams);
  }, [singleSelectedComponent, handleRedirectToNSPage]);

  if (!selectedComponentName) return null;

  return (
    <ComponentActions
      componentName={selectedComponentName}
      onUnlinkClick={unlinkComponentAction}
      onLinkOutClick={handleLinkOut}
    />
  );
}

function EditTabBarComponent() {
  const libraryInteractionView = useAtomValue(libraryInteractionViewAtom);

  if (libraryInteractionView !== null) {
    return null;
  }

  return <EditTabBar />;
}

function EditDetailsPanelContent() {
  const selectionType = useAtomValue(selectionTypeAtom);
  const selectedTab = useAtomValue(detailsPanelSelectionStateAtom);
  const selectedBlockId = useAtomValue(selectedBlockIdAtom);
  const libraryInteractionView = useAtomValue(libraryInteractionViewAtom);
  const workspaceId = useAtomValue(workspaceIdAtom);

  if (libraryInteractionView !== null) {
    return (
      <>
        {libraryInteractionView === "PUBLISH" && <PublishToLibraryWrapper />}
        {libraryInteractionView === "LINK" && (
          <Suspense fallback={<LinkComponent.Fallback />}>
            <LinkComponent />
          </Suspense>
        )}
        {libraryInteractionView === "SWAP" && <div>Swap</div>}
      </>
    );
  }

  return (
    <Scrollbar className={style.scrollArea} disableScrollX>
      {selectedTab === "EDIT" && (
        <>
          {selectionType === "text" && <TextItemDetails />}
          {selectionType === "block" && selectedBlockId && <BlockDetails selectedBlockId={selectedBlockId} />}
        </>
      )}
      {selectedTab === "ACTIVITY" && <TextItemActivity />}
      {selectedTab === "COMMENTS" && <TextItemComments />}
      {selectedTab === "VARIANTS" && (
        <VariantsPanel
          workspaceId={workspaceId}
          atoms={{
            onlySelectedItemAtom: derivedOnlySelectedTextItemAtom,
            workspaceVariantsAtom: deferredVariantsAtom,
            onlySelectedItemVariantsAtom: derivedOnlySelectedTextItemVariantsAtom,
            blockSelectedVariantIdFamilyAtom: blockSelectedVariantIdFamilyAtom,
            detailsPanelPropsAtom: detailsPanelPropsAtom,
            setAddVariantFormChangesActionAtom: setAddVariantFormChangesActionAtom,
            resetAddVariantFormChangesActionAtom: resetAddVariantFormChangesActionAtom,
            attachVariantActionAtom: attachVariantActionAtom,
            updateItemVariantActionAtom: updateTextItemVariantActionAtom,
            editableItemVariantChangesAtom: editableTextItemVariantChangesAtom,
            removeVariantFromItemsActionAtom: removeVariantFromTextItemsActionAtom,
            itemVariantsFamilyAtom: textItemVariantsFamilyAtom,
          }}
        />
      )}
    </Scrollbar>
  );
}

export default EditDetailsPanel;
