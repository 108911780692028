import { useEffect } from "react";

function findParentWithAttribute(element: HTMLElement, attributeName: string): HTMLElement | null {
  let current = element;

  while (current && current !== document.body) {
    if (current.hasAttribute(attributeName)) {
      return current;
    }
    current = current.parentElement as HTMLElement;
    if (!current) break;
  }

  return null;
}

/**
 * Use this hook to execute a function whenever a user clicks anywhere inside elements which have
 * been marked with a specific attribute. Unless stopped with `event.stopPropagation()`, click events
 * from anywhere in the subtree of marked elements will trigger the function.
 */
export function useGlobalScopedClickHandler({
  onClick,
  parentAttribute,
}: {
  onClick: () => void;
  parentAttribute: string;
}) {
  useEffect(
    function addGlobalDeselectHandler() {
      const handleGlobalClick = (e: MouseEvent) => {
        const target = e.target as HTMLElement;
        const clearSelectionElement = findParentWithAttribute(target, parentAttribute);
        if (clearSelectionElement) onClick();
      };

      document.addEventListener("click", handleGlobalClick);
      return () => document.removeEventListener("click", handleGlobalClick);
    },
    [onClick, parentAttribute]
  );
}
