import TextItemTextInputs from "@/components/TextItemTextInputs/TextItemTextInputs";
import {
  editableHasChangesAtom,
  editableTextItemVariantChangesAtom,
  stopInlineEditingActionAtom,
} from "@/stores/Editing";
import {
  selectedTextItemLibraryInstanceDataAtom,
  selectedTextItemsAssigneeAtom,
  selectedTextItemsNotesAtom,
  selectedTextItemsRichTextAtom,
  selectedTextItemsStatusAtom,
  selectedTextItemsTagsAtom,
} from "@/stores/EditMetadata";
import { removeVariantFromTextItemsActionAtom } from "@/stores/Project";
import {
  derivedOnlySelectedTextItemAtom,
  detailsPanelPropsAtom,
  detailsPanelSelectionStateAtom,
  selectedTextItemIdsAtom,
} from "@/stores/ProjectSelection";
import { blockSelectedVariantIdFamilyAtom, updateTextItemVariantActionAtom } from "@/stores/Variants";
import { createNewTagActionAtom, unwrappedAllTagsAtom, usersByIdAtom, variantNameFamilyAtom } from "@/stores/Workspace";
import { TextEntityMetadata } from "@ds/organisms/TextEntityMetadata";
import useRichTextInputProps from "@ds/organisms/TextEntityMetadata/useRichTextInputProps";
import { RichTextInputProps, RichTextInputRef } from "@shared/types/RichText";
import { ITextItemVariant, ITextItemVariantUpdate, ITipTapRichText } from "@shared/types/TextItem";
import { BASE_VARIANT_ID } from "@shared/types/Variant";
import classNames from "classnames";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { forwardRef, memo, useCallback, useEffect, useImperativeHandle, useMemo, useState } from "react";
import style from "./style.module.css";

function MetadataPanel() {
  const onCreateNewTag = useSetAtom(createNewTagActionAtom);
  const selectedTextItemIds = useAtomValue(selectedTextItemIdsAtom);
  const onlySelectedTextItem = useAtomValue(derivedOnlySelectedTextItemAtom);
  const saveRichTextEdit = useSetAtom(selectedTextItemsRichTextAtom);
  const setEditableHasChanges = useSetAtom(editableHasChangesAtom);
  const setEditableTextItemVariantChanges = useSetAtom(editableTextItemVariantChangesAtom);

  // Variant atoms
  const activeVariantId = useAtomValue(blockSelectedVariantIdFamilyAtom(onlySelectedTextItem?.blockId ?? null));
  const activeVariantName = useAtomValue(variantNameFamilyAtom(activeVariantId));
  const setUpdateTextItemVariantAction = useSetAtom(updateTextItemVariantActionAtom);
  const removeVariantFromTextItemsAction = useSetAtom(removeVariantFromTextItemsActionAtom);
  const stopInlineEditing = useSetAtom(stopInlineEditingActionAtom);

  // Details panel atom state
  const setDetailsPanelEditState = useSetAtom(detailsPanelSelectionStateAtom);
  const [detailsPanelProps, setDetailsPanelProps] = useAtom(detailsPanelPropsAtom);
  const [resetFormSignal, setResetFormSignal] = useState(false);

  // Metadata about the linked library component, if the selected text item is linked to a library component
  const libraryInstanceData = useAtomValue(selectedTextItemLibraryInstanceDataAtom);

  const handleSave = useCallback(
    async ({ localStateRichText }: { localStateRichText: ITipTapRichText | null }) => {
      if (localStateRichText) {
        await saveRichTextEdit(localStateRichText);
      }
    },
    [saveRichTextEdit]
  );

  useEffect(
    function revertUnsavedChangesOnExternalSignal() {
      if (detailsPanelProps?.resetFormState) {
        setDetailsPanelProps((prevProps) => ({
          ...prevProps,
          resetFormState: false,
        }));
        setResetFormSignal((prev) => !prev);
      }
    },
    [detailsPanelProps?.resetFormState, setDetailsPanelProps]
  );

  // Show variant metadata only if there is a single selection and the active variant on the block is not the base variant
  // (If the text item is non-block, its active variant id is always "BASE_VARIANT_ID")
  const showVariantMetadata = useMemo(
    () => !!onlySelectedTextItem?._id && activeVariantId !== BASE_VARIANT_ID,
    [onlySelectedTextItem?._id, activeVariantId]
  );
  const activeVariantProp = useMemo(
    () => ({ id: activeVariantId, name: activeVariantName ?? "" }),
    [activeVariantId, activeVariantName]
  );
  const textItemVariantProp: (ITextItemVariant & { name: string; textItemId: string }) | undefined = useMemo(() => {
    if (!onlySelectedTextItem?._id) return undefined;
    // Find the variant on the text item that matches the active variant on the block
    const variant = onlySelectedTextItem.variants.find((v) => v.variantId === activeVariantId);
    return variant ? { ...variant, name: activeVariantName ?? "", textItemId: onlySelectedTextItem._id } : undefined;
  }, [onlySelectedTextItem?._id, onlySelectedTextItem?.variants, activeVariantName, activeVariantId]);

  const onDeleteVariant = useCallback(
    function _onDeleteVariant() {
      removeVariantFromTextItemsAction({ itemIds: selectedTextItemIds, variantId: activeVariantId });
    },
    [activeVariantId, removeVariantFromTextItemsAction, selectedTextItemIds]
  );

  // Update handler for text item's variant in Edit panel
  const onUpdateVariant = useCallback(
    (update: ITextItemVariantUpdate) => {
      if (!onlySelectedTextItem?._id) return;
      setUpdateTextItemVariantAction({ itemId: onlySelectedTextItem._id, update });
    },
    [onlySelectedTextItem?._id, setUpdateTextItemVariantAction]
  );

  // When the user clicks "Add variant" in the Edit panel, we switch the to the Variants tab with the addVariantsForm open
  // and the selected option as the active variant
  const onAddVariant = useCallback(() => {
    setDetailsPanelEditState("VARIANTS");
    setDetailsPanelProps({
      variants: {
        showAddForm: true,
        defaultVariant: {
          id: activeVariantId,
          name: activeVariantName ?? "",
        },
      },
    });
  }, [activeVariantId, activeVariantName, setDetailsPanelEditState, setDetailsPanelProps]);

  const onVariantFormHasChanges = useCallback(
    (hasChanges: boolean) => {
      setEditableTextItemVariantChanges({ [activeVariantId]: hasChanges });
    },
    [activeVariantId, setEditableTextItemVariantChanges]
  );

  const onContainerClick = useCallback(() => {
    stopInlineEditing({ skipConfirmation: true, skipFocus: true });
    // Hack because Chrome has a bug where unfocusing a different div
    // causes the active element to lose it's blinking cursor (FF works fine)
    setTimeout(() => {
      const activeElement = document.activeElement;
      if (activeElement instanceof HTMLTextAreaElement) {
        activeElement.blur();
        activeElement.focus();
      }
    }, 50);
  }, [stopInlineEditing]);

  return (
    <div
      className={classNames(style.wrapper, {
        [style.withInstanceWarning]: (libraryInstanceData?.instanceCount || 0) > 1,
      })}
      onClick={onContainerClick}
    >
      <TextEntityMetadata
        componentInstanceCounts={libraryInstanceData}
        statusAtom={selectedTextItemsStatusAtom}
        assigneeAtom={selectedTextItemsAssigneeAtom}
        tagsAtom={selectedTextItemsTagsAtom}
        notesAtom={selectedTextItemsNotesAtom}
        richTextAtom={selectedTextItemsRichTextAtom}
        onUnsavedChanges={setEditableHasChanges}
        resetFormSignal={resetFormSignal}
        selectedIds={selectedTextItemIds}
        onlySelectedItem={onlySelectedTextItem}
        usersByIdAtom={usersByIdAtom}
        allTagsAtom={unwrappedAllTagsAtom}
        onCreateNewTag={onCreateNewTag}
        handleSave={handleSave}
        onDeleteVariant={onDeleteVariant}
        RichTextInputComponent={RichTextInput}
        showVariantMetadata={showVariantMetadata}
        activeVariant={activeVariantProp}
        textItemVariant={textItemVariantProp}
        onUpdateVariant={onUpdateVariant}
        onAddVariant={onAddVariant}
        variantPlaceholderText={onlySelectedTextItem?.text}
        onVariantFormHasChanges={onVariantFormHasChanges}
      />
    </div>
  );
}

/**
 * Wrapper for our legacy TextItemTextInput component that wraps TipTap's rich text editor.
 * @param props Wrapper props:
 * - initialVariableRichValue: The value to pass down to the legacy TextItemTextInput component.
 *
 * @important
 * The initialVariableRichValue prop gets passed down to our legacy TextItemTextInput component, and passes down many layers of prop drilling
 * before being used to initialize the TipTap editor. It's important that the value here does *not* change on edit --
 * it's used for initializing editor state, and thus should only change when we're re-initializing the selected page
 * (e.g. changing our selection).
 */
export const RichTextInput = memo(
  forwardRef<RichTextInputRef, RichTextInputProps>(function RichTextInput(props: RichTextInputProps, ref) {
    const { handleCharacterLimitChange, legacyHandleTextChange, reset, accessEditorInstance } =
      useRichTextInputProps(props);

    // Expose reset function through ref
    useImperativeHandle(ref, () => ({ reset }), [reset]);

    const textLabelLeft = useMemo(() => <div></div>, []);

    return (
      <TextItemTextInputs
        hideTopLabels={true}
        useNSLabels
        textItem={props.initialVariableRichValue}
        legacyHandleTextChange={legacyHandleTextChange}
        readonly={false}
        isBaseText={true}
        isVariant={false}
        shouldShowRichText={true}
        handleCharacterLimitChange={handleCharacterLimitChange}
        characterLimit={props.characterLimit ?? null}
        textLabelLeft={textLabelLeft}
        disabled={props.richTextInputDisabled}
        pluralInputsDisabled={props.pluralsDisabled}
        variablesDisabled={props.variablesDisabled}
        characterLimitDisabled={props.characterLimitDisabled}
        placeholder={props.placeholder}
        emptyEditorClass={props.emptyEditorClass}
        accessEditorInstance={accessEditorInstance}
      />
    );
  })
);

export default MetadataPanel;
