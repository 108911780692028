import BetaBadge from "@/components/BetaBadge";
import {
  libraryCreateComponentModalIsOpenAtom,
  libraryCreateFolderModalIsOpenAtom,
  selectedLibraryFolderIdAtom,
  totalCountsForFolderAtomFamily,
} from "@/stores/Library";
import { clearLibraryFiltersAtom, isFilteringLibraryResultsAtom } from "@/stores/LibraryFiltering";
import { searchAtom } from "@/stores/Location";
import Button from "@ds/atoms/Button";
import Text from "@ds/atoms/Text";
import { LEARN_MORE_URL } from "@shared/frontend/learnMoreLinks";
import classNames from "classnames";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { useCallback, useMemo } from "react";
import style from "./style.module.css";

function LibraryItemsListEmptyState() {
  const isFilteringLibraryResults = useAtomValue(isFilteringLibraryResultsAtom);
  const [search] = useAtom(searchAtom);
  const isFilteringOrSearching = isFilteringLibraryResults || search;

  if (isFilteringOrSearching) {
    return <NoSearchResults />;
  }

  return <NoComponentsInLibrary />;
}

function NoComponentsInLibrary() {
  const setLibraryCreateComponentModalIsOpen = useSetAtom(libraryCreateComponentModalIsOpenAtom);
  const setLibraryCreateFolderModalIsOpen = useSetAtom(libraryCreateFolderModalIsOpenAtom);

  const onCreateComponent = useCallback(() => {
    setLibraryCreateComponentModalIsOpen(true);
  }, [setLibraryCreateComponentModalIsOpen]);

  const onCreateFolder = useCallback(() => {
    setLibraryCreateFolderModalIsOpen(true);
  }, [setLibraryCreateFolderModalIsOpen]);

  return (
    <div className={style.noComponentsInLibraryContainer}>
      <div className={style.center}>
        <div className={style.titleContainer}>
          <Text weight="strong" size="large">
            Create your first components
          </Text>
          <Text color="secondary" inline className={style.textCentered}>
            Build a library of text you can reuse across design projects and in development. Work faster and scale your
            content. <a href={LEARN_MORE_URL.LIBRARY}>Learn more</a>
          </Text>
        </div>
        <div className={style.actionsContainer}>
          <div className={style.actionCard} onClick={onCreateComponent}>
            <div className={style.actionCardContent}>
              <Text weight="medium">Draft a new component</Text>
              <Text color="secondary" size="small">
                Create a new text component from scratch
              </Text>
            </div>
          </div>
          <div className={style.actionCard}>
            <div className={style.actionCardContent} onClick={onCreateFolder}>
              <Text weight="medium">Create a folder</Text>
              <Text color="secondary" size="small">
                Organize components into folders
              </Text>
            </div>
          </div>
          <div className={classNames(style.actionCard, style.disabled)}>
            <div className={style.actionCardContent}>
              <Text weight="medium" color="secondary">
                Import a string file&nbsp;
                <BetaBadge className={style.comingSoonButton} text="Coming soon" />
              </Text>
              <Text color="secondary" size="small">
                Generate components from a spreadsheet or string file
              </Text>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function NoSearchResults() {
  const isFilteringLibraryResults = useAtomValue(isFilteringLibraryResultsAtom);
  const clearLibraryFilters = useSetAtom(clearLibraryFiltersAtom);
  const [search, setSearch] = useAtom(searchAtom);
  const currentFolderId = useAtomValue(selectedLibraryFolderIdAtom);
  const totalCounts = useAtomValue(totalCountsForFolderAtomFamily(currentFolderId ?? undefined));

  const handleClearFilters = useCallback(() => {
    clearLibraryFilters();
  }, [clearLibraryFilters]);

  const handleClearSearch = useCallback(() => {
    setSearch("");
  }, [setSearch]);

  const message = useMemo(() => {
    const components = totalCounts?.componentCount ?? 0;
    const folders = totalCounts?.folderCount ?? 0;

    let message = "";

    if (components === 0) {
      message = "No components found";
    } else {
      message = `There are ${components} components`;
    }

    if (folders > 0) {
      message += ` and ${folders} folders`;
    }

    if (currentFolderId) {
      message += ` in this folder`;
    } else {
      message += ` at the root of the library`;
    }

    if (search) {
      if (components === 0) {
        message += ` matching your search.`;
      } else {
        message += `, but none of them match your search.`;
      }
    } else if (isFilteringLibraryResults) {
      if (components === 0) {
        message += ` matching your selected filters.`;
      } else {
        message += `, but none of them match your filters.`;
      }
    } else {
      message += `.`;
    }

    return message;
  }, [search, isFilteringLibraryResults, currentFolderId, totalCounts]);

  return (
    <div className={style.noResultsContainer}>
      <div className={style.noResultsCard}>
        <Text>{search ? `Nothing found for "${search}"` : "Nothing found"}</Text>
        <Text color="secondary">{message}</Text>
        <div className={style.clearSearchContainer}>
          {!!search && (
            <Button onClick={handleClearSearch} size="micro" level="secondary">
              Clear search
            </Button>
          )}
          {isFilteringLibraryResults && (
            <Button onClick={handleClearFilters} size="micro" level="secondary">
              Clear filters
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}

export default LibraryItemsListEmptyState;
