import { Atom, useAtomValue } from "jotai";
import React, { Suspense, useCallback } from "react";

export type MaybeAtom<T> = T | Atom<T | Promise<T>>;

function IfAtom<T>(props: { value: Atom<T | Promise<T>>; render: (value: T) => React.ReactNode }) {
  const value = useAtomValue(props.value);
  return <>{props.render(value)}</>;
}

interface MaybeAtomProps<T> {
  value: MaybeAtom<T>;
  fallback?: React.ReactNode;
  children: (value: T) => React.ReactNode;
}

/**
 * MaybeAtom is useful for accepting a prop that can be `T` or `Atom<T>` and easily rendering JSX based off of it without
 * all of the boilerplate of managing suspense with nested components.
 *
 * Example usage:
 * ```tsx
 * function MyComponent(props: { text: MaybeAtom<string> }) {
 *  return (
 *    <MaybeAtom value={props.value} fallback="Loading...">
 *      {(value: string) => <span>{value}</span>}
 *    </MaybeAtom>
 *  )
 * }
 * ```
 */
export function MaybeAtom<T>(props: MaybeAtomProps<T>) {
  const { value } = props;
  const getIsAtom = useCallback((value: MaybeAtomProps<T>["value"]): value is Atom<T | Promise<T>> => {
    return typeof value === "object" && value !== null && "read" in value;
  }, []);

  if (getIsAtom(value) && props.fallback) {
    return (
      <Suspense fallback={props.fallback}>
        <IfAtom render={props.children} value={value} />
      </Suspense>
    );
  }

  if (getIsAtom(value)) {
    return <IfAtom render={props.children} value={value} />;
  }

  return <>{props.children(value)}</>;
}
