import { atom, Atom, useAtomValue } from "jotai";
import { selectAtom, unwrap } from "jotai/utils";
import isEqual from "lodash.isequal";
import React, { useCallback, useMemo, useRef } from "react";
import { zIndexModalCombobox } from "../../tokens/zIndex";
import BaseCombobox, { ComboboxOption } from "../BaseCombobox";

export interface IProps {
  className?: string;
  style?: React.CSSProperties;

  selectedFolder: { _id: string; name: string } | null;
  onChange: (folder: { _id: string; name: string } | null) => void;
  foldersAtom: Atom<{ _id: string; name: string }[] | Promise<{ _id: string; name: string }[]>>;
  dontShowCurrentFolder?: boolean;
  currentFolderId?: string | null;
}

export const ALL_COMPONENTS = { value: "__ALL_COMPONENTS__", label: "All Components" };

export function LibraryComponentFolderFilterDropdown(props: IProps) {
  const selectedFolderId = props.selectedFolder?._id ?? null;
  const { onChange } = props;

  const { current: optionsAtom } = useRef(
    atom(async (get) => {
      let folders = await get(props.foldersAtom);

      // Remove if in folder and we don't want to show it
      if (props.dontShowCurrentFolder && props.currentFolderId) {
        folders = folders.filter((folder) => folder._id !== props.currentFolderId);
      }

      // Skip adding ALL_COMPONENTS if we are in root and dontShowSelectedFolder is true
      if (props.dontShowCurrentFolder && !props.currentFolderId) {
        return folders.map((folder) => ({
          value: folder._id,
          label: folder.name,
        }));
      }

      return [ALL_COMPONENTS].concat(
        folders.map((folder) => ({
          value: folder._id,
          label: folder.name,
        }))
      );
    })
  );

  const optionSelectedAtom = useMemo(() => {
    const baseAtom = unwrap(
      atom(async (get) => {
        if (!selectedFolderId) return null;
        const options = await get(optionsAtom);
        const found = options.find((option) => option.value === selectedFolderId) ?? null;

        return found ? [found] : [];
      }),
      () => []
    );

    return selectAtom(baseAtom, (option) => option, isEqual);
  }, [optionsAtom, selectedFolderId]);

  const optionSelected = useAtomValue(optionSelectedAtom) ?? [];

  const setSelectedItemsCallback = useCallback(
    (options: ComboboxOption[]) => {
      onChange(options[0] ? { _id: options[0].value, name: options[0].label } : null);
    },
    [onChange]
  );

  return (
    <BaseCombobox
      optionsAtom={optionsAtom}
      selectedItems={optionSelected}
      setSelectedItems={setSelectedItemsCallback}
      placeholder="Choose a destination..."
      zIndex={zIndexModalCombobox}
    />
  );
}

Object.assign(LibraryComponentFolderFilterDropdown, {
  isAllComponentsOption: (folderId: string) => folderId === ALL_COMPONENTS.value,
});

export default LibraryComponentFolderFilterDropdown as typeof LibraryComponentFolderFilterDropdown & {
  isAllComponentsOption: (folderId: string) => boolean;
};
