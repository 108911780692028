import {
  libraryComponentFolderFamilyAtom,
  moveLibraryComponentFolderActionAtom,
  reorderLibraryComponentFoldersActionAtom,
} from "@/stores/ComponentFolders";
import {
  libraryDraggedSelectionAtom,
  navigateToLibraryFolderActionAtom,
  reorderLibraryComponentsActionAtom,
} from "@/stores/Library";
import { DragLocation } from "@ds/atoms/DragAndDroppable";
import Icon from "@ds/atoms/Icon";
import NavItem from "@ds/molecules/NavItem";
import FolderIcon from "@mui/icons-material/Folder";
import { ILibraryComponentFolderItem } from "@shared/types/Library";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { memo, useCallback } from "react";
import { z } from "zod";
import style from "./style.module.css";

const allowedItemKeys = { "ditto/componentItem": z.string(), "ditto/folder": z.string() };

const LibraryComponentFolderNavItem = memo(function LibraryComponentFolderNavItem(props: {
  item: ILibraryComponentFolderItem;
  edgeThreshold?: number;
  disableCustomPreview?: boolean;
}) {
  const folder = useAtomValue(libraryComponentFolderFamilyAtom(props.item._id));
  const edgeThreshold = props.edgeThreshold; // Default to 15% threshold if not provided

  const navigateToFolder = useSetAtom(navigateToLibraryFolderActionAtom);
  const reorderLibraryComponentsAction = useSetAtom(reorderLibraryComponentsActionAtom);
  const moveFolder = useSetAtom(moveLibraryComponentFolderActionAtom);
  const [libraryDraggedSelection, setLibraryDraggedSelection] = useAtom(libraryDraggedSelectionAtom);
  const reorderFolders = useSetAtom(reorderLibraryComponentFoldersActionAtom);

  const handleDrop = useCallback(
    (componentIds: string[], dragLocation: DragLocation, dropKey: keyof typeof allowedItemKeys) => {
      if (dropKey === "ditto/folder") {
        const droppedFolderId = componentIds[0];

        if (dragLocation === "above-edge" || dragLocation === "below-edge") {
          reorderFolders({
            folderId: droppedFolderId,
            before: dragLocation === "above-edge" ? folder._id : undefined,
            after: dragLocation === "below-edge" ? folder._id : undefined,
          });
          return;
        }

        moveFolder({
          folderId: droppedFolderId,
          targetFolderId: folder._id,
        });
      } else {
        reorderLibraryComponentsAction([
          {
            componentIds,
            folderId: folder._id,
          },
        ]);
      }
    },
    [folder._id, reorderLibraryComponentsAction, moveFolder, reorderFolders]
  );

  const handleDragStart = useCallback(
    function _handleDragStart() {
      setLibraryDraggedSelection({ _id: props.item._id, type: "folder", origin: "left-sidebar" });
    },
    [setLibraryDraggedSelection, props.item._id]
  );

  const handleFolderClick = useCallback(
    function _handleFolderClick() {
      navigateToFolder(props.item._id);
    },
    [navigateToFolder, props.item._id]
  );

  const handleDragEnd = useCallback(() => setLibraryDraggedSelection(null), [setLibraryDraggedSelection]);

  const showDropIndicatorsOnEdgeOnly = libraryDraggedSelection?.type === "folder" && edgeThreshold !== undefined;

  return (
    <NavItem
      id={props.item._id}
      type="item"
      icon={<Icon Icon={<FolderIcon />} size="xs" color="secondary" className={style.folderIcon} />}
      label={folder.name}
      onClick={handleFolderClick}
      dragAndDrop={{
        allowedItemKeys,
        selectionType: libraryDraggedSelection?.type ?? "none",
        onDrop: handleDrop,
        onDragStart: handleDragStart,
        onDragEnd: handleDragEnd,
        getDraggableItems: () => [{ "ditto/folder": props.item._id }],
      }}
      highlightDropTarget
      disableCustomPreview={props.disableCustomPreview}
      dropTargetType="folder"
      showDropIndicators
      edgeThreshold={showDropIndicatorsOnEdgeOnly ? edgeThreshold : undefined}
    />
  );
});

export default LibraryComponentFolderNavItem;
