import React from "react";

interface IProps {
  className?: string;
  size: number; // Diamater & viewbox size
  color: string;
}

export function Dot(props: IProps) {
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox={`0 0 ${props.size} ${props.size}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <circle cx={props.size / 2} cy={props.size / 2} r={props.size / 2} fill={`var(--${props.color})`} />
    </svg>
  );
}

export default Dot;
