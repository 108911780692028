import { z } from "zod";
import { ZObjectId } from "./lib";

export const ZFolder = z.object({
  _id: ZObjectId,
  name: z.string(),
  workspace_id: ZObjectId,
  doc_ids: z.array(ZObjectId),
  invite_only: z.boolean(),
  createdAt: z.date().optional(),
  updatedAt: z.date().optional(),
});

export type IFolder = z.infer<typeof ZFolder>;
