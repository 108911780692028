import useSegment from "@/hooks/useSegment";
import ChangeItem from "@ds/molecules/ChangeItem";
import { CustomChangeItemComponentProps } from "@ds/organisms/ChangeItemList";
import { TrackArgs } from "@shared/types/analytics";
import React, { useCallback } from "react";

function TextItemActivityItem({ changeItem }: CustomChangeItemComponentProps) {
  const { track } = useSegment();

  const sendTrackingEvent = useCallback(
    ({ event, properties }: TrackArgs) => {
      track({ event, properties: { version: "NS", application: "web_app", ...properties } });
    },
    [track]
  );

  return <ChangeItem changeItem={changeItem} sendTrackingEvent={sendTrackingEvent} />;
}

export default TextItemActivityItem;
