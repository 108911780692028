import { Types } from "mongoose";
import { z } from "zod";
import { ICommentThread } from "./CommentThread";
import { ZDFVariable, ZDFVariableCollection } from "./FigmaVariables";
import { IFeatureFlags, ZFeatureFlags } from "./Project";
import { SlackNotificationTypes } from "./SlackNotifications";
import { ActualComponentSchema, ZTextItem } from "./TextItem";
import { ZDate, ZObjectId } from "./lib";

const ZSelectedPageSchema = z.object({
  name: z.string(),
  figma_id: z.string(),
});

export type ISelectedPageSchema = z.infer<typeof ZSelectedPageSchema>;

const ZDocumentIntegrationFigmaSchemaType = z.object({
  file_id: z.string(),
  file_name: z.string(),
  branch_id: z.string(),
  selected_pages: z.array(ZSelectedPageSchema),
  resynced_at: ZDate,
  previews_updated_at: ZDate,
  ditto_component_key: z.string(),
  position: z.object({
    x: z.number(),
    y: z.number(),
    width: z.number(),
    height: z.number(),
  }),
  text_layer_rules: z.object({
    show_synced_frame: z.boolean(),
    show_component_icon: z.boolean(),
    show_status_icon: z.boolean(),
    show_api_id: z.boolean(),
  }),
  variableCollections: z.array(ZDFVariableCollection),
  variables: z.array(ZDFVariable),
});

export type DocumentIntegrationFigmaSchemaType = z.infer<typeof ZDocumentIntegrationFigmaSchemaType>;

const ZDocumentIntegrationsSchemaType = z.object({
  figma: ZDocumentIntegrationFigmaSchemaType.partial(),
  slack: z.object({
    channel_id: z.string().nullable(),
    channel_name: z.string().nullable(),
    webhook_url: z.string().nullable(),
    configuration_url: z.string().nullable(),
    notif_types: SlackNotificationTypes,
  }),
});

export type DocumentIntegrationsSchemaType = z.infer<typeof ZDocumentIntegrationsSchemaType>;

export interface PopulatedBlock extends Block {
  comps: ActualComponentSchema[];
}

export interface Block {
  name: string;
  apiID: string | null;
  comps: (Types.ObjectId | ActualComponentSchema)[];
  _id: Types.ObjectId;
}

export type PopulatedGroup = PopulatedGroupUnlinkable | PopulatedGroupLinkable | PopulatedGroupLinked;

export interface GroupIntegrationsSchemaType {
  figma: GroupIntegrationFigmaType | {};
}

export interface GroupBase {
  _id: Types.ObjectId;
  name: string;
  apiID: string | null;
  applied_variant_id: Types.ObjectId | null;
  figma_artboard_ID: string;
  figma_page_id: string | null;
  previews: {
    fullsize: string;
  };
  state: "ERROR" | "NONE";
  blocks: Block[];
  comps: (Types.ObjectId | ActualComponentSchema)[];
  pinned: boolean;
  hidden: boolean;
  nonblocks_collapsed: boolean;
  linking_enabled: boolean;
  integrations: GroupIntegrationsSchemaType | {};
  dontShowSetupSuggestions?: true;
}

interface PopulatedGroupBase extends GroupBase {
  comps: ActualComponentSchema[];
  blocks: PopulatedBlock[];
}

interface PopulatedGroupUnlinkable extends PopulatedGroupBase {
  linking_enabled: false;
  integrations: { figma: Partial<GroupIntegrationFigmaType> };
}

export interface GroupUnlinkable extends GroupBase {
  linking_enabled: false;
  integrations: { figma: Partial<GroupIntegrationFigmaType> };
}

interface PopulatedGroupLinkable extends PopulatedGroupBase {
  linking_enabled: true;
  integrations: { figma: Partial<GroupIntegrationFigmaType> };
}
export interface GroupLinkable extends GroupBase {
  linking_enabled: true;
  integrations: { figma: Partial<GroupIntegrationFigmaType> };
}

interface PopulatedGroupLinked extends PopulatedGroupBase {
  linking_enabled: true;
  integrations: {
    figma: GroupIntegrationFigmaType;
  };
}

export interface GroupLinked extends GroupBase {
  linking_enabled: true;
  integrations: {
    figma: GroupIntegrationFigmaType;
  };
}

export type Group = GroupUnlinkable | GroupLinkable | GroupLinked;

export const isGroupUnlinkable = (g: Group): g is GroupUnlinkable => !g.linking_enabled;

export const isGroupLinkable = (g: Group): g is GroupLinkable =>
  g.linking_enabled && !(g.integrations.figma as any).frame_id;

export const isGroupLinked = (g: Group): g is GroupLinked =>
  g.linking_enabled && (g.integrations.figma as any).frame_id && (g.integrations.figma as any).page_id;

export interface GroupIntegrationFigmaType {
  frame_id: string;
  frame_name: string;
  frame_name_synced_with_group_name: boolean;
  page_id: string;
  previews: {
    fullsize: string;
  };
  applied_variant_id: Types.ObjectId | null;
  position: {
    x: number;
    y: number;
    width: number;
    height: number;
  };
  /**
   * This field is set by resync when it detects a missing frame. It should be set to false wherever
   * that frame is fixed -- either in the confirm-frame-removal job, in the plugin, or elsewhere.
   */
  needsMissingFrameHandling: boolean;
}

export const ZActualDocument = z.object({
  _id: ZObjectId,
  workspace_ID: ZObjectId,
  developer_mode_enabled: z.boolean(),
  integrations: ZDocumentIntegrationsSchemaType,
  feature_flags: ZFeatureFlags,
  figma_branch_id: z.string(),
  doc_name: z.string(),
  tags: z.array(z.string()),
  groups: z.array(z.any()), // TODO: This should be a zod version of Group
  graveyard: z.array(z.union([ZObjectId, ZTextItem])),
  date_time_created: ZDate,
  time_last_resync: ZDate,
  time_last_resync_plugin: ZDate.nullable(),
  edited_at: ZDate,
  edited_by: ZObjectId.nullable(),
  previewsUpdatedAt: ZDate,
  folder_id: ZObjectId.nullable(),
  is_locked: z.boolean(),
  isSample: z.boolean(),
});

export interface ActualDocumentSchema {
  _id: Types.ObjectId;
  workspace_ID: Types.ObjectId;
  developer_mode_enabled: boolean;
  integrations: DocumentIntegrationsSchemaType;
  feature_flags: IFeatureFlags;
  figma_branch_id: string;
  doc_name: string;
  tags: string[];
  groups: Group[];
  graveyard: Types.ObjectId[] | ActualComponentSchema[];
  date_time_created: Date;
  time_last_resync: Date;
  time_last_resync_plugin: Date | null;
  edited_at: Date;
  edited_by: Types.ObjectId | null;
  previewsUpdatedAt: Date;
  folder_id: Types.ObjectId | null;
  is_locked: boolean;
  isSample: boolean;
}

export interface PopulatedActualDocumentSchema extends Omit<ActualDocumentSchema, "groups" | "graveyard"> {
  graveyard: ActualComponentSchema[];
  groups: PopulatedGroup[];
}

export const arePopulatedComponents = (components: Group["comps"]): components is ActualComponentSchema[] =>
  !components[0] || ("_id" in components[0] && "text" in components[0]);

export const arePopulatedCommentThreads = (
  commentThreads: ActualComponentSchema["comment_threads"]
): commentThreads is ICommentThread[] =>
  commentThreads[0] && "_id" in commentThreads[0] && "comments" in commentThreads[0];

export const isProjectLinked = <
  T extends {
    integrations: ActualDocumentSchema["integrations"];
  }
>(
  project: T
): project is T & {
  integrations: {
    figma: DocumentIntegrationFigmaSchemaType;
    slack: T["integrations"]["slack"];
  };
} => {
  if (!project.integrations) return false;
  if (!project.integrations.figma) return false;
  if (!(project.integrations.figma as any).file_id) return false;
  return true;
};

export const DRAFTED_GROUPS_PAGE_NAME = "Drafted Frames";

/**
 * Given a project, returns a function that takes a group and returns the name of the page that the group is on.
 */
export function createPageNameGetter(project: ActualDocumentSchema) {
  const pageNamesById = new Map<string, string>();
  if (isProjectLinked(project)) {
    project.integrations.figma.selected_pages.forEach((page) => {
      pageNamesById.set(page.figma_id, page.name);
    });
  }

  const fn = (group: Group) => {
    // this should never happen but is an extra safety net since this
    // function is called from within a couple of JavaScript files
    if (!group) {
      return "";
    }

    if (!isGroupLinked(group)) {
      return DRAFTED_GROUPS_PAGE_NAME;
    }

    return pageNamesById.get(group.integrations.figma.page_id) || "";
  };

  /**
   * In some cases we might have a page id but not have access to the main group;
   * adding the map as a property on the function allows us to access it in
   * special cases.
   */
  fn.byPageId = (pageId: string) => pageNamesById.get(pageId);

  return fn;
}
