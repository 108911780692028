import FolderIcon from "@mui/icons-material/FolderOutlined";
import React from "react";
import Badge from "../../atoms/Badge";
import Icon from "../../atoms/Icon";
import Text from "../../atoms/Text";
import style from "./index.module.css";

interface IProps {
  className?: string;
  style?: React.CSSProperties;

  /**
   * Name of folder being deleted.
   */
  selectedFolderName: string;
  /**
   * Number of child components in the folder being deleted.
   */
  childComponentsCount: number;
  /**
   * Number of child folders in the folder being deleted.
   */
  childFoldersCount: number;
  /**
   * Name of parent folder of the folder being deleted.
   */
  folderParentName: string;
}

function DeleteFolderModalContent(props: IProps) {
  return (
    <div className={style.DeleteFolderModalContentWrapper}>
      <Text className={style.DeleteFolderModalContentText} color="secondary">
        {props.childComponentsCount > 0 &&
          `${props.childComponentsCount} component${props.childComponentsCount === 1 ? "" : "s"}`}
        {props.childComponentsCount > 0 && props.childFoldersCount > 0 && " and "}
        {props.childFoldersCount > 0 && `${props.childFoldersCount} folder${props.childFoldersCount === 1 ? "" : "s"}`}
        {" in "}
        <Badge className={style.folderBadgeWrapper} type="minimal" size="sm">
          <Icon Icon={<FolderIcon />} color="secondary" size="xs" />
          <Text className={style.folderBadgeText} color="primary" inline>
            {props.selectedFolderName}
          </Text>
        </Badge>
        {" will be moved to "}
        <Badge className={style.folderBadgeWrapper} type="minimal" size="sm">
          <Icon Icon={<FolderIcon />} color="secondary" size="xs" />
          <Text className={style.folderBadgeText} color="primary" inline>
            {props.folderParentName}
          </Text>
        </Badge>
      </Text>
    </div>
  );
}

export default DeleteFolderModalContent;
