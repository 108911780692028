import ChevronLeft from "@mui/icons-material/ChevronLeft";
import FilterIcon from "@mui/icons-material/FilterList";
import { IDittoProject } from "@shared/types/DittoProject";
import { ITextItemStatus } from "@shared/types/TextItem";
import { Atom } from "jotai";
import React, { useMemo } from "react";
import Button from "../../atoms/Button";
import Icon from "../../atoms/Icon";
import Text from "../../atoms/Text";
import AddFiltersDropdown from "../../molecules/AddFiltersDropdown";
import ProjectFilterDropdown from "../../molecules/ProjectFilterDropdown";
import StatusFilterDropdown from "../../molecules/StatusFilterDropdown";
import TagsFilterDropdown from "../../molecules/TagsFilterDropdown";
import style from "./index.module.css";
import { FilterOptions, FilterType } from "./useCompactTextEntityList";

interface IOption<T> {
  value: T;
  label: string;
}

interface IFiltersAreaProps {
  inFolder: boolean;
  selectedFilters: string[];
  handleRemoveFilter: (filter: FilterType) => void;
  handleClearFilters: () => void;
  handleBackFolderClick: () => void;
  headerText: string;
  formattedSelectedFilters: IOption<string>[];
  handleSetSelectedFilters: (filters: IOption<string>[]) => void;
  selectedTags: string[];
  setSelectedTags: (tags: string[]) => void;
  tagsAtom: Atom<string[] | Promise<string[]>>;
  selectedStatuses: ITextItemStatus[];
  setSelectedStatuses: (statuses: ITextItemStatus[]) => void;
  selectedProjectId: string | null;
  setSelectedProjectId: (projectId: string | null) => void;
  projectsAtom: Atom<IDittoProject[] | Promise<IDittoProject[]>>;
  title?: string;
  handleTitleBackClick?: () => void;
  enabledFilters: FilterType[];
}

function FiltersArea(props: IFiltersAreaProps) {
  const formattedFilterOptions = useMemo(
    () =>
      props.enabledFilters.map((filter) => ({
        value: filter,
        label: FilterOptions[filter],
      })),
    [props.enabledFilters]
  );
  const computedHeaderText = useMemo(() => {
    // Return the custom title if it exists unless we are in a folder.
    if (props.title && !props.inFolder) return props.title;
    else return props.headerText;
  }, [props.headerText, props.inFolder, props.title]);

  return (
    <div className={style.filtersArea}>
      {props.selectedFilters.length > 0 && (
        <div className={style.filters}>
          <div className={style.left}>
            {props.selectedFilters.map((filter) => {
              if (filter === "tags") {
                return (
                  <TagsFilterDropdown
                    key={filter}
                    triggerClassName={style.tagsTrigger}
                    autoFocus
                    selectedTags={props.selectedTags}
                    setSelectedTags={props.setSelectedTags}
                    tagsAtom={props.tagsAtom}
                    onRemoveFilter={() => props.handleRemoveFilter(filter)}
                  />
                );
              } else if (filter === "status") {
                return (
                  <StatusFilterDropdown
                    key={filter}
                    autoFocus
                    value={props.selectedStatuses}
                    onChange={props.setSelectedStatuses}
                    onRemoveFilter={() => props.handleRemoveFilter(filter)}
                  />
                );
              } else if (filter === "usedInProject") {
                return (
                  <div className={style.usedInProjectFilter} key={filter}>
                    <Text color="secondary" size="micro" style={{ whiteSpace: "nowrap" }}>
                      Used in:
                    </Text>
                    <ProjectFilterDropdown
                      autoFocus
                      key={filter}
                      size="micro"
                      selectedProjectId={props.selectedProjectId}
                      onChange={props.setSelectedProjectId}
                      projectsAtom={props.projectsAtom}
                    />
                  </div>
                );
              } else return <React.Fragment key={filter} />;
            })}
          </div>

          <div className={style.right}>
            <Button level="subtle" size="micro" onClick={props.handleClearFilters}>
              Clear filters
            </Button>
          </div>
        </div>
      )}

      <div className={style.titleAndButtonRow}>
        {props.inFolder && (
          <Button type="icon" level="secondary" onClick={props.handleBackFolderClick}>
            <Icon Icon={<ChevronLeft />} />
          </Button>
        )}
        {!props.inFolder && props.title && (
          <Button type="icon" level="secondary" onClick={props.handleTitleBackClick}>
            <Icon color="secondary" Icon={<ChevronLeft />} />
          </Button>
        )}

        <Text className={style.headerText} weight={props.inFolder ? "base" : "strong"} size="small">
          {computedHeaderText}
        </Text>

        <AddFiltersDropdown
          size="small"
          Icon={<FilterIcon />}
          options={formattedFilterOptions}
          selectedOptions={props.formattedSelectedFilters}
          setSelectedOptions={props.handleSetSelectedFilters}
          hideToggledState
          alignPopover="end"
          popoverClassName={style.addFiltersDropdownPopover}
        />
      </div>
    </div>
  );
}

export default FiltersArea;
