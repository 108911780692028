import { composeCallbacks, useAutoScroll } from "@/hooks/useAutoScroll";
import { reorderTextItemsActionAtom } from "@/stores/Editing";
import {
  blockFamilyAtom,
  INavBlockItem,
  isValidBlock,
  moveBlocksActionAtom,
  projectBlocksAtom,
} from "@/stores/Project";
import { onClickBlockActionAtom, selectedBlockIdAtom, selectionTypeAtom } from "@/stores/ProjectSelection";
import { DragLocation } from "@ds/atoms/DragAndDroppable";
import Icon from "@ds/atoms/Icon";
import BlockIcon from "@ds/icons/BlockIcon";
import NavItem from "@ds/molecules/NavItem";
import { useAtomValue, useSetAtom } from "jotai";
import { useAtomCallback } from "jotai/react/utils";
import { memo, useCallback, useMemo, useState } from "react";
import { DragStartEvent } from "react-aria";
import { z } from "zod";
import { leftSidebarScrollRefAtom } from "..";
import { textItemListScrollRefAtom } from "../../../TextItemList";
import MessageNavItem from "../MessageNavItem";
import TextNavItem from "../TextNavItem";

const BlockNavItem = memo(function BlockNavItem(props: { item: INavBlockItem; disableCustomPreview?: boolean }) {
  const selectionType = useAtomValue(selectionTypeAtom);
  const block = useAtomValue(blockFamilyAtom(props.item._id));
  const selectedBlockId = useAtomValue(selectedBlockIdAtom);
  const textItemScrollContainer = useAtomValue(textItemListScrollRefAtom);
  const leftSidebarScrollContainer = useAtomValue(leftSidebarScrollRefAtom);
  const isCollapsed = useAtomValue(props.item.collapsedAtom);

  const [isDragging, setIsDragging] = useState(false);

  const moveBlockItemsAction = useSetAtom(moveBlocksActionAtom);
  const reorderTextItemsAction = useSetAtom(reorderTextItemsActionAtom);
  const onClickBlockAction = useSetAtom(onClickBlockActionAtom);

  const projectBlocksAtoms = useAtomCallback((get) => get(projectBlocksAtom));
  const scrollProps = useAutoScroll(leftSidebarScrollContainer, textItemScrollContainer);

  const isSelected = selectedBlockId === props.item._id;

  const getDraggableItems = useCallback(
    function _getDraggableItems() {
      return [
        {
          "ditto/blockItem": block._id,
          "plain/text": block._id,
        },
      ];
    },
    [block._id]
  );

  async function handleDrop(itemIds: string[], dragLocation: DragLocation) {
    /**
     * When dropping text items onto a block in the left sidebar, if the block is collapsed, insert at end of block children.
     * Otherwise, insert as first child in block.
     */
    if (selectionType === "text") {
      const blocks = await projectBlocksAtoms();
      const currentBlock = blocks.find((b) => b._id === props.item._id);
      const referenceTextItem = isCollapsed
        ? currentBlock?.allTextItems[currentBlock.allTextItems.length - 1]
        : currentBlock?.allTextItems[0];

      reorderTextItemsAction([
        {
          textItemIds: itemIds,
          blockId: props.item._id,
          ...(isCollapsed ? { after: referenceTextItem?._id } : { before: referenceTextItem?._id }),
        },
      ]);
    } else if (selectionType === "block") {
      moveBlockItemsAction({
        blockIds: itemIds,
        destinationBlockId: block._id,
        direction: dragLocation as "above" | "below" | null,
      });
    }
  }

  const onClick = useCallback(() => {
    onClickBlockAction(props.item._id);
  }, [props.item._id, onClickBlockAction]);

  const onDragStart = useCallback(
    (e: DragStartEvent) => {
      if (props.item._id) {
        onClickBlockAction(props.item._id);
        setIsDragging(true);
      }
    },
    [props.item._id, onClickBlockAction, setIsDragging]
  );

  const resetDragState = useCallback(() => {
    setIsDragging(false);
  }, [setIsDragging]);

  const onDragEnd = useCallback(() => {
    resetDragState();
  }, [resetDragState]);

  const onDragCancel = useCallback(() => {
    resetDragState();
  }, [resetDragState]);

  const groupChildren = useMemo(() => {
    return props.item.children.map((child) => {
      if (child.type === "text") {
        return (
          <TextNavItem
            key={child._id}
            item={child}
            isNested
            parentSelected={isSelected}
            disabled={isDragging}
            disableCustomPreview={props.disableCustomPreview}
          />
        );
      }
      // Currently, we render message items for # items in block that don't match search filter query
      // e.g. "3 text items not shown"
      return <MessageNavItem key={child._id} item={child} isNested labelOptions={{ color: "secondary" }} />;
    });
  }, [props.item.children, isSelected, isDragging, props.disableCustomPreview]);

  if (!isValidBlock(block)) return <></>;

  const dragProps = composeCallbacks([scrollProps, { onDragStart, onDragEnd, onDragCancel }]);

  return (
    <NavItem
      id={block._id}
      type="group"
      label={block.name}
      icon={<Icon Icon={<BlockIcon />} size="xs" />}
      dragAndDrop={{
        ...dragProps,
        allowedItemKeys: { "ditto/blockItem": z.string(), "ditto/textItem": z.string() },
        selectionType,
        getDraggableItems,
        onDrop: handleDrop,
        onDragStart,
      }}
      onClick={onClick}
      selected={isSelected}
      isCollapsedAtom={props.item.collapsedAtom}
      showDropIndicators
      disableCustomPreview={props.disableCustomPreview}
      dropTargetType="block"
      groupChildren={groupChildren}
    />
  );
});

export default BlockNavItem;
