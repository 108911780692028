import { IListWorkspaceTagsResponse } from "@shared/client/routes/workspace.schema";
import classNames from "classnames";
import { atom, Getter, useAtom, useAtomValue, WritableAtom } from "jotai";
import { useAtomCallback } from "jotai/utils";
import debounce from "lodash.debounce";
import React, {
  ForwardRefExoticComponent,
  RefAttributes,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  MergedAssigneeMetadata,
  MergedNotesMetadata,
  MergedStatusMetadata,
  MergedTagsMetadata,
} from "../../../../shared/frontend/lib/metadataHelpers";
import { SoonAtom } from "../../../../shared/frontend/stores/types";
import { isDiffRichText } from "../../../../shared/lib/text";
import { ILibraryComponent } from "../../../../shared/types/LibraryComponent";
import { RichTextInputProps, RichTextInputRef } from "../../../../shared/types/RichText";
import {
  ITextItem,
  ITextItemStatus,
  ITextItemVariableRichValue,
  ITextItemVariant,
  ITextItemVariantUpdate,
  ITipTapRichText,
} from "../../../../shared/types/TextItem";
import { IUser } from "../../../../shared/types/User";
import MissingFontWarning from "../../molecules/MissingFontWarning/index";
import TextItemVariant from "../TextItemVariant";
import TextItemVariantNotPresent from "../TextItemVariantNotPresent";
import CTAButtons from "./CTAButtons";
import EditableComponentName from "./EditableComponentName";
import EditAssignee from "./EditAssignee";
import EditNotes from "./EditNotes";
import EditStatus from "./EditStatus";
import EditTags from "./EditTags";
import style from "./index.module.css";
import InstancesNotice from "./InstancesNotice";
import RichTextInputSection from "./RichTextInputSection";

export type EditMetadataAtom<Value, Payload> = WritableAtom<Value | Promise<Value>, [Payload], Promise<boolean>>;

/**
 * Copied from file://./../../../../plugin-figma/src/ui/figma/NS/stores/Selection.ts
 */
type FontInfo =
  | { isSingleSelect: false }
  | { isSingleSelect: true; hasMissingFont: false }
  | { isSingleSelect: true; hasMissingFont: true; isTextMismatch: boolean };

interface IProps {
  className?: string;
  style?: React.CSSProperties;
  statusAtom: EditMetadataAtom<MergedStatusMetadata, ITextItemStatus>;
  assigneeAtom: EditMetadataAtom<MergedAssigneeMetadata, string | null>;
  tagsAtom: EditMetadataAtom<MergedTagsMetadata, string[]>;
  notesAtom: EditMetadataAtom<MergedNotesMetadata, string>;
  richTextAtom: EditMetadataAtom<ITipTapRichText | null, ITipTapRichText>;
  componentNameAtom?: EditMetadataAtom<string | null, string>;
  /**
   * If specified, may show warning for multi-instance edits.
   */
  componentInstanceCounts?: { instanceCount: number; projectCount: number } | null;
  onUnsavedChanges?: (hasChanges: boolean) => void;
  selectedIds: string[];
  onlySelectedItem: ITextItem | ILibraryComponent | null;
  usersByIdAtom: SoonAtom<Record<string, IUser>>;
  allTagsAtom: SoonAtom<IListWorkspaceTagsResponse>;
  resetFormSignal?: boolean;
  onCreateNewTag: (value: string) => void;
  handleSave: ({
    hasComponentNameChanges,
    hasRichTextChanges,
    localStateComponentName,
    localStateRichText,
  }: {
    hasComponentNameChanges?: boolean;
    hasRichTextChanges?: boolean;
    localStateComponentName?: string | null;
    localStateRichText: ITipTapRichText | null;
  }) => Promise<void>;
  onUpdateVariant?: (update: ITextItemVariantUpdate) => void;
  onAddVariant?: () => void;
  onVariantFormHasChanges?: (hasChanges: boolean) => void;
  onDeleteVariant: (variantId: string) => void;
  showVariantMetadata?: boolean;
  activeVariant?: { id: string; name: string };
  textItemVariant?: ITextItemVariant & { variantId: string; textItemId: string };
  variantPlaceholderText?: string;
  inputSize?: "base" | "small";
  RichTextInputComponent: ForwardRefExoticComponent<RichTextInputProps & RefAttributes<RichTextInputRef>>;
  readonlyTextInput?: boolean;
  selectionFontInfo?: FontInfo;
}

/**
 * Used for notes since we don't want to fire requests when the user is typing.
 */
const debouncedAutoSaveNotes = debounce((newNotes: string, submitNotesUpdate: (newNotes: string) => void) => {
  submitNotesUpdate(newNotes);
}, 1000);

export function TextEntityMetadata(props: IProps) {
  // Atoms for reading and writing metadata that is persisted in jotai/backend
  const [persistedNotes, setPersistedNotes] = useAtom(props.notesAtom);
  const persistedRichText = useAtomValue(props.richTextAtom);
  const componentNameAtom = useMemo(() => props.componentNameAtom ?? atom(null), [props.componentNameAtom]);
  const persistedComponentName = useAtomValue(componentNameAtom);

  // Local state values for any metadata that is not immediately persisted to the backend/jotai
  const [localStateRichText, setLocalStateRichText] = useState(persistedRichText);
  const [localStateComponentName, setLocalStateComponentName] = useState(persistedComponentName);
  const [localStateNotes, setLocalStateNotes] = useState(persistedNotes);

  // Tracking changes to metadata that requires explicit save
  const [hasRichTextChanges, setHasRichTextChanges] = useState(false);
  const [hasComponentNameChanges, setHasComponentNameChanges] = useState(false);
  const hasUnsavedChanges = hasRichTextChanges || hasComponentNameChanges;
  const richTextInputRef = useRef<RichTextInputRef>(null);

  const { onUnsavedChanges } = props;

  useEffect(
    function updateHasUnsavedChangesAtom() {
      onUnsavedChanges?.(hasUnsavedChanges);
    },
    [hasUnsavedChanges, onUnsavedChanges]
  );

  // Reset local state when the persisted values change
  useEffect(() => setLocalStateNotes(persistedNotes), [persistedNotes]);
  useEffect(() => setLocalStateRichText(persistedRichText), [persistedRichText]);
  useEffect(() => setLocalStateComponentName(persistedComponentName), [persistedComponentName]);

  // This debounced method will be passed as the onChange, so we don't fire requests on every keystroke.
  const debouncedSetNotes = useCallback(
    (newNotes: string) => {
      setLocalStateNotes(newNotes);
      debouncedAutoSaveNotes(newNotes, setPersistedNotes);
    },
    [setPersistedNotes]
  );

  // This method will be passed as the onBlur, so we can fire a request
  // immediately when the user hits enter, clicks out, or changes selection.
  const immediateSetNotes = useCallback(
    (newNotes: string) => {
      debouncedAutoSaveNotes.cancel();
      setLocalStateNotes(newNotes);
      setPersistedNotes(newNotes);
    },
    [setPersistedNotes]
  );

  const handleChangeRichText = useCallback(
    (richText: ITipTapRichText) => {
      setLocalStateRichText(richText);
      setHasRichTextChanges(isDiffRichText(persistedRichText, richText));
    },
    [persistedRichText]
  );

  const handleChangeComponentName = useCallback(
    (newName: string) => {
      setLocalStateComponentName(newName);
      setHasComponentNameChanges(persistedComponentName !== newName);
    },
    [persistedComponentName]
  );

  // Reset all local values that are allowed to drift from the persisted values
  // This includes any values that auto-save on a delay
  const resetLocalState = useAtomCallback(
    useCallback(
      async (get: Getter) => {
        setLocalStateNotes(await get(props.notesAtom));
        setLocalStateRichText(await get(props.richTextAtom));
        setLocalStateComponentName(await get(componentNameAtom));
        setHasRichTextChanges(false);
        setHasComponentNameChanges(false);
      },
      [props.notesAtom, props.richTextAtom, componentNameAtom]
    )
  );

  useLayoutEffect(
    function resetLocalStateOnSelectionChange() {
      resetLocalState();
    },
    [props.selectedIds, resetLocalState]
  );

  // Revert any unsaved changes to fields that require explicit save
  const revertUnsavedChanges = useAtomCallback(
    useCallback(
      async (get: Getter) => {
        richTextInputRef.current?.reset(); // Resets the tiptap editor
        setLocalStateRichText(await get(props.richTextAtom));
        setLocalStateComponentName(await get(componentNameAtom));
        setHasRichTextChanges(false);
        setHasComponentNameChanges(false);
      },
      [props.richTextAtom, componentNameAtom]
    )
  );

  useEffect(
    function revertUnsavedChangesOnExternalSignal() {
      revertUnsavedChanges();
    },
    [props.resetFormSignal, revertUnsavedChanges]
  );

  const handleClickSave = useCallback(async () => {
    await props.handleSave({
      hasRichTextChanges,
      hasComponentNameChanges,
      localStateRichText,
      localStateComponentName,
    });
    setHasRichTextChanges(false);
    setHasComponentNameChanges(false);
  }, [hasComponentNameChanges, hasRichTextChanges, localStateComponentName, localStateRichText, props]);

  const inputSize = props.inputSize ?? "base";
  const labelHeight = props.inputSize === "small" ? 28 : 32;
  const buttonSize = props.inputSize === "small" ? "micro" : "small";

  return (
    <div
      style={props.style}
      className={classNames(style.TextEntityMetadataWrapper, props.className)}
      data-testid="textItemMetadata"
    >
      <InstancesNotice componentCounts={props.componentInstanceCounts} />
      <EditableComponentName
        componentName={localStateComponentName ?? undefined}
        onChange={handleChangeComponentName}
      />

      {!!props.onlySelectedItem && (
        <RichTextInputSection
          RichTextInputComponent={props.RichTextInputComponent}
          setRef={richTextInputRef}
          readonly={props.readonlyTextInput}
          initialVariableRichValue={props.onlySelectedItem as ITextItemVariableRichValue}
          setBaseText={handleChangeRichText}
          // Disable text item rich text inputs (including plurals, variables, character limit) if variant is selected
          richTextInputDisabled={props.showVariantMetadata}
          variablesDisabled={props.showVariantMetadata}
          pluralsDisabled={true}
          characterLimitDisabled={true}
          // Specifically for the plugin, to handle missing fonts
          markupUnderBaseInput={
            props.selectionFontInfo?.isSingleSelect &&
            props.selectionFontInfo?.hasMissingFont && (
              <MissingFontWarning
                preludeText={
                  props.selectionFontInfo.isTextMismatch
                    ? "Couldn't update Figma text layer due to missing fonts"
                    : "Can't update Figma text layer due to missing fonts"
                }
              />
            )
          }
        />
      )}
      {hasUnsavedChanges && (
        <CTAButtons buttonSize={buttonSize} onCancel={revertUnsavedChanges} onSave={handleClickSave} />
      )}

      {!props.showVariantMetadata && (
        <div className={style.metadataInputs}>
          <EditStatus statusAtom={props.statusAtom} inputSize={inputSize} labelHeight={labelHeight} />
          <EditAssignee
            assigneeAtom={props.assigneeAtom}
            usersByIdAtom={props.usersByIdAtom}
            inputSize={inputSize}
            labelHeight={labelHeight}
          />
          <EditTags
            tagsAtom={props.tagsAtom}
            allTagsAtom={props.allTagsAtom}
            inputSize={inputSize}
            labelHeight={labelHeight}
            onCreateNewTag={props.onCreateNewTag}
          />
          <EditNotes
            notes={localStateNotes}
            onChange={debouncedSetNotes}
            onBlur={immediateSetNotes}
            inputSize={inputSize}
            labelHeight={labelHeight}
          />
        </div>
      )}
      {props.showVariantMetadata && props.textItemVariant && (
        <TextItemVariant
          variantId={props.textItemVariant.variantId}
          variantStatus={props.textItemVariant.status}
          variantRichText={props.textItemVariant!.rich_text}
          variantText={props.textItemVariant!.text}
          variantVariables={props.textItemVariant!.variables}
          variantName={props.activeVariant?.name ?? ""}
          placeholder={props.variantPlaceholderText}
          onSave={props.onUpdateVariant ?? (() => {})}
          inline={true}
          RichTextInput={props.RichTextInputComponent}
          onHasChanges={props.onVariantFormHasChanges}
          onDeleteVariant={props.onDeleteVariant}
        />
      )}
      {props.showVariantMetadata && !props.textItemVariant && (
        <TextItemVariantNotPresent
          variantName={props.activeVariant?.name ?? ""}
          onAdd={props.onAddVariant ?? (() => {})}
        />
      )}
    </div>
  );
}

export default TextEntityMetadata;
