import * as httpDittoProject from "@/http/dittoProject";
import atomWithURLStorage from "@shared/frontend/stores/atomWithURLStorage";
import { cacheAtom } from "@shared/frontend/stores/cacheAtom";
import { IFigmaFilePage } from "@shared/types/FigmaFile";
import logger from "@shared/utils/logger";
import { atom } from "jotai";
import { soon, soonAll } from "jotai-derive";
import { atomFamily } from "jotai/utils";
import { chain } from "lodash";
import { locationAtom } from "./Location";
import {
  forceShowAllTextItemsInBlockAtomFamily,
  projectFigmaFileIdAtom,
  projectIdAtom,
  textItemsMapAtom,
  unwrappedProjectAtom,
} from "./Project";
import { syncedTextNodesMapAtom } from "./ProjectDesignPreviews";

// we want an atom that represents all of the active filters on the project page
// if there's already a filter active from the URL params, that filter should be visible in the filter bar
// we should be able to show a filter on the bar without adding it to the URL

export const PROJECT_FILTERS = {
  status: {
    value: "status",
    label: "Status",
  },
  assignee: {
    value: "assignee",
    label: "Assignee",
  },
  tags: {
    value: "tags",
    label: "Tags",
  },
  page: {
    value: "page",
    label: "Page",
  },
} as const;

export type FilterKey = keyof typeof PROJECT_FILTERS;

// Atom family for storing the values of the filters in the URL. This lets us abstract away the logic of storing
// and syncing the values of the filters in the URL.
export const selectedFiltersAtomFamily = atomFamily((key: FilterKey) => {
  const urlStorageAtom = atomWithURLStorage(key, locationAtom, {
    onSet: (get, set) => {
      const project = get(unwrappedProjectAtom);
      const blockIds = project.blocks.map((block) => block._id);

      // Reset all of the forceShowAllTextItemsInBlock atoms to false, since the filters have changed
      for (const blockId of blockIds) {
        const forceShowAllTextItemsInBlockAtom = forceShowAllTextItemsInBlockAtomFamily(blockId || "");
        const forceShowAllTextItemsInBlock = get(forceShowAllTextItemsInBlockAtom);
        if (forceShowAllTextItemsInBlock) {
          set(forceShowAllTextItemsInBlockAtom, false);
        }
      }
    },
  });
  urlStorageAtom.debugLabel = `Selected Filters (${key})`;

  return urlStorageAtom;
});

// Atom which reduces the selected filters into a list of FilterKey values, and allows filters to be added or removed
export const selectedFiltersListAtom = atom(
  // The getter loops over all our possible filter keys and checks if each one has a value stored in the atom family
  // (i.e., if the key has any value in the URL)
  (get) => {
    const selectedFilterKeys: FilterKey[] = [];
    for (const key of Object.keys(PROJECT_FILTERS) as FilterKey[]) {
      const filterAtom = selectedFiltersAtomFamily(key);
      const filterValue = get(filterAtom);

      if (filterValue !== null && filterValue !== undefined) {
        selectedFilterKeys.push(key);
      }
    }
    return selectedFilterKeys;
  },
  // The setter takes an array of filter keys. We loop over *all* possible filter keys -- if the setter has the key
  // present, we make sure that the atom family at least has a default value. If the setter doesn't have the key
  // present, we remove the key from the atom family (removing it from the URL)
  (get, set, newSelectedFilterKeys: FilterKey[]) => {
    const selectedFilterKeysSet = new Set(newSelectedFilterKeys);
    const allFilterKeys = Object.keys(PROJECT_FILTERS) as FilterKey[];

    for (const key of allFilterKeys) {
      const filterAtom = selectedFiltersAtomFamily(key);

      if (!selectedFilterKeysSet.has(key)) {
        set(filterAtom, null);
      }

      const filterValue = get(filterAtom);
      if (selectedFilterKeysSet.has(key) && (filterValue === null || filterValue === undefined)) {
        set(filterAtom, []);
      }
    }
  }
);

export const isFilteringAtom = atom((get) => get(selectedFiltersListAtom).length > 0);

/**
 * Map from page node IDs to Figma file pages -- check the IFigmaFilePage type, but for now it's just { id: string, name: string }
 */
const syncedFigmaPagesMapAtom = cacheAtom(
  atom(async (get) => {
    const projectId = get(projectIdAtom);
    if (!projectId) return {};

    const projectFileId = await get(projectFigmaFileIdAtom);
    if (!projectFileId) return {};

    try {
      const [getPagesRequest] = httpDittoProject.getSyncedFigmaPages({ projectId });
      const pages = (await getPagesRequest).data.pages;

      const pagesMap = pages.reduce<Record<string, IFigmaFilePage>>((map, page) => {
        map[page.id] = page;
        return map;
      }, {});

      return pagesMap;
    } catch (error) {
      logger.error("Error fetching synced Figma pages", { context: { projectId } }, error);
      return {};
    }
  })
);

export const pagesFilterActiveAtom = atom((get) => get(selectedFiltersAtomFamily("page")) !== null);

/**
 * We store the page filters in the URL, but we store them only by their page node IDs
 */
export const selectedPageFiltersAtom = atom(
  (get) => {
    const pageNodeIds = get(selectedFiltersAtomFamily("page"));
    if (!pageNodeIds) return [];

    return soon(get(syncedFigmaPagesMapAtom), (syncedFigmaPagesMap) => {
      return pageNodeIds.map((pageNodeId) => syncedFigmaPagesMap[pageNodeId]);
    });
  },
  (get, set, newSelectedPages: IFigmaFilePage[] | null) => {
    const filterAtom = selectedFiltersAtomFamily("page");
    set(filterAtom, newSelectedPages?.map((page) => page.id) ?? null);
  }
);

export const pageFiltersOptionsAtom = atom((get) => {
  const dependencies = soonAll([get(syncedTextNodesMapAtom), get(textItemsMapAtom), get(syncedFigmaPagesMapAtom)]);
  return soon(dependencies, async ([syncedTextNodesMap, textItemsMap, syncedFigmaPagesMap]) => {
    /**
     * Filter selectable pages by whether they have connected text items.
     */
    const textNodesByTextId = chain(syncedTextNodesMap)
      .values()
      .groupBy((x) => x.pluginData?.textItemId)
      .value();

    const pageIdsWithTextItems = chain(textItemsMap)
      .values()
      .flatMap((x) => textNodesByTextId[x._id])
      .filter()
      .map((x) => x.pageId)
      .uniq()
      .value();

    const filteredFigmaPages = Object.values(syncedFigmaPagesMap).filter((page) =>
      pageIdsWithTextItems.includes(page.id)
    );

    return filteredFigmaPages;
  });
});
