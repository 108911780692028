import { ILogContext, ILogger } from "./_types";

let realLogger: ILogger | null = null;
const logBuffer: { method: keyof ILogger; args: any[] }[] = [];

async function initializeLogger() {
  if (realLogger) return;
  // Used for dynamic imports to ensure that we don't import code with node/browser-specific
  // dependencies in the wrong environment.
  const isNodeProcess = typeof process === "object" && typeof require === "function";

  if (isNodeProcess) {
    // @ts-ignore Ignoring here because figma is only defined in the figma plugin
    if (global.figma) {
      realLogger = (await import("./_pluginLogger")).default;
    } else {
      realLogger = (await import("./_nodeLogger")).default;
    }
  } else {
    realLogger = (await import("./_browserLogger")).default;
  }
  // Your logic to determine which logger to load
  // and then set the `realLogger` variable.

  // After setting realLogger, flush the buffer
  for (const logEntry of logBuffer) {
    const method = logEntry.method;
    (realLogger![method] as any)(...logEntry.args);
  }
  logBuffer.length = 0; // Clear the buffer
}

function handleLog(method: keyof ILogger, ...args: any[]) {
  if (realLogger) {
    (realLogger[method] as any)(...args);
  } else {
    logBuffer.push({ method, args });
  }
}

const loggerProxy: ILogger = {
  silly: (message: any, context?: ILogContext, error?: any) => {
    handleLog("silly", message, context, error);
  },
  debug: (message: any, context?: ILogContext, error?: any) => {
    handleLog("debug", message, context, error);
  },
  verbose: (message: any, context?: ILogContext, error?: any) => {
    handleLog("verbose", message, context, error);
  },
  http: (message: any, context?: ILogContext, error?: any) => {
    handleLog("http", message, context, error);
  },
  info: (message: any, context?: ILogContext, error?: any) => {
    handleLog("info", message, context, error);
  },
  warn: (message: any, context?: ILogContext, error?: any) => {
    handleLog("warn", message, context, error);
  },
  error: (message: any, context: ILogContext, error?: Error | unknown) => {
    handleLog("error", message, context, error || new Error(message));
    return ""; // Return an empty string, as the real logger's error might not be immediately available
  },
};

// Don't forget to call initializeLogger at the start of your app
initializeLogger();

const logger = loggerProxy;
export default logger;
