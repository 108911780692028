import { routes } from "@/defs";
import useRedirectToNSPage from "@/hooks/useRedirectToNSPage";
import { clearLibrarySelectionActionAtom, isInvalidFolderIdAtom } from "@/stores/Library";
import { useGlobalScopedClickHandler } from "@shared/frontend/hooks/useGlobalScopedClickHandler";
import { showToastActionAtom } from "@shared/frontend/stores/Toast";
import { useAtomValue, useSetAtom } from "jotai";
import { Suspense, useEffect } from "react";
import LibraryCreateComponentFolderModal from "../LibraryCreateComponentFolderModal";
import LibraryCreateComponentModal from "../LibraryCreateComponentModal";
import LibraryDetailsPanel from "../LibraryDetailsPanel";
import LibraryItemsList from "../LibraryItemsList";
import LibraryLeftSidebar from "../LibraryLeftSidebar";
import style from "./style.module.css";

function LibraryContent() {
  const clearSelectionAction = useSetAtom(clearLibrarySelectionActionAtom);

  useGlobalScopedClickHandler({
    onClick: clearSelectionAction,
    parentAttribute: "data-clear-library-selection",
  });

  useEffect(
    function addEscapeListener() {
      function keyboardEventListener(event: KeyboardEvent) {
        if (event.key === "Escape") clearSelectionAction();
      }

      document.addEventListener("keydown", keyboardEventListener);
      return () => document.removeEventListener("keydown", keyboardEventListener);
    },
    [clearSelectionAction]
  );

  return (
    <div className={style.libraryContentContainer}>
      <Suspense fallback={<></>}>
        <LibraryLeftSidebar />
      </Suspense>
      <Suspense fallback={<></>}>
        <LibraryItemsList />
      </Suspense>
      <Suspense fallback={<></>}>
        <LibraryDetailsPanel />
      </Suspense>
      <Suspense fallback={<></>}>
        <LibraryCreateComponentModal />
      </Suspense>
      <Suspense fallback={<></>}>
        <LibraryCreateComponentFolderModal />
      </Suspense>
      <Suspense fallback={<></>}>
        <LibraryRedirectOnInvalidFolderId />
      </Suspense>
    </div>
  );
}

/**
 * Redirects to the library page if the folder id is invalid
 * This is to ensure that the user is always redirected to the correct page
 * even if they navigate to the library page manually
 */
function LibraryRedirectOnInvalidFolderId() {
  const isInvalidFolderId = useAtomValue(isInvalidFolderIdAtom);
  const redirectToNSPage = useRedirectToNSPage();
  const showToastAction = useSetAtom(showToastActionAtom);

  useEffect(() => {
    if (isInvalidFolderId) {
      redirectToNSPage(routes.nonNavRoutes.library.getPath());
      showToastAction({
        message: "Folder does not exist",
      });
    }
  }, [isInvalidFolderId, redirectToNSPage, showToastAction]);

  return null;
}

export default LibraryContent;
