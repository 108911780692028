import { useWorkspace } from "@/store/workspaceContext";
import { IVariable } from "@shared/types/Variable";
import { IPopulatedVariableFolder } from "@shared/types/VariableFolder";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import http, { API } from "../../http/index";
import {
  VariableActivityFilter,
  VariableHistoryItem,
  VariableInstanceData,
  VariablePageHistory,
  VariableType,
} from "./types";

const useVariables = () => {
  const [variables, setVariables] = useState<IVariable[]>([]);
  const [variablesByFolder, setVariablesByFolder] = useState<Record<string, IVariable[]>>({});
  const [selectedVariable, setSelectedVariable] = useState<IVariable | null>(null);
  const [query, setQuery] = useState<string>("");
  const [typeFilter, setTypeFilter] = useState<VariableType>("Any");
  const [activityFilter, setActivityFilter] = useState<VariableActivityFilter>("Edit");
  const [loadingVariables, setLoadingVariables] = useState<boolean>(true);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [loadingHistory, setLoadingHistory] = useState<boolean>(false);
  const [allHistoryFetched, setAllHistoryFetched] = useState<boolean>(false);
  const [showCreateVariableModal, setShowCreateVariableModal] = useState<boolean>(false);
  const [variablePageHistory, setVariablePageHistory] = useState<VariablePageHistory>({
    items: [],
    skip: 20,
    page: 0,
    limit: 20,
    allHistoryFetched: false,
  });
  const [showExportModal, setShowExportModal] = useState<boolean>(false);
  const [instanceData, setInstanceData] = useState<VariableInstanceData>({
    projects: [],
    wsComps: [],
    libraryComponents: [],
  });
  const [selectedVariableHistoryLoading, setSelectedVariableHistoryLoading] = useState<boolean>(false);
  const [selectedVariableHistory, setSelectedVariableHistory] = useState<VariableHistoryItem[]>([]);

  const [loadingVariableFolders, setLoadingVariableFolders] = useState(true);
  const [variableFolders, setVariableFolders] = useState<IPopulatedVariableFolder[]>([]);
  const [variablesToMove, setVariablesToMove] = useState<IVariable[]>([]);
  const [notification, setNotification] = useState<{
    message: string;
    type: string;
    time: number;
  } | null>(null);

  const { workspaceInfo } = useWorkspace();
  const foldersAllowed = workspaceInfo?.plan === "growth" || workspaceInfo?.plan === "enterprise";

  const { id, folder_id: selectedFolderId } = useParams<{
    id?: string;
    folder_id?: string;
  }>();
  const history = useHistory();

  const handleInsertNewVariable = async (variable: IVariable) => {
    refreshVariablePageHistory();

    const variables = await getVariables();
    setVariables(variables);

    handleRouteChange(variable._id);
  };

  useEffect(() => {
    if (!id) {
      setSelectedVariable(null);
    }
  }, [id]);

  const openCreateVariableModal = () => setShowCreateVariableModal(true);

  const closeCreateVariableModal = () => setShowCreateVariableModal(false);

  const handleQueryChange = (value: string) => {
    setQuery(value);
  };

  const chooseType = (type: VariableType) => setTypeFilter(type);

  const handleSelectVariableId = (id: string) => {
    const newlySelected = variables.find((v) => v._id === id);
    if (newlySelected) handleSelectVariable(newlySelected);
    else {
      variableFolders.forEach((folder) => {
        if (folder.variableIds.find((v) => v === id)) {
          history.push(`/variables/folder/${folder._id}/${id}`);
        }
      });
    }
  };

  async function getVariables(showLoading: boolean = true) {
    if (showLoading) setLoadingVariables(true);
    let { url } = API.variable.get.all;

    const queryParams = new URLSearchParams();
    if (query) {
      queryParams.set("search", query);
    }
    if (typeFilter !== "Any") {
      queryParams.set("type", typeFilter.toLowerCase());
    }
    if (selectedFolderId) {
      queryParams.set("folderId", selectedFolderId);
      if (variableFolders.find((folder) => folder._id === selectedFolderId)?.isSample)
        queryParams.set("includeSampleData", "true");
    } else if (!selectedFolderId && !query && typeFilter === "Any") {
      // When we search outside a folder we always search everything
      queryParams.set("folderId", "none");
    }
    url += `?${queryParams.toString()}`;

    const response = await http.get(url);
    const { data: variables } = response;
    setVariables(variables);
    setVariablesByFolder(
      variables.reduce((acc: Record<string, IVariable[]>, curr: IVariable) => {
        const key = curr.folder_id || "none";
        acc[key] = [...(acc[key] || []), curr];
        return acc;
      }, {})
    );
    if (showLoading) setLoadingVariables(false);
    if (!variables) {
      throw Error(`Error doing request to ${url} because ${response.statusText}`);
    }
    return variables;
  }

  /**
   * New paths should only be pushed onto browser history
   * if the user is not already at those paths
   */
  const handleRouteChange = (variableId?: string) => {
    let prefix = "/variables";
    if (selectedFolderId) prefix += `/folder/${selectedFolderId}`;
    if (variableId) prefix += `/${variableId}`;
    history.push(prefix);
  };

  const handleSelectVariable = (variable: IVariable) => {
    if (selectedVariable && selectedVariable._id === variable._id) {
      handleRouteChange();
      setSelectedVariable(null);
    } else {
      handleRouteChange(variable._id);
      setSelectedVariable(variable);
    }
  };

  const handleMoveVariables = async (folderId: string, variableIds: string[]) => {
    try {
      let { url, body } = API.variableFolder.post.moveVariablesToFolder;
      await http.post(url(folderId), body(variableIds));
      selectVariableFolder(
        folderId === "none" ? undefined : folderId,
        variableIds.length == 1 ? variableIds[0] : undefined
      );
    } catch (err) {
      console.error("Error moving variants", err);
    }
  };

  const handleUpdateVariable = async (updatedVariable: IVariable) => {
    try {
      setIsSaving(true);
      const { url, body } = API.variable.put.update;
      const { data } = await http.put(
        url(updatedVariable._id),
        body({
          variable: updatedVariable,
        })
      );
      const variableIndex = variables.findIndex((v) => v._id === data._id);
      if (variableIndex !== -1) {
        const updatedVariables = [...variables];
        updatedVariables[variableIndex] = data;
        setVariables(updatedVariables);
        setSelectedVariable(data);
        refreshVariablePageHistory();
        fetchSelectedVariableData();
        getVariables(false);
      }
    } catch (err) {
      console.error("Error updating variable:", err);
    } finally {
      setIsSaving(false);
    }
  };

  const handleDeleteVariable = async (variable: IVariable) => {
    try {
      const { url } = API.variable.delete.delete;
      await http.delete(url(variable._id));
      const updatedArray = [...variables.filter((v) => v._id !== variable._id)];
      const updatedVariablesByFolder = { ...variablesByFolder };
      const folder = selectedFolderId || "none";
      updatedVariablesByFolder[folder] = variablesByFolder[folder].filter((v) => v._id !== variable._id);
      setVariablesByFolder(updatedVariablesByFolder);
      setVariables(updatedArray);
      setSelectedVariable(null);
      setVariablesToMove([]);
      refreshVariablePageHistory();
    } catch (err) {
      console.error("Error deleting variable:", err);
    }
  };

  const handleDeleteVariables = async (variableIds: string[]) => {
    try {
      const { url, body } = API.variable.post.deleteVariables;
      const { data } = await http.post(url, body({ variableIds }));
      const deletedCount = data.deletedCount;
      const deletedMessage = `Successfully deleted ${deletedCount} variable${deletedCount === 1 ? "" : "s"}`;

      const updatedVariablesByFolder = { ...variablesByFolder };
      const folder = selectedFolderId || "none";
      updatedVariablesByFolder[folder] = variablesByFolder[folder].filter((v) => !variableIds.includes(v._id));
      setVariablesByFolder(updatedVariablesByFolder);

      setNotification({
        message: deletedMessage,
        time: 5000,
        type: "success",
      });

      setVariables((prev) => {
        let temp = [...prev];
        temp = temp.filter((v) => !variableIds.includes(v._id));
        return temp;
      });
      setVariablesToMove([]);
      refreshVariablePageHistory();
      return true;
    } catch (err) {
      setNotification({
        message: "Error deleting variables",
        time: 5000,
        type: "error",
      });
      return false;
    }
  };

  useEffect(
    function autoHideNotification() {
      if (notification) {
        setTimeout(() => {
          setNotification(null);
        }, notification.time);
      }
    },
    [notification]
  );

  /**
   * Fetches array of ActivityItems for variable page history
   * @param {integer} skip How many items to skip
   * @param {integer} limit How many items to take
   * @returns {array} Array of ActivityItems
   */
  async function fetchVariablePageHistory(skip: number, limit: number) {
    const { url } = API.changes.get.variablePage;
    const { data: activityHistory } = await http.get(url(skip, limit, selectedFolderId));

    return activityHistory.info;
  }
  const fetchVariableHistoryNewPage = async () => {
    try {
      let page = variablePageHistory.page + 1;

      const skip = variablePageHistory.skip * page;
      const limit = variablePageHistory.limit;

      let activityHistory = await fetchVariablePageHistory(skip, limit);

      const allHistoryFetched = activityHistory.length < variablePageHistory.limit;
      setAllHistoryFetched(allHistoryFetched);
      setVariablePageHistory((prev) => ({
        ...prev,
        items: [...prev.items, ...activityHistory],
        page: page,
        allHistoryFetched,
      }));
      setLoadingHistory(false);
    } catch (error) {
      setLoadingHistory(false);
      console.error("in useVariables.js while fetching page of variable history: " + error.message);
    }
  };

  const refreshVariablePageHistory = async () => {
    try {
      let page = variablePageHistory.page;
      const skip = 0;
      const limit = variablePageHistory.limit * (page + 1);

      let activityHistory = await fetchVariablePageHistory(skip, limit);

      const allHistoryFetched = activityHistory.length < variablePageHistory.limit * (page + 1);
      setAllHistoryFetched(allHistoryFetched);
      setVariablePageHistory((prev) => ({
        ...prev,
        items: [...activityHistory],
        page: page,
        allHistoryFetched,
      }));
    } catch (error) {
      console.error("in useVariables.js while refreshing variable page history: " + error.message);
    }
  };

  const fetchVariableInstances = async () => {
    try {
      if (!selectedVariable?._id) return;
      const { url } = API.variable.get.instances;
      const { data: instances } = await http.get(url(selectedVariable._id));
      setInstanceData(instances);
    } catch (e) {
      console.error("in useVariables..jsx: ", e.message);
    }
  };
  const fetchSelectedVariableData = () => {
    setSelectedVariableHistoryLoading(true);
    fetchVariableInstances();
    fetchSelectedVariableHistory();
  };
  const fetchSelectedVariableHistory = async () => {
    try {
      if (!selectedVariable?._id) return;
      const { url } = API.changes.get.variable;
      const {
        data: { info: changes },
      } = await http.get(url(selectedVariable._id));
      setSelectedVariableHistory(changes);
      setSelectedVariableHistoryLoading(false);
      refreshVariablePageHistory();
    } catch (e) {
      setSelectedVariableHistoryLoading(false);
      console.error("in useVariables.js: ", e.message);
    }
  };

  const openExportModal = () => setShowExportModal(true);

  const closeExportModal = () => setShowExportModal(false);

  useEffect(() => {
    if (id && variables && selectedVariable?._id !== id) {
      handleSelectVariableId(id);
    }
  }, [selectedVariable, variables, id]);

  useEffect(() => {
    if (selectedVariable) fetchSelectedVariableData();
  }, [selectedVariable]);

  useEffect(() => {
    refreshVariablePageHistory();
    getVariables();
  }, [setVariables, selectedFolderId, workspaceInfo, query, typeFilter]);

  useEffect(() => {
    fetchVariableFolders();
  }, [variables]);

  async function fetchVariableFolders() {
    setLoadingVariableFolders(true);
    const { url } = API.variableFolder.get.folders;
    const { data } = await http.get(url);
    setVariableFolders(
      data.sort((a: IPopulatedVariableFolder, b: IPopulatedVariableFolder) =>
        a.name.toLowerCase().localeCompare(b.name.toLowerCase())
      )
    );
    setLoadingVariableFolders(false);
  }

  async function handleCreateNewVariableFolder(name: string) {
    const { url } = API.variableFolder.post.createFolder;
    const { data } = await http.post(url, { name });
    setVariableFolders((folders) => {
      const array = [...folders, data].sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
      return array;
    });
    refreshVariablePageHistory();
    return data;
  }

  async function handleRenameVariableFolder(name: string, folderId: string) {
    const { url } = API.variableFolder.put.updateFolder;
    await http.put(url(folderId), { name });
    fetchVariableFolders();
  }

  async function handleDeleteVariableFolder(folderId: string) {
    const { url } = API.variableFolder.delete.deleteFolder;
    await http.delete(url(folderId));
    await fetchVariableFolders();
    await getVariables();
    history.push("/variables");
  }

  const selectVariableFolder = async (folderId?: string, variableId?: string) => {
    setSelectedVariable(null);
    setVariables([]);
    setVariablesToMove([]);
    if (folderId) {
      history.push(`/variables/folder/${folderId}${variableId ? "/" + variableId : ""}`);
    } else {
      history.push(`/variables${variableId ? "/" + variableId : ""}`);
    }
  };

  return {
    query,
    setQuery,
    handleQueryChange,
    typeFilter,
    chooseType,
    variables,
    variablesByFolder,
    loadingVariables,
    handleInsertNewVariable,
    handleDeleteVariable,
    handleDeleteVariables,
    selectedVariable,
    setSelectedVariable,
    handleSelectVariable,
    handleSelectVariableId,
    activityFilter,
    setActivityFilter,
    handleUpdateVariable,
    isSaving,
    allHistoryFetched,
    loadingHistory: !selectedVariable ? loadingHistory : selectedVariableHistoryLoading,
    variableHistory: !selectedVariable ? variablePageHistory.items : selectedVariableHistory,
    fetchVariableHistoryNewPage,
    instanceData,
    selectedVariableHistoryLoading,
    selectedVariableHistory,
    showCreateVariableModal,
    openCreateVariableModal,
    closeCreateVariableModal,
    openExportModal,
    closeExportModal,
    showExportModal,
    setLoadingVariables,
    handleMoveVariables,
    foldersAllowed,
    loadingVariableFolders,
    variableFolders,
    handleCreateNewVariableFolder,
    handleRenameVariableFolder,
    handleDeleteVariableFolder,
    selectedFolderId,
    variablesToMove,
    setVariables,
    setVariablesToMove,
    notification,
    selectVariableFolder,
    handleRouteChange,
  };
};
export { useVariables };
