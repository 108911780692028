import ArrowForward from "@mui/icons-material/ArrowForward";
import FolderIcon from "@mui/icons-material/FolderOutlined";
import classNames from "classnames";
import React, { useCallback } from "react";
import { serializeTipTapRichText } from "../../../../shared/frontend/richText/serializer";
import { DEV_INTEGRATIONS_DEV_ID_COPIED } from "../../../../shared/segment-event-names";
import { ENTRY_TYPES, IChangeItem } from "../../../../shared/types/ActualChange";
import { TrackFn } from "../../../../shared/types/analytics";
import { ITextItemStatus } from "../../../../shared/types/TextItem";
import CopyableBadge from "../../molecules/CopyableBadge";
import LibraryComponentNameSpan from "../../molecules/LibraryComponentNameSpan";
import { RichTextRender } from "../../molecules/RichTextRender";
import Badge from "../Badge";
import EditedText from "../EditedText";
import Icon from "../Icon";
import Text from "../Text";
import style from "./index.module.css";

interface IProps {
  changeItem: IChangeItem;
  /**
   * A method that allows us to call our Segment `track` method
   * with the version and application properties already set.
   */
  sendTrackingEvent: TrackFn;
}

export function ChangeItemContent({ changeItem, sendTrackingEvent }: IProps) {
  switch (changeItem.entry_type) {
    case ENTRY_TYPES.DITTO_PROJECT_CREATED:
      return (
        <Text color="primary" size="small">
          {changeItem.doc_name}
        </Text>
      );
    case ENTRY_TYPES.DITTO_PROJECT_RENAMED:
      return <EditedText size="small" textBefore={changeItem.data.previousName} textAfter={changeItem.data.newName} />;
    case ENTRY_TYPES.DITTO_PROJECT_DEVELOPER_ID_UPDATED:
      return (
        <DeveloperIdUpdated
          type="project"
          previousDevId={changeItem.data.previousDevId}
          newDevId={changeItem.data.newDevId}
          sendTrackingEvent={sendTrackingEvent}
        />
      );
    case ENTRY_TYPES.TEXT_ITEM_DEVELOPER_ID_UPDATED:
      return (
        <DeveloperIdUpdated
          type="textItem"
          previousDevId={changeItem.data.previousDevId}
          newDevId={changeItem.data.newDevId}
          sendTrackingEvent={sendTrackingEvent}
        />
      );
    case ENTRY_TYPES.DITTO_PROJECT_MOVED_TO_FOLDER:
      return (
        <Text color="primary" size="small">
          {changeItem.data.folderNameAfter ?? changeItem.data.folderNameBefore}
        </Text>
      );
    case ENTRY_TYPES.DITTO_BLOCK_CREATED:
      return (
        <Text color="primary" size="small">
          {changeItem.data.name}
        </Text>
      );
    case ENTRY_TYPES.DITTO_BLOCK_UPDATED:
      if (changeItem.data.before.name === changeItem.data.after.name) {
        return (
          <Text color="primary" size="small">
            {changeItem.data.after.name}
          </Text>
        );
      }
      return (
        <EditedText size="small" textBefore={changeItem.data.before.name} textAfter={changeItem.data.after.name} />
      );
    case ENTRY_TYPES.DITTO_BLOCK_DELETED:
      return (
        <Text color="tertiary" size="small">
          {changeItem.data.name}
        </Text>
      );
    case ENTRY_TYPES.TEXT_ITEM_CREATED:
      return (
        <Text color="primary" size="small">
          {changeItem.data.text}
        </Text>
      );
    case ENTRY_TYPES.TEXT_ITEM_DELETED:
      return (
        <Text color="tertiary" size="small">
          {changeItem.data.text}
        </Text>
      );
    case ENTRY_TYPES.TEXT_ITEM_VARIANT_ATTACHED:
      if (changeItem.data.text)
        return (
          <Text color="primary" size="small">
            {changeItem.data.text}
          </Text>
        );
      else
        return (
          <Text color="tertiary" size="small">
            No text value
          </Text>
        );
    case ENTRY_TYPES.SYNC_CONFLICT_RESOLVED:
    case ENTRY_TYPES.TEXT_ITEM_VARIANT_EDIT:
    case ENTRY_TYPES.EDIT:
      return (
        <EditedText
          size="small"
          textBefore={changeItem.text_before}
          textAfter={changeItem.text_after}
          richTextBefore={changeItem.data.rich_text_before}
          richTextAfter={changeItem.data.rich_text_after}
        />
      );
    case ENTRY_TYPES.STATUS:
    case ENTRY_TYPES.DUPES_STATUS:
    case ENTRY_TYPES.TEXT_ITEM_VARIANT_STATUS:
      return <StatusChanged status={changeItem.status} />;
    case ENTRY_TYPES.COMP_ASSIGNED:
    case ENTRY_TYPES.MULTI_COMP_ASSIGNED:
    case ENTRY_TYPES.TEXT_ITEM_CHARACTER_LIMIT_UPDATE:
    case ENTRY_TYPES.PLURAL_ADDED:
    case ENTRY_TYPES.PLURAL_EDITED:
    case ENTRY_TYPES.PLURAL_REMOVED:
    case ENTRY_TYPES.VARIANT_CREATED:
    case ENTRY_TYPES.TEXT_ITEM_VARIANT_REMOVED:
    case ENTRY_TYPES.LIBRARY_COMPONENT_VARIANT_REMOVED:
    case ENTRY_TYPES.LIBRARY_COMPONENT_ASSIGNED:
      return null;

    case ENTRY_TYPES.LIBRARY_COMPONENT_LINKED_TO_TEXT_ITEMS:
      return (
        <ComponentChangeContent name={changeItem.data.componentName}>
          <EditedText size="small" textBefore={changeItem.data.text_before} textAfter={changeItem.data.text_after} />
        </ComponentChangeContent>
      );
    case ENTRY_TYPES.LIBRARY_COMPONENT_UNLINKED_FROM_TEXT_ITEMS:
      return (
        <RichTextRender className={style.unlinkedComponentWrapper} richText={changeItem.data.richText} size="small" />
      );
    case ENTRY_TYPES.LIBRARY_COMPONENT_CREATED:
      return (
        <ComponentChangeContent name={changeItem.data.componentName}>
          <RichTextRender richText={changeItem.data.rich_text} size="small" />
        </ComponentChangeContent>
      );
    case ENTRY_TYPES.LIBRARY_COMPONENT_PUBLISHED:
      return (
        <ComponentChangeContent name={changeItem.data.componentName}>
          <RichTextRender richText={changeItem.data.richText} size="small" />
        </ComponentChangeContent>
      );
    case ENTRY_TYPES.LIBRARY_COMPONENT_FOLDER_CREATED:
      return <ComponentFolderCreatedContent name={changeItem.data.folderName} />;
    case ENTRY_TYPES.LIBRARY_COMPONENT_FOLDER_RENAMED:
      return (
        <ComponentFolderRenamedContent nameBefore={changeItem.data.previousName} nameAfter={changeItem.data.newName} />
      );
    case ENTRY_TYPES.LIBRARY_COMPONENT_FOLDER_DELETED:
      return <ComponentFolderDeletedContent name={changeItem.data.folderName} />;
    case ENTRY_TYPES.LIBRARY_COMPONENT_FOLDER_MOVED:
      return (
        <FolderMovedContent
          folderName={changeItem.data.folderName}
          targetFolderName={changeItem.data.targetFolderName}
        />
      );
    case ENTRY_TYPES.LIBRARY_COMPONENTS_MOVED_TO_FOLDER:
      return (
        <ComponentsMovedToFolderContent
          folderName={changeItem.data.folderName}
          componentIds={changeItem.data.componentIds}
          componentName={changeItem.data.componentName}
        />
      );
    case ENTRY_TYPES.LIBRARY_COMPONENT_EDIT:
    case ENTRY_TYPES.LIBRARY_COMPONENT_VARIANT_EDIT:
      return (
        <ComponentChangeContent name={changeItem.data.componentName}>
          <EditedText
            size="small"
            textBefore={serializeTipTapRichText(changeItem.data.rich_text_before).text}
            textAfter={serializeTipTapRichText(changeItem.data.rich_text_after).text}
            richTextBefore={changeItem.data.rich_text_before}
            richTextAfter={changeItem.data.rich_text_after}
          />
        </ComponentChangeContent>
      );
    case ENTRY_TYPES.LIBRARY_COMPONENT_DELETED:
      return (
        <ComponentChangeContent name={changeItem.data.componentName} disabled>
          <Text color="tertiary" size="small">
            {changeItem.data.text}
          </Text>
        </ComponentChangeContent>
      );
    case ENTRY_TYPES.LIBRARY_COMPONENT_STATUS:
      return (
        <ComponentChangeContent name={changeItem.data.componentName}>
          <StatusChanged status={changeItem.data.status as ITextItemStatus} />
        </ComponentChangeContent>
      );
    case ENTRY_TYPES.LIBRARY_COMPONENT_VARIANT_ATTACHED: {
      const text = serializeTipTapRichText(changeItem.data.rich_text).text;
      if (serializeTipTapRichText(changeItem.data.rich_text).text)
        return (
          <Text color="primary" size="small">
            {text}
          </Text>
        );
      else
        return (
          <Text color="tertiary" size="small">
            No text value
          </Text>
        );
    }
    case ENTRY_TYPES.LIBRARY_COMPONENT_VARIANT_STATUS:
      return (
        <ComponentChangeContent name={changeItem.data.componentName}>
          <StatusChanged status={changeItem.data.status as ITextItemStatus} />
        </ComponentChangeContent>
      );
    case ENTRY_TYPES.LIBRARY_COMPONENT_RENAMED:
      return (
        <ComponentChangeContent name={changeItem.data.componentNameBefore}>
          <EditedText
            size="small"
            textBefore={changeItem.data.componentNameBefore}
            textAfter={changeItem.data.componentNameAfter}
          />
        </ComponentChangeContent>
      );
    default:
      const _exhaustiveCheck: never = changeItem;
      return null;
  }
}

function ComponentChangeContent(props: { name: string; children: React.ReactNode; disabled?: boolean }) {
  const disabled = props.disabled ?? false;
  const color = disabled ? "tertiary" : undefined;

  return (
    <div className={classNames(style.componentWrapper, { [style.disabled]: disabled })}>
      <div>
        <LibraryComponentNameSpan color={color}>{props.name}</LibraryComponentNameSpan>
      </div>
      {props.children}
    </div>
  );
}

function ComponentFolderCreatedContent(props: { name: string }) {
  return (
    <div className={style.componentFolderCreatedWrapper}>
      <Icon Icon={<FolderIcon />} color="secondary" size="xs" />
      <Text className={style.folderNameWrapper} color="primary" size="small" inline>
        {props.name}
      </Text>
    </div>
  );
}

function ComponentFolderRenamedContent(props: { nameBefore: string; nameAfter: string }) {
  return (
    <div className={style.componentFolderRenamedWrapper}>
      <span className={style.folderNameAndIconWrapper}>
        <Icon Icon={<FolderIcon />} color="secondary" size="xs" />
        <Text className={style.folderNameWrapper} color="secondary" size="small" inline>
          {props.nameBefore}
        </Text>
      </span>
      <Icon Icon={<ArrowForward />} color="secondary" size="xs" />
      <span className={style.folderNameAndIconWrapper}>
        <Icon Icon={<FolderIcon />} color="secondary" size="xs" />
        <Text className={style.folderNameWrapper} color="primary" size="small" inline>
          {props.nameAfter}
        </Text>
      </span>
    </div>
  );
}

function ComponentFolderDeletedContent(props: { name: string }) {
  return (
    <div className={style.componentFolderDeletedWrapper}>
      <Icon Icon={<FolderIcon />} color="secondary" size="xs" />
      <Text className={style.folderNameWrapper} color="tertiary" size="small" inline>
        Deleted folder: {props.name}
      </Text>
    </div>
  );
}

function FolderMovedContent(props: { folderName: string; targetFolderName: string }) {
  const movingToRoot = props.targetFolderName === "All Components";

  return (
    <div className={style.componentsMovedToFolderWrapper}>
      <Text size="small">Moved</Text>
      <Badge className={style.badgeWrapper} type="minimal" size="sm">
        <Icon Icon={<FolderIcon />} color="secondary" size="xs" />
        <Text className={style.badgeText} color="primary" size="small" inline>
          {props.folderName}
        </Text>
      </Badge>
      <Text size="small" inline>
        to
      </Text>
      <Badge className={style.badgeWrapper} type="minimal" size="sm">
        {movingToRoot ? null : <Icon Icon={<FolderIcon />} color="secondary" size="xs" />}
        <Text className={style.badgeText} color="primary" size="small" inline>
          {props.targetFolderName}
        </Text>
      </Badge>
    </div>
  );
}

function ComponentsMovedToFolderContent(props: { folderName: string; componentIds: string[]; componentName?: string }) {
  const showComponentName = props.componentName !== undefined;

  return (
    <div className={style.componentsMovedToFolderWrapper}>
      <Text size="small">Moved&nbsp;</Text>
      {showComponentName ? (
        <LibraryComponentNameSpan>{props.componentName}</LibraryComponentNameSpan>
      ) : (
        <Text color="primary" size="small">
          {props.componentIds.length} components
        </Text>
      )}
      <Text size="small" inline>
        &nbsp;to&nbsp;
      </Text>
      <Badge className={style.badgeWrapper} type="minimal" size="sm">
        <Icon Icon={<FolderIcon />} color="secondary" size="xs" />
        <Text className={style.badgeText} color="primary" size="small" inline>
          {props.folderName}
        </Text>
      </Badge>
    </div>
  );
}

const statuses: Record<ITextItemStatus, { color: "secondary" | "danger" | "warning" | "positive"; label: string }> = {
  NONE: { color: "secondary", label: "No status" },
  WIP: { color: "danger", label: "Work in Progress" },
  REVIEW: { color: "warning", label: "Ready for Review" },
  FINAL: { color: "positive", label: "Final" },
} as const;

function StatusChanged({ status }: { status: ITextItemStatus }) {
  const { color, label } = statuses[status];

  return (
    <Text
      color="primary"
      size="small"
      className={classNames(style.statusWrapper, {
        [style[`status-color-${color}`]]: color,
      })}
    >
      Marked as{" "}
      <Text size="small" weight="medium">
        {label}
      </Text>
    </Text>
  );
}

function DeveloperIdUpdated({
  type,
  previousDevId,
  newDevId,
  sendTrackingEvent,
}: {
  type: "project" | "textItem";
  previousDevId: string;
  newDevId: string;
  sendTrackingEvent: TrackFn;
}) {
  const handleCopyDevId = useCallback(() => {
    sendTrackingEvent({
      event: DEV_INTEGRATIONS_DEV_ID_COPIED,
      properties: {
        type,
        location: "activity",
      },
    });
  }, [sendTrackingEvent, type]);

  return (
    <div className={style.developerIdUpdatedContainer}>
      <CopyableBadge
        fontStyle="code"
        color="subtle"
        text={previousDevId}
        containerClassName={style.devIdBadgeContainer}
        onCopy={handleCopyDevId}
      />
      <Icon Icon={<ArrowForward />} color="secondary" size="xxs" />
      <CopyableBadge
        fontStyle="code"
        color="blue"
        text={newDevId}
        containerClassName={style.devIdBadgeContainer}
        onCopy={handleCopyDevId}
      />
    </div>
  );
}

function ChangeItemContentWrapper({ changeItem, sendTrackingEvent }: IProps) {
  return (
    <div className={style.changeItemContentWrapper} data-testid="change-item-content">
      <ChangeItemContent changeItem={changeItem} sendTrackingEvent={sendTrackingEvent} />
    </div>
  );
}

export default ChangeItemContentWrapper;
