/**
 * A simple queue designed for holding http request that cannot yet be executed.
 */
export default class RequestQueue {
  queue: { resolve: (getAuthToken: () => Promise<string>) => void }[];
  resolved: boolean;
  getAuthToken: () => Promise<string>;
  onUnauthorized: (() => void) | undefined;
  constructor() {
    this.queue = [];
    this.resolved = false;
  }

  /**
   * Adds a request to the request queue.
   * @returns The promise associated with the queued request that can
   * be awaited the calling context.
   */
  async addRequest() {
    if (this.resolved) {
      if (!this.getAuthToken) {
        throw new Error("invalid state");
      }

      return Promise.resolve(this.getAuthToken);
    }

    let resolve;

    const promise = new Promise<() => Promise<string>>((r) => {
      resolve = r;
    });

    this.queue.push({ resolve });

    return promise;
  }

  /**
   * Resolves all queued requests.
   */
  resolveAll(getAuthToken: () => Promise<string>, onUnauthorized?: () => void) {
    this.resolved = true;
    this.queue.forEach((q) => q.resolve(getAuthToken));
    this.getAuthToken = getAuthToken;
    this.onUnauthorized = onUnauthorized;
  }
}
