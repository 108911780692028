import * as httpDittoProject from "@/http/dittoProject";
import { textItemFamilyAtom } from "@/stores/TextItem";
import { normalizeMoveAction } from "@/utils/normalizeMoveAction";
import { AddVariantFormChanges, DEFAULT_ADD_VARIANT_CHANGES } from "@ds/organisms/VariantsPanel";
import { DEFAULT_RICH_TEXT } from "@shared/common/constants";
import { EMPTY_RICH_TEXT } from "@shared/frontend/constants";
import client from "@shared/frontend/http/httpClient";
import { serializeTipTapRichText } from "@shared/frontend/richText/serializer";
import asyncMutableDerivedAtom from "@shared/frontend/stores/asyncMutableDerivedAtom";
import { RESET } from "@shared/frontend/stores/symbols";
import { showToastActionAtom } from "@shared/frontend/stores/Toast";
import { isDiffRichText } from "@shared/lib/text";
import { IDittoProjectBlock } from "@shared/types/DittoProject";
import { IDittoBlockData, IDittoProjectData, IMoveTextItemsAction } from "@shared/types/http/DittoProject";
import { ITextItem, ITipTapRichText } from "@shared/types/TextItem";
import logger from "@shared/utils/logger";
import ObjectId from "bson-objectid";
import { atom } from "jotai";
import { atomFamily, unwrap } from "jotai/utils";
import { generateKeyBetween } from "../../util/fractionalIndexing";
import { updateLibraryComponentActionAtom } from "./Components";
import { discardChangesModalActionAtom } from "./Modals";
import { blockFamilyAtom, projectAtom, projectBlocksAtom, projectIdAtom, workspaceIdAtom } from "./Project";
import {
  clearSelectionActionAtom,
  selectedBlockIdAtom,
  selectedItemsAtom,
  setSelectedBlockIdsActionAtom,
  setSelectedTextIdsActionAtom,
} from "./ProjectSelection";
import { deferredVariantsNamesMapAtom } from "./Workspace";

// MARK: - Source Atoms

type InlineEditingExistingTextItem = { type: "existing"; id: string; richText: ITipTapRichText; variantId?: string };
type InlineEditingNewTextItem = {
  type: "new";
  blockId: string | null;
  location: "start" | "end";
  richText: ITipTapRichText;
};

type InlineEditingState = InlineEditingExistingTextItem | InlineEditingNewTextItem | null;

export const newTextItemTextValueAtom = atom<ITipTapRichText>(DEFAULT_RICH_TEXT);
const _inlineEditingAtom = atom<InlineEditingState>(null);

// initial edit state
const _initialInlineEditAtom = atom<InlineEditingState>(null);

/**
 * Use this atom to set the inline editing state to a value.
 * To clear the state, with or without a discard modal, use the stopInlineEditingActionAtom.
 */
export const inlineEditingAtom = atom(
  (get) => get(_inlineEditingAtom),
  (get, set, value: InlineEditingExistingTextItem | InlineEditingNewTextItem) => {
    // set our initial state when we have a new edit value, and our current edit state has not yet been set
    const currEditingState = get(_inlineEditingAtom);
    if (!currEditingState) {
      set(_initialInlineEditAtom, value);
    }

    // set state to the new value
    set(_inlineEditingAtom, value);
  }
);

/**
 * An action to stop inline editing.
 * This will prompt the user with a discard modal if there are unsaved changes, unless `skipConfirmation` is true.
 * To conditionally run additional code only after confirming, pass a function to `onConfirm`.
 * Note: There is no need to include an onConfirm when skipping confirmation - just run the other code after this action.
 */
export const stopInlineEditingActionAtom = atom(
  null,
  (
    get,
    set,
    {
      skipConfirmation,
      onConfirm,
      skipFocus = false,
    }: { skipConfirmation?: boolean; onConfirm?: () => void; skipFocus?: boolean }
  ) => {
    const currEditingState = get(inlineEditingAtom);

    const handleStopEditing = () => {
      set(_inlineEditingAtom, null);
      set(_initialInlineEditAtom, null);
      set(newTextItemTextValueAtom, DEFAULT_RICH_TEXT);

      if (currEditingState?.type === "new") {
        set(selectedItemsAtom, { type: "none" });
      }
      onConfirm?.();

      // Focus the text item list again, so keyboard navigation keeps working
      if (!skipFocus) {
        set(focusTextItemListActionAtom);
      }
    };

    // If we're not currently editing or we don't want to confirm, just run the stop editing action
    if (!currEditingState || skipConfirmation) {
      handleStopEditing();
    } else {
      // Surface a discard modal
      set(discardChangesModalActionAtom, {
        ignoreInlineChanges: false,
        onConfirm: handleStopEditing,
        activeElement: document.activeElement,
      });
    }
  }
);

/**
 * Tracks whether there are changes to the inline editing state.
 *
 * We don't track updates to edit state of type "new", so it defaults to having text changes if string is non-empty.
 * Otherwise, compare difference in rich text b/w current edit state and initial edit state.
 */
export const hasInlineEditingChangesAtom = atom((get) => {
  const inlineEditingState = get(_inlineEditingAtom);

  if (inlineEditingState?.type === "new") {
    const { text } = serializeTipTapRichText(get(newTextItemTextValueAtom), { type: "display" });
    return text !== "";
  }

  const initialInlineEditState = get(_initialInlineEditAtom);
  return isDiffRichText(initialInlineEditState?.richText, inlineEditingState?.richText);
});

export const newBlockAtom = atom<{ name: string; optimisticId: string; atIndex?: number } | null>(null);

export const isDeletingTextItemAtom = atom(false);

export const blockDetailsEditStateAtom = atom<IDittoProjectBlock | null>(null);

// MARK: - Derived Atoms

export const isInlineEditingNewTextAtom = atom((get) => {
  const inlineEditingState = get(inlineEditingAtom);
  return inlineEditingState?.type === "new";
});

export const isEditingNewContentAtom = atom((get) => {
  const inlineEditingNew = get(isInlineEditingNewTextAtom);
  const newBlockState = get(newBlockAtom);

  return inlineEditingNew || !!newBlockState;
});

export const isTextItemEditingInlineAtomFamily = atomFamily((id: string) =>
  atom((get) => {
    const inlineEditingState = get(inlineEditingAtom);
    return inlineEditingState?.type === "existing" && inlineEditingState.id === id;
  })
);

/**
 * If the user is adding a new text item to the block with this blockId,
 * will return whether it's being added at the top or bottom of the list.
 *
 * If the user is not adding a new text item to this block, will return null.
 */
export const newTextItemLocationFamilyAtom = atomFamily((blockId: string | null) =>
  atom<"start" | "end" | null>((get) => {
    const inlineEditingState = get(inlineEditingAtom);
    if (inlineEditingState?.type === "new" && inlineEditingState.blockId === blockId) {
      return inlineEditingState.location;
    } else {
      return null;
    }
  })
);

const selectedBlockAtom = atom(async (get) => {
  const blockId = get(selectedBlockIdAtom);
  if (!blockId) return null;

  const blockAtom = blockFamilyAtom(blockId);
  return await get(blockAtom);
});

const {
  valueAtom: _editableBlockAtom,
  resetAtom: _editableBlockResetAtom,
  hasChanged: editableBlockHasChangesAtom,
} = asyncMutableDerivedAtom({
  loadData: async (get) => get(selectedBlockAtom),
});

export { editableBlockHasChangesAtom };

type EditableBlock = IDittoProjectBlock | null;

export const updateEditableBlockAtom = atom(
  null,
  async (get, set, value: EditableBlock | ((prev: EditableBlock) => EditableBlock) | typeof RESET) => {
    if (value === RESET) {
      set(_editableBlockResetAtom);
      return;
    }

    const newValue = typeof value === "function" ? value(await get(_editableBlockAtom)) : value;
    set(_editableBlockAtom, newValue);
  }
);

export const unwrappedEditableBlockAtom = unwrap(
  _editableBlockAtom,
  (prev) =>
    prev ?? {
      _id: "",
      name: "Loading",
      frameCount: 0,
    }
);

// MARK: - Edit Panel state

export const editableHasChangesAtom = atom(false);

// Tracks unsaved changes, mapping variantId to a flag for changes for that text item variant
export const editableTextItemVariantChangesAtom = atom<Record<string, boolean>>({});

// Reset state for whether we have unsaved changes to a text item's variants
export const resetTextItemVariantChangesActionAtom = atom(null, (_get, set) => {
  set(editableTextItemVariantChangesAtom, {});
});

export const hasTextItemVariantChangesAtom = atom((get) =>
  Object.values(get(editableTextItemVariantChangesAtom)).some((change) => change)
);

/**
 * Returns a formatted string of variants on the text item with unsaved changes.
 * Examples of name formatting:
 * - "French, English, and German"
 * - "French and English"
 * - "French"
 */
export const formattedTextItemVariantsWithChangesAtom = atom<string>((get) => {
  const textItemVariantChanges = get(editableTextItemVariantChangesAtom);
  const deferredVariantsNamesMap = get(deferredVariantsNamesMapAtom);
  const variantNames = Object.entries(textItemVariantChanges)
    .filter(([_, hasChanges]) => hasChanges)
    .map(([variantId]) => deferredVariantsNamesMap[variantId]);

  const formattedVariantNames =
    variantNames.length === 1
      ? variantNames[0]
      : variantNames.length === 2
      ? `${variantNames[0]} and ${variantNames[1]}`
      : variantNames.slice(0, -1).join(", ") + ", and " + variantNames[variantNames.length - 1];

  return `Your changes to the ${formattedVariantNames} variant${
    variantNames.length > 1 ? "s" : ""
  } will be discarded. This can't be undone.`;
});

// Tracks what parts of the Add Variant Form have user input
const _addVariantFormChanges = atom<AddVariantFormChanges>(DEFAULT_ADD_VARIANT_CHANGES);

/**
 * Use this to update a piece of the _addVariantFormChanges state
 */
export const setAddVariantFormChangesActionAtom = atom(null, (_get, set, changes: Partial<AddVariantFormChanges>) => {
  set(_addVariantFormChanges, (prev) => ({ ...prev, ...changes }));
});

/**
 * Use this to fully reset the _addVariantFormChanges state back to no changes
 */
export const resetAddVariantFormChangesActionAtom = atom(null, (_get, set) => {
  set(_addVariantFormChanges, DEFAULT_ADD_VARIANT_CHANGES);
});

// Tracks if any part of the Add Variant Form has user input
export const hasNewVariantChangesAtom = atom((get) => {
  const addVariantChanges = get(_addVariantFormChanges);
  return Object.values(addVariantChanges).some((change) => change);
});

// MARK: - Actions

export const addNewTextItemActionAtom = atom(
  null,
  (get, set, { blockId = null, location = "end" }: { blockId?: string | null; location?: "start" | "end" } = {}) => {
    set(clearSelectionActionAtom);
    set(inlineEditingAtom, { type: "new", blockId, richText: EMPTY_RICH_TEXT, location });
  }
);

export const cancelNewTextItemActionAtom = atom(
  null,
  (get, set, { skipConfirmation }: { skipConfirmation: boolean }) => {
    set(stopInlineEditingActionAtom, { skipConfirmation });
  }
);

export const textItemListRefAtom = atom<HTMLDivElement | null>(null);
export const focusTextItemListActionAtom = atom(null, (get) => {
  get(textItemListRefAtom)?.focus();
});

export const updateTextActionAtom = atom(null, async (get, set, textItemId: string, richText: ITipTapRichText) => {
  const projectId = get(projectIdAtom);
  if (!projectId) throw new Error("projectIdAtom is not set");
  const workspaceId = get(workspaceIdAtom);
  if (!workspaceId) throw new Error("workspceIdAtom is not set");

  const { text } = serializeTipTapRichText(richText);

  const textItemAtom = textItemFamilyAtom(textItemId);
  const originalTextItem = await get(textItemAtom);

  const oldRichText = originalTextItem.rich_text;

  if (!isDiffRichText(oldRichText, richText)) return;

  const { text: oldText } = serializeTipTapRichText(oldRichText);

  set(textItemAtom, (prev) => {
    return { ...prev, rich_text: richText, text };
  });
  try {
    const result = await client.dittoProject.updateTextItems({
      projectId,
      updates: [{ textItemIds: [textItemId], richText }],
    });

    const updatedLibraryComponents = Object.values(result.updatedLibraryComponents);

    for (const updatedLibraryComponent of updatedLibraryComponents) {
      set(updateLibraryComponentActionAtom, { _id: updatedLibraryComponent._id, update: updatedLibraryComponent });
    }
  } catch (e) {
    set(showToastActionAtom, { message: "Something went wrong updating this text" });
    logger.error(`Error updating text item with id ${textItemId}}`, { context: { projectId } }, e);
    set(textItemAtom, (prev) => ({ ...prev, rich_text: oldRichText, text: oldText }));
  }
});

export const saveNewTextItemActionAtom = atom(null, async (get, set, richText: ITipTapRichText) => {
  const inlineEditingState = get(inlineEditingAtom);
  if (inlineEditingState?.type !== "new") throw new Error("newTextItemAtom is not set");
  const projectId = get(projectIdAtom);
  if (!projectId) throw new Error("projectIdAtom is not set");
  const workspaceId = get(workspaceIdAtom);
  if (!workspaceId) throw new Error("workspceIdAtom is not set");

  const newTextItemId = new ObjectId().toString();
  const { text } = serializeTipTapRichText(richText);

  const newTextItemPlaceholder: ITextItem = {
    _id: newTextItemId,
    assignee: null,
    assignedAt: null,
    text,
    rich_text: richText,
    workspace_ID: workspaceId,
    doc_ID: projectId,
    status: "NONE",
    tags: [],
    apiID: null,
    variables: [],
    plurals: [],
    notes: null,
    in_graveyard: false,
    graveyard_apiID: null,
    has_conflict: false,
    ws_comp: null,
    comment_threads: [],
    variants: [],
    integrations: {},
    lastSync: null,
    text_last_modified_at: new Date(),
    characterLimit: null,
    figma_node_ID: null,
    figma_node_ID_cached: null,
    lastSyncRichText: null,
    date_time_created: new Date(),
    is_hidden: false,
    isSample: false,
    version: 2,
  };

  // First, optimistically update the UI with estimated data
  textItemFamilyAtom(newTextItemId, newTextItemPlaceholder);

  const optimisticBlockTextItem = await set(addTextItemToBlockActionAtom, {
    blockId: inlineEditingState.blockId,
    textItemId: newTextItemId,
    location: inlineEditingState.location,
  });

  set(stopInlineEditingActionAtom, { skipConfirmation: true });

  // select newly saved text item
  set(setSelectedTextIdsActionAtom, [newTextItemId]);

  let textItem: ITextItem;

  try {
    // Now actually commit the change
    const [request] = httpDittoProject.createTextItems({
      projectId,
      textItems: [
        {
          _id: newTextItemId,
          richText,
          blockId: inlineEditingState.blockId,
        },
      ],
      addToStart: inlineEditingState.location === "start",
      source: "web_app",
    });

    const response = await request;
    textItem = response.data.textItems[0];
  } catch (e) {
    set(showToastActionAtom, { message: "Something went wrong adding this text" });
    logger.error(`Error saving new text item`, { context: { projectId } }, e);

    // Roll back the changes in the UI
    textItemFamilyAtom.remove(newTextItemId);
    set(setSelectedTextIdsActionAtom, []);

    await set(projectBlocksAtom, (prevProjectBlocks) =>
      rollbackTextItemAddition(prevProjectBlocks, inlineEditingState.blockId, newTextItemId)
    );
    return;
  }

  set(textItemFamilyAtom(textItem._id), textItem);

  // If the real textItem has a different sortKey, we need to update it everywhere
  if (textItem.sortKey && textItem.sortKey !== optimisticBlockTextItem.sortKey) {
    const blockTextItem = { _id: newTextItemId, sortKey: textItem.sortKey };
    await set(projectBlocksAtom, (prevProjectBlocks) =>
      updateTextItem(prevProjectBlocks, textItem.blockId, blockTextItem)
    );
  }
});

const addTextItemToBlockActionAtom = atom(
  null,
  async (
    get,
    set,
    {
      blockId,
      textItemId,
      location,
    }: { blockId: string | null | undefined; textItemId: string; location: "start" | "end" }
  ) => {
    const blocks = await get(projectBlocksAtom);
    const blockToUpdate = blocks.find((block) => block._id === blockId);
    const blockTextItem = { _id: textItemId, sortKey: "" };

    if (!blockToUpdate) {
      blockTextItem.sortKey = generateKeyBetween(null, null);

      // If no matching block is found, create a new block with this text item
      const updatedBlocks = blocks.concat({
        _id: blockId ?? null,
        name: "New Block",
        allTextItems: [blockTextItem],
        textItems: [blockTextItem],
      });

      await set(projectBlocksAtom, updatedBlocks);
      return blockTextItem;
    } else if (location === "start") {
      // Add the text item to the start of the found block
      const firstItem = blockToUpdate.allTextItems[0] || null;
      const firstItemSortKey = firstItem?.sortKey || null;
      blockTextItem.sortKey = generateKeyBetween(null, firstItemSortKey);

      const updatedBlocks = blocks.map((existingBlock) => {
        if (existingBlock._id === blockId) {
          return {
            ...blockToUpdate,
            textItems: [blockTextItem].concat(blockToUpdate.textItems),
            allTextItems: [blockTextItem].concat(blockToUpdate.allTextItems),
          };
        } else {
          return existingBlock;
        }
      });
      await set(projectBlocksAtom, updatedBlocks);
      return blockTextItem;
    } else {
      // Add the text item to the end of the found block
      const lastItemSortKey = blockToUpdate.allTextItems.at(-1)?.sortKey || null;
      blockTextItem.sortKey = generateKeyBetween(lastItemSortKey, null);

      const updatedBlocks = blocks.map((existingBlock) => {
        if (existingBlock._id === blockId) {
          return {
            ...blockToUpdate,
            textItems: blockToUpdate.textItems.concat(blockTextItem),
            allTextItems: blockToUpdate.allTextItems.concat(blockTextItem),
          };
        } else {
          return existingBlock;
        }
      });
      await set(projectBlocksAtom, updatedBlocks);
      return blockTextItem;
    }
  }
);

function updateTextItem(
  blocks: IDittoBlockData[],
  blockId: string | null | undefined,
  blockTextItem: { _id: string; sortKey: string }
): IDittoBlockData[] {
  const blockIndex = blocks.findIndex((block) => block._id === blockId);
  if (blockIndex === -1) {
    // If we didn't find the block, we can't update anything
    return blocks;
  } else {
    const replaceItem = (item: { _id: string; sortKey: string }) => {
      if (item._id === blockTextItem._id) {
        return blockTextItem;
      } else {
        return item;
      }
    };

    const updatedBlocks = [...blocks];
    updatedBlocks[blockIndex] = {
      ...blocks[blockIndex],
      textItems: blocks[blockIndex].textItems.map(replaceItem),
      allTextItems: blocks[blockIndex].allTextItems.map(replaceItem),
    };

    return updatedBlocks;
  }
}

function rollbackTextItemAddition(
  blocks: IDittoBlockData[],
  blockId: string | null | undefined,
  textItemId: string
): IDittoBlockData[] {
  const blockIndex = blocks.findIndex((block) => block._id === blockId);
  if (blockIndex === -1) {
    // If no matching block, just return unedited. This is unexpected.
    return blocks;
  } else {
    // Remove the text item by id from each list
    const updatedBlocks = [...blocks];
    const blockToUpdate = updatedBlocks[blockIndex];
    updatedBlocks[blockIndex] = {
      ...blockToUpdate,
      textItems: blockToUpdate.textItems.filter((textItem) => textItem._id !== textItemId),
      allTextItems: blockToUpdate.allTextItems.filter((textItem) => textItem._id !== textItemId),
    };
    return updatedBlocks;
  }
}

function moveTextItem(
  listKey: "allTextItems" | "textItems",
  props: {
    project: IDittoProjectData;
    oldBlockIndex: number;
    newBlockIndex: number;
    textItemId: string;
    action: IMoveTextItemsAction;
    referenceTextItemId?: string;
  }
) {
  const oldBlockPosition = props.project.blocks[props.oldBlockIndex][listKey].findIndex(
    (item) => item._id === props.textItemId
  );
  if (oldBlockPosition === -1) throw new Error("TextItem not found in old block");
  const existingTextItem = props.project.blocks[props.oldBlockIndex][listKey].splice(oldBlockPosition, 1)[0];

  let positionToInsert = props.project.blocks[props.newBlockIndex][listKey].length;
  if (props.referenceTextItemId) {
    const referenceTextItemInList = props.project.blocks[props.newBlockIndex][listKey].findIndex(
      (item) => item._id === props.referenceTextItemId
    );
    if (referenceTextItemInList === -1) throw new Error("Reference TextItem not found in new block");
    if (props.action.before) positionToInsert = referenceTextItemInList;
    if (props.action.after) positionToInsert = referenceTextItemInList + 1;
  }

  props.project.blocks[props.newBlockIndex][listKey].splice(positionToInsert, 0, existingTextItem);
}

export const reorderTextItemsActionAtom = atom(null, async (get, set, actions: IMoveTextItemsAction[]) => {
  const projectId = get(projectIdAtom);
  if (!projectId) throw new Error("projectIdAtom is not set");

  const oldProject = await get(projectAtom);
  const project = { ...oldProject };

  const fixedActions = actions.map((action) => {
    const actionDestinationBlock = project.blocks.find((block) => block._id === (action.blockId ?? null));

    if (!actionDestinationBlock) {
      throw new Error("Block not found");
    }

    const blockItems = actionDestinationBlock.textItems.map((x) => x._id);

    const direction = action.before ? "before" : "after";

    const normalized = normalizeMoveAction({
      itemIds: action.textItemIds,
      referenceItemId: action.before ?? action.after ?? undefined,
      direction,
      itemIdsInCollection: blockItems,
      allItemIds: project.blocks.flatMap((block) => block.textItems.map((x) => x._id)),
    });

    return {
      ...action,
      textItemIds: normalized.itemIds,
      before: normalized.direction === "before" ? normalized.referenceItemId : undefined,
      after: normalized.direction === "after" ? normalized.referenceItemId : undefined,
    };
  });

  const blockIndicesChanged = new Set<number>();

  for (const action of fixedActions) {
    const referenceTextItemId = action.before ?? action.after;

    // if we're inserting the text items *after* a reference item, we need to do it in reverse order since we are
    // inserting them one at a time.
    //
    // e.g. if our array is ["a", "b", "c"] and we want to insert ["X", "Y"] after "b",
    // -- we first insert "Y": ["a", "b", "Y", "c"]
    // -- then "X": ["a", "b", "X", "Y", "c"]
    const textItemIdsToMove = [...action.textItemIds];
    if (action.after) textItemIdsToMove.reverse();

    for (const textItemId of textItemIdsToMove) {
      // Get the actual text item data to get its block.
      const textItemAtom = textItemFamilyAtom(textItemId);
      const textItem = await get(textItemAtom);

      // Find the indexes of the old and new blocks.
      const oldBlockIndex = project.blocks.findIndex((block) => block._id === (textItem.blockId ?? null));
      const newBlockIndex = project.blocks.findIndex((block) => block._id === (action.blockId ?? null));
      if (oldBlockIndex === -1) throw new Error("Old block not found");
      if (newBlockIndex === -1) throw new Error("New block not found");

      blockIndicesChanged.add(oldBlockIndex);
      blockIndicesChanged.add(newBlockIndex);

      // Get the reference id in the new block
      const referenceTextItemIdInAllTextItems = project.blocks[newBlockIndex].allTextItems.findIndex(
        (item) => item._id === referenceTextItemId
      );
      if (referenceTextItemIdInAllTextItems === -1 && referenceTextItemId)
        throw new Error("Reference TextItem not found in new block");

      // Handle moving the text item in allTextItems array from old block location to new block location.
      moveTextItem("allTextItems", {
        project,
        oldBlockIndex,
        newBlockIndex,
        textItemId,
        action,
        referenceTextItemId,
      });
      // Handle moving the text item in filtered textItems array from old block location to new block location.
      moveTextItem("textItems", {
        project,
        oldBlockIndex,
        newBlockIndex,
        textItemId,
        action,
        referenceTextItemId,
      });

      // Update the local textItem data
      set(textItemAtom, {
        ...textItem,
        blockId: project.blocks[newBlockIndex]._id,
      });
    }
  }

  project.blocks = project.blocks.map((block, i) => (blockIndicesChanged.has(i) ? { ...block } : block));

  /**
   * Update the text entries for only the blocks which have changed.
   */
  set(projectAtom, project);

  const [request] = httpDittoProject.reorderTextItems({
    projectId: projectId,
    actions: fixedActions,
  });

  try {
    // The backend request is reponsible for updating the text items' sort keys -- make sure we update those in state.
    const response = await request;
    const idKeyMap = response.data;
    for (const entry of Object.entries(idKeyMap)) {
      const [textItemId, sortKey] = entry;
      const textItemAtom = textItemFamilyAtom(textItemId);

      const prevTextItem = await get(textItemAtom);
      if (prevTextItem.sortKey !== sortKey) {
        set(textItemAtom, (prev) => ({ ...prev, sortKey }));
        const blockTextItem = { _id: textItemId, sortKey };
        await set(projectBlocksAtom, (prevProjectBlocks) =>
          updateTextItem(prevProjectBlocks, prevTextItem.blockId, blockTextItem)
        );
      }
    }
  } catch (error) {
    logger.error("Error persisting reordering text items", { context: { actions: fixedActions } }, error);
    throw error;
    // TODO: Add support for error handling and rolling back optimistic updates
    // see https://linear.app/dittowords/issue/DIT-8005/add-support-for-error-handling-and-optimistic-update-rollbacks-when
  }
});

export const deleteTextItemsActionAtom = atom(null, async (get, set, textItemIds: string[]) => {
  const dedupedTextItemIds = [...new Set(textItemIds)];

  const projectId = get(projectIdAtom);
  if (!projectId) throw new Error("projectIdAtom is not set");

  const itemsToDelete: { item: ITextItem; blockId: string | null }[] = await Promise.all(
    dedupedTextItemIds.map(async (id) => {
      const item = await get(textItemFamilyAtom(id));
      return { item, blockId: item.blockId ?? null };
    })
  );

  // Perform optimistic delete on project state
  const projectBlocks = await get(projectBlocksAtom);
  const updatedBlocks = getUpdatedBlocksFromDeletion(itemsToDelete, projectBlocks);
  await set(projectBlocksAtom, updatedBlocks);

  try {
    await client.dittoProject.deleteTextItems({ projectId, textItemIds: dedupedTextItemIds });
  } catch (e) {
    set(showToastActionAtom, { message: "Something went wrong deleting these texts" });
    logger.error(`Error deleting text items`, { context: { textItemIds: dedupedTextItemIds } }, e);

    // Get CURRENT project blocks (may have changed since initial get)
    const currentBlocks = await get(projectBlocksAtom);

    // Reinsert deleted items into their original blocks
    const restoredBlocks = currentBlocks.map((block) => {
      const itemsToRestore = itemsToDelete.filter(({ blockId }) => blockId === block._id);
      if (!itemsToRestore.length) return block;

      const newTextItemsField = [
        ...block.textItems,
        ...itemsToRestore.map(({ item }) => ({
          _id: item._id,
          sortKey: item.sortKey ?? "",
        })),
      ].sort((a, b) => a.sortKey.localeCompare(b.sortKey));

      const newAllTextItemsField = [
        ...block.allTextItems,
        ...itemsToRestore.map(({ item }) => ({
          _id: item._id,
          sortKey: item.sortKey ?? "",
        })),
      ].sort((a, b) => a.sortKey.localeCompare(b.sortKey));

      return {
        ...block,
        textItems: newTextItemsField,
        allTextItems: newAllTextItemsField,
      };
    });

    await set(projectBlocksAtom, restoredBlocks);
  }
});

export const addNewBlockActionAtom = atom(null, (get, set, update?: { name?: string; atIndex?: number }) => {
  const name = update?.name ?? "";

  set(newBlockAtom, { name, optimisticId: new ObjectId().toString(), atIndex: update?.atIndex });
  set(clearSelectionActionAtom);
});

export const changeNewBlockNameActionAtom = atom(null, (get, set, name: string) => {
  set(newBlockAtom, (prev) => (prev ? { ...prev, name } : prev));
});

export const cancelNewBlockActionAtom = atom(null, (get, set) => {
  set(newBlockAtom, null);
});

export const saveNewBlockActionAtom = atom(null, async (get, set) => {
  const newBlock = get(newBlockAtom);
  const projectId = get(projectIdAtom);
  if (!newBlock) throw new Error("newBlockAtom is not set");
  if (!projectId) throw new Error("projectIdAtom is not set");

  // Optimistically add the new block in the UI
  const { name: optimisticBlockName } = await set(addBlockActionAtom, {
    _id: newBlock.optimisticId,
    name: newBlock.name,
    atIndex: newBlock.atIndex,
  });
  set(newBlockAtom, null);

  // select new block after it's been added
  set(setSelectedBlockIdsActionAtom, newBlock.optimisticId);

  let blockName = optimisticBlockName;

  try {
    // Create the block in the backend
    const response = await client.dittoProject.createBlocks({
      projectId: projectId,
      blocks: [
        {
          _id: newBlock.optimisticId,
          name: newBlock.name,
        },
      ],
      atIndex: newBlock.atIndex,
      source: "web_app",
    });

    const blockFromBackend = response.find((block) => block._id === newBlock.optimisticId);
    if (blockFromBackend) blockName = blockFromBackend?.name;
  } catch (e) {
    set(showToastActionAtom, { message: "Something went wrong adding this block" });
    logger.error(`Error saving new block`, { context: { projectId } }, e);
    await set(rollbackBlockCreationActionAtom, newBlock.optimisticId);
  }

  if (blockName !== optimisticBlockName) {
    await set(updateBlockNameActionAtom, { _id: newBlock.optimisticId, name: blockName });
  }
});

/**
 * Adds a block with the provided name and id to Jotai
 * - blockFamilyAtom
 * - projectAtom.blocks list
 *
 * Only updates Jotai, does not communicate with the backend
 */
const addBlockActionAtom = atom(
  null,
  async (get, set, { _id, name, atIndex }: { _id: string; name: string; atIndex?: number }) => {
    const oldProjectBlocks = await get(projectBlocksAtom);

    const newBlockName = name || `Block ${oldProjectBlocks.length}`;

    blockFamilyAtom(_id, {
      _id,
      name: newBlockName,
      frameCount: 0,
    });

    const blockToAdd = {
      _id,
      name: newBlockName,
      allTextItems: [],
      textItems: [],
    };

    if (atIndex !== undefined) {
      const newBlocks = [...oldProjectBlocks];
      newBlocks.splice(atIndex, 0, blockToAdd);
      await set(projectBlocksAtom, newBlocks);
    } else {
      // Add the block to the project block list
      // This list should have a block with id null at the end, for non-block text items,
      // The null block should remain at the end, so add this block right before it as long as it's present
      const lastBlock = oldProjectBlocks.at(-1);
      if (lastBlock && lastBlock._id === null) {
        await set(projectBlocksAtom, oldProjectBlocks.slice(0, -1).concat(blockToAdd, lastBlock));
      } else {
        await set(projectBlocksAtom, oldProjectBlocks.concat(blockToAdd));
      }
    }
    return { _id, name: newBlockName };
  }
);

/**
 * Updates the name of a block by _id in Jotai
 * - blockFamilyAtom
 * - projectAtom.blocks list
 *
 * Only updates Jotai, does not communicate with the backend
 */
const updateBlockNameActionAtom = atom(null, async (get, set, { _id, name }: { _id: string; name: string }) => {
  // Update the name in the block family atom
  set(blockFamilyAtom(_id), (prevBlock) => {
    if (prevBlock.name !== name) {
      return { ...prevBlock, name };
    } else {
      return prevBlock;
    }
  });

  // Update the name in the project block list
  set(projectBlocksAtom, (prevProjectBlocks) => {
    const blockIndex = prevProjectBlocks.findIndex((b) => b._id === _id);
    const blockToUpdate = prevProjectBlocks[blockIndex];

    if (blockIndex !== -1 && blockToUpdate.name !== name) {
      return prevProjectBlocks.with(blockIndex, {
        ...blockToUpdate,
        name,
      });
    } else {
      return prevProjectBlocks;
    }
  });
});

/**
 * Removes a block by id from Jotai
 * - blockFamilyAtom
 * - projectAtom.blocks list
 *
 * Only updates Jotai, does not communicate with the backend
 * This is for rolling back an optimistic update on failure to create a block
 */
const rollbackBlockCreationActionAtom = atom(null, async (get, set, blockId: string) => {
  const inlineEditingState = get(inlineEditingAtom);

  // Reset inline edit state if we were trying to add a text item to the new block
  if (inlineEditingState?.type === "new" && inlineEditingState?.blockId === blockId) {
    set(stopInlineEditingActionAtom, { skipConfirmation: true });
  }

  // Remove from block family atom
  blockFamilyAtom.remove(blockId);

  // Remove from project block list
  await set(projectBlocksAtom, (prevProjectBlocks) => prevProjectBlocks.filter((b) => b._id !== blockId));
});

export function getUpdatedBlocksFromDeletion(
  textItemsToDelete: { item: ITextItem; blockId: string | null }[],
  projectBlocks: IDittoBlockData[]
) {
  const blocksToUpdate = new Map<string | null, IDittoBlockData>();

  for (const { item: itemToDelete, blockId } of textItemsToDelete) {
    const block = projectBlocks.find((b) => b._id === blockId);
    if (!block) continue;

    if (!blocksToUpdate.has(blockId)) {
      blocksToUpdate.set(blockId, { ...block });
    }

    const updatedBlock = blocksToUpdate.get(blockId)!;
    updatedBlock.textItems = updatedBlock.textItems.filter((ti) => ti._id !== itemToDelete._id);
    updatedBlock.allTextItems = updatedBlock.allTextItems.filter((ti) => ti._id !== itemToDelete._id);
  }

  // Apply all block updates
  const updatedBlocks = projectBlocks.map((block) => {
    const updated = blocksToUpdate.get(block._id ?? null);
    return updated ? updated : block;
  });
  return updatedBlocks;
}
