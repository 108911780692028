import {
  clearLibrarySelectionActionAtom,
  hasExactlyOneComponentSelectedAtom,
  LibraryDetailsEditPanelTabAtom,
  selectedComponentIdsAtom,
} from "@/stores/Library";
import TabGroup from "@ds/molecules/TabGroup";
import TitleRow from "@ds/organisms/DetailsPanelTitleRow";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { Suspense, useMemo } from "react";
import Skeleton from "react-loading-skeleton";
import LibraryEditActivityPanel from "../LibraryEditActivityPanel";
import LibraryEditCommentsPanel from "../LibraryEditCommentsPanel";
import LibraryEditEditPanel from "../LibraryEditEditPanel";
import LibraryEditInstancesPanel from "../LibraryEditInstancesPanel";
import LibraryEditVariantsPanel from "../LibraryEditVariantsPanel";
import style from "./style.module.css";

function LibraryEditActivityPanelWithSuspense() {
  return (
    <Suspense fallback={<Skeleton height={61} baseColor="white" style={{ margin: "10px" }} />}>
      <LibraryEditActivityPanel />
    </Suspense>
  );
}

function LibraryEditInstancesPanelWithSuspense() {
  return (
    <Suspense fallback={<Skeleton height={61} baseColor="white" style={{ margin: "10px" }} />}>
      <LibraryEditInstancesPanel />
    </Suspense>
  );
}

const EditTab = {
  id: "EDIT",
  label: "Edit",
  Content: LibraryEditEditPanel,
};
const ActivityTab = {
  id: "ACTIVITY",
  label: "Activity",
  Content: LibraryEditActivityPanelWithSuspense,
};
const CommentsTab = {
  id: "COMMENTS",
  label: "Comments",
  Content: LibraryEditCommentsPanel,
};
const VariantsTab = {
  id: "VARIANTS",
  label: "Variants",
  Content: LibraryEditVariantsPanel,
};
const InstancesTab = {
  id: "INSTANCES",
  label: "Instances",
  Content: LibraryEditInstancesPanelWithSuspense,
};

function LibraryEditDetailsPanel() {
  const [selectedTab, setSelectedTab] = useAtom(LibraryDetailsEditPanelTabAtom);
  const selectedComponentIds = useAtomValue(selectedComponentIdsAtom);
  const hasExactlyOneComponentSelected = useAtomValue(hasExactlyOneComponentSelectedAtom);
  const clearLibrarySelectionAction = useSetAtom(clearLibrarySelectionActionAtom);

  const tabs = useMemo(() => {
    if (hasExactlyOneComponentSelected) {
      return [EditTab, ActivityTab, CommentsTab, VariantsTab, InstancesTab];
    }

    // Note: If more than one component is selected, we will render the content of the EditTab and no tab bar
    return [EditTab];
  }, [hasExactlyOneComponentSelected]);

  const selectedTabValue = useMemo(() => {
    let matchingTab = tabs.find((tab) => tab.id === selectedTab);
    if (matchingTab) {
      return matchingTab.id;
    }

    // Note: In this case, we will render the content of the EditTab and no tab bar
    return "EDIT";
  }, [selectedTab, tabs]);

  const title =
    selectedComponentIds.length === 1 ? "Selected component" : `Selected ${selectedComponentIds.length} components`;

  return (
    <>
      <TitleRow title={title} closeAction={clearLibrarySelectionAction} />
      <Suspense fallback={<></>}>
        <TabGroup
          value={selectedTabValue}
          onChange={setSelectedTab}
          tabs={tabs}
          className={style.tabRoot}
          triggerClassName={style.tabTrigger}
          size="small"
          noActiveTabLine
          hideWithOneTab
        />
      </Suspense>
    </>
  );
}
export default LibraryEditDetailsPanel;
