import classNames from "classnames";
import React from "react";
import style from "./index.module.css";

interface IProps {
  className?: string;
  style?: React.CSSProperties;
  children: React.ReactNode;
  count: number;
}

export function MultiDragPreview(props: IProps) {
  return (
    <div
      style={props.style}
      className={classNames(style.multiDragPreviewWrapper, props.className)}
      data-testid="multi-drag-preview"
    >
      <div className={style.childrenWrapper}>{props.children}</div>

      {props.count > 1 && <div className={style.countBadge}>{props.count}</div>}

      {props.count > 1 && (
        <div className={style.stackedFooters}>
          <div className={classNames(style.footerWrapper, style.level1)}>
            <div className={style.footer} />
          </div>

          {props.count > 2 && (
            <div className={classNames(style.footerWrapper, style.level2)}>
              <div className={style.footer} />
            </div>
          )}

          {props.count > 3 && (
            <div className={classNames(style.footerWrapper, style.level3)}>
              <div className={style.footer} />
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default MultiDragPreview;
