import { buildDittoClient } from "@shared/client/buildDittoClient";
import * as DittoEvents from "@shared/ditto-events";
import batchedAsyncAtomFamily from "@shared/frontend/stores/batchedAsyncAtomFamily";
import { REFRESH_SILENTLY, REFRESH_WITH_SUSPENSE } from "@shared/frontend/stores/symbols";
import { IMoveLibraryComponentsAction } from "@shared/types/http/LibraryComponent";
import { ILibraryComponent } from "@shared/types/LibraryComponent";
import logger from "@shared/utils/logger";
import { Atom, atom, WritableAtom } from "jotai";

type LibraryComponentFamilyAtomType = ReturnType<typeof batchedAsyncAtomFamily<ILibraryComponent>>["familyAtom"];
type LibraryComponentResetFamilyAtomType = ReturnType<typeof batchedAsyncAtomFamily<ILibraryComponent>>["resetAtom"];
type UpdateLibraryComponentActionAtomType = WritableAtom<
  null,
  [data: { _id: string; update: ILibraryComponent | typeof REFRESH_WITH_SUSPENSE | typeof REFRESH_SILENTLY }],
  void
>;
type HandleLibraryComponentsUpdatedActionAtomType = WritableAtom<
  null,
  [data: DittoEvents.ILibraryComponentsUpdatedData],
  void
>;
type HandleLibraryComponentCreatedActionAtomType = WritableAtom<
  null,
  [data: DittoEvents.ILibraryComponentCreatedData],
  void
>;
type DeleteLibraryComponentsActionAtomType = WritableAtom<
  null,
  [
    data: {
      ids: string[];
      onDelete?: (components: ILibraryComponent[]) => Promise<void>;
      onRollback?: (components: ILibraryComponent[]) => Promise<void>;
    }
  ],
  Promise<void>
>;
type HandleLibraryComponentsMovedActionAtomType = WritableAtom<
  null,
  [data: { actions: IMoveLibraryComponentsAction[] }],
  void
>;

interface LibraryComponentsStore {
  libraryComponentFamilyAtom: LibraryComponentFamilyAtomType;
  nullableLibraryComponentFamilyAtom: (id: string | null) => ReturnType<LibraryComponentFamilyAtomType> | Atom<null>;
  resetLibraryComponentFamilyActionAtom: LibraryComponentResetFamilyAtomType;
  updateLibraryComponentActionAtom: UpdateLibraryComponentActionAtomType;
  deleteLibraryComponentsActionAtom: DeleteLibraryComponentsActionAtomType;
  handleLibraryComponentsUpdatedActionAtom: HandleLibraryComponentsUpdatedActionAtomType;
  handleLibraryComponentCreatedActionAtom: HandleLibraryComponentCreatedActionAtomType;
  handleLibraryComponentsMovedActionAtom: HandleLibraryComponentsMovedActionAtomType;
}

export function createLibraryComponentsStore(client: ReturnType<typeof buildDittoClient>): LibraryComponentsStore {
  /**
   * The source of truth for all library components.
   */
  const { familyAtom: libraryComponentFamilyAtom, resetAtom: resetLibraryComponentFamilyActionAtom } =
    batchedAsyncAtomFamily<ILibraryComponent>({
      asyncFetchRequest: async (get, ids) => {
        const data = await client.libraryComponent.getLibraryComponents({ componentIds: ids });
        return data.components;
      },
      getId: (item) => item._id.toString(),
      debugPrefix: "Library Component",
      throttleOptions: {
        leading: true,
      },
      throwOnFailToFetch: true,
    });

  /**
   * This is a null atom that is always a null value
   */
  const nullAtom = atom(() => null);
  /**
   * This is a wrapper around the componentFamilyAtom that returns an atom that is null if the id is null.
   * This is useful for when we want to fetch a component by a TextItem's ws_comp field.
   */
  const nullableLibraryComponentFamilyAtom = function (id: string | null) {
    if (id === null) {
      // it's ABSOLUTELY CRITICAL that we define this atom outside of this function! if we instead do
      // return atom(null) here, then the componentFamilyAtom will be re-evaluated on every render,
      // which will cause a re-render loop.
      return nullAtom;
    } else {
      return libraryComponentFamilyAtom(id);
    }
  };

  /**
   * This is an action atom that updates a component.
   */
  const updateLibraryComponentActionAtom = atom(
    null,
    async (
      get,
      set,
      data: { _id: string; update: ILibraryComponent | typeof REFRESH_WITH_SUSPENSE | typeof REFRESH_SILENTLY }
    ) => {
      try {
        if (data.update === REFRESH_WITH_SUSPENSE || data.update === REFRESH_SILENTLY) {
          set(libraryComponentFamilyAtom(data._id), data.update);
        } else {
          let update: ILibraryComponent = data.update;
          set(libraryComponentFamilyAtom(data._id), (prev) => {
            return {
              ...prev,
              ...update,
            };
          });
        }
      } catch (e) {
        logger.error(
          "Failed to update library component",
          {
            context: { componentId: data._id },
          },
          e
        );
      }
    }
  );

  const handleLibraryComponentsUpdatedActionAtom = atom(
    null,
    (get, set, data: DittoEvents.ILibraryComponentsUpdatedData) => {
      for (const componentId of data.libraryComponentIds) {
        set(updateLibraryComponentActionAtom, { _id: componentId, update: REFRESH_SILENTLY });
      }
    }
  );

  const handleLibraryComponentCreatedActionAtom = atom(
    null,
    async (get, set, data: DittoEvents.ILibraryComponentCreatedData) => {
      const _val = await get(libraryComponentFamilyAtom(data.componentId));
    }
  );

  const deleteLibraryComponentsActionAtom = atom(
    null,
    async (
      get,
      set,
      data: {
        ids: string[];
        onDelete?: (components: ILibraryComponent[]) => Promise<void>;
        onRollback?: (components: ILibraryComponent[]) => Promise<void>;
      }
    ) => {
      let componentPromises: (ILibraryComponent | Promise<ILibraryComponent>)[] = [];

      for (const id of data.ids) {
        const component = get(libraryComponentFamilyAtom(id));
        componentPromises.push(component);
      }

      const components = await Promise.all(componentPromises);

      // Optimistically remove the component from the store
      for (const component of components) {
        libraryComponentFamilyAtom.remove(component._id);
      }

      if (data.onDelete) {
        await data.onDelete(components);
      }

      try {
        await client.libraryComponent.deleteLibraryComponents({ componentIds: data.ids });
      } catch (e) {
        // If the deletion fails, we need to re-add the component to the store
        for (const component of components) {
          set(libraryComponentFamilyAtom(component._id), component);
        }

        if (data.onRollback) {
          await data.onRollback(components);
        }

        logger.error(
          "Failed to delete library component",
          {
            context: { componentId: data.ids },
          },
          e
        );
      }
    }
  );

  const handleLibraryComponentsMovedActionAtom = atom(
    null,
    (get, set, data: { actions: IMoveLibraryComponentsAction[] }) => {
      for (const action of data.actions) {
        for (const componentId of action.componentIds) {
          set(updateLibraryComponentActionAtom, { _id: componentId, update: REFRESH_SILENTLY });
        }
      }
    }
  );

  return {
    libraryComponentFamilyAtom,
    nullableLibraryComponentFamilyAtom,
    resetLibraryComponentFamilyActionAtom,
    updateLibraryComponentActionAtom,
    deleteLibraryComponentsActionAtom,
    handleLibraryComponentsUpdatedActionAtom,
    handleLibraryComponentCreatedActionAtom,
    handleLibraryComponentsMovedActionAtom,
  };
}
