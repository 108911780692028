import CloseIcon from "@mui/icons-material/Close";
import React, { useState } from "react";
import BootstrapModal from "react-bootstrap/Modal";
import ButtonPrimary from "../button/buttonprimary";
import ButtonSecondary from "../button/buttonsecondary";

import style from "./style.module.css";

const DeleteFolderModal = ({ onHide, folder, handleDeleteFolder }) => {
  const { _id, name, doc_ids } = folder;

  const [isSaving, setIsSaving] = useState(false);

  const onDelete = async () => {
    try {
      setIsSaving(true);
      await handleDeleteFolder(_id);
    } catch (err) {
      console.error(err);
    }
    setIsSaving(false);
    onHide();
  };
  return (
    <div>
      <BootstrapModal
        show={true}
        className={style.modal}
        dialogClassName={style.dialog}
        backdropClassName={style.backdrop}
        onHide={onHide}
        centered
      >
        <BootstrapModal.Header className={style.header}>
          <BootstrapModal.Title className={style.title}>
            Delete folder <span>{name}</span>?
          </BootstrapModal.Title>
          <CloseIcon className={style.close} onClick={onHide} />
        </BootstrapModal.Header>
        <BootstrapModal.Body className={style.body}>
          <p>Deleting this folder will move all projects back into your main workspace.</p>
          <div className={style.form}>
            <ButtonSecondary text="Cancel" onClick={onHide} />
            <ButtonPrimary text={!isSaving ? "Delete" : "Deleting..."} onClick={onDelete} />
          </div>
        </BootstrapModal.Body>
      </BootstrapModal>
    </div>
  );
};

export default DeleteFolderModal;
