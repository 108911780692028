import { useHTML5DragPreview } from "@shared/frontend/hooks/useHTML5DragPreview";
import React, { useCallback, useRef } from "react";
import { IUser } from "../../../../shared/types/User";
import ButtonWrapFooter from "../ButtonWrapFooter";
import { IProps as ITextItemProps, TextItemComponentState, TextItemStatic } from "../TextItem";
import style from "./index.module.css";

export type ITextEntity = { _id: string } & Pick<
  ITextItemProps,
  "status" | "tags" | "notes" | "defaultValue" | "component" | "instanceCount"
> & {
    assignee?: Pick<IUser, "name" | "picture"> | null;
  };

interface IProps {
  /**
   * Text item or component data.
   */
  data: ITextEntity;

  /**
   * Whether or not the text entity is selected.
   */
  isSelected: boolean;

  /**
   * Optional markup to display above the text entity.
   */
  Header?: React.ReactNode;

  /**
   * If specified, the text entity will be draggable.
   * Must indicate the mime type of the data to be transferred.
   */
  dragAndDrop?: { mime: string };

  /**
   * Text to display to the left of the action button.
   */
  helperText?: string;

  /**
   * Text to display on the action button.
   */
  actionText: string;
  /**
   * Callback for when the action button is clicked.
   * @param id - ID of the text entity that will be acted upon
   * @returns void
   */
  onActionClick: (id: string) => void;
  /**
   * Callback for when the drag ends.
   * @returns void
   */
  onDragEnd?: () => void;
  /**
   * Callback for when the text entity is clicked.
   * @param id - ID of the text entity that was clicked
   * @returns void
   */
  onSelect: (id: string) => void;
  /**
   * Callback for when the text entity is deselected (e.g. when the user clicks the cancel button)
   * @returns void
   */
  onDeselect: () => void;
  /**
   * Custom styles for the component.
   */
  style?: React.CSSProperties;

  /**
   * Custom class name for the component.
   */

  className?: string;
}

export function ActionableTextEntity(props: IProps) {
  const { data, isSelected, helperText, onActionClick, onSelect, onDeselect } = props;

  const containerRef = useRef<HTMLDivElement>(null);

  const dragPreview = useHTML5DragPreview(
    useCallback(
      () => (
        <ActionableTextEntityContent
          {...props}
          onClick={() => null}
          state="focus"
          // ensure the dragged text item is the same width as the drag source
          style={{ width: containerRef.current?.offsetWidth || undefined }}
        />
      ),
      [props]
    )
  );
  const handleClick = useCallback(() => {
    onActionClick(data._id);
  }, [data._id, onActionClick]);

  const handleSelect = useCallback(() => {
    onSelect(data._id);
  }, [data._id, onSelect]);

  const handleDragStart = useCallback(
    function handleDragStart(event: React.DragEvent<HTMLDivElement>) {
      if (!props.dragAndDrop) return;

      dragPreview.handleDragStart(event);

      event.dataTransfer.setData(
        props.dragAndDrop.mime,
        JSON.stringify({
          _id: data._id,
          rich_text: data.defaultValue!,
          type: props.dragAndDrop.mime,
        })
      );
    },
    [data, props, dragPreview]
  );

  const handleDragEnd = useCallback(() => {
    dragPreview.handleDragEnd();
    if (props.onDragEnd) {
      props.onDragEnd();
    }
  }, [props, dragPreview]);

  if (!data) return null;

  const theme = props.data.component ? "purple" : "blue";

  return (
    <div
      key={data._id}
      className={props.className}
      draggable={!!props.dragAndDrop}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      ref={containerRef}
    >
      {props.Header}
      <ButtonWrapFooter
        key={data._id}
        visible={isSelected}
        theme={theme}
        renderSeparator
        primaryText={props.actionText}
        onPrimary={handleClick}
        secondaryText="Cancel"
        onSecondary={onDeselect}
        helperText={helperText}
      >
        <ActionableTextEntityContent
          {...props}
          onClick={handleSelect}
          borderColor={isSelected ? "transparent" : undefined}
        />
      </ButtonWrapFooter>
    </div>
  );
}

function ActionableTextEntityContent(
  props: IProps & {
    onClick: () => void;
    state?: TextItemComponentState;
    borderColor?: string;
    style?: React.CSSProperties;
  }
) {
  const { data, onClick, state, borderColor } = props;
  return (
    <TextItemStatic
      {...data}
      assignee={data.assignee ?? undefined}
      instanceCount={data.instanceCount}
      borderColor={borderColor}
      level="compact"
      state={state}
      showComponentInstances={!!data.component}
      onClick={onClick}
      hideVariantsBadge={true}
      className={style.textItem}
      style={props.style}
    />
  );
}

export default ActionableTextEntity;
