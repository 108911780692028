import { hasLinkingFlowChangesAtom, hasPublishingFlowChangesAtom } from "@/stores/ComponentLinkingFlow";
import { atom } from "jotai";
import {
  editableHasChangesAtom,
  formattedTextItemVariantsWithChangesAtom,
  hasInlineEditingChangesAtom,
  hasNewVariantChangesAtom,
  hasTextItemVariantChangesAtom,
  isInlineEditingNewTextAtom,
  resetAddVariantFormChangesActionAtom,
  resetTextItemVariantChangesActionAtom,
  stopInlineEditingActionAtom,
} from "./Editing";
import { libraryDetailsPanelResetFormStateSignalAtom } from "./Library";
import { commentEditsAtom, detailsPanelPropsAtom, resetCommentEditsActionAtom } from "./ProjectSelection";

export const modalAtom = atom<IModalState | null>(null);

interface IModalState {
  headline: string;
  content: string;
  onAction: () => void;
  onOpenChange: (open: boolean) => void;
  onCancel?: () => void;
  actionText?: string;
  cancelText?: string;
  type?: "default" | "danger";
}

/**
 * Resets state after choosing Discard from the confirmation modal.
 * If `ignoreInlineChanges` is true, inline editing changes will not be discarded.
 */
export const discardChangesActionAtom = atom(
  null,
  (get, set, { ignoreInlineChanges }: { ignoreInlineChanges: boolean }) => {
    const hasTextItemVariantChanges = get(hasTextItemVariantChangesAtom);
    const hasEditableFormChanges = get(editableHasChangesAtom);
    if (!ignoreInlineChanges) {
      set(stopInlineEditingActionAtom, { skipConfirmation: true });
    }
    set(editableHasChangesAtom, false);
    set(resetCommentEditsActionAtom);
    set(resetTextItemVariantChangesActionAtom);
    set(resetAddVariantFormChangesActionAtom);
    set(modalAtom, null);

    if (hasEditableFormChanges) {
      set(libraryDetailsPanelResetFormStateSignalAtom, (x) => !x);
    }

    // If we had variant changes, clear variant form state in Variants panel when discarding changes
    if (hasTextItemVariantChanges) {
      set(detailsPanelPropsAtom, (props) => ({
        ...props,
        variants: undefined,
        resetFormState: true,
      }));
    }
  }
);

type IDiscardChangesModalProps = {
  onConfirm: () => void;
  ignoreInlineChanges: boolean;
  activeElement: Element | null;
  isLibraryModal?: boolean;
};

/**
 * An action that checks if the user has unsaved changes before performing a specified action.
 * If the user has unsaved changes, a modal will be displayed to confirm discarding changes.
 * Otherwise, the action will be performed immediately.
 *
 * Pass `ignoreInlineChanges: true` if the onConfirm action will not discard inline editing changes.
 */
export const discardChangesModalActionAtom = atom(
  null,
  (get, set, { onConfirm, ignoreInlineChanges, activeElement, isLibraryModal }: IDiscardChangesModalProps) => {
    let headline = "";
    let content = "";

    // Define a variable for the entity type based on modal type
    const entityType = isLibraryModal ? "component" : "text item";

    // TEXT ITEM CHANGES
    const hasTextItemChanges = get(editableHasChangesAtom);

    // VARIANT CHANGES
    const hasTextItemVariantChanges = get(hasTextItemVariantChangesAtom);
    const hasNewVariantChanges = get(hasNewVariantChangesAtom);
    const hasVariantChanges = hasTextItemVariantChanges || hasNewVariantChanges;

    // INLINE EDITING CHANGES
    const hasInlineEditingChanges = ignoreInlineChanges ? false : get(hasInlineEditingChangesAtom);

    // ADDING NEW TEXT ITEM
    const isAddingNewTextItem = hasInlineEditingChanges && get(isInlineEditingNewTextAtom);

    // COMENT CHANGES
    const commentEdits = get(commentEditsAtom);
    const hasCommentChanges = commentEdits.size > 0;

    // LINKING FLOW CHANGES
    const hasLinkingFlowChanges = get(hasLinkingFlowChangesAtom);

    // PUBLISHING FLOW CHANGES
    const hasPublishingFlowChanges = isLibraryModal ? false : get(hasPublishingFlowChangesAtom);

    const changesCount =
      Number(isAddingNewTextItem) +
      Number(hasTextItemChanges) +
      Number(hasCommentChanges) +
      Number(hasVariantChanges) +
      Number(hasInlineEditingChanges) +
      Number(hasPublishingFlowChanges) +
      Number(hasLinkingFlowChanges);

    if (changesCount === 0) {
      onConfirm();
      return;
    }

    if (changesCount === 1) {
      if (isAddingNewTextItem) {
        headline = `Discard new ${entityType}?`;
        content = "Your changes will be discarded. This can't be undone.";
      } else if (hasTextItemChanges || hasInlineEditingChanges) {
        headline = `Discard unsaved ${entityType} changes?`;
        content = "Your changes will be discarded. This can't be undone.";
      } else if (hasCommentChanges) {
        const entity = commentEdits.size === 1 ? "comment" : "comments";
        headline = `Discard unsaved ${entity}?`;
        content = `Your ${entity} will be discarded. This can't be undone.`;
      } else if (hasVariantChanges) {
        if (hasTextItemVariantChanges) {
          headline = "Discard changes to variants?";
          content = get(formattedTextItemVariantsWithChangesAtom);
        } else if (hasNewVariantChanges) {
          headline = `Discard unsaved ${entityType} variant changes?`;
          content = "Your changes will be discarded. This can't be undone.";
        }
      } else if (hasPublishingFlowChanges) {
        headline = "Discard unsaved changes?";
        content = "This component will not be published.";
      } else if (hasLinkingFlowChanges) {
        headline = "Discard unsaved changes?";
        content = `This ${entityType} will not be linked to a component.`;
      }
    } else {
      headline = "Discard unsaved changes?";
      const changedEntities: string[] = [];
      if (isAddingNewTextItem) {
        changedEntities.push(`new ${entityType}`);
      }
      if (hasCommentChanges) {
        changedEntities.push(commentEdits.size === 1 ? "comment" : "comments");
      }
      if (hasTextItemChanges || hasInlineEditingChanges) {
        changedEntities.push(`${entityType} changes`);
      }
      if (hasVariantChanges) {
        changedEntities.push(`${entityType} variant changes`);
      }
      if (hasPublishingFlowChanges) {
        changedEntities.push("publishing changes");
      }
      if (hasLinkingFlowChanges) {
        changedEntities.push("linking changes");
      }
      const entitiesString = changedEntities.slice(0, -1).join(", ") + " and " + changedEntities.at(-1);
      content = `Your ${entitiesString} will be discarded. This can't be undone.`;
    }

    set(modalAtom, {
      headline,
      content,
      actionText: "Discard",
      onAction: () => {
        set(discardChangesActionAtom, { ignoreInlineChanges });

        onConfirm();
      },
      onOpenChange: (open) => {
        if (open) return;
        set(modalAtom, null);
      },
      onCancel: () => {
        if (activeElement) {
          // check if active element has the focus method and call it if so
          (activeElement as HTMLElement).focus?.();
        }
        set(modalAtom, null);
      },
    });
  }
);
