import PermissionRequiredFolder from "@/components/permissions/PermissionRequired/PermissionRequiredFolder";
import useRedirectToNSPage from "@/hooks/useRedirectToNSPage";
import { useScroll } from "@/hooks/useScroll";
import http, { API } from "@/http";
import * as httpVariant from "@/http/variantTyped";
import { UnsavedChangesContext } from "@/store/unsavedChangesContext";
import { WebappPermissionProvider as UserPermissionProvider } from "@/store/webappPermissionContext";
import { useVariantSelect } from "@shared/frontend/useVariantSelect";
import {
  UserPermissionContext,
  userHasPermission,
  userHasResourcePermission,
} from "@shared/frontend/userPermissionContext";
import { VARIANTS_NOT_IN_FOLDERS_ID } from "@shared/lib/PermissionGroups";
import * as SegmentEvents from "@shared/segment-event-names";
import classNames from "classnames";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import NewComponentFolderModal from "../../components/NewComponentFolderModal";
import Notification from "../../components/notification-toast";
import { routes } from "../../defs";
import useSegment from "../../hooks/useSegment";
import { VariantContext } from "./VariantContext";
import DeleteVariantFolderModal from "./components/DeleteVariantFolderModal";
import MoveVariantModal from "./components/MoveVariantModal";
import VariantCreateModal from "./components/VariantCreateModal";
import { EditVariantPanel } from "./components/VariantDetail/EditVariantPanel";
import VariantActivity from "./components/VariantDetail/VariantActivity";
import VariantList from "./components/VariantList";
import VariantNav from "./components/VariantNav";
import TitleBar from "./components/VariantTitleBar";
import style from "./style.module.css";
import { useVariants } from "./useVariants";

const Variants = () => {
  const segment = useSegment();
  const {
    loadingVariants,
    setLoadingVariants,
    unfilteredVariants,
    variants,
    updateVariantInMemory,
    uniqueApiIDs,
    uniqueVariantNames,
    query,
    loadingHistory,
    variantHistory,
    setQuery,
    handleQueryChange,
    handleCreateNewVariant,
    handleDeleteVariants,
    handleMoveVariants,
    handleRenameVariant,
    handleRenameVariantApiID,
    fetchVariantHistoryNewPage,
    refreshVariantHistory,
    foldersAllowed,
    variantFolders,
    handleCreateNewVariantFolder,
    handleRenameVariantFolder,
    handleDeleteVariantFolder,
    selectedFolderId,
    variantsToMove,
    setVariantsToMove,
    notification,
    variantSelectionState,
    variantSelectionActions,
  } = useVariants();

  const { checkDetailPanelChanges } = useContext(UnsavedChangesContext);

  const handleRedirectToNSPage = useRedirectToNSPage();

  const onVariantClick = (variantId) => {
    checkDetailPanelChanges(() => variantSelectionActions.handleSingleSelect(variantId));
  };

  const onDeleteVariant = async (variantId) => {
    handleDeleteVariants([variantId]);
  };

  // NOTE: this function is passed data after the user utilizes the modal
  // to select a new or existing folder, and the modal that is used is internal
  // to the edit variant component
  const onMoveToFolder = (variantId, folder) => {
    onMoveVariants(folder, [variantId]);
  };

  const onUpdateDescription = (variantId, updatedDescription) => {
    const [request] = httpVariant.updateDescription({
      variantId,
      description: updatedDescription,
    });

    request.then(() =>
      updateVariantInMemory(variantId, {
        description: updatedDescription,
      })
    );
  };

  const onUpdateName = (variantId, updatedName) => {
    handleRenameVariant(variantId, updatedName);
  };

  const onClickComponentInstance = (componentId) => {
    history.push(`/components/${componentId}`);
  };

  const onClickLibraryComponentInstance = (folderId, libraryComponentId) => {
    const { pathname, searchParams } = routes.nonNavRoutes.library.getUrlParts({
      folderId,
      selectedLibraryComponentIds: [libraryComponentId],
    });
    handleRedirectToNSPage(pathname, searchParams);
  };

  const onClickProjectInstance = (projectId, version) => {
    history.push(`/projects${version === 2 ? "NS" : ""}/${projectId}`);
  };

  const [showCreateVariantModal, setShowCreateVariantModal] = useState(false);
  const [showCreateFolderModal, setShowCreateFolderModal] = useState(false);
  const [showMoveVariantModal, setMoveVariantModal] = useState(false);
  const [showDeleteFolderModal, setShowDeleteFolderModal] = useState(false);
  const [selectedFolder, setSelectedFolder] = useState({});

  const showCreateFolderButton = userHasPermission("variant_folder:create");
  const showFolders = !selectedFolder?._id && (variantFolders.length > 0 || showCreateFolderButton);

  const { userHasResourcePermission: checkResourcePermission } = useContext(UserPermissionContext);
  const userCanCreateOutsideFolders = checkResourcePermission("variant_folder:edit", VARIANTS_NOT_IN_FOLDERS_ID);

  const fetchVariantFolders = async () => {
    const { data } = await http.get(API.variantFolder.get.folders.url);
    return data
      .map((folder) => ({
        _id: folder._id,
        name: folder.name,
        variantIds: folder.variantIds,
        isSample: folder.isSample,
      }))
      .filter((folder) => checkResourcePermission("variant_folder:edit", folder._id));
  };

  const { selectedVariantFolder, folders, onChange, onDeleteFolder } = useVariantSelect(fetchVariantFolders, {
    key: "variant-select-variants-page",
    defaultFolder: selectedFolder,
    showUsedInProjectOption: false,
    showAllVariantsOption: userCanCreateOutsideFolders,
  });

  const onCreateNewFolder = (name) => {
    handleCreateNewVariantFolder(name);
    segment.track({
      event: SegmentEvents.CREATED_VARIANT_FOLDER,
    });
    setShowCreateFolderModal(false);
  };

  const onMoveVariants = async (folder, variantIds) => {
    let moveToFolder = folder;
    if (folder._id === "__new__") {
      moveToFolder = await handleCreateNewVariantFolder(folder.name);
    }
    handleMoveVariants(moveToFolder._id, variantIds);
    segment.track({
      event: "Moved Variants to Folder",
      properties: {
        num: variantIds.length,
      },
    });
    setMoveVariantModal(false);
    setVariantsToMove([]);
  };

  const onRenameVariantFolder = async (name, folderId) => {
    await handleRenameVariantFolder(name, folderId);
  };

  const onDeleteVariantFolder = async () => {
    await handleDeleteVariantFolder(selectedFolderId);
    segment.track({
      event: "Deleted Variant Folder",
    });
    setShowDeleteFolderModal(false);
    setSelectedFolder({});
    // reset local storage value
    onDeleteFolder();
    history.push(`/variants`);
  };

  const history = useHistory();
  const onFolderClick = (folderId) => {
    checkDetailPanelChanges(() => {
      setVariantsToMove([]);
      setLoadingVariants(true);
      history.push(folderId ? `/variants/folder/${folderId}` : "/variants");
    });
  };

  const toggleMultiSelected = (variant) => {
    if (variantsToMove.find((v) => v._id === variant._id)) {
      setVariantsToMove(variantsToMove.filter((v) => v._id !== variant._id));
    } else {
      setVariantsToMove([...variantsToMove, variant]);
    }
  };

  useEffect(
    function setSelectedFolderFromUrlParams() {
      if (selectedFolderId) {
        setSelectedFolder(variantFolders.find((f) => f._id === selectedFolderId));
      } else {
        setSelectedFolder(null);
      }
    },
    [selectedFolderId, variantFolders]
  );

  useEffect(
    function mountEscapeKeyListener() {
      function handleEscapeKey(event) {
        if (event.keyCode !== 27) {
          return;
        }

        if (variantSelectionState.type === "single") {
          variantSelectionActions.clearSelection();
          return;
        }

        setVariantsToMove([]);
      }
      document.addEventListener("keydown", handleEscapeKey);
      return function unMountEscapeKeyListener() {
        document.removeEventListener("keydown", handleEscapeKey);
      };
    },
    [variantSelectionState]
  );

  const selectedVariantId = variantSelectionState.type === "single" ? variantSelectionState.selectedVariant._id : null;

  const { scrollToId } = useScroll({ containerId: "variantContainer" });

  const onVariantNavClick = (variantId) => {
    scrollToId(variantId);
    variantSelectionActions.handleSingleSelect(variantId);
  };

  if (selectedFolder && !selectedFolder.isSample && !userHasResourcePermission("variant_folder:comment")) {
    return <PermissionRequiredFolder />;
  }

  return (
    <VariantContext.Provider
      value={{
        selectedFolderId,
        variantFolders,
      }}
    >
      <div className={style.variantsPage}>
        <UserPermissionProvider resourceId={selectedFolderId} resourceType="variant_folder">
          <TitleBar
            title="Variants"
            alignLeft
            selectedFolder={selectedFolder}
            onNewVariantClick={() => setShowCreateVariantModal(true)}
            onHomeClick={() => {
              onFolderClick(null);
            }}
          />
          <div className={style.container}>
            <VariantNav
              selectedVariantId={selectedVariantId}
              loadingVariants={loadingVariants}
              variants={variants}
              variantFolders={variantFolders}
              showFolders={showFolders}
              isSearching={query !== ""}
              selectedFolder={selectedFolder}
              onVariantClick={onVariantNavClick}
              onFolderClick={onFolderClick}
            />
            <VariantList
              selectedVariantId={
                variantSelectionState.type === "single" ? variantSelectionState.selectedVariant._id : null
              }
              onVariantClick={onVariantClick}
              loadingVariants={loadingVariants}
              unfilteredVariants={unfilteredVariants}
              variants={variants}
              variantFolders={variantFolders}
              showFolders={showFolders}
              foldersAllowed={foldersAllowed}
              uniqueApiIDs={uniqueApiIDs}
              uniqueVariantNames={uniqueVariantNames}
              query={query}
              setQuery={setQuery}
              openCreateVariantFolderModal={() => setShowCreateFolderModal(true)}
              openMoveVariantFolderModal={(variant) => {
                setVariantsToMove([variant]);
                setMoveVariantModal(true);
              }}
              handleQueryChange={handleQueryChange}
              handleDeleteVariants={handleDeleteVariants}
              handleRenameVariant={handleRenameVariant}
              handleRenameVariantApiID={handleRenameVariantApiID}
              handleRenameVariantFolder={onRenameVariantFolder}
              handleDeleteVariantFolder={() => setShowDeleteFolderModal(true)}
              handleFolderClick={onFolderClick}
              selectedFolder={selectedFolder}
              toggleMultiSelected={toggleMultiSelected}
              multiSelectedVariants={variantsToMove}
            />
            {variantSelectionState.type === "none" && (
              <VariantActivity
                loadingHistory={loadingHistory}
                variantHistory={variantHistory}
                fetchVariantHistoryNewPage={fetchVariantHistoryNewPage}
                refreshVariantHistory={refreshVariantHistory}
              />
            )}
            {variantSelectionState.type === "single" && (
              <EditVariantPanel
                handleSwitchTab={(tab) => variantSelectionActions.handleSelectTab(tab)}
                selectedTab={variantSelectionState.tab}
                selectedVariant={variantSelectionState.selectedVariant}
                variantActions={{
                  onDelete: onDeleteVariant,
                  onMoveToFolder,
                  onUpdateDescription,
                  onUpdateName,
                  onClickComponentInstance,
                  onClickProjectInstance,
                  onClickLibraryComponentInstance,
                  onEditApiID: handleRenameVariantApiID,
                }}
              />
            )}
            {variantsToMove.length > 0 && (
              <MultiSelectBanner
                foldersEnabled={foldersAllowed}
                selectedVariants={variantsToMove}
                handleDeleteVariants={handleDeleteVariants}
                handleMoveToFolder={() => {
                  setMoveVariantModal(true);
                }}
                inSampleFolder={selectedFolder?.isSample}
              />
            )}
          </div>
          {showCreateVariantModal && (
            <VariantCreateModal
              onHide={() => setShowCreateVariantModal(false)}
              uniqueVariantNames={uniqueVariantNames}
              handleCreateNewVariant={handleCreateNewVariant}
              selectedVariantFolder={selectedVariantFolder}
              folders={folders}
              onChange={onChange}
            />
          )}
          {showCreateFolderModal && (
            <NewComponentFolderModal
              onHide={() => setShowCreateFolderModal(false)}
              folderNames={variantFolders.map((f) => f.name)}
              onCreateNewFolder={onCreateNewFolder}
            />
          )}
          {showMoveVariantModal && (
            <MoveVariantModal
              variants={variantsToMove}
              variantFolders={variantFolders.filter((v) => !v.isSample)}
              onHide={() => setMoveVariantModal(false)}
              onSubmit={(folder) =>
                onMoveVariants(
                  folder,
                  variantsToMove.map((v) => v._id)
                )
              }
              selectedFolderId={selectedFolderId}
            />
          )}
          {showDeleteFolderModal && (
            <DeleteVariantFolderModal
              onHide={() => setShowDeleteFolderModal(false)}
              onSubmit={onDeleteVariantFolder}
              folderName={selectedFolder.name}
              variantCount={selectedFolder.variantCount}
            />
          )}
          {notification && <Notification notification={notification} />}
        </UserPermissionProvider>
      </div>
    </VariantContext.Provider>
  );
};

const MultiSelectBanner = ({
  foldersEnabled,
  selectedVariants,
  handleDeleteVariants,
  handleMoveToFolder,
  inSampleFolder,
}) => {
  const text = `${selectedVariants.length} selected`;
  const canDelete = selectedVariants.every(
    (v) => v.docs?.length === 0 && (!v.components || v.components.length === 0) && !v.integrations?.length
  );

  return (
    <div className={style.multiSelectBanner}>
      <div className={style.text}>{text}</div>
      <div className={style.buttons}>
        <button
          onClick={() => {
            if (inSampleFolder) return;
            !foldersEnabled ? undefined : handleMoveToFolder(selectedVariants);
          }}
          className={classNames({
            [style.disabled]: !foldersEnabled || inSampleFolder,
          })}
          data-tip
          data-for="variant-folders-tooltip"
        >
          Move to folder
        </button>
        {(!foldersEnabled || inSampleFolder) && (
          <ReactTooltip
            id="variant-folders-tooltip"
            place="top"
            effect="solid"
            className={style.tooltip}
            delayUpdate={inSampleFolder ? 0 : 500}
            delayHide={inSampleFolder ? 0 : 500}
          >
            {!inSampleFolder && (
              <span className={style.tooltipBody}>
                <a target="_blank" href="/account/workspace">
                  Upgrade
                </a>{" "}
                to Growth to create variant folders
              </span>
            )}
            {inSampleFolder && <span className={style.tooltipBody}>Sample data cannot be moved to other folders.</span>}
          </ReactTooltip>
        )}
        {canDelete && (
          <button
            data-tip
            data-for="delete-variants-tooltip"
            className={classNames({
              [style.disabled]: inSampleFolder,
            })}
            onClick={() => {
              if (inSampleFolder) return;
              handleDeleteVariants(selectedVariants.map((v) => v._id));
            }}
          >
            Delete
          </button>
        )}
        {canDelete && inSampleFolder && (
          <ReactTooltip id="delete-variants-tooltip" place="top" effect="solid" className={style.tooltip}>
            <span className={style.tooltipBody}>Deleting is disabled for sample data.</span>
          </ReactTooltip>
        )}
      </div>
    </div>
  );
};

export default Variants;
