import { ZCommentThread, ZCommentType, ZSuggestion } from "@shared/types/CommentThread";
import { ZObjectId } from "@shared/types/lib";
import { z } from "zod";

const COMMENTS_BASE_PATH = "/comments";

export const ZGetProjectCommentIdsRequest = {
  params: z.object({
    projectId: z.string(),
  }),
  query: z.object({
    skip: z.string().optional(),
    limit: z.string().optional(),
    type: ZCommentType.optional(),
  }),
};
export const ZGetProjectCommentIdsResponse = z.array(ZObjectId);
export const GetProjectCommentIdsMethod = "GET";
export const GetProjectCommentIdsPath = `${COMMENTS_BASE_PATH}/project/:projectId`;

export const ZGetLibraryCommentIdsRequest = {
  query: z.object({
    skip: z.string().optional(),
    limit: z.string().optional(),
    type: ZCommentType.optional(),
  }),
};
export const ZGetLibraryCommentIdsResponse = z.array(ZObjectId);
export const GetLibraryCommentIdsMethod = "GET";
export const GetLibraryCommentIdsPath = `${COMMENTS_BASE_PATH}/library`;

export const ZGetTextItemCommentIdsRequest = {
  params: z.object({
    textItemId: z.string(),
  }),
  query: z.object({
    skip: z.string().optional(),
    limit: z.string().optional(),
    type: ZCommentType.optional(),
    isResolved: z.string().optional(),
  }),
};
export const ZGetTextItemCommentIdsResponse = z.array(ZObjectId);
export const GetTextItemCommentIdsMethod = "GET";
export const GetTextItemCommentIdsPath = `${COMMENTS_BASE_PATH}/text-item/:textItemId`;

export const ZGetLibraryComponentCommentIdsRequest = {
  params: z.object({
    libraryComponentId: z.string(),
  }),
  query: z.object({
    skip: z.string().optional(),
    limit: z.string().optional(),
    type: ZCommentType.optional(),
    isResolved: z.string().optional(),
  }),
};
export const ZGetLibraryComponentCommentIdsResponse = z.array(ZObjectId);
export const GetLibraryComponentCommentIdsMethod = "GET";
export const GetLibraryComponentCommentIdsPath = `${COMMENTS_BASE_PATH}/library/:libraryComponentId`;

export const ZGetCommentsByCommentIdsRequest = {
  query: z.object({
    commentIds: z.string().array(),
    projectId: z.string().optional(),
    type: ZCommentType.optional(),
  }),
};
export const ZGetCommentsByCommentIdsResponse = z.array(ZCommentThread);
export const GetCommentsByCommentIdsMethod = "GET";
export const GetCommentsByCommentIdsPath = `${COMMENTS_BASE_PATH}`;

export const ZCreateCommentThreadRequest = {
  body: z.object({
    comp_id: ZObjectId.optional(),
    projectId: ZObjectId.optional(),
    first_comment: z.string(),
    mentionedUserIds: z.string().array(),
    suggestion: ZSuggestion.optional(),
    library_comp_id: ZObjectId.optional(),
    from: z.string(),
  }),
};
export const ZCreateCommentThreadResponse = ZCommentThread;
export const CreateCommentThreadMethod = "POST";
export const CreateCommentThreadPath = `${COMMENTS_BASE_PATH}`;

export const ZPostReplyRequest = {
  params: z.object({
    threadId: z.string(),
  }),
  body: z.object({
    text: z.string(),
    mentionedUserIds: z.array(z.string()),
    from: z.string(),
  }),
};
export const ZPostReplyResponse = ZCommentThread;
export const PostReplyMethod = "POST";
export const PostReplyPath = `${COMMENTS_BASE_PATH}/reply/:threadId`;

export const ZResolveThreadRequest = {
  params: z.object({
    threadId: z.string(),
  }),
  body: z.object({
    is_resolved: z.boolean(),
    suggestion_accepted: z.boolean().optional(),
    from: z.string(),
  }),
};
export const ZResolveThreadResponse = ZCommentThread;
export const ResolveThreadMethod = "POST";
export const ResolveThreadPath = `${COMMENTS_BASE_PATH}/resolve/:threadId`;
