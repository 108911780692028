import React, { useCallback } from "react";
import { ITextItemStatus } from "../../../../shared/types/TextItem";
import Select, { SelectOption } from "../../molecules/Select";
import { StatusIcon } from "../TextEntityMetadata/icons";

const statuses: Array<{ value: ITextItemStatus | "MIXED"; label: string; isHidden: boolean }> = [
  { value: "NONE", label: "None", isHidden: false },
  { value: "WIP", label: "Work in Progress", isHidden: false },
  { value: "REVIEW", label: "Ready for Review", isHidden: false },
  { value: "FINAL", label: "Final", isHidden: false },
  { value: "MIXED", label: "Mixed", isHidden: true },
];

interface IStatusSelectProps {
  status: ITextItemStatus | "MIXED";
  size?: "base" | "small";
  setStatus: (status: ITextItemStatus) => void;
}

const StatusSelect = React.memo(function StatusSelect({ status, size = "base", setStatus }: IStatusSelectProps) {
  const handleStatusChange = useCallback(
    (status: string) => {
      setStatus(status as ITextItemStatus);
    },
    [setStatus]
  );

  return (
    <Select
      size={size}
      value={status}
      iconSize="xxxs"
      onChange={handleStatusChange}
      leadingIcon={<StatusIcon status={status} />}
    >
      {statuses.map((status) => (
        <SelectOption
          key={status.value}
          leadingIcon={<StatusIcon status={status.value} />}
          iconSize="xxxs"
          label={status.label}
          value={status.value}
          hidden={status.isHidden}
        />
      ))}
    </Select>
  );
});

export default StatusSelect;
