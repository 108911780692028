import useSegment from "@/hooks/useSegment";
import { hasComponentSuggestionsAtom } from "@/stores/ComponentLinkingFlow";
import { libraryInteractionViewAtom } from "@/stores/ProjectSelection";
import LibrarySection from "@ds/organisms/LibrarySection";
import { LINK_TO_COMPONENT_ICON_CLICKED, PUBLISH_TEXT_ICON_CLICKED } from "@shared/segment-event-names";
import { useAtomValue, useSetAtom } from "jotai";
import { Suspense, useCallback } from "react";
import Skeleton from "react-loading-skeleton";

function Library() {
  const setLibraryInteractionView = useSetAtom(libraryInteractionViewAtom);
  const hasSuggestions = useAtomValue(hasComponentSuggestionsAtom);
  const { track } = useSegment();

  const onClickPublish = useCallback(() => {
    setLibraryInteractionView("PUBLISH");
    track({ event: PUBLISH_TEXT_ICON_CLICKED, properties: { version: "NS" } });
  }, [track, setLibraryInteractionView]);

  const onClickLink = useCallback(() => {
    setLibraryInteractionView("LINK");
    track({ event: LINK_TO_COMPONENT_ICON_CLICKED, properties: { has_suggestions: hasSuggestions, version: "NS" } });
  }, [track, setLibraryInteractionView, hasSuggestions]);

  return (
    <LibrarySection
      dividerBelow
      hasLinkSuggestions={hasSuggestions}
      onClickPublish={onClickPublish}
      onClickLink={onClickLink}
    />
  );
}

function LibraryWithSuspense() {
  return (
    <Suspense fallback={<Skeleton height={52.88} baseColor="white" />}>
      <Library />
    </Suspense>
  );
}

export default LibraryWithSuspense;
