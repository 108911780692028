import LinkOffIcon from "@mui/icons-material/LinkOff";
import classNames from "classnames";
import React from "react";
import Button from "../../atoms/Button";
import Icon from "../../atoms/Icon";
import ArrowOutBubbleIcon from "../../atoms/Icon/icons/ArrowOutBubbleIcon";
import LibraryComponentNameSpan from "../../molecules/LibraryComponentNameSpan";
import Tooltip from "../../molecules/Tooltip";
import style from "./index.module.css";

interface IProps {
  className?: string;
  style?: React.CSSProperties;
  componentName: string;

  onLinkOutClick: () => void;
  onUnlinkClick: () => void;
}

export function ComponentActions(props: IProps) {
  return (
    <div
      style={props.style}
      className={classNames(style.ComponentActionsWrapper, props.className)}
      data-testid="component-actions"
    >
      <LibraryComponentNameSpan size="small" iconSize="xxs" weight="medium">
        {props.componentName}
      </LibraryComponentNameSpan>

      <div className={style.actionButtons}>
        <Tooltip content="View component in library" side="left" type="invert">
          <Button level="secondary" type="icon" onClick={props.onLinkOutClick}>
            <Icon color="secondary" Icon={<ArrowOutBubbleIcon />} />
          </Button>
        </Tooltip>

        <Tooltip content="Unlink from component" side="left" type="invert">
          <Button level="secondary" type="icon" onClick={props.onUnlinkClick}>
            <Icon color="secondary" Icon={<LinkOffIcon />} />
          </Button>
        </Tooltip>
      </div>
    </div>
  );
}

ComponentActions.Fallback = (props: { style?: React.CSSProperties; className?: string }) => {
  return (
    <ComponentActions
      componentName="Loading..."
      onLinkOutClick={() => {}}
      onUnlinkClick={() => {}}
      style={props.style}
      className={props.className}
    />
  );
};

export default ComponentActions;
