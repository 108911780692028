import classNames from "classnames";
import { Atom, useAtomValue } from "jotai";
import React, { useMemo } from "react";
import Text from "../../atoms/Text";
import DropdownCombobox from "../DropdownCombobox";
import style from "./index.module.css";

export interface IPageOption {
  name: string;
  id: string;
}

interface IOption {
  value: string;
  label: string;
}

interface IProps {
  pagesAtom: Atom<IPageOption[] | Promise<IPageOption[]>>;
  selectedPages: string[];
  setSelectedPages: (value: string[]) => void;
  onRemoveFilter: () => void;

  className?: string;
  style?: React.CSSProperties;
  autoFocus?: boolean;
}

export function PageFilterDropdown(props: IProps) {
  const pages = useAtomValue(props.pagesAtom);

  const formattedOptions = useMemo(() => {
    return pages.map((option) => ({
      value: option.id,
      label: option.name,
    }));
  }, [pages]);

  const formattedSelectedOptions = useMemo(() => {
    return props.selectedPages.map((option) => ({
      value: option,
      label: pages.find((page) => page.id === option)?.name || "",
    }));
  }, [pages, props.selectedPages]);

  function onChange(selectedOptions: { value: string; label: string }[]) {
    props.setSelectedPages(selectedOptions.map((v) => v.value));
  }

  return (
    <DropdownCombobox
      multiple
      inputPlaceholder="Choose page..."
      className={classNames(style.pageFilterDropdown, props.className)}
      options={formattedOptions}
      selectedOptions={formattedSelectedOptions}
      onChange={onChange}
      TriggerComponent={TriggerComponent}
      OptionComponent={OptionComponent}
      onRemoveFilter={props.onRemoveFilter}
      autoFocus={props.autoFocus}
    />
  );
}

interface ITriggerProps {
  selectedOptions: IOption[];
}

function TriggerComponent(props: ITriggerProps) {
  const selectedOptions = props.selectedOptions;

  if (selectedOptions.length === 0) {
    return (
      <Text size="small" color="secondary">
        Figma page
      </Text>
    );
  }

  if (selectedOptions.length === 1) {
    return (
      <Text size="small" color="secondary">
        {selectedOptions[0].label}
      </Text>
    );
  }

  if (selectedOptions.length > 1) {
    return (
      <Text size="small" color="secondary">
        {selectedOptions.length} pages
      </Text>
    );
  }

  return <div>PageFilterDropdown</div>;
}

interface IOptionProps {
  option: IOption;
}

function OptionComponent(props: IOptionProps) {
  return (
    <Text size="small" color="secondary">
      {props.option.label}
    </Text>
  );
}

export default PageFilterDropdown;
