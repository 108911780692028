export const BASE_URL =
  process.env.ENV === "production"
    ? "https://app.dittowords.com"
    : /* @ts-ignore */
    process.env.ENV === "staging"
    ? "https://staging.dittowords.com"
    : /* @ts-ignore */
    process.env.ENV === "kooky"
    ? "https://kooky.dittowords.com"
    : "http://localhost:4000";

export const WEB_BASE_URL = BASE_URL === "http://localhost:4000" ? "http://localhost:3000" : BASE_URL;
/**
 * Parse Ditto project URL and returns the projectId found in the url.
 * Example: /projects/{projectId}/page/42:1096
 *
 * @param url The ditto project URL
 * @returns the projectId
 */
const REGEX = new RegExp(`${WEB_BASE_URL}\/projects(?:\-beta)?\/([0-9a-zA-Z]{24})`);

export default function parseDittoURL(url: string): string | undefined {
  return REGEX.exec(url)?.[1] || undefined;
}
