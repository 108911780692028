import FolderIcon from "@mui/icons-material/Folder";
import classNames from "classnames";
import React, { forwardRef, useMemo } from "react";
import Icon from "../../atoms/Icon";
import Text from "../../atoms/Text";
import style from "./index.module.css";

interface IProps {
  className?: string;
  style?: React.CSSProperties;
  name: string;
  componentCount: number;
  folderCount: number;
  onClick: () => void;
  highlightDropTarget?: boolean;
  renderDragGhost?: boolean;
  highlightFocused?: boolean;
}

export const LibraryFolderCard = forwardRef<HTMLDivElement, IProps>(function LibraryFolderCard(props, forwardedRef) {
  const { name, componentCount, folderCount, onClick, highlightDropTarget, renderDragGhost, highlightFocused } = props;

  const countsText = useMemo(() => {
    let text = "";

    if (folderCount === 1) {
      text += `${folderCount} folder`;
    } else if (folderCount > 1) {
      text += `${folderCount} folders`;
    }

    if (componentCount > 0 && folderCount > 0) {
      text += ", ";
    }

    if (componentCount === 1) {
      text += `${componentCount} component`;
    } else if (componentCount > 1) {
      text += `${componentCount} components`;
    }

    if (text.length === 0) {
      return "Empty";
    }

    return text;
  }, [componentCount, folderCount]);

  return (
    <div
      style={props.style}
      className={classNames(style.libraryFolderCardWrapper, props.className, {
        [style.highlightDropTarget]: highlightDropTarget && !renderDragGhost,
        [style.highlightFocused]: highlightFocused,
      })}
      data-testid="library-folder-card"
      onClick={onClick}
      ref={forwardedRef}
    >
      {renderDragGhost && <div className={style.dragGhost} />}

      <div className={style.iconCol}>
        <Icon Icon={<FolderIcon />} size="small" color="secondary" />
      </div>

      <div className={style.content}>
        <Text weight="medium" truncate>
          {name}
        </Text>
        <Text size="small" color="tertiary">
          {countsText}
        </Text>
      </div>
    </div>
  );
});

export default LibraryFolderCard;
