import { LEARN_MORE_URL } from "@shared/frontend/learnMoreLinks";
import React, { useState } from "react";
import Text from "../../atoms/Text";
import EditableDeveloperId, { EditableDeveloperIdProps } from "../../organisms/EditableDeveloperId";
import style from "./index.module.css";

interface IProps {
  /**
   * A jotai atom for getting and setting the developer ID.
   */
  developerIdAtom: EditableDeveloperIdProps["developerIdAtom"];
  /**
   * A callback to be run when the user clicks to copy the developer ID value.
   */
  onCopyDevId: () => void;
  /**
   * A callback to be run when the user clicks on the dev docs link
   */
  onDocsClick: () => void;
}

export function DeveloperIdSection(props: IProps) {
  const [isEditing, setIsEditing] = useState(false);

  return (
    <div className={style.container}>
      <div className={style.editContainer}>
        <div className={style.labelWrapper}>
          <Text size="small" color="primary" weight="strong">
            Dev ID
          </Text>
        </div>
        <EditableDeveloperId
          developerIdAtom={props.developerIdAtom}
          onToggleEditMode={setIsEditing}
          onCopy={props.onCopyDevId}
        />
      </div>
      {!isEditing && (
        <div>
          <Text size="micro" color="secondary" inline>
            Learn about{" "}
            <a
              href={LEARN_MORE_URL.DEVELOPER_HELP_DOCS}
              className={style.link}
              target="_blank"
              rel="noopener noreferrer"
              onClick={props.onDocsClick}
            >
              <Text size="micro" color="action" inline asLink>
                developer integrations
              </Text>
            </a>
          </Text>
        </div>
      )}
    </div>
  );
}

export default DeveloperIdSection;
