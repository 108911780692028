import { isDiffRichText } from "@shared/lib/text";
import { ILibraryComponent } from "@shared/types/LibraryComponent";
import { SkinnyPlural } from "@shared/types/RichText";
import { ITextItem, ITextItemStatus, ITipTapRichText } from "@shared/types/TextItem";
import { IUser } from "@shared/types/User";

/** Each of the "getMergedX" functions below will take an array of items and return a single, merged value for that metadata field. */

// These are the response types for each of the merged metadata helpers below.
export type MixedValue = { mixed: true };
export type MergedStatusMetadata = ITextItemStatus | "MIXED";
export type MergedAssigneeMetadata = { value: string; label: string } | null;
export type MergedTagsMetadata = string[] | MixedValue;
export type MergedNotesMetadata = string | MixedValue;

/**
 * If all statuses are the same, return that status. Otherwise, return "MIXED".
 *
 * @param items The text items or components to check.
 * @returns The merged status.
 */
export function getMergedStatus(items: ITextItem[] | ILibraryComponent[]): MergedStatusMetadata {
  const firstStatus = items[0]?.status;

  if (firstStatus && items.every((item) => item.status === firstStatus)) {
    return firstStatus;
  }

  return "MIXED";
}

/**
 * If there are multiple values for assignee, returns { value: "MIXED", label: "Mixed Assignees" }.
 * If all values of assignee are the same:
 * - If the assignee is a user in the workspace, returns { value: assignee, label: assignee.name }.
 * - If the assignee is null or not found in the users list, returns null.
 *
 * @param items The text items or components to check.
 * @returns The merged assignee.
 */
export function getMergedAssignee(
  items: ITextItem[] | ILibraryComponent[],
  usersById: Record<string, IUser>
): MergedAssigneeMetadata {
  if (!items.length) return null;

  const firstAssignee = items[0].assignee;

  if (items.every((item) => item.assignee === firstAssignee)) {
    // If all items are unassigned, or assigned to a non-existent user - return null
    if (!firstAssignee || !usersById[firstAssignee]) return null;
    return { value: firstAssignee, label: usersById[firstAssignee].name };
  }

  return { value: "MIXED", label: "Mixed Assignees" };
}

/**
 * If all sets of tags are the same, return that set of tags. Otherwise, return a 'mixed tags' object.
 * Note: Order does not matter. If two items have the same set of tags in different orders,
 *  we will consider them to have the same tags.
 */
export function getMergedTags(items: ITextItem[] | ILibraryComponent[]): MergedTagsMetadata {
  if (items.length === 0) {
    return [];
  }

  const getTagHash = (tags: string[]) =>
    tags
      .toSorted((a, b) => a.localeCompare(b))
      .join(",")
      .toLowerCase();

  const referenceTags = items[0].tags;
  const referenceTagsHash = getTagHash(referenceTags);

  // Check if every item's tags match the reference
  const allTagsMatch = items.every((item) => {
    return item.tags.length === referenceTags.length && getTagHash(item.tags) === referenceTagsHash;
  });

  return allTagsMatch ? referenceTags : { mixed: true };
}

/**
 * If all notes are the same, return that note. Otherwise, return { mixed: true }.
 * Additionally, if the returned value would be null, returns empty string.
 */
export function getMergedNotes(items: ITextItem[] | ILibraryComponent[]): MergedNotesMetadata {
  if (!items.length) return "";

  const firstNote = items[0].notes;

  if (items.every((t) => t.notes === firstNote)) {
    return firstNote ?? "";
  }

  return { mixed: true } as const;
}

/**
 * If all texts are the same, return that text. Otherwise, return null.
 *
 * Note: As of now, we do not allow editing multiple text items at once, so this method is not used for multiselect like others in this file.
 */
export function getMergedText(items: ITextItem[] | ILibraryComponent[]): ITipTapRichText | null {
  if (!items.length) return null;

  const firstRichText = items[0].rich_text;

  // If any of the rich texts differ, return null.
  if (items.some((item) => isDiffRichText(item.rich_text, firstRichText))) {
    return null;
  }

  return firstRichText;
}

/**
 * The merged character limit is the minimum of all character limits.
 * If no character limit is set on any item, returns null.
 */
export function getMergedCharacterLimit(items: ITextItem[] | ILibraryComponent[]): number | null {
  const characterLimits = items.map((textItem) => textItem.characterLimit ?? Infinity);

  const minimum = Math.min(...characterLimits);

  return minimum === Infinity ? null : minimum;
}

/**
 * This method will not do any merging.
 * It will only return a value if there is exactly one item.
 */
export function getMergedPlurals(items: ITextItem[] | ILibraryComponent[]): SkinnyPlural[] | null {
  return items.length === 1 ? items[0].plurals : null;
}

/**
 * This method will not do any merging.
 * If there is exactly one item, and it's a library component, returns its name. Otherwise, returns null.
 */
export function getMergedComponentName(items: ITextItem[] | ILibraryComponent[]): string | null {
  if (items.length !== 1) {
    return null;
  }

  // Library Components have a name property, Text Items do not
  if ("name" in items[0]) {
    return items[0].name;
  }

  return null;
}

/**
 * Determines which tags were removed between the original tags and the new tags.
 * @param originalTags
 * @param newTags
 */
export function getRemovedTags(originalTags: string[][], newTags: string[]): string[] {
  const originalTagsSet = new Set(originalTags.flat());
  const newTagsSet = new Set(newTags);

  return Array.from(originalTagsSet).filter((baseTag) => !newTagsSet.has(baseTag));
}
