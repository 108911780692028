import React, { useMemo } from "react";
import URLLink, { IProps as URLLinkProps } from ".";

type RenderedLink = string | JSX.Element;

const urlRegex = /([\w\d-.@:%\/\+~#=]{1,256}\.[\w\d]{2,6}(?:(?:[?\/][-\w\d@:%&\/\[\]?._\+~#=]*)|(?!\w)))/g;

export function getRenderedLinks(text: string, linkColor?: URLLinkProps["color"]): RenderedLink[] {
  if (text) {
    let parts: RenderedLink[] = text.split(urlRegex);
    for (let i = 0; i < parts.length; i++) {
      if (urlRegex.test(parts[i] as string)) {
        parts[i] = <URLLink text={parts[i] as string} color={linkColor} key={i} />;
      }
    }
    return parts;
  }
  return [];
}

const useRenderedLinks = (text: string, linkColor?: URLLinkProps["color"]): RenderedLink[] =>
  useMemo(() => getRenderedLinks(text, linkColor), [text, linkColor]);

export default useRenderedLinks;
