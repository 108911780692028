import { ZUserFlags } from "@shared/types/User";
import { z } from "zod";
import { ZUser } from "../../types/User";

export const ZCreateFigmaAuthRequest = {
  body: z.object({
    code: z.string(),
    redirectUri: z.string(),
  }),
};
export const ZCreateFigmaAuthResponse = z.unknown();

export const ZUpdateUserProfilePictureRequest = {
  body: z.object({
    picture: z.string().optional().nullable(),
  }),
};
export const ZUpdateUserProfilePictureResponse = z.unknown();

export const ZMarkNotifAsReadRequest = {
  params: z.object({ notificationId: z.string() }),
};
export const ZMarkNotifAsReadResponse = z.unknown();

export const ZUpdateUserFlagsRequest = {
  body: ZUserFlags.strict().deepPartial(),
};
export const ZUpdateUserFlagsResponse = z.object({
  ok: z.boolean(),
  user: ZUser,
});
export const UpdateUserFlagsMethod = "PUT";
export const UpdateUserFlagsPath = "/user/setFlag";
