import { isNonEmptyArray } from "@shared/utils/array";
import logger from "@shared/utils/logger";
import { Atom, atom } from "jotai";
import { soon, soonAll } from "jotai-derive";
import { atomFamily } from "jotai/utils";
import { buildDittoClient } from "../../client/buildDittoClient";
import { ILibraryComponentFolder } from "../../types/LibraryComponentFolder";
import asyncMutableDerivedAtom from "./asyncMutableDerivedAtom";
import batchedAsyncAtomFamily from "./batchedAsyncAtomFamily";

export function createLibraryComponentFoldersStore(client: ReturnType<typeof buildDittoClient>) {
  /**
   * The singular source of truth for all library component folders.
   */
  const { familyAtom: libraryComponentFolderFamilyAtom, resetAtom: resetLibraryComponentFolderFamilyActionAtom } =
    batchedAsyncAtomFamily<ILibraryComponentFolder>({
      asyncFetchRequest: async (get, ids) => {
        const data = await client.libraryComponentFolder.getLibraryComponentFolders({ folderIds: ids });
        return data.folders;
      },
      getId: (item) => item._id.toString(),
      debugPrefix: "Library Component",
      throttleOptions: {
        leading: true,
      },
    });

  const { valueAtom: _libraryComponentFoldersListAtom, refreshAtom: refreshLibraryComponentFoldersListAtom } =
    asyncMutableDerivedAtom<string[]>({
      loadData: async () => {
        const data = await client.libraryComponentFolder.getLibraryComponentFolders({ fields: "_id" });
        return data.folders.map((folder) => folder._id);
      },
    });

  const libraryComponentFoldersListAtom = atom<
    ILibraryComponentFolder[] | Promise<ILibraryComponentFolder[]>,
    [string[] | Promise<string[]> | ((prev: string[]) => string[] | Promise<string[]>)],
    void
  >(
    (get) => {
      return soon(get(_libraryComponentFoldersListAtom), (ids) => {
        const promises = ids.map((id) => get(libraryComponentFolderFamilyAtom(id)));
        if (!isNonEmptyArray(promises)) return [];
        return soon(soonAll(promises), (folders) => folders);
      });
    },
    async (get, set, ids: string[] | Promise<string[]>) => {
      set(_libraryComponentFoldersListAtom, ids);
    }
  );

  // MARK: - Derived Atoms

  /**
   * Tracks the full breadcrumb path from one folder to the root of the library.
   * Also returns the folder data initially queried by id.
   */
  const libraryComponentFoldersBreadcrumbsAtomFamily = atomFamily(
    (folderId: string): Atom<Promise<{ folder: ILibraryComponentFolder; breadcrumbs: string[] }>> => {
      return atom(async (get) => {
        const folder = await get(libraryComponentFolderFamilyAtom(folderId));

        if (!folder.parentId)
          return {
            folder,
            breadcrumbs: [],
          };

        const breadcrumbs: string[] = [];

        let parentFolder = await get(libraryComponentFolderFamilyAtom(folder.parentId));
        while (parentFolder) {
          breadcrumbs.unshift(parentFolder.name);
          if (!parentFolder.parentId) break;
          parentFolder = await get(libraryComponentFolderFamilyAtom(parentFolder.parentId));
        }
        return {
          folder,
          breadcrumbs,
        };
      });
    }
  );

  // MARK: - Websocket Handlers

  /**
   * Removes a folder from the libraryComponentFoldersListAtom by id
   */
  const removeFolderFromListActionAtom = atom(null, async (get, set, folderId: string) => {
    const folderIds = await get(_libraryComponentFoldersListAtom);
    const newFolderIds = folderIds.filter((id) => id !== folderId);
    set(_libraryComponentFoldersListAtom, newFolderIds);
  });

  const handleLibraryComponentFolderCreatedActionAtom = atom(
    null,
    async (get, set, args: { newFolderId: string; newFolderParentId: string | null }) => {
      const { newFolderId } = args;
      const folderIds = await get(_libraryComponentFoldersListAtom);
      if (folderIds.some((folderId) => folderId === newFolderId)) {
        return;
      }
      set(_libraryComponentFoldersListAtom, [...folderIds, newFolderId]);
    }
  );

  /**
   * Called when we receive a websocket event that a library component folder has been updated.
   * This handles both name updates and parent folder changes.
   */
  const handleLibraryComponentFolderUpdatedActionAtom = atom(
    null,
    async (get, set, args: { folderId: string; name: string; parentId?: string | null }) => {
      // Update the folder's data in the atom
      set(libraryComponentFolderFamilyAtom(args.folderId), (prev) => {
        // Only update if there are actual changes
        if (prev.name === args.name && (args.parentId === undefined || prev.parentId === args.parentId)) {
          return prev;
        }

        return {
          ...prev,
          name: args.name,
          ...(args.parentId !== undefined && { parentId: args.parentId }),
        };
      });
    }
  );

  const saveLibraryComponentFolderNameActionAtom = atom(
    null,
    async (get, set, args: { folderId: string; folderName: string }) => {
      const { folderId, folderName } = args;

      const existingFolderData = await get(libraryComponentFolderFamilyAtom(folderId));
      if (existingFolderData.name === folderName) return;

      set(libraryComponentFolderFamilyAtom(folderId), { ...existingFolderData, name: folderName });

      try {
        await client.libraryComponentFolder.updateLibraryComponentFolder({
          folderId,
          folderData: { ...existingFolderData, name: folderName },
        });
      } catch (error) {
        logger.error(
          `Error updating name to ${folderName} for library component folder with id ${folderId}`,
          {},
          error
        );
        // revert optimistic update
        set(libraryComponentFolderFamilyAtom(folderId), existingFolderData);
      }
      return;
    }
  );

  return {
    libraryComponentFolderFamilyAtom,
    libraryComponentFoldersBreadcrumbsAtomFamily,
    resetLibraryComponentFolderFamilyActionAtom,
    libraryComponentFoldersListAtom,
    removeFolderFromListActionAtom,
    refreshLibraryComponentFoldersListAtom,
    handleLibraryComponentFolderCreatedActionAtom,
    handleLibraryComponentFolderUpdatedActionAtom,
    saveLibraryComponentFolderNameActionAtom,
  };
}
