import { ZTextItem } from "@shared/types/TextItem";
import { z } from "zod";

export const ZGetTextItemsRequest = {
  query: z.object({
    projectId: z.string().optional(),
    ids: z.array(z.string()).optional(),
  }),
};
export const ZGetTextItemsResponse = z.object({
  textItems: z.array(ZTextItem),
});
export const GetTextItemsMethod = "GET";
export const GetTextItemsPath = "/text-item";
