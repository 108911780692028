import TextItemTextInputs from "@/components/TextItemTextInputs/TextItemTextInputs";
import { editableHasChangesAtom } from "@/stores/Editing";
import {
  selectedLibraryComponentsAssigneeAtom,
  selectedLibraryComponentsNameAtom,
  selectedLibraryComponentsNotesAtom,
  selectedLibraryComponentsRichTextAtom,
  selectedLibraryComponentsStatusAtom,
  selectedLibraryComponentsTagsAtom,
  updateLibraryComponentsActionAtom,
} from "@/stores/EditMetadata";
import {
  derivedOnlySelectedComponentAtom,
  libraryDetailsPanelResetFormStateSignalAtom,
  selectedComponentIdsAtom,
} from "@/stores/Library";
import { createNewTagActionAtom, unwrappedAllTagsAtom, usersByIdAtom } from "@/stores/Workspace";
import { TextEntityMetadata } from "@ds/organisms/TextEntityMetadata";
import useRichTextInputProps from "@ds/organisms/TextEntityMetadata/useRichTextInputProps";
import { ILibraryComponentUpdate } from "@shared/types/DittoProject";
import { RichTextInputProps, RichTextInputRef } from "@shared/types/RichText";
import { ITipTapRichText } from "@shared/types/TextItem";
import { useAtomValue, useSetAtom } from "jotai";
import { forwardRef, memo, useCallback, useImperativeHandle, useMemo } from "react";
import style from "./style.module.css";

const noop = () => {};

function LibraryMetadataPanel() {
  const onCreateNewTag = useSetAtom(createNewTagActionAtom);
  const selectedComponentIds = useAtomValue(selectedComponentIdsAtom);
  const onlySelectedComponent = useAtomValue(derivedOnlySelectedComponentAtom);
  const shouldResetFormStateSignal = useAtomValue(libraryDetailsPanelResetFormStateSignalAtom);
  const generalUpdateComponentsAction = useSetAtom(updateLibraryComponentsActionAtom);
  const setEditableHasChanges = useSetAtom(editableHasChangesAtom);

  const handleSave = useCallback(
    async ({
      hasComponentNameChanges,
      hasRichTextChanges,
      localStateComponentName,
      localStateRichText,
    }: {
      hasComponentNameChanges: boolean;
      hasRichTextChanges: boolean;
      localStateComponentName: string | null;
      localStateRichText: ITipTapRichText | null;
    }) => {
      // Currently, we only allow these savable changes if exactly one component is selected
      if (!onlySelectedComponent?._id) {
        return;
      }

      let hasUpdates = false;

      const update: ILibraryComponentUpdate = {
        libraryComponentIds: [onlySelectedComponent._id],
      };

      if (hasRichTextChanges && localStateRichText !== null) {
        update.richText = localStateRichText;
        hasUpdates = true;
      }
      if (hasComponentNameChanges && localStateComponentName !== null) {
        update.name = localStateComponentName;
        hasUpdates = true;
      }

      if (hasUpdates) {
        await generalUpdateComponentsAction(update);
      }
    },
    [onlySelectedComponent?._id, generalUpdateComponentsAction]
  );

  return (
    <div className={style.wrapper}>
      <TextEntityMetadata
        statusAtom={selectedLibraryComponentsStatusAtom}
        assigneeAtom={selectedLibraryComponentsAssigneeAtom}
        tagsAtom={selectedLibraryComponentsTagsAtom}
        notesAtom={selectedLibraryComponentsNotesAtom}
        richTextAtom={selectedLibraryComponentsRichTextAtom}
        componentNameAtom={selectedLibraryComponentsNameAtom}
        onUnsavedChanges={setEditableHasChanges}
        resetFormSignal={shouldResetFormStateSignal}
        selectedIds={selectedComponentIds}
        onlySelectedItem={onlySelectedComponent}
        usersByIdAtom={usersByIdAtom}
        allTagsAtom={unwrappedAllTagsAtom}
        onCreateNewTag={onCreateNewTag}
        handleSave={handleSave}
        onDeleteVariant={noop}
        RichTextInputComponent={RichTextInput}
        variantPlaceholderText={onlySelectedComponent?.text}
      />
    </div>
  );
}

/**
 * Wrapper for our legacy TextItemTextInput component that wraps TipTap's rich text editor.
 * @param props Wrapper props:
 * - initialVariableRichValue: The value to pass down to the legacy TextItemTextInput component.
 *
 * @important
 * The initialVariableRichValue prop gets passed down to our legacy TextItemTextInput component, and passes down many layers of prop drilling
 * before being used to initialize the TipTap editor. It's important that the value here does *not* change on edit --
 * it's used for initializing editor state, and thus should only change when we're re-initializing the selected page
 * (e.g. changing our selection).
 */
export const RichTextInput = memo(
  forwardRef<RichTextInputRef, RichTextInputProps>(function RichTextInput(props: RichTextInputProps, ref) {
    const { handleCharacterLimitChange, legacyHandleTextChange, reset, accessEditorInstance } =
      useRichTextInputProps(props);

    // Expose reset function through ref
    useImperativeHandle(ref, () => ({ reset }), [reset]);

    const textLabelLeft = useMemo(() => <div></div>, []);

    return (
      <TextItemTextInputs
        overrideClassname={style.richTextInput}
        hideTopLabels={true}
        useNSLabels
        textItem={props.initialVariableRichValue}
        legacyHandleTextChange={legacyHandleTextChange}
        readonly={false}
        isBaseText={true}
        isVariant={false}
        shouldShowRichText={true}
        handleCharacterLimitChange={handleCharacterLimitChange}
        characterLimit={props.characterLimit ?? null}
        textLabelLeft={textLabelLeft}
        disabled={props.richTextInputDisabled}
        pluralInputsDisabled={props.pluralsDisabled}
        variablesDisabled={props.variablesDisabled}
        characterLimitDisabled={props.characterLimitDisabled}
        placeholder={props.placeholder}
        emptyEditorClass={props.emptyEditorClass}
        accessEditorInstance={accessEditorInstance}
      />
    );
  })
);

export default LibraryMetadataPanel;
