import useSegment from "@/hooks/useSegment";
import { hiddenFieldsListAtom, resetHiddenFieldsActionAtom, updateHiddenFieldsActionAtom } from "@/stores/HiddenFields";
import { designPreviewToggledAtom, searchAtom } from "@/stores/Location";
import { allTagsInProjectAtom, projectIdAtom, projectSidebarCollapsedAtom } from "@/stores/Project";
import {
  pageFiltersOptionsAtom,
  PROJECT_FILTERS,
  selectedFiltersAtomFamily,
  selectedFiltersListAtom,
} from "@/stores/ProjectFiltering";
import { deferredUsersAtom } from "@/stores/Workspace";
import DesignFilterBar from "@ds/organisms/FilterBar";
import * as SegmentEvents from "@shared/segment-event-names";
import { useAtomValue } from "jotai";
import { Suspense } from "react";

function FilterBar() {
  const { track } = useSegment();
  const projectId = useAtomValue(projectIdAtom);

  if (!projectId) return null;

  const onDesignPreviewToggledChange = (designPreviewToggled: boolean) => {
    if (!designPreviewToggled) return;

    track({
      event: SegmentEvents.DESIGN_PREVIEW_OPENED,
      properties: { from: "filter-bar", version: "NS", location: "project" },
    });
  };

  return (
    <Suspense fallback={<DesignFilterBar.Fallback />}>
      <DesignFilterBar
        filterContextId={projectId}
        designPreviewToggledAtom={designPreviewToggledAtom}
        onDesignPreviewToggledChange={onDesignPreviewToggledChange}
        searchAtom={searchAtom}
        sidebarCollapseStateAtom={projectSidebarCollapsedAtom}
        hiddenFieldsListAtom={hiddenFieldsListAtom}
        resetHiddenFieldsActionAtom={resetHiddenFieldsActionAtom}
        updateHiddenFieldsActionAtom={updateHiddenFieldsActionAtom}
        useSegment={useSegment}
        filters={PROJECT_FILTERS}
        selectedFiltersValuesAtomFamily={selectedFiltersAtomFamily}
        pagesAtom={pageFiltersOptionsAtom}
        selectedFiltersAtom={selectedFiltersListAtom}
        usersAtom={deferredUsersAtom}
        tagsAtom={allTagsInProjectAtom}
      />
    </Suspense>
  );
}

FilterBar.Fallback = DesignFilterBar.Fallback;

export default FilterBar;
