import { showToastActionAtom } from "@shared/frontend/stores/Toast";
import logger from "@shared/utils/logger";
import { atom, Getter, Setter } from "jotai";

interface IOptimisticUpdateAction<InputType, OriginalDataType, InMemoryUpdateResponse = void> {
  /**
   * A method to retrieve any data from jotai that may be needed to perform the rollback on failure.
   */
  getOriginalData: (get: Getter, input: InputType) => OriginalDataType | Promise<OriginalDataType>;
  /**
   * A method to make the updates to jotai optimistically.
   * This will be called before the backend update is attempted in a full update.
   * This will be called in the in-memory update action for frontend-only updates, like responding to a websocket event
   * If necessary, you can return data from this method to use in the persisted update action.
   * @param localOnly indicates whether this will be part of a full update or a frontend-only update.
   */
  updateDataInMemory: (
    get: Getter,
    set: Setter,
    input: InputType,
    localOnly: boolean
  ) => InMemoryUpdateResponse | Promise<InMemoryUpdateResponse>;
  /**
   * A method to persist the updates in the backend or to a third-party API.
   * This method should also include any subsequent in-memory updates that should happen after successful backend update,
   *  such as using the response from the backend to update the data in memory or displaying success UI to the user.
   * This will be called after the optimistic update is made in a persisted update.
   */
  persistUpdate: (
    get: Getter,
    set: Setter,
    input: InputType,
    inMemoryUpdateResponse?: InMemoryUpdateResponse
  ) => Promise<void>;
  /**
   * A method rolling back the optimistic updates if an error occurs during the backend update.
   * The `updateDataInMemory` method is provided for a convenience if the rollback should follow the same logic as the optimistic update.
   */
  rollbackDataInMemory: (
    get: Getter,
    set: Setter,
    props: {
      error: any;
      input: InputType;
      originalData: OriginalDataType;
      updateDataInMemory: (get: Getter, set: Setter, input: InputType) => void | Promise<void>;
    }
  ) => void | Promise<void>;
  errorText: string | ((input: InputType) => string); // Text to use for logger.error message
  errorToastMessage?: string | ((input: InputType) => string | null); // User-friendly message for displaying a toast on error - if missing, will use errorText
  skipToastOnError?: boolean; // If true, will log error but not show a toast
  debugPrefix?: string;
}

export default function optimisticUpdateActionAtom<InputType, OriginalDataType>(
  props: IOptimisticUpdateAction<InputType, OriginalDataType>
) {
  // Use this for in-memory updates, like responding to a websocket event
  const inMemoryUpdateActionAtom = atom(null, async (get, set, input: InputType) => {
    await props.updateDataInMemory(get, set, input, true);
  });

  // Use this to perform the full update: optimistic in-memory update, backend update, and rollback on failure
  // Returns true on success, false on failure
  const persistedUpdateActionAtom = atom(null, async (get, set, input: InputType): Promise<boolean> => {
    const originalData = await props.getOriginalData(get, input);
    const inMemoryResponse = await props.updateDataInMemory(get, set, input, false);
    try {
      await props.persistUpdate(get, set, input, inMemoryResponse);
      return true;
    } catch (error) {
      // Log the error
      const errorText = typeof props.errorText === "function" ? props.errorText(input) : props.errorText;
      logger.error(errorText, { context: { data: input } }, error);

      // Perform the rollback and account for any other side effects
      await props.rollbackDataInMemory(get, set, {
        error,
        input,
        originalData,
        updateDataInMemory: (get, set, input) => props.updateDataInMemory(get, set, input, true),
      });

      // Show a toast to the user, if configured
      if (!props.skipToastOnError) {
        let errorToastMessage: string | null = null;
        if (typeof props.errorToastMessage === "function") {
          errorToastMessage = props.errorToastMessage(input);
        } else {
          errorToastMessage = props.errorToastMessage ?? errorText;
        }
        if (errorToastMessage) {
          set(showToastActionAtom, { message: errorToastMessage });
        }
      }

      return false;
    }
  });

  inMemoryUpdateActionAtom.debugLabel = `${props.debugPrefix ?? "Optimistic Update"} In-Memory Update Action Atom`;
  persistedUpdateActionAtom.debugLabel = `${props.debugPrefix ?? "Optimistic Update"} Persisted Update Action Atom`;

  /**
   * By default, this action will perform the entire persisted update.
   * To perform only the in-memory update (i.e. websocket handler), pass `{ localOnly: true }` as the final argument.
   */
  const actionAtom = atom(null, async (_get, set, input: InputType, { localOnly = false } = {}) => {
    if (localOnly) {
      await set(inMemoryUpdateActionAtom, input);
      return true;
    } else {
      const success = await set(persistedUpdateActionAtom, input);
      return success;
    }
  });

  return actionAtom;
}
