import { IRole, IUserWithPermissionsFigmaAuthenticated } from "@shared/types/User";
import { atom } from "jotai";

export const userAtom = atom<(IUserWithPermissionsFigmaAuthenticated & { billingRole: IRole }) | null>(null);

export const userEmailAtom = atom((get) => {
  const user = get(userAtom);
  return user?.email;
});

export const userRoleAtom = atom((get) => {
  const user = get(userAtom);
  return user?.role;
});
