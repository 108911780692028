import { TrackedFigmaNodeTypesForDeletion } from "@shared/types/FigmaData";
import { ZFigmaSyncResponseType, ZUpdateTextNodeInstanceLastReconciledRichText } from "@shared/types/figmaSync";
import { ZFVConfigVariableCollection, ZUpdateFigmaVariablesConfigBody } from "@shared/types/FigmaVariables";
import { ZFigmaSelection, ZFigmaSyncRequestBody } from "@shared/types/http/figma";
import { ZObjectId } from "@shared/types/lib";
import { ZTipTapRichText } from "@shared/types/TextItem";
import { z } from "zod";

export const ZGetApiIdForNodesRequest = {
  query: z.object({
    nodeIds: z.array(z.string()),
    framework: z.string(),
    dittoComponentKey: z.string(),
    branchId: z.string().optional(),
  }),
};
export const ZGetApiIdForNodesResponse = z.unknown();

export const ZGetFigmaVariablesIntegrationConfigRequest = {
  params: z.object({
    projectId: z.string(),
  }),
};
export const ZGetFigmaVariablesIntegrationConfigResponse = z.unknown();

export const ZGetFigmaVariablesByFolderIdRequest = {
  params: z.object({
    projectId: z.string(),
    folderId: z.string(),
  }),
};
export const ZGetFigmaVariablesByFolderIdResponse = z.unknown();

export const ZUpdateFigmaVariablesIntegrationConfigRequest = {
  params: z.object({
    projectId: z.string(),
  }),
  body: ZUpdateFigmaVariablesConfigBody,
};
export const ZUpdateFigmaVariablesIntegrationConfigResponse = z.unknown();

export const ZDeleteFigmaVariablesIntegrationConfigRequest = {
  params: z.object({
    projectId: z.string(),
  }),
};
export const ZDeleteFigmaVariablesIntegrationConfigResponse = z.unknown();

export const ZCreateComponentsFromVariableCollectionRequest = {
  params: z.object({
    projectId: z.string(),
  }),
  body: ZFVConfigVariableCollection,
};
export const ZCreateComponentsFromVariableCollectionResponse = z.unknown();

export const ZFigmaSyncRequest = {
  body: ZFigmaSyncRequestBody,
};

export const ZFigmaSyncResponse = z.unknown();

export const ZFigmaSyncResponseRequest = {
  body: z.array(ZFigmaSyncResponseType),
};

export const ZFigmaSyncResponseResponse = z.unknown();

export const ZFigmaSyncResultsRequest = {
  body: z.array(ZUpdateTextNodeInstanceLastReconciledRichText),
};
export const ZFigmaSyncResultsResponse = z.unknown();

export const ZFigmaInstaSyncRequest = {
  body: z.object({
    selection: ZFigmaSelection,
    figmaEditedNodeId: z.string().nullable(),
    projectId: ZObjectId,
    fileId: z.string(),
    branchId: z.string().nullable(),
  }),
};
export const ZFigmaInstaSyncResponse = z.unknown();

export const ZGetFigmaSuggestionsForPageRequest = {
  params: z.object({
    projectId: z.string(),
    figmaPageId: z.string(),
  }),
  query: z.object({
    fileId: z.string(),
    branchId: z.string().optional(),
  }),
};
export const ZGetFigmaSuggestionsForPageResponse = z.unknown();

export const ZFigmaInstaSyncTextItemsRequest = {
  body: z.object({
    textItemIds: z.array(z.string()),
    projectId: ZObjectId,
    fileId: z.string(),
    branchId: z.string().nullable(),
    pageId: z.string(),
  }),
};
export const ZFigmaInstaSyncTextItemsResponse = z.unknown();

export const ZFigmaNodesDeletedRequest = {
  body: z.object({
    nodes: z.array(
      z.object({
        id: z.string(),
        type: z.enum(TrackedFigmaNodeTypesForDeletion),
      })
    ),
    projectId: ZObjectId,
    fileId: z.string(),
    branchId: z.string().nullable(),
  }),
};
export const ZFigmaNodesDeletedResponse = z.object({
  textItemIdsToDeletedNodeIds: z.record(z.string(), z.array(z.string())),
});
export const FigmaNodesDeletedPath = "/figma/nodes-deleted";
export const FigmaNodesDeletedMethod = "POST";

export const ZManualFigmaCacheUpdateRequest = {
  params: z.object({
    projectId: z.string(),
  }),
  body: z.object({
    nodesDeleted: z.array(z.string()).optional(),
    nodesHidden: z.array(z.string()).optional(),
    nodesLinked: z.array(z.tuple([z.string(), z.string()])).optional(), // [nodeId, textItemId]
    nodesTextChanged: z.array(z.tuple([z.string(), ZTipTapRichText])).optional(), // [nodeId, text]
  }),
};
export const ZManualFigmaCacheUpdateResponse = z.unknown();
export const ManualFigmaCacheUpdateMethod = "PUT";
export const ManualFigmaCacheUpdatePath = `/figma/cache/:projectId`;
