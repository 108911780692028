import { serializeTipTapRichText } from "@shared/frontend/richText/serializer";
import { RichTextInputProps, RichTextInputRef } from "@shared/types/RichText";
import {
  ActualComponentStatus,
  ITextItemVariant,
  ITextItemVariantUpdate,
  ITipTapRichText,
} from "@shared/types/TextItem";
import { AddVariantData, AddVariantUpdateType } from "@shared/types/Variant";
import { Atom, PrimitiveAtom, useAtom, useAtomValue, useSetAtom, WritableAtom } from "jotai";
import { AtomFamily } from "jotai/vanilla/utils/atomFamily";
import React, {
  ForwardRefExoticComponent,
  memo,
  RefAttributes,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { RichTextInput } from "../../../../src/views/NS/ProjectNS/components/DetailsPanel/Metadata";
import Button from "../../../src/atoms/Button";
import Text from "../../../src/atoms/Text";
import TextInput from "../../../src/atoms/TextInput";
import { ComboboxOption } from "../../../src/molecules/BaseCombobox";
import AddVariantForm from "../AddVariantForm";
import TextItemVariant, { ITextItemVariantRef } from "../TextItemVariant";
import style from "./style.module.css";

/**
 * Helper function to generate a unique key for a text item - variant pair.
 * @param textItemId The textItemId.
 * @param variantId The variantId.
 * @returns Unique string key in format `{textItemId}-{variantId}`
 */
export const getVariantKey = (textItemId: string, variantId: string) => `${textItemId}-${variantId}`;

export type AddVariantFormChanges = {
  selectedVariant: boolean;
  richText: boolean;
  status: boolean;
};

export const DEFAULT_ADD_VARIANT_CHANGES: AddVariantFormChanges = {
  selectedVariant: false,
  richText: false,
  status: false,
};

export type DetailsPanelProps = {
  variants?: {
    showAddForm?: boolean;
    // Default variant option to be shown in AddVariantForm combobox
    defaultVariant?: {
      id: string;
      name: string;
    };
  };

  // Flag to reset variant form state when switching between variant tabs or text items
  resetFormState?: boolean;
};

type MaybePromise<T> = T | Promise<T>;

type MinimalItem = { _id: string; text: string; blockId?: string | null };

interface VariantsPanelAtoms {
  /**
   * Only selected text item or library component.
   */
  onlySelectedItemAtom: Atom<MaybePromise<MinimalItem | null>>;

  /**
   * Workspace variants.
   */
  workspaceVariantsAtom: Atom<MaybePromise<{ _id: string; name: string }[]>>;

  /**
   * Only selected text item variants or library component variants.
   */
  onlySelectedItemVariantsAtom: Atom<MaybePromise<(ITextItemVariant & { name: string })[]>>;

  /**
   * If the variants panel is in the project page, we need to know the selected variant on the block
   * to sort the variants in the combobox.
   */
  blockSelectedVariantIdFamilyAtom: (blockId: string | null) => Atom<MaybePromise<string>>;

  /**
   * Parameters passed from the details panel if jumping to the variants panel from the details
   * panel.
   */
  detailsPanelPropsAtom: PrimitiveAtom<DetailsPanelProps>;

  /**
   * Track which parts of the 'add variant' form has changes.
   */
  setAddVariantFormChangesActionAtom: WritableAtom<null, [changes: Partial<AddVariantFormChanges>], void>;

  /**
   * Reset the 'add variant' form changes.
   */
  resetAddVariantFormChangesActionAtom: WritableAtom<null, [], void>;

  /**
   * Attach a variant to a text item or library component.
   */
  attachVariantActionAtom: WritableAtom<
    null,
    [
      props: {
        variant: AddVariantData;
        updateType: AddVariantUpdateType;
        itemId: string;
        workspaceId: string;
      }
    ],
    Promise<void>
  >;

  /**
   * Update a variant on a text item or library component.
   */
  updateItemVariantActionAtom: WritableAtom<
    null,
    [
      props: {
        itemId: string;
        update: ITextItemVariantUpdate;
        workspaceId: string;
      }
    ],
    Promise<void>
  >;

  /**
   * Tracks unsaved changes, mapping variantId to a flag for changes for that
   * variant.
   */
  editableItemVariantChangesAtom: PrimitiveAtom<Record<string, boolean>>;

  /**
   * Remove a variant from a text item or library component.
   */
  removeVariantFromItemsActionAtom: WritableAtom<
    null,
    [
      props: {
        itemIds: string[];
        variantId: string;
        workspaceId: string;
      }
    ],
    Promise<void>
  >;

  /**
   * Get a text item variant or library component variant by variantId and item
   * id.
   */
  itemVariantsFamilyAtom: AtomFamily<
    string,
    Atom<MaybePromise<(ITextItemVariant & { name: string; placeholder: string }) | null>>
  >;
}

interface VariantsPanelProps {
  workspaceId: string;
  atoms: VariantsPanelAtoms;
}

function VariantsPanel({ workspaceId, atoms }: VariantsPanelProps) {
  const selectedTextItem = useAtomValue(atoms.onlySelectedItemAtom);
  const wsVariants = useAtomValue(atoms.workspaceVariantsAtom);
  const selectedTextItemVariants = useAtomValue(atoms.onlySelectedItemVariantsAtom);
  const blockSelectedVariantId = useAtomValue(
    atoms.blockSelectedVariantIdFamilyAtom(selectedTextItem?.blockId ?? null)
  );
  const [detailsPanelProps, setDetailsPanelProps] = useAtom(atoms.detailsPanelPropsAtom);
  const [addVariantFormOpen, setAddVariantFormOpen] = useState(false);
  const setAddVariantFormHasChanges = useSetAtom(atoms.setAddVariantFormChangesActionAtom);
  const resetAddVariantFormHasChanges = useSetAtom(atoms.resetAddVariantFormChangesActionAtom);
  const setAttachVariantAction = useSetAtom(atoms.attachVariantActionAtom);
  const setUpdateTextItemVariantAction = useSetAtom(atoms.updateItemVariantActionAtom);
  const setEditableTextItemVariantChanges = useSetAtom(atoms.editableItemVariantChangesAtom);
  const removeVariantFromTextItemsAction = useSetAtom(atoms.removeVariantFromItemsActionAtom);

  // Map to keep track of whether each TextItemVariant form has changes (maps variantId to flag)
  const [variantChangesMap, setVariantChangesMap] = useState<Record<string, boolean>>({});
  const [variantsFilter, setVariantsFilter] = useState("");

  const variantFormRefs = useRef<Record<string, ITextItemVariantRef>>({});

  // Variant options for add variant form
  const variantOptions: ComboboxOption[] = useMemo(
    () =>
      wsVariants
        // Remove the currently selected variant from the options in the combobox for adding a new variant
        .filter(
          (variant) =>
            !Object.values(selectedTextItemVariants).some(
              (selectedVariant) => selectedVariant.variantId === variant._id
            )
        )
        .map((variant) => ({ value: variant._id, label: variant.name })),
    [wsVariants, selectedTextItemVariants]
  );

  // Filter that's available when there are 3 or more variants on a text item
  const filteredAndSortedSelectedTextItemVariants = useMemo(() => {
    if (selectedTextItem?._id) {
      return (
        Object.values(selectedTextItemVariants)
          .filter((variant) => variant.name.toLowerCase().includes(variantsFilter.toLowerCase()))
          .sort((a, b) => a.name.localeCompare(b.name, undefined, { sensitivity: "base" }))
          .sort((a, b) => {
            // Put matching variant first
            if (a.variantId === blockSelectedVariantId) return -1;
            if (b.variantId === blockSelectedVariantId) return 1;
            return 0;
          }) ?? []
      );
    }
    return [];
  }, [blockSelectedVariantId, selectedTextItem?._id, selectedTextItemVariants, variantsFilter]);

  const defaultAddVariantOption: ComboboxOption | undefined = useMemo(() => {
    if (!detailsPanelProps.variants?.defaultVariant) return;
    return {
      value: detailsPanelProps.variants.defaultVariant.id,
      label: detailsPanelProps.variants.defaultVariant.name,
    };
  }, [detailsPanelProps.variants?.defaultVariant]);

  useEffect(() => {
    setAddVariantFormOpen(false);
    resetAddVariantFormHasChanges();
    setVariantsFilter("");
  }, [selectedTextItem?._id, blockSelectedVariantId, resetAddVariantFormHasChanges]);

  // Open/close the add variant form based on external triggers
  useEffect(
    function syncShowAddVariantForm() {
      if (detailsPanelProps?.variants?.showAddForm) {
        setAddVariantFormOpen(true);
        if (detailsPanelProps.variants.defaultVariant?.id) {
          setAddVariantFormHasChanges({ ...DEFAULT_ADD_VARIANT_CHANGES, selectedVariant: true });
        } else {
          resetAddVariantFormHasChanges();
        }
      } else {
        setAddVariantFormOpen(false);
        resetAddVariantFormHasChanges();
      }
    },
    [
      detailsPanelProps?.variants?.showAddForm,
      detailsPanelProps?.variants?.defaultVariant?.id,
      resetAddVariantFormHasChanges,
      setAddVariantFormHasChanges,
    ]
  );

  // Reset the variant form based on external triggers
  useEffect(
    function handleResetVariantForm() {
      // We received a signal to reset the form - call the reset method then clear the resetFormState flag
      if (detailsPanelProps?.resetFormState) {
        function resetVariantFormStates() {
          Object.values(variantFormRefs.current).forEach((ref) => ref.resetFormState());
          setVariantChangesMap({});
        }

        resetVariantFormStates();
        setDetailsPanelProps((prevProps) => ({
          ...prevProps,
          resetFormState: false,
        }));
      }
    },
    [detailsPanelProps?.resetFormState, setDetailsPanelProps]
  );

  /**
   * Handler for when one of the TextItemVariant forms in the Variants panel has changes.
   * Updates the global Jotai state that tracks which variants have unsaved changes.
   *
   * @param hasChanges - Boolean indicating if the specific variant form has unsaved changes
   * @param variantId - ID of the variant the form is associated with
   */
  const onHasTextItemVariantFormChanges = useCallback(
    (hasChanges: boolean, variantId: string) => {
      setVariantChangesMap((prev) => {
        if (prev[variantId] === hasChanges) return prev;

        const newMap = { ...prev, [variantId]: hasChanges };
        setEditableTextItemVariantChanges(newMap);
        return newMap;
      });
    },
    [setEditableTextItemVariantChanges]
  );

  const onAddVariantClick = useCallback(() => {
    setAddVariantFormOpen(true);
    resetAddVariantFormHasChanges();
  }, [setAddVariantFormOpen, resetAddVariantFormHasChanges]);

  const onCancelVariantAdd = useCallback(() => {
    setAddVariantFormOpen(false);
    resetAddVariantFormHasChanges();
    setDetailsPanelProps((prevProps) => ({ ...prevProps, variants: {} }));
  }, [setAddVariantFormOpen, resetAddVariantFormHasChanges, setDetailsPanelProps]);

  const onSaveVariant = useCallback(
    (variant: AddVariantData, updateType: AddVariantUpdateType) => {
      if (!selectedTextItem?._id) return;
      setAttachVariantAction({ variant, updateType, itemId: selectedTextItem._id, workspaceId });
      setAddVariantFormOpen(false);
      resetAddVariantFormHasChanges();
      setDetailsPanelProps((prevProps) => ({ ...prevProps, variants: {} }));
    },
    [
      setAttachVariantAction,
      setAddVariantFormOpen,
      resetAddVariantFormHasChanges,
      setDetailsPanelProps,
      selectedTextItem?._id,
      workspaceId,
    ]
  );

  const onUpdateTextItemVariant = useCallback(
    (update: ITextItemVariantUpdate) => {
      if (!selectedTextItem?._id) return;
      setUpdateTextItemVariantAction({ itemId: selectedTextItem._id, update, workspaceId });
    },
    [setUpdateTextItemVariantAction, selectedTextItem?._id, workspaceId]
  );

  // On each interaction with the Add Variant form,
  // determine whether the form has changes that should trigger a discard confirmation
  const handleNewVariantFormUpdate = useCallback(
    ({
      selectedVariant,
      status,
      richText,
    }: {
      selectedVariant?: ComboboxOption | null;
      status?: ActualComponentStatus;
      richText?: ITipTapRichText;
    }) => {
      let changes: Partial<AddVariantFormChanges> = {};
      if (selectedVariant !== undefined) {
        changes.selectedVariant = !!selectedVariant;
      }
      if (status) {
        changes.status = status !== "NONE";
      }
      if (richText) {
        const { text } = serializeTipTapRichText(richText, { type: "display" });
        changes.richText = text !== "";
      }
      if (Object.keys(changes).length) {
        setAddVariantFormHasChanges(changes);
      }
    },
    [setAddVariantFormHasChanges]
  );

  const handleOnDeleteVariant = useCallback(
    function _handleOnDeleteVariant(variantId: string) {
      if (!selectedTextItem?._id) return;
      removeVariantFromTextItemsAction({ itemIds: [selectedTextItem._id], variantId, workspaceId });
    },
    [removeVariantFromTextItemsAction, selectedTextItem?._id, workspaceId]
  );

  // Multiselection is not currently supported for variants
  if (!selectedTextItem) {
    return <></>;
  }

  return (
    <div className={style.VariantsPanel}>
      <div className={style.baseVariantSection}>
        <Text size="small" weight="strong">
          Base text
        </Text>
        <TextInput value={selectedTextItem.text} disabled />
      </div>
      {!addVariantFormOpen && (
        <div className={style.addVariantButtonWrapper}>
          <Button expansion="block" level="outline" onClick={onAddVariantClick}>
            Add variant
          </Button>
        </div>
      )}
      {addVariantFormOpen && (
        <AddVariantForm
          selectedTextItemVariantNames={Object.values(selectedTextItemVariants).map((v) => v.name)}
          variantOptions={variantOptions}
          placeholder={selectedTextItem.text}
          defaultOption={defaultAddVariantOption}
          onEdit={handleNewVariantFormUpdate}
          onCancel={onCancelVariantAdd}
          onSave={onSaveVariant}
          RichTextInput={RichTextInput}
        />
      )}
      {/* Show filter when 3 or more variants on a text item */}
      {selectedTextItemVariants.length >= 3 && (
        <div className={style.filterVariantsInputWrapper}>
          <TextInput value={variantsFilter} onChange={setVariantsFilter} placeholder="Filter variants..." />
        </div>
      )}
      {filteredAndSortedSelectedTextItemVariants.map((selectedVariant, idx) => {
        const className = idx === 0 && selectedTextItemVariants.length >= 3 ? style.borderTopHidden : "";
        return (
          <TextItemVariantWrapper
            key={`${selectedVariant.variantId}-${selectedTextItem._id}`}
            className={className}
            variantId={selectedVariant.variantId}
            textItemId={selectedTextItem._id}
            onSave={onUpdateTextItemVariant}
            onDeleteVariant={handleOnDeleteVariant}
            onHasChanges={(hasChanges) => onHasTextItemVariantFormChanges(hasChanges, selectedVariant.variantId)}
            RichTextInput={RichTextInput as any}
            atoms={atoms}
          />
        );
      })}
    </div>
  );
}

interface ITextItemVariantWrapperProps {
  variantId: string;
  textItemId: string;
  onSave: (update: ITextItemVariantUpdate) => void;
  onDeleteVariant: (variantId: string) => void;
  onHasChanges: (hasChanges: boolean) => void;
  RichTextInput: ForwardRefExoticComponent<RichTextInputProps & RefAttributes<RichTextInputRef>>;
  className?: string;
  atoms: VariantsPanelAtoms;
}

/**
 * Memoized wrapper for TextItemVariant that only takes in variantId, textItemId (and callbacks, refs).
 *
 * We use this wrapper to prevent re-renders of a TextItemVariantForm with active edits when saving edits in another TextItemVariant form,
 * by only passing in variantId and textItemId and using those to get the text item variant data from Jotai.
 */
const TextItemVariantWrapper = memo(function _TextItemVariantWrapper(props: ITextItemVariantWrapperProps) {
  const textItemVariant = useAtomValue(
    props.atoms.itemVariantsFamilyAtom(getVariantKey(props.textItemId, props.variantId))
  );
  if (!textItemVariant) return <></>;

  return (
    <TextItemVariant
      className={props.className}
      variantId={textItemVariant.variantId}
      variantStatus={textItemVariant.status}
      variantRichText={textItemVariant.rich_text}
      variantText={textItemVariant.text}
      variantVariables={textItemVariant.variables}
      variantName={textItemVariant.name}
      placeholder={textItemVariant.placeholder}
      RichTextInput={props.RichTextInput}
      onSave={props.onSave}
      onDeleteVariant={props.onDeleteVariant}
      onHasChanges={props.onHasChanges}
    />
  );
});

export default VariantsPanel;
