import { libraryComponentActivityFamilyAtom } from "@/stores/Activity";
import { derivedOnlySelectedComponentAtom } from "@/stores/Library";
import Scrollbar from "@ds/molecules/Scrollbar";
import ChangeItemList from "@ds/organisms/ChangeItemList";
import { useAtomValue } from "jotai";
import LibraryEditActivityItem from "./LibraryEditActivityItem";
import style from "./style.module.css";

function LibraryEditActivityPanel() {
  const onlySelectedComponent = useAtomValue(derivedOnlySelectedComponentAtom);
  const selectedComponentActivity = useAtomValue(
    libraryComponentActivityFamilyAtom(onlySelectedComponent?._id ?? null)
  );

  return (
    <Scrollbar className={style.scrollArea}>
      <ChangeItemList changeItems={selectedComponentActivity} ChangeItemComponent={LibraryEditActivityItem} />
    </Scrollbar>
  );
}

export default LibraryEditActivityPanel;
