import useBetterLocalStorage from "@/hooks/useBetterLocalStorage";
import { useWorkspace } from "@/store/workspaceContext";
import {
  createLibraryComponentFolderActionAtom,
  libraryCreateFolderModalIsOpenAtom,
  selectedLibraryFolderIdAtom,
} from "@/stores/Library";
import Button from "@ds/atoms/Button";
import Text from "@ds/atoms/Text";
import TextInput from "@ds/atoms/TextInput";
import Callout from "@ds/molecules/Callout";
import CompactInputLabel from "@ds/molecules/CompactInputLabel";
import Modal from "@ds/molecules/Modal";
import { IObjectId } from "@shared/types/lib";
import { atom, PrimitiveAtom, useAtom, useAtomValue, useSetAtom } from "jotai";
import React, { useCallback, useRef } from "react";
import style from "./style.module.css";

function LibraryCreateComponentFolderModal() {
  const workspace = useWorkspace();

  const [hasCreatedFolder, setHasCreatedFolder] = useBetterLocalStorage<boolean>(
    "HAS_CREATED_LIBRARY_COMPONENT_FOLDER",
    false
  );
  const [libraryCreateFolderModalIsOpen, setLibraryCreateFolderModalIsOpen] = useAtom(
    libraryCreateFolderModalIsOpenAtom
  );
  const createLibraryComponentFolderAction = useSetAtom(createLibraryComponentFolderActionAtom);

  const folderNameAtomRef = useRef(atom(""));

  const resetModal = useCallback(function resetModal() {
    folderNameAtomRef.current = atom("");
  }, []);

  const handleOpenChange = useCallback(
    function handleOpenChange(open: boolean) {
      setLibraryCreateFolderModalIsOpen(open);
    },
    [setLibraryCreateFolderModalIsOpen]
  );

  const onCreate = useCallback(
    async function _handleCreate(props: { name: string; parentId: IObjectId | null }) {
      createLibraryComponentFolderAction({
        parentId: props.parentId,
        name: props.name,
        workspaceId: workspace.workspaceInfo._id,
      });
      resetModal();
      handleOpenChange(false);
      setHasCreatedFolder(true);
    },
    [createLibraryComponentFolderAction, handleOpenChange, resetModal, workspace.workspaceInfo._id, setHasCreatedFolder]
  );

  const onCancel = useCallback(
    function _closeModal() {
      handleOpenChange(false);
    },
    [handleOpenChange]
  );

  const createLibraryComponentFolderProps = useCreateLibraryComponentFolder({
    folderNameAtom: folderNameAtomRef.current,
    onCancel,
    onCreate,
  });

  const modalDescription = "Folders help to group and organize text to make it easier to find and reuse in projects.";

  return (
    <>
      {libraryCreateFolderModalIsOpen && <div className={style.modalOverlay} />}
      <Modal
        headline="Create folder"
        description={modalDescription}
        open={libraryCreateFolderModalIsOpen}
        onOpenChange={handleOpenChange}
        modal={false}
        className={style.modalContainer}
      >
        <div className={style.contentContainer}>
          {!hasCreatedFolder && <Callout content={modalDescription} variant="secondary" className={style.callout} />}
          <CompactInputLabel
            label="Folder name"
            helper="You can change folder names at any time"
            input={<TextInput {...createLibraryComponentFolderProps.folderName} />}
          />
          <div className={style.footer}>
            <Button level="outline" onClick={createLibraryComponentFolderProps.onCancel}>
              Cancel
            </Button>
            <Button
              onClick={createLibraryComponentFolderProps.onCreate}
              disabled={!createLibraryComponentFolderProps.isValidName}
            >
              Create
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
}

export function useCreateLibraryComponentFolder(props: {
  folderNameAtom: PrimitiveAtom<string>;
  onCancel: () => void;
  onCreate: (props: { name: string; parentId: string | null }) => void;
}) {
  const { onCreate } = props;
  const folderId = useAtomValue(selectedLibraryFolderIdAtom);
  const [folderName, setFolderName] = useAtom(props.folderNameAtom);

  const isValidName = folderName.trim().length > 0;

  const handleTextChange = useCallback(
    (newText: string) => {
      setFolderName(newText);
    },
    [setFolderName]
  );
  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Enter" && isValidName) {
        onCreate({ name: folderName, parentId: folderId || null });
      }
    },
    [folderId, folderName, onCreate, isValidName]
  );

  return {
    folderName: {
      value: folderName,
      onKeyDown: handleKeyDown,
      onChange: handleTextChange,
      placeholder: "Give this folder a name...",
    },
    onCancel: props.onCancel,
    onCreate: () => props.onCreate({ name: folderName, parentId: folderId || null }),
    isValidName,
  };
}

export default LibraryCreateComponentFolderModal;
