import client from "@shared/frontend/http/httpClient";
import asyncMutableDerivedAtom from "@shared/frontend/stores/asyncMutableDerivedAtom";
import paginatedAtom from "@shared/frontend/stores/paginatedAtom";
import { extractLibraryComponentIds, extractTextItemIds, IChangeItem } from "@shared/types/ActualChange";
import fetchWithRetry from "@shared/utils/fetchWithRetry";
import logger from "@shared/utils/logger";
import { validateChangeItems } from "@shared/utils/validateChangeItems";
import { atom } from "jotai";
import { atomFamily, splitAtom, unwrap } from "jotai/utils";
import { projectIdAtom } from "./Project";

// MARK: - Project Activity Atoms

const {
  valueAtom: _projectActivityAtom,
  fetchNextPageActionAtom,
  hasMoreAtom,
  loadingAtom,
} = paginatedAtom<IChangeItem, string | null>({
  dependencyAtom: projectIdAtom,
  pageSize: 20,
  async pageRequest({ page, pageSize }, projectId) {
    try {
      if (!projectId) return [];

      const response = await client.changes.getDittoProjectActivity({
        projectId,
        skip: `${page * pageSize}`,
        limit: `${pageSize}`,
      });

      return validateChangeItems(response);
    } catch (error) {
      logger.error("Failed to fetch project activity", { context: { projectId, page, pageSize } }, error);
      return [];
    }
  },
  debugPrefix: "Project Activity",
});

export const projectActivitySplitAtom = splitAtom(unwrap(_projectActivityAtom, (prev) => prev ?? []));

export const projectActivityAtom = _projectActivityAtom;
export const fetchNextActivityPageActionAtom = fetchNextPageActionAtom;
export const hasMoreActivityAtom = hasMoreAtom;
export const activityLoadingAtom = loadingAtom;

// MARK -- Text Item Activity Atoms

/**
 * This atom represents change history for individual text items.
 * It is an atomFamily keyed by textItemId.
 */
export const textItemActivityFamilyAtom = atomFamily((textItemId: string | null) => {
  const { valueAtom: familyAtom } = asyncMutableDerivedAtom<IChangeItem[]>({
    loadData: async () => {
      if (!textItemId) return [];

      const response = await client.changes.getTextItemActivity({ textItemId });
      const validItems = validateChangeItems(response);
      return validItems;
    },
  });

  familyAtom.debugLabel = `Text Item Activity Family ${textItemId}`;

  return familyAtom;
});

/**
 * This atom is an action that updates any text item activity family atoms already in memory with new changes.
 * Use this for updating the textItemActivityFamilyAtom when a list of new changes are received via websocket.
 */
export const updateTextItemActivityActionAtom = atom(null, async (get, set, changeItems: IChangeItem[]) => {
  const idsInCache = [...textItemActivityFamilyAtom.getParams()];

  // Group all changes by textItemId
  const changesByTextItemId = new Map<string, IChangeItem[]>();

  changeItems.toReversed().forEach(async (changeItem) => {
    const textItemIds = extractTextItemIds(changeItem);
    if (!textItemIds.length) return;

    textItemIds.forEach(async (textItemId) => {
      if (!idsInCache.includes(textItemId)) return;

      if (!changesByTextItemId.has(textItemId)) {
        changesByTextItemId.set(textItemId, []);
      }
      changesByTextItemId.get(textItemId)!.unshift(changeItem);
    });
  });

  // Update each text item's activity with latest changes
  for (const [textItemId, changes] of changesByTextItemId) {
    const existingValue = await get(textItemActivityFamilyAtom(textItemId));
    set(textItemActivityFamilyAtom(textItemId), [...changes, ...existingValue]);
  }
});

// MARK: - Library Activity Items
export const {
  valueAtom: libraryActivityAtom,
  fetchNextPageActionAtom: libraryFetchNextActivityPageActionAtom,
  hasMoreAtom: libraryHasMoreActivityAtom,
  loadingAtom: libraryActivityLoadingAtom,
} = paginatedAtom<IChangeItem, string | null>({
  dependencyAtom: atom(null),
  pageSize: 20,
  async pageRequest({ page, pageSize }) {
    try {
      const response = await client.changes.getComponentLibraryActivity({
        skip: `${page * pageSize}`,
        limit: `${pageSize}`,
      });

      return validateChangeItems(response);
    } catch (error) {
      logger.error("Failed to fetch library activity", { context: { page, pageSize } }, error);
      return [];
    }
  },
  debugPrefix: "Library Activity",
});

export const libraryComponentActivityFamilyAtom = atomFamily((libraryComponentId: string | null) => {
  const { valueAtom: familyAtom } = asyncMutableDerivedAtom<IChangeItem[]>({
    loadData: async () => {
      if (!libraryComponentId) return [];

      const response = await client.changes.getLibraryComponentActivity({ libraryComponentId });
      const validItems = validateChangeItems(response);
      return validItems;
    },
  });

  familyAtom.debugLabel = `Library Component Activity Family ${libraryComponentId}`;

  return familyAtom;
});

/**
 * This atom is an action that updates any library component activity family atoms already in memory with new changes.
 * Use this for updating the libraryComponentActivityFamilyAtom when a list of new changes are received via websocket.
 * Matches the implementation of updateTextItemActivityActionAtom
 */
export const updateLibraryComponentActivityActionAtom = atom(
  null,
  async (get, set, validatedChangeItems: IChangeItem[]) => {
    const idsInCache = [...libraryComponentActivityFamilyAtom.getParams()];

    // Group all changes by componentId
    const changesByComponentId = new Map<string, IChangeItem[]>();

    validatedChangeItems.toReversed().forEach(async (changeItem) => {
      const componentIds = extractLibraryComponentIds(changeItem);
      if (!componentIds.length) return;

      componentIds.forEach(async (componentId) => {
        if (!idsInCache.includes(componentId)) return;

        if (!changesByComponentId.has(componentId)) {
          changesByComponentId.set(componentId, []);
        }
        changesByComponentId.get(componentId)!.unshift(changeItem);
      });
    });

    // Update each component's activity with latest changes
    for (const [componentId, changes] of changesByComponentId) {
      const existingValue = await get(libraryComponentActivityFamilyAtom(componentId));
      set(libraryComponentActivityFamilyAtom(componentId), [...changes, ...existingValue]);
    }
  }
);

/**
 * This atom is the action to call when we receive a websocket event that new library activity items have been created.
 * This will fetch the activity data by ids and then update the overall and by-component library activity atoms.
 */
export const handleLibraryActivityUpdatedActionAtom = atom(null, async (get, set, changeItemIds: string[]) => {
  const {
    success,
    response: items,
    error,
  } = await fetchWithRetry({
    request: () => {
      return client.changes.getActivityByItemIds({
        itemIds: changeItemIds,
      });
    },
    requestName: "webapp/websocket/fetchLibraryChangeItems",
    validationFn: (response) => response.length === changeItemIds.length,
    fallbackResponse: [],
    initialDelay: 300,
  });

  if (!success) {
    logger.error(
      `Failed to find all library change items by ids after retry`,
      {
        context: { itemIds: changeItemIds, returnedItems: items },
      },
      error || new Error("Failed to find all library change items by ids after retry")
    );
  }

  const validItems = validateChangeItems(items);
  if (validItems.length) {
    set(libraryActivityAtom, (prev: IChangeItem[]) => {
      return [...validItems, ...prev];
    });
    set(updateLibraryComponentActivityActionAtom, validItems);
  }
});
