import React, { useCallback, useState } from "react";
import Button from "../../atoms/Button";
import style from "./index.module.css";

interface IProps {
  buttonSize: "base" | "small" | "micro";
  onCancel: () => void;
  onSave: () => void | Promise<void>;
}

function CTAButtons(props: IProps) {
  const [isSaving, setIsSaving] = useState(false);
  const { onCancel, onSave } = props;

  const handleSave = useCallback(async () => {
    setIsSaving(true);
    await onSave();
    setIsSaving(false);
  }, [onSave]);

  return (
    <div className={style.ctaButtons}>
      <Button size={props.buttonSize} disabled={isSaving} level="secondary" onClick={onCancel}>
        Cancel
      </Button>
      <Button size={props.buttonSize} disabled={isSaving} level="primary" onClick={handleSave}>
        Save
      </Button>
    </div>
  );
}

export default CTAButtons;
