import type { IDittoProject } from "@shared/types/DittoProject";
import type { ILibraryComponent } from "@shared/types/LibraryComponent";
import type { ITextItem } from "@shared/types/TextItem";
import { Virtualizer } from "@tanstack/react-virtual";
import { atom, Atom, Getter, Setter, useAtom, useAtomValue, useSetAtom, WritableAtom } from "jotai";
import { soon } from "jotai-derive";
import { unwrap, useAtomCallback } from "jotai/utils";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import * as DittoEvents from "../../../../shared/ditto-events";
import { useDittoEventListener } from "../../../../shared/ditto-events/frontend";
import useStableRef from "../../../../shared/frontend/hooks/useStableRef";
import atomWithDebounce from "../../../../shared/frontend/stores/atomWithDebounce";
import batchedAsyncAtomFamily from "../../../../shared/frontend/stores/batchedAsyncAtomFamily";
import { REFRESH_SILENTLY, REFRESH_WITH_SUSPENSE } from "../../../../shared/frontend/stores/symbols";
import { ILibraryComponentFolder } from "../../../../shared/types/LibraryComponentFolder";
import { ITextItemStatus } from "../../../../shared/types/TextItem";
import { IUser } from "../../../../shared/types/User";
import { IProps as ITextItemProps } from "../../molecules/TextItem";

export type IFolderEntity = ILibraryComponentFolder;

export type ITextEntity = { _id: string } & Pick<
  ITextItemProps,
  "status" | "tags" | "notes" | "defaultValue" | "component" | "instanceCount"
> & {
    assignee?: Pick<IUser, "name" | "picture"> | null;
  };

export type StructuredAtomBlock = {
  _id: string | null;
  name?: string | null;
  entities: { _id: string; atom: Atom<ITextEntity | Promise<ITextEntity>> }[];
};

export type StructuredAtoms = {
  blocks: StructuredAtomBlock[];
};

type StructuredAtomsMaybePromise = StructuredAtoms | Promise<StructuredAtoms>;

export type EmptyStates = {
  whenSearching?: (query: string) => React.ReactNode;
  whenFiltering?: React.ReactNode;
  whenInFolder?: React.ReactNode;
  default: React.ReactNode;
};

interface IExternalProps {
  emptyStates: EmptyStates;

  /**
   * Function that returns an atom from which data for the list should be derived. This is an abstraction that enables
   * the text entity list to be agnostic from the source which data is loaded from. The atoms exported from file://./data.ts
   * can be used to create the getDataAtom function in different contexts.
   */
  getEntityListAtom: (dependencyAtoms: {
    queryAtom: Atom<string>;
    statusesAtom: Atom<ITextItemStatus[]>;
    tagsAtom: Atom<string[]>;
    folderIdAtom: Atom<string | null>;
    projectIdAtom: Atom<string | null>;
  }) => WritableAtom<
    StructuredAtomsMaybePromise,
    [StructuredAtomsMaybePromise | typeof REFRESH_SILENTLY | typeof REFRESH_WITH_SUSPENSE],
    void
  >;

  getFoldersListAtom?: (dependencyAtoms: {
    selectedFolderIdAtom: Atom<string | null | Promise<string | null>>;
  }) => WritableAtom<IFolderEntity[] | Promise<IFolderEntity[]>, [], void>;

  dragAndDrop?: { mime: string };

  /**
   * List of selected text items to link to an item in this list.
   */
  selectedTextItems?: ITextItem[];

  /**
   * Atom for the suggested items to link to.
   */
  suggestedItemIdsAtom?: Atom<string[] | Promise<string[]>>;

  /**
   * Text to display on the action button for each list item in the library list.
   */
  actionText: string;

  /**
   * A numeric value to add to the default padding at the end of the list.
   * This value will account for any offsets between the top of the viewport and the top of the list.
   * For instance, if we're showing a second nav header, this should be the height of the header.
   */
  extraPaddingEnd?: number;

  /**
   * Atom Family used to populate component information - needed for rendering components AND needed for rendering
   * text items that are linked to components.
   * @param id - ID of the list item
   * @returns Atom for the list item by ID.
   */
  libraryComponentFamilyAtom: (
    id: string | null
  ) => ReturnType<ReturnType<typeof batchedAsyncAtomFamily<ILibraryComponent>>["familyAtom"]>;

  /**
   * Atom Family used to populate folders for this listType.
   * @param id - ID of the folder
   * @returns Atom for the folder by ID.
   */
  listItemFolderFamilyAtom: (
    id: string
  ) => ReturnType<ReturnType<typeof batchedAsyncAtomFamily<IFolderEntity>>["familyAtom"]>;

  /**
   * Atom Family used to populate breadcrumbs for the selected folder.
   * @param id - ID of the folder
   * @returns Atom for the breadcrumbs by ID.
   */
  libraryComponentFoldersBreadcrumbsAtomFamily: (
    id: string
  ) => Atom<Promise<{ folder: ILibraryComponentFolder; breadcrumbs: string[] }>>;

  /**
   * Atom for all tags used by these items.
   */
  tagsAtom: Atom<string[] | Promise<string[]>>;
  /**
   * Atom for all projects in the workspace.
   */
  projectsAtom: Atom<IDittoProject[] | Promise<IDittoProject[]>>;
  /**
   * Atom for all users in the workspace by ID.
   */
  usersByIdAtom: Atom<Record<string, IUser> | Promise<Record<string, IUser>>>;
  /**
   * Callback action atom for when user selects the action on a selected component in the library list.
   * @param componentId - ID of the component that will be acted upon
   */
  itemActionClickAtom: WritableAtom<void, [string], void>;
  /**
   * Run when user clicks to add a component to the file.
   */
  onItemActionClick?: () => void;
  /**
   * Run when user drags a component to the file.
   */
  onItemDragEnd?: () => void;
  /**
   * Title to display at the top of the list. If not provided, the default title will be used.
   */
  title?: string;
  /**
   * Callback atom for when the title back button is clicked. Only used when a custom title is provided.
   * @returns void
   */
  onTitleBackClickActionAtom?: WritableAtom<void, [], void>;

  projectId?: string;

  /**
   * Which filters we want to support in the library list
   */
  filters?: FilterType[];

  /**
   * Placeholder text for the search input.
   */
  searchPlaceholder?: string;

  /**
   * Callback atom for when the action button is clicked.
   * @param componentId - ID of the component that will be acted upon
   */
  onComponentActionClick?: (componentId: string) => void;
}

const suggestedItemIdsAtom = atom<string[]>((get) => []);
const defaultFoldersListAtom = atom<IFolderEntity[], [], void>(
  (get) => [],
  () => {}
);

/**
 * External hook used to integrate the CompactTextEntityList with the rest of the app. Just pass
 * in all the required atoms, spread the props on the CompactTextEntityList component, and you're
 * good to go.
 */
export function useCompactTextEntityList(props: IExternalProps) {
  // local state atoms
  const { current: selectedStatusesAtom } = useRef(atom<ITextItemStatus[]>([]));
  const { current: selectedTagsAtom } = useRef(atom<string[]>([]));
  const { current: selectedProjectIdAtom } = useRef(atom<string | null>(null));

  const { current: selectedFolderIdAtom } = useRef(atom<string | null>(null));
  const { current: selectedFolderAtom } = useRef(
    atom((get) => {
      const folderId = get(selectedFolderIdAtom);
      if (!folderId) return null;
      return soon(get(props.listItemFolderFamilyAtom(folderId)), (folder) => folder ?? null);
    })
  );
  const selectedFolder = useAtomValue(selectedFolderAtom);
  const [selectedFolderId, setSelectedFolderId] = useAtom(selectedFolderIdAtom);

  const { current: selectedSuggestedComponentIdAtom } = useRef(atom<string | null>(null));
  const {
    current: { currentValueAtom: searchQueryAtom, debouncedValueAtom: debouncedSearchQueryAtom },
  } = useRef(atomWithDebounce("", 100));

  const { current: selectedEntityIdAtom } = useRef(atom<string | null>(null));
  const [selectedEntityId, setSelectedEntityId] = useAtom(selectedEntityIdAtom);

  const _suggestedComponentIds = useAtomValue(props.suggestedItemIdsAtom || suggestedItemIdsAtom);
  const [suggestedComponentIds, setSuggestedComponentIds] = useState(_suggestedComponentIds);
  useEffect(() => setSuggestedComponentIds(_suggestedComponentIds), [_suggestedComponentIds]);

  // Entity list component is computed using an external function
  // which is passed an assortment of internal atoms to be able to
  // get information such as current query and filters.
  const { current: entityListAtom } = useStableRef(() =>
    props.getEntityListAtom({
      queryAtom: debouncedSearchQueryAtom,
      statusesAtom: selectedStatusesAtom,
      tagsAtom: selectedTagsAtom,
      folderIdAtom: selectedFolderIdAtom,
      projectIdAtom: selectedProjectIdAtom,
    })
  );
  const { current: updateEntityListAtom } = useStableRef(() => {
    return atom(
      null,
      async (
        get,
        set,
        arg:
          | ((prev: StructuredAtoms) => StructuredAtomsMaybePromise)
          | typeof REFRESH_SILENTLY
          | typeof REFRESH_WITH_SUSPENSE
      ) => {
        if (arg === REFRESH_SILENTLY || arg === REFRESH_WITH_SUSPENSE) {
          set(entityListAtom, arg);
          return;
        }

        const prevValue = await get(entityListAtom);
        set(entityListAtom, arg(prevValue));
      }
    );
  });
  const updateEntityList = useSetAtom(updateEntityListAtom);
  const refreshEntityList = useCallback(() => updateEntityList(REFRESH_SILENTLY), [updateEntityList]);

  // this is only used to have a list to check against when receiving Ditto events,
  // so it's fine to unwrap the atom; we don't always need it to be perfectly up to date
  // when the entity list is refreshing
  const { current: unwrappedEntityIdsAtom } = useStableRef(() =>
    unwrap(
      atom(async (get) =>
        (await get(entityListAtom)).blocks.flatMap((block) => block.entities.map((entity) => entity._id))
      ),
      (prev) => prev ?? []
    )
  );
  const unwrappedEntityIds = useAtomValue(unwrappedEntityIdsAtom);

  useDittoEventListener(DittoEvents.libraryComponentCreated, refreshEntityList);
  useDittoEventListener(DittoEvents.libraryComponentsUpdated, refreshEntityList);
  useDittoEventListener(
    DittoEvents.libraryComponentsDeleted,
    function handleLibraryComponentsDeleted(data) {
      const deletedEntityIds = new Set(data.componentIds);
      setSelectedEntityId((prev) => {
        if (prev && deletedEntityIds.has(prev)) return null;
        return prev;
      });
      setSuggestedComponentIds((prev) => prev.filter((id) => !deletedEntityIds.has(id)));
      updateEntityList((prev) => filterOutDeletedEntities(prev, deletedEntityIds));
    },
    [setSelectedEntityId]
  );

  useDittoEventListener(DittoEvents.textItemsCreated, refreshEntityList);
  useDittoEventListener(DittoEvents.textItemsUpdated, refreshEntityList);
  useDittoEventListener(
    DittoEvents.textItemsMoved,
    function handleTextItemsMoved(data) {
      const textItemIds = new Set(data.actions.flatMap((action) => action.textItemIds));
      if (unwrappedEntityIds.some((id) => textItemIds.has(id))) {
        refreshEntityList();
      }
    },
    [unwrappedEntityIds]
  );
  useDittoEventListener(
    DittoEvents.textItemsDeleted,
    function handleTextItemsDeleted(data) {
      const deletedEntityIds = new Set(data.textItemIds);
      setSelectedEntityId((prev) => {
        if (prev && deletedEntityIds.has(prev)) return null;
        return prev;
      });
      updateEntityList((prev) => filterOutDeletedEntities(prev, deletedEntityIds));
    },
    [setSelectedEntityId]
  );

  const { current: foldersListAtom } = useStableRef(() => {
    return props.getFoldersListAtom ? props.getFoldersListAtom({ selectedFolderIdAtom }) : defaultFoldersListAtom;
  });

  const refreshFoldersListAtom = atom(null, async (get, set, id?: string) => {
    set(foldersListAtom);
    if (id) {
      set(props.listItemFolderFamilyAtom(id), REFRESH_SILENTLY);
    }
  });

  const refreshFoldersList = useSetAtom(refreshFoldersListAtom);

  useDittoEventListener(DittoEvents.libraryComponentFolderCreated, () => refreshFoldersList());
  useDittoEventListener(DittoEvents.libraryComponentFolderUpdated, (data) => refreshFoldersList(data.folderId));
  useDittoEventListener(DittoEvents.libraryComponentFolderReordered, (data) =>
    refreshFoldersList(data.parentFolderId ?? undefined)
  );
  useDittoEventListener(
    DittoEvents.libraryComponentFolderDeleted,
    (data) => {
      // if the deleted folder is the currently selected folder, set the selected folder to the parent folder
      if (data.folderId === selectedFolder?._id) {
        setSelectedFolderId(selectedFolder?.parentId ?? null);
      }

      refreshFoldersList();
    },
    [selectedFolder, setSelectedFolderId]
  );

  // List virtualization
  const { current: virtualizerAtom } = useStableRef(() => atom<Record<string, Virtualizer<Element, Element>>>({}));

  // Hook-internal state
  const searchQuery = useAtomValue(searchQueryAtom);
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useAtom(debouncedSearchQueryAtom);
  const [selectedTags, setSelectedTags] = useAtom(selectedTagsAtom);
  const [selectedStatuses, setSelectedStatuses] = useAtom(selectedStatusesAtom);
  const [selectedProjectId, setSelectedProjectId] = useAtom(selectedProjectIdAtom);

  const enabledFilters: FilterType[] = useMemo(
    () => props.filters ?? ["status", "tags", "usedInProject"],
    [props.filters]
  );

  // MARK: - Legacy Stuff
  const [selectedSuggestedComponentId, setSelectedSuggestedComponentId] = useAtom(selectedSuggestedComponentIdAtom);
  const componentActionClickSetter = useSetAtom(props.itemActionClickAtom);

  const onComponentActionClick = useCallback(
    (componentId: string) => {
      componentActionClickSetter(componentId);
      props.onComponentActionClick?.(componentId);
    },
    [componentActionClickSetter, props]
  );

  const [selectedFilters, setSelectedFilters] = useState<FilterType[]>([]);
  const searchInputRef = useRef<HTMLInputElement>(null);
  const allTags = useAtomValue(props.tagsAtom);

  const inFolder = !!selectedFolder;
  const isSearching = !!debouncedSearchQuery;
  const isFiltering = useMemo(
    () => selectedTags.length > 0 || selectedStatuses.length > 0,
    [selectedTags, selectedStatuses]
  );

  const { current: showFolderLabelsAtom } = useStableRef(() =>
    unwrap(
      atom(async (get) => {
        const folders = await get(foldersListAtom);

        const debouncedSearchQuery = get(debouncedSearchQueryAtom);
        const isSearching = !!debouncedSearchQuery;

        const selectedFolderId = get(selectedFolderIdAtom);
        const inFolder = !!selectedFolderId;

        return isSearching && !inFolder && folders.length > 0;
      }),
      () => false
    )
  );
  const showFolderLabels = useAtomValue(showFolderLabelsAtom);

  const tagsSet = useMemo(() => new Set(allTags), [allTags]);

  const queryTagMatches = useMemo(() => {
    const queryWords = searchQuery.split(" ");
    return queryWords.filter((word) => !selectedTags.includes(word)).filter((word) => tagsSet.has(word));
  }, [searchQuery, tagsSet, selectedTags]);

  const formattedSelectedFilters = useMemo(
    () => selectedFilters.map((filter) => ({ value: filter, label: FilterOptions[filter] })),
    [selectedFilters]
  );

  const headerText = useMemo(() => {
    if (isSearching) {
      return "Search results";
    } else if (inFolder) {
      return selectedFolder?.name ?? "All components";
    } else {
      return "All components";
    }
  }, [isSearching, inFolder, selectedFolder]);

  const handleTagClick = useCallback(
    (tag: string) => {
      // remove the tag from the query
      setDebouncedSearchQuery(searchQuery.replace(new RegExp(`\\s?${tag}\\s?`, "g"), ""));

      // add the tag to the selected tags
      const newTags = !selectedTags.includes(tag) ? [...selectedTags, tag] : selectedTags;
      setSelectedTags(newTags);

      // make sure the tags filter is visible
      setSelectedFilters((prev) => (!prev.includes("tags") ? [...prev, "tags"] : prev));

      setImmediate(() => {
        if (searchInputRef.current) {
          searchInputRef.current.focus();
        }
      });
    },
    [selectedTags, setSelectedTags, setDebouncedSearchQuery, setSelectedFilters, searchQuery]
  );

  const handleClearFilters = useCallback(() => {
    setSelectedFilters([]);
    setSelectedTags([]);
    setSelectedStatuses([]);
    setSelectedProjectId(null);
  }, [setSelectedProjectId, setSelectedStatuses, setSelectedTags]);

  const handleRemoveFilter = useCallback(
    (filter: FilterType) => {
      setSelectedFilters((prev) => prev.filter((f) => f !== filter));

      if (filter === "tags") {
        setSelectedTags([]);
      } else if (filter === "status") {
        setSelectedStatuses([]);
      }
    },
    [setSelectedTags, setSelectedStatuses]
  );

  const handleSetSelectedFilters = useCallback(
    (filters: { value: string; label: string }[]) => {
      setSelectedFilters(filters.map((filter) => filter.value as FilterType));
    },
    [setSelectedFilters]
  );

  const handleFolderClick = useCallback(
    (folder: IFolderEntity) => {
      setSelectedFolderId(folder._id);
      setSelectedEntityId(null);
    },
    [setSelectedFolderId, setSelectedEntityId]
  );

  const handleComponentSelect = useCallback(
    (componentId: string) => {
      if (selectedEntityId === componentId) {
        setSelectedEntityId(null);
      } else {
        setSelectedEntityId(componentId);
      }
      if (selectedSuggestedComponentId) setSelectedSuggestedComponentId(null);
    },
    [selectedEntityId, selectedSuggestedComponentId, setSelectedSuggestedComponentId, setSelectedEntityId]
  );

  const handleSuggestedComponentSelect = useCallback(
    (componentId: string) => {
      setSelectedSuggestedComponentId(componentId);
      if (selectedEntityId) setSelectedEntityId(null);
    },
    [setSelectedSuggestedComponentId, selectedEntityId, setSelectedEntityId]
  );

  const handleBackFolderClick = useCallback(() => {
    setSelectedFolderId(selectedFolder?.parentId ?? null);
  }, [setSelectedFolderId, selectedFolder]);

  const handleComponentDeselect = useCallback(() => {
    setSelectedEntityId(null);
  }, [setSelectedEntityId]);

  const handleSuggestedComponentDeselect = useCallback(() => {
    setSelectedSuggestedComponentId(null);
  }, [setSelectedSuggestedComponentId]);

  const handleTitleBackClick = useAtomCallback(
    useCallback(
      (get: Getter, set: Setter) => {
        if (!props.onTitleBackClickActionAtom) return;
        set(props.onTitleBackClickActionAtom);
      },
      [props.onTitleBackClickActionAtom]
    )
  );

  return {
    selectedFilters,
    searchInputRef,
    inFolder,
    isSearching,
    isFiltering,
    showFolderLabels,
    queryTagMatches,
    headerText,
    formattedSelectedFilters,
    actionText: props.actionText,
    title: props.title,
    extraPaddingEnd: props.extraPaddingEnd,

    handleTitleBackClick,
    handleTagClick,
    handleClearFilters,
    handleRemoveFilter,
    handleSetSelectedFilters,
    handleFolderClick,
    handleComponentSelect,
    handleSuggestedComponentSelect,
    handleBackFolderClick,
    handleComponentDeselect,
    handleSuggestedComponentDeselect,
    onComponentActionClick,

    onItemActionClick: props.onItemActionClick,
    onItemDragEnd: props.onItemDragEnd,

    entityListAtom,
    suggestedComponentIds,
    listItemFolderFamilyAtom: props.listItemFolderFamilyAtom,
    usersByIdAtom: props.usersByIdAtom,
    tagsAtom: props.tagsAtom,
    foldersListAtom,
    currentFolder: selectedFolder,
    searchQuery,
    debouncedSearchQuery,
    onSearchQueryChange: setDebouncedSearchQuery,
    selectedFolderId,
    setSelectedFolderId,
    selectedEntityId,
    setSelectedEntityId,
    selectedSuggestedComponentId,
    setSelectedSuggestedComponentId,
    originalRichText: !props.selectedTextItems
      ? undefined
      : props.selectedTextItems.length === 1
      ? props.selectedTextItems[0].rich_text
      : undefined,
    selectedTags,
    setSelectedTags,
    selectedStatuses,
    setSelectedStatuses,
    selectedProjectId,
    setSelectedProjectId,
    projectsAtom: props.projectsAtom,
    enabledFilters,
    dragAndDrop: props.dragAndDrop,
    libraryComponentFamilyAtom: props.libraryComponentFamilyAtom,
    emptyStates: props.emptyStates,
    virtualizerAtom,
    searchPlaceholder: props.searchPlaceholder,
    libraryComponentFoldersBreadcrumbsAtomFamily: props.libraryComponentFoldersBreadcrumbsAtomFamily,
  };
}

export type IProps = ReturnType<typeof useCompactTextEntityList> & {
  className?: string;
  style?: React.CSSProperties;
};

export const FilterOptions = {
  status: "Status",
  tags: "Tags",
  usedInProject: "Project",
} as const;

export type FilterType = keyof typeof FilterOptions;

function filterOutDeletedEntities(list: StructuredAtoms, deletedEntityIds: Set<string>) {
  return {
    ...list,
    blocks: list.blocks.reduce((acc, block) => {
      const filteredEntities = block.entities.filter((entity) => !deletedEntityIds.has(entity._id));
      if (!filteredEntities.length) return acc;
      return [...acc, { ...block, entities: filteredEntities }];
    }, [] as (typeof list)["blocks"]),
  };
}
