import classNames from "classnames";
import React from "react";
import ToastBase, { IProps as IToastBaseProps } from "../../molecules/ToastBase";
import style from "./index.module.css";

interface IProps extends IToastBaseProps {
  innerClassName?: string;
  onAnimationEnd?: () => void;
  animationDuration?: number;
  isAnimatingOut?: boolean;
}

export function Toast(props: IProps) {
  const { onAnimationEnd, animationDuration = 200, isAnimatingOut, ...rest } = props;

  const _onAnimationEnd = (e: React.AnimationEvent<HTMLDivElement>) => {
    if (e.animationName === style.exit && onAnimationEnd) {
      onAnimationEnd();
    }
  };

  return (
    <div
      className={classNames(style.ToastWrapper, props.className, { [style.exit]: isAnimatingOut })}
      data-testid="toast-component"
      onAnimationEnd={_onAnimationEnd}
      style={{ animationDuration: `${animationDuration}ms`, ...props.style }}
    >
      {!props.content && <ToastBase {...rest} className={props.innerClassName} />}
      {props.content && <>{props.content}</>}
    </div>
  );
}

export default Toast;
