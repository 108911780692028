import React, { useCallback, useEffect, useState } from "react";
import { MergedNotesMetadata } from "../../../../shared/frontend/lib/metadataHelpers";
import TextAreaInput from "../../atoms/TextAreaInput";
import LabelWrapper from "../../molecules/LabelWrapper";
import { InfoIcon } from "./icons";
import style from "./index.module.css";

interface IProps {
  notes: MergedNotesMetadata;
  onChange: (value: string) => void;
  onBlur?: (value: string) => void;
  inputSize: "base" | "small";
  labelHeight: number;
}

function EditNotes(props: IProps) {
  const [localNotes, setLocalNotes] = useState(props.notes);
  const { onChange, onBlur } = props;

  useEffect(
    function updateLocalStateOnPropChange() {
      setLocalNotes(props.notes);
    },
    [props.notes]
  );

  const handleChange = useCallback(
    (value: string) => {
      setLocalNotes(value); // Optimistic UI update
      onChange(value); // Alert the parent of the change
    },
    [onChange]
  );

  const handleOnBlur = useCallback(() => {
    if (typeof localNotes === "string") {
      onBlur?.(localNotes); // Alert the parent of the change
    }
  }, [onBlur, localNotes]);

  const onFocus = useCallback(() => {
    if (typeof localNotes === "object") {
      setLocalNotes("");
    }
  }, [localNotes]);

  return (
    <LabelWrapper
      className={style.labelWrapper}
      labelFlexStart
      labelHeight={props.labelHeight}
      label="Notes"
      leadingIcon={<InfoIcon />}
    >
      <div className={style.inputWidthWrapper}>
        <TextAreaInput
          content={typeof localNotes === "string" ? localNotes : "Mixed notes"}
          placeholder={"Add notes..."}
          size={props.inputSize}
          onChange={handleChange}
          onBlur={handleOnBlur}
          onFocus={onFocus}
          blurOnEnter
        />
      </div>
    </LabelWrapper>
  );
}

export default EditNotes;
