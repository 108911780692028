import { EditorContent } from "@tiptap/react";
import classnames from "classnames";
import React, { useCallback, useState } from "react";
import { IUser } from "../../../../shared/types/User";
import Button from "../../atoms/Button";
import style from "./index.module.css";
import useCommentEditor from "./useCommentEditor";

export type SaveCommentCallback = (
  contentToSave: string,
  mentionedUsersInfo: IUser[],
  resetEditorState: () => void
) => Promise<void>;

interface IProps {
  mentionableUsers: IUser[];
  placeholderText: string;
  submitButtonText: string;
  onChange?: (contentToSave: string, mentionedUsersInfo: IUser[]) => void;
  onCancel?: () => void;
  onSave: SaveCommentCallback;
  shouldAutofocus?: boolean;
  alwaysShowSubmitButtons?: boolean;
  className?: string;
}

export function CommentEditor({
  mentionableUsers,
  placeholderText,
  submitButtonText,
  onChange,
  onCancel,
  onSave,
  shouldAutofocus = false,
  alwaysShowSubmitButtons = false,
  className,
}: IProps) {
  const [isSaving, setIsSaving] = useState(false);
  const [contentToSave, setContentToSave] = useState<string>("");
  const [mentionedUsersInfo, setMentionedUsersInfo] = useState<IUser[]>([]);

  const isSaveDisabled = isSaving || !contentToSave;

  const handleChange = useCallback(
    (content: string, mentionedUsers: IUser[]) => {
      setContentToSave(content);
      setMentionedUsersInfo(mentionedUsers);
      onChange && onChange(content, mentionedUsers);
    },
    [onChange]
  );

  const editor = useCommentEditor({ mentionableUsers, placeholderText, shouldAutofocus, onUpdate: handleChange });

  const resetEditorState = useCallback(() => {
    editor?.commands?.clearContent();
    editor?.commands?.clearNodes();
    editor?.commands?.focus();
    setIsSaving(false);
  }, [editor]);

  const handleCancel = useCallback(() => {
    resetEditorState();
    onCancel && onCancel();
  }, [onCancel, resetEditorState]);

  const handleSubmit = useCallback(async () => {
    if (isSaveDisabled) return;
    setIsSaving(true);
    await onSave(contentToSave, mentionedUsersInfo, resetEditorState);
  }, [isSaveDisabled, contentToSave, mentionedUsersInfo, resetEditorState, onSave]);

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      // Submit on Enter, but not Shift+Enter
      if (event.key === "Enter" && !event.shiftKey) {
        event.preventDefault();
        handleSubmit();
      }
    },
    [handleSubmit]
  );

  return (
    <div className={classnames(style.commentEditorWrapper, className)} data-testid="comment-box">
      <EditorContent
        editor={editor}
        className={style.commentEditorContent}
        onKeyDown={handleKeyDown}
        placeholder={placeholderText}
      />
      {(contentToSave || alwaysShowSubmitButtons) && (
        <div className={style.submitButtonsContainer}>
          <Button size="small" level="secondary" data-testid="discard-comment-button" onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            size="small"
            level="primary"
            data-testid="post-comment-button"
            onClick={handleSubmit}
            disabled={isSaveDisabled}
          >
            {submitButtonText}
          </Button>
        </div>
      )}
    </div>
  );
}

export default CommentEditor;
