import classNames from "classnames";
import React from "react";
import style from "./index.module.css";

import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import { useAtom, WritableAtom } from "jotai";
import { useCallback, useEffect, useState } from "react";
import Button from "../../atoms/Button";
import TextInput from "../../atoms/TextInput";

interface IProps {
  searchAtom: WritableAtom<string | null, [newSearch: string | null], void>;
  className?: string;
  style?: React.CSSProperties;
  placeholder?: string;
}

export function FilterBarSearch(props: IProps) {
  const [searchQuery, setSearchQuery] = useAtom(props.searchAtom);
  const [isSearchActive, setIsSearchActive] = useState(Boolean(searchQuery));

  const resetSearch = useCallback(() => {
    setSearchQuery("");
    setIsSearchActive(false);
  }, [setSearchQuery]);

  const handlePotentialSearchHotkey = useCallback(
    (event: KeyboardEvent) => {
      // metaKey = Cmd on macOS
      // ctrlKey = Ctrl on Windows
      // key 70 = "f"
      // If the user presses Cmd/Ctrl + F, bring up the search bar
      if ((event.metaKey || event.ctrlKey) && event.key === "f") {
        setIsSearchActive((isAlreadySearching) => {
          // If the search bar was already open, allow the browser's default find behavior
          if (!isAlreadySearching) {
            event.preventDefault();
          }
          return true;
        });
      }

      if (event.key === "Escape") {
        resetSearch();
      }
    },
    [resetSearch]
  );

  useEffect(() => {
    window.addEventListener("keydown", handlePotentialSearchHotkey);

    return () => {
      window.removeEventListener("keydown", handlePotentialSearchHotkey);
    };
  }, [handlePotentialSearchHotkey]);

  const setSearchActive = useCallback(() => {
    setIsSearchActive(true);
  }, []);

  const onSearchQueryChange = useCallback(
    (newValue: string) => {
      setSearchQuery(newValue);
    },
    [setSearchQuery]
  );

  if (!isSearchActive) {
    return (
      <Button type="icon" level="subtle" onClick={setSearchActive}>
        <SearchIcon />
      </Button>
    );
  }

  return (
    <TextInput
      style={props.style}
      autoFocus
      placeholder={props.placeholder ?? "Find..."}
      value={searchQuery ?? undefined}
      onChange={onSearchQueryChange}
      trailingIcon={
        <Button type="icon" level="subtle" size="small" onClick={resetSearch}>
          <CloseIcon />
        </Button>
      }
      className={classNames(style.searchInput, props.className)}
      data-testid="filter-bar-search"
    />
  );
}

export default FilterBarSearch;
