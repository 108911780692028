import React from "react";

interface IProps {
  className?: string;
  style?: React.CSSProperties;
}

export function FrameIcon(props: IProps) {
  return (
    <svg width="16" height="16" fill="black" viewBox="0 0 16 16" style={props.style} className={props.className}>
      <path
        fill="var(--color-icon)"
        fillRule="evenodd"
        d="M5.5 3a.5.5 0 0 1 .5.5V5h4V3.5a.5.5 0 0 1 1 0V5h1.5a.5.5 0 0 1 0 1H11v4h1.5a.5.5 0 0 1 0 1H11v1.5a.5.5 0 0 1-1 0V11H6v1.5a.5.5 0 0 1-1 0V11H3.5a.5.5 0 0 1 0-1H5V6H3.5a.5.5 0 0 1 0-1H5V3.5a.5.5 0 0 1 .5-.5m4.5 7V6H6v4z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default FrameIcon;
