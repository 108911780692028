import { useEffect, useState } from "react";
import useLocalStorage from "react-use/lib/useLocalStorage";

export interface VariantFolder {
  _id: string;
  name: string;
  variantIds: string[];
}

type FetchVariantFoldersType = () => Promise<VariantFolder[]>;

interface VariantSelectOptions {
  key?: string;
  defaultFolder?: VariantFolder;
  showUsedInProjectOption?: boolean;
  showAllVariantsOption?: boolean;
}

export const ALL_VARIANTS_FOLDER_ID = "all-variants";
export const IN_THIS_PROJECT_FOLDER_ID = "in-this-project";

const allVariantsOption = {
  _id: ALL_VARIANTS_FOLDER_ID,
  name: "All Variants",
  variantIds: [],
};
const inThisProjectOption = {
  _id: IN_THIS_PROJECT_FOLDER_ID,
  name: "Used in this project",
  variantIds: [],
};

export const useVariantSelect = (fetchVariantFolders: FetchVariantFoldersType, options?: VariantSelectOptions) => {
  const [selectedFolder, setSelectedFolder] = useLocalStorage<VariantFolder>(
    options?.key ?? "variant-select",
    allVariantsOption
  );

  let initialFolders: VariantFolder[] = [];

  if (options?.showAllVariantsOption) {
    initialFolders.push(allVariantsOption);
  }

  if (options?.showUsedInProjectOption) {
    initialFolders.push(inThisProjectOption);
  }

  const [folders, setFolders] = useState<VariantFolder[]>(initialFolders);
  const [loadingFolders, setLoadingFolders] = useState<boolean>(true);

  // yes, it sucks that this is a useEffect, but it's the only way to get the
  // default folder to be selected on mount if one has already been persisted to storage.
  useEffect(
    function overrideDefaultFolderOnMount() {
      if (options?.defaultFolder) {
        setSelectedFolder(options.defaultFolder);
      }
    },
    [options?.defaultFolder]
  );

  const getVariantFolders = async () => {
    try {
      setLoadingFolders(true);
      const fetchedFolders = await fetchVariantFolders();
      setFolders([...initialFolders, ...fetchedFolders]);
      if (!options?.showAllVariantsOption) {
        setSelectedFolder(fetchedFolders[0]);
      }
    } catch (error) {
      console.error("Error fetching variant folders", error);
    } finally {
      setLoadingFolders(false);
    }
  };

  const handleSelect = (id: string) => {
    const folder = folders.find((option) => option._id === id);
    if (folder) {
      setSelectedFolder(folder);
    }

    window.analytics.track("Filtered By Variant Folder", {
      folderId: id,
      folderName: folder?.name,
      application: "web_app",
      version: "legacy",
    });
  };

  const handleDeleteFolder = async () => {
    await getVariantFolders();
    if (options?.showAllVariantsOption || folders.length === 0) {
      setSelectedFolder(allVariantsOption);
    } else {
      setSelectedFolder(folders[0]);
    }
  };

  useEffect(function fetchVariantFoldersOnMount() {
    getVariantFolders();
  }, []);

  return {
    selectedVariantFolder: selectedFolder || allVariantsOption,
    folders,
    onChange: handleSelect,
    onDeleteFolder: handleDeleteFolder,
  };
};
