import useRedirectToNSPage from "@/hooks/useRedirectToNSPage";
import parseFigmaFileURL from "@shared/common/parseFigmaFileURL";
import * as DittoEvents from "@shared/ditto-events";
import { useDittoEventListener } from "@shared/ditto-events/frontend";
import client from "@shared/frontend/http/httpClient";
import * as SegmentEvents from "@shared/segment-event-names";
import {
  CLICKED_GO_TO_PROJECT_OF_IMPORTED_FILE,
  GET_PAGES_ERROR_GENERIC,
  IMPORT_FILE_ERROR,
  IMPORT_FILE_STARTED,
  IMPORT_FILE_SUCCESS,
} from "@shared/segment-event-names";
import { FrameIdsByPageId } from "@shared/types/figma";
import logger from "@shared/utils/logger";
import { useCallback, useEffect, useRef, useState } from "react";
import { ActualDocumentSchema } from "../../../shared/types/ActualDocument";
import { routes } from "../../defs";
import useSegment from "../../hooks/useSegment";
import http, { API } from "../../http";
import { pollingBackgroundJobRequest } from "../../http/lib/clientHelpers";
import { Props } from "./index";

export type FilePage = { id: string; name: string };
export type SelectedPage = {
  id: string;
  name: string;
  isSelected: boolean;
};
export type FramesByPage = {
  [pageId: string]: SelectedPage[];
};
export type FileData = {
  groupsByPage: FramesByPage;
  name: string;
  pageInfoByGroupId: any; //unused
  pages: FilePage[];
};
export enum ImportStep {
  PROJECT_TYPE = 0,
  DRAFT_PROJECT = 1,
  EXISTING_PROJECT = 2,
  SELECT_FRAMES = 3,
  BRANCH_CONFIRM = 4,
  NEW_DITTO_PROJECT = 5,
}
export type ImportError =
  | "unauthenticated"
  | "invalid_url"
  | "rate_limit"
  | "large_images"
  | "file_exists_diff_ws"
  | "branch_file_deleted"
  | "other_branch_imported"
  | "other_branch_imported_v2"
  | "generic"
  | "enable_adv_branching_error"
  | "sample_project_already_exists"
  | "sample_project_not_imported"
  | "imported_wrong_sample_project"
  | "";

export interface HandleSelectFrameArgs {
  pageId: string;
  frameId: string;
  isShiftClick: boolean;
}

type ProjectNames = {
  [name: string]: boolean;
};
const { url: importJobURL } = API.jobs.get.pollImport;

const useImport = (props: Props) => {
  const segment = useSegment();

  const { hasFigmaConnected, folder } = props;

  const [importStep, setImportStep] = useState<ImportStep>(props.initialStep ?? ImportStep.PROJECT_TYPE);
  const [linkInput, setLinkInput] = useState<string>("");
  const [figmaFileId, setFigmaFileId] = useState<string | undefined>("");
  const [figmaBranchId, setFigmaBranchId] = useState<string | undefined>("");
  const [filePages, setFilePages] = useState<FilePage[]>([]); // all pages in file
  const [selectedFrames, setSelectedFrames] = useState<string[]>([]);
  const [selectedFramesByPage, setSelectedFramesByPage] = useState<FramesByPage>({});
  const [originalFileData, setOriginalFileData] = useState<FileData | null>(null);
  const [importError, setImportError] = useState<ImportError>("");
  const [isImportLoading, setIsImportLoading] = useState<boolean>(false);
  const [isGetPagesLoading, setIsGetPagesLoading] = useState<boolean>(false);
  const [docID, setDocID] = useState<string>("");
  const [draftName, setDraftName] = useState<string>("");
  const [projectName, setProjectName] = useState<string>("");
  const [branchImportConfirmed, setBranchImportConfirmed] = useState<boolean>(false);
  const [mainProject, setMainProject] = useState<{
    _id: string;
    name: string;
    figma_url: string;
  } | null>(null);
  const [showSampleImportMessage, setShowSampleImportMessage] = useState<boolean>(false);

  const redirectToNSPage = useRedirectToNSPage();

  // Two variables are used to track import progress
  // importProgress is what is shown and is gradually increased to currentMaxProgress so we have a clean increasing animation
  // currentMaxProgress is where the progress is actually at
  const [importProgress, setImportProgress] = useState(0);
  const currentMaxProgress = useRef(0);

  useDittoEventListener(
    DittoEvents.importProgress,
    (data) => {
      currentMaxProgress.current = getProgressStepValue(data.step);
    },
    []
  );

  const getProgressStepValue = (newStep: (typeof DittoEvents.ImportProgressSteps)[number]) => {
    const stepIdx = DittoEvents.ImportProgressSteps.findIndex((step) => step === newStep) + 1;
    return Math.ceil((stepIdx / DittoEvents.ImportProgressSteps.length) * 100);
  };

  // A recursive function that increases the percentage gradually to the current progress number
  // We do this so the progress animation is smooth
  const updateImportProgress = () => {
    setImportProgress((prev) => {
      if (prev < currentMaxProgress.current) {
        return prev + 1;
      }
      return prev;
    });
    // currentMaxProgress.current would be 0 if the loading state was reset because of an error
    if (currentMaxProgress.current !== 0) {
      setTimeout(updateImportProgress, 5);
    }
  };

  useEffect(() => {
    const isFigmaImportStep = importStep !== ImportStep.PROJECT_TYPE && importStep !== ImportStep.DRAFT_PROJECT;
    if (isFigmaImportStep) {
      if (!hasFigmaConnected) {
        setImportError("unauthenticated");
      } else if (linkInput === "") {
        setImportError("");
      } else {
        const { fileId, branchId } = parseFigmaFileURL(linkInput);

        if (!fileId) setImportError("invalid_url");
        if (branchImportConfirmed) {
          setBranchImportConfirmed(false);
        } else {
          setImportError("");
        }
      }
    }
  }, [importStep, linkInput]);

  const pollForResponse = async (jobId: string, interval: number) => {
    const response = await http.get(importJobURL(jobId));
    let result = response.data;
    const { state } = result;

    if (response?.status === 200 && !["failed", "completed"].includes(state)) {
      await new Promise((res) => setTimeout(res, interval));
      result = await pollForResponse(jobId, interval);
    }

    // Show the user that the progress is done
    currentMaxProgress.current = getProgressStepValue("finished");
    // Wait half a second so the user sees 100% progress
    await new Promise((res) => setTimeout(res, 500));
    return result;
  };

  const toggleBranchImportConfirmed = (e: React.ChangeEvent<HTMLInputElement>) =>
    setBranchImportConfirmed(e.target.checked);

  const onConfirmBranchImport = async (e: React.FormEvent) => {
    setIsGetPagesLoading(true);
    await getPages(e);
  };

  const importFile = async () => {
    setIsImportLoading(true);
    // start the progress loader
    currentMaxProgress.current = getProgressStepValue("frontend-start");
    updateImportProgress();
    const selectedPageIds: string[] = [];
    const selectedPageInfo: { figma_id: string; name: string }[] = [];

    Object.keys(selectedFramesByPage).forEach((pageId) => {
      if (selectedFramesByPage[pageId].filter((frame) => frame.isSelected).length > 0) {
        selectedPageIds.push(pageId);
      }
    });

    originalFileData?.pages.forEach((page) => {
      if (selectedPageIds.includes(page.id)) {
        selectedPageInfo.push({ figma_id: page.id, name: page.name });
      }
    });

    const { url, body } = API.jobs.post.imports;

    const frameIdsByPageId: FrameIdsByPageId = {};
    Object.entries(selectedFramesByPage).forEach(([pageId, pageFrames]) => {
      pageFrames.forEach((frame) => {
        if (!frame.isSelected) return;
        frameIdsByPageId[pageId] ??= [];
        frameIdsByPageId[pageId].push(frame.id);
      });
    });

    const reqBody = body({
      folder_id: folder?._id,
      figma_file_ID: figmaFileId,
      figma_branch_id: figmaBranchId,
      selected_pages: selectedPageInfo,
      selected_frames: selectedFrames,
      frameIdsByPageId,
    });
    segment.track({
      event: IMPORT_FILE_STARTED,
      properties: {
        fileId: figmaFileId,
        selectedPagesCount: selectedPageInfo.length,
        selectedFramesCount: selectedFrames.length,
      },
    });
    if (figmaFileId === "") {
      setIsImportLoading(false);
      return;
    }
    try {
      const { data: response } = await http.post(url, reqBody);
      currentMaxProgress.current = getProgressStepValue("request-sent");
      if (response.errorStatus) {
        setIsImportLoading(false);
        currentMaxProgress.current = 0;
        switch (response.errorStatus) {
          case 404:
            setImportError("invalid_url");
            segment.track({
              event: IMPORT_FILE_ERROR,
              properties: {
                error: "invalid_url",
                fileId: reqBody.figma_file_ID,
              },
            });
            break;
          case 403:
            setImportError("unauthenticated");
            segment.track({
              event: IMPORT_FILE_ERROR,
              properties: {
                error: "unauthenticated",
                fileId: reqBody.figma_file_ID,
              },
            });
            break;
          case 429:
            setImportError("rate_limit");
            segment.track({
              event: IMPORT_FILE_ERROR,
              properties: {
                error: "rate_limit",
                fileId: reqBody.figma_file_ID,
              },
            });
            break;
          default:
          case 500:
            setImportError("large_images");
            segment.track({
              event: IMPORT_FILE_ERROR,
              properties: {
                error: "large_images",
                fileId: reqBody.figma_file_ID,
              },
            });
            break;
        }
      } else if (response.existsInDiffWS) {
        setImportError("file_exists_diff_ws");
        segment.track({
          event: IMPORT_FILE_ERROR,
          properties: {
            error: "file_exists_diff_ws",
            fileId: reqBody.figma_file_ID,
          },
        });
        setIsImportLoading(false);
        currentMaxProgress.current = 0;
      } else if (response.exists) {
        const importErrorMessage = response.import_error_message;

        // Note: Remove this 'if' statement after branching is deployed
        if (importErrorMessage === "other_branch_imported" && response?.advancedBranchingEnabled) {
          setImportError("other_branch_imported_v2");
        } else {
          setImportError(importErrorMessage);
        }
        segment.track({
          event: IMPORT_FILE_ERROR,
          properties: {
            error: importErrorMessage,
            fileId: reqBody.figma_file_ID,
          },
        });
        setDocID(response.doc_id);
        setIsImportLoading(false);
        currentMaxProgress.current = 0;
      } else {
        const job = response;
        await pollForResponse(job.id, 5000);
        setDocID(job.docId);
        setIsImportLoading(false);
        segment.track({
          event: IMPORT_FILE_SUCCESS,
        });
        goToProject(job.docId, figmaBranchId);
      }
    } catch (error) {
      segment.track({
        event: IMPORT_FILE_ERROR,
        properties: {
          error: JSON.stringify(error),
          fileId: reqBody.figma_file_ID,
        },
      });
      setImportError("generic");
      console.error("error doing importFile() in importmodal.jsx: ", error.message);
    } finally {
      setIsImportLoading(false);
    }
  };

  const handleEnableAdvancedBranching = async () => {
    try {
      const { url } = API.workspace.post.enableAdvancedBranching;
      await http.post(url, {});
      setImportError("");
    } catch (error) {
      setImportError("enable_adv_branching_error");
      console.error("Error doing 'handleEnableAdvancedBranching'", error);
    }
  };
  const checkFigmaFileExists = async () => {
    try {
      let canImport = false;
      const { fileId, branchId } = parseFigmaFileURL(linkInput);
      if (!fileId) {
        setImportError("invalid_url");
        return canImport;
      }

      const { url } = API.api.get.checkFileIDExists;
      const { data: response } = await http.get(url(fileId, branchId));
      if (response.errorStatus) {
        switch (response.errorStatus) {
          case 403:
            setImportError("unauthenticated");
            break;
          case 404:
            if (branchId) setImportError("branch_file_deleted");
            else setImportError("invalid_url");
            break;
          case 429:
            setImportError("rate_limit");
            break;
          case 500:
            setImportError("large_images");
            break;
          default:
            setImportError("generic");
            break;
        }
      } else if (response.existsInDiffWS) {
        setImportError("file_exists_diff_ws");
      } else if (response.exists) {
        const importErrorMessage = response?.branch_file_status;
        // Note: Remove this 'if' statement after branching is deployed
        if (importErrorMessage === "other_branch_imported" && response?.advancedBranchingEnabled) {
          setImportError("other_branch_imported_v2");
        } else {
          setImportError(importErrorMessage);
        }

        setDocID(response.id);
      } else {
        canImport = true;
      }
      const shouldShowBranchImport =
        response?.branch_file_status === "main_branch_imported" &&
        response?.existing_main_branch &&
        !branchImportConfirmed &&
        branchId;
      if (shouldShowBranchImport) {
        setMainProject(response.existing_main_branch);
        setIsGetPagesLoading(false);
        setImportStep(ImportStep.BRANCH_CONFIRM);
        return;
      }
      return canImport;
    } catch (error) {
      console.error("Error checking if figma file exists: ", error);
      return false;
    }
  };
  const getPages = useCallback(
    async (e: React.FormEvent) => {
      e.preventDefault();
      setIsGetPagesLoading(true);
      const canImportFile = await checkFigmaFileExists();
      if (!canImportFile) {
        setIsGetPagesLoading(false);
        return;
      }
      const { fileId, branchId } = parseFigmaFileURL(linkInput);

      setFigmaFileId(fileId);
      setFigmaBranchId(branchId);

      let fetchPagesSuccessfull = false;
      try {
        const response = await pollingBackgroundJobRequest<any>({
          url: `/jobs/figmaGetPages`,
          requestBody: {
            figmaFileId: fileId,
            figmaBranchId: branchId,
            onImport: true,
          },
        });
        setIsGetPagesLoading(false);
        if (response.errorStatus) {
          switch (response.errorStatus) {
            case 403:
              setImportError("unauthenticated");
              break;
            case 404:
              if (branchId) setImportError("branch_file_deleted");
              else setImportError("invalid_url");
              break;
            case 429:
              setImportError("rate_limit");
              break;
            case 500:
              setImportError("large_images");
              break;
            default:
              setImportError("generic");
              break;
          }
        } else if (response.existsInDiffWS) {
          setImportError("file_exists_diff_ws");
          setIsImportLoading(false);
        } else if (response.exists) {
          const importErrorMessage = response.import_error_message;
          setImportError(importErrorMessage);
          setDocID(response.doc_id);
          setIsImportLoading(false);
        }
        // the user is trying to import the sample project
        else if (response.status === "is-sample-project") {
          let sampleProjectInWorkspace: ActualDocumentSchema | null;
          const { data } = await http.get("/api/projects/sample");
          sampleProjectInWorkspace = data;

          setShowSampleImportMessage(true);

          // 1. the user imported the sample project, and they already have the sample project
          //    in their workspace -- just show them a link to the sample project
          if (sampleProjectInWorkspace) {
            // if the project is not yet activated, activate it
            if (sampleProjectInWorkspace.integrations.figma.file_id === "sampleProject") {
              await http.post(`doc/setFileId/${sampleProjectInWorkspace._id}`, {
                fileId,
              });
              segment.track({
                event: SegmentEvents.SAMPLE_PROJECT_ACTIVATED,
              });
            }

            setDocID(sampleProjectInWorkspace?._id.toString());
          }

          // 2. the user tried to import the un-duplicated sample project - bad!
          else if (response?.isOriginalSampleProject) {
            setImportError("imported_wrong_sample_project");
          }

          // 3. the user imported the sample project and they have no sample data,
          //    so we need to create the sample data for them
          else {
            // hit backend to create sample data
            const sampleRes = await http.put("/workspace/createSampleData", {
              figmaFileId: fileId,
            });
            segment.track({
              event: "Sample Project Activated",
            });
            setDocID(sampleRes.data.ids.projectId);
          }
        } else {
          // print pages fetched
          fetchPagesSuccessfull = true;
          let temp = response.groupsByPage;
          Object.keys(temp).forEach((pageId) => {
            temp[pageId] = temp[pageId].map((frame) => {
              return { ...frame, isSelected: false };
            });
          });
          setSelectedFramesByPage(temp);
          setOriginalFileData(response);
          setFilePages(response.pages);
          setImportStep(ImportStep.SELECT_FRAMES);
        }
      } catch (error) {
        console.error("caught error doing /getPages in importmodal.jsx: ", error);
        setIsGetPagesLoading(false);
        setImportError("generic");
        segment.track({
          event: GET_PAGES_ERROR_GENERIC,
          properties: {
            message: JSON.stringify(error),
            fileId: figmaFileId,
          },
        });
      } finally {
        if (!fetchPagesSuccessfull && importStep === ImportStep.BRANCH_CONFIRM) {
          setImportStep(ImportStep.EXISTING_PROJECT);
        }
      }
    },
    [mainProject, linkInput, branchImportConfirmed, hasFigmaConnected]
  );

  const handleLinkChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setLinkInput(e.target.value);
  };

  const goToAccountUser = () => {
    redirectToNSPage("/account/user");
  };

  const previousFrameClick = useRef<{ pageId: string; frameId: string; index: number } | null>(null);

  const handleSelectFrame = (args: HandleSelectFrameArgs) => {
    const { pageId, frameId, isShiftClick } = args;
    setSelectedFramesByPage((prev) => {
      let temp = prev;
      let frameIndex = temp[pageId].findIndex((frame) => frame.id === frameId);

      if (isShiftClick && previousFrameClick.current?.pageId === pageId) {
        const startIndex =
          frameIndex < previousFrameClick.current.index ? frameIndex : previousFrameClick.current.index;
        const endIndex = frameIndex > previousFrameClick.current.index ? frameIndex : previousFrameClick.current.index;

        for (let i = startIndex; i <= endIndex; i++) {
          temp[pageId][i].isSelected = true;
        }

        const frameIdsToSelect = temp[pageId].slice(startIndex, endIndex + 1).map((frame) => frame.id);
        // We have to do this to make sure duplicated don't get added to selectedFrames
        const newFramesToSelect = frameIdsToSelect.filter((id) => !selectedFrames.includes(id));
        setSelectedFrames((prev) => prev.concat(newFramesToSelect));
      } else {
        temp[pageId][frameIndex].isSelected = !temp[pageId][frameIndex].isSelected;
        if (!selectedFrames.includes(frameId)) {
          setSelectedFrames((prev) => prev.concat(frameId));
        } else if (!temp[pageId][frameIndex].isSelected) {
          setSelectedFrames((prev) => prev.filter((frame) => frame !== frameId));
        }
      }

      // Set previousFrameClick if click was to select, otherwise set it to null
      if (temp[pageId][frameIndex].isSelected) {
        previousFrameClick.current = { pageId, frameId, index: frameIndex };
      } else {
        previousFrameClick.current = null;
      }

      return temp;
    });
  };

  // Sets all frames on a page to selected or unselected, based on param
  const handleToggleAllSelectedForPage = (pageId: string, selected: boolean) => {
    setSelectedFramesByPage((prev) => {
      const temp = { ...prev };
      temp[pageId].forEach((frame) => (frame.isSelected = selected));
      const frameIds = temp[pageId].map((frame) => frame.id);
      if (selected) {
        setSelectedFrames((prev) => prev.concat(frameIds));
      } else {
        setSelectedFrames((prev) => prev.filter((frame) => !frameIds.includes(frame)));
      }
      return temp;
    });
  };

  function goToProject(id: string, branchId?: string | null) {
    segment.track({
      event: CLICKED_GO_TO_PROJECT_OF_IMPORTED_FILE,
      properties: {
        docId: id,
        branchId,
      },
    });
    if (!branchId) {
      redirectToNSPage(routes.nonNavRoutes.project.getPath(id));
    } else {
      redirectToNSPage(routes.nonNavRoutes.branchProject.getPath(id, branchId));
    }
  }

  const createDraftProject = async (e) => {
    e.preventDefault();
    setIsImportLoading(true);
    const { url, body } = API.project.post.create;
    http
      .post(url, body({ projectName: draftName, folderId: folder ? folder._id : null }))
      .then((res) => goToProject(res?.data?._id))
      .catch(({ response }) => {
        console.error("Error creating new draft project", response);
        setIsImportLoading(false);
      });
  };

  async function createDittoProject() {
    setIsImportLoading(true);
    try {
      const response = await client.dittoProject.createDittoProject({ projectName, folderId: folder?._id });
      redirectToNSPage(routes.nonNavRoutes.projectBeta.getPath(response._id));
    } catch (error) {
      logger.error("Error creating new ditto project", { context: { projectName } }, error);
      setIsImportLoading(false);
    }
  }
  const handleDraftNameChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const name = e.target.value;
    setDraftName(name);
  };
  const goToSelectedDoc = () => goToProject(docID);
  const onCreateNSProject = () => setImportStep(ImportStep.NEW_DITTO_PROJECT);
  const onDraftNewProject = () => setImportStep(ImportStep.DRAFT_PROJECT);
  const onImportExistingFile = () => {
    setImportStep(ImportStep.EXISTING_PROJECT);
  };

  const goBackToStart = () => {
    setImportError("");
    setImportStep(ImportStep.PROJECT_TYPE);
  };

  return {
    filePages,
    originalFileData,
    selectedFrames,
    selectedFramesByPage,
    handleSelectFrame,
    importFile,
    isImportLoading,
    importError,
    importStep,
    linkInput,
    handleLinkChange,
    goToAccountUser,
    onCreateNSProject,
    onDraftNewProject,
    onImportExistingFile,
    getPages,
    isGetPagesLoading,
    goToSelectedDoc,
    docID,
    draftName,
    goBackToStart,
    createDraftProject,
    handleDraftNameChange,
    branchImportConfirmed,
    toggleBranchImportConfirmed,
    onConfirmBranchImport,
    mainProject,
    handleEnableAdvancedBranching,
    showSampleImportMessage,
    importProgress,
    handleToggleAllSelectedForPage,
    projectName,
    setProjectName,
    createDittoProject,
  };
};

export { useImport };
