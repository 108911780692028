import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import classNames from "classnames";
import React, { useMemo } from "react";
import TimeAgo from "react-timeago";
import { IComment } from "../../../../shared/types/CommentThread";
import { IUser } from "../../../../shared/types/User";
import { getNoSecondsFormatter } from "../../../../shared/utils/timeAgoFormatters";
import Avatar from "../../atoms/Avatar";
import Button from "../../atoms/Button";
import Dot from "../../atoms/Dot";
import Text from "../../atoms/Text";
import { getRenderedLinks } from "../../atoms/URLLink/useRenderedLinks";
import style from "./index.module.css";

export type ICommentDisplayUser = Pick<IUser, "name" | "picture" | "userId">;

interface IProps {
  className?: string;
  comment: IComment;
  isResolved: boolean; // Applies to the entire thread
  onResolve?: () => void; // Will only display resolve UI if this is provided
  mentionableUsersByUserId: Record<string, ICommentDisplayUser>;
}

const MENTION_EXPRESSION = /<@(.+?)>/g;
const generateCommentJsx = (rawComment: string, users: IProps["mentionableUsersByUserId"]): React.ReactNode[] => {
  const parts: string[] = rawComment.split(MENTION_EXPRESSION);

  const substitutedParts: React.ReactNode[] = JSON.parse(JSON.stringify(parts));

  // start at 1 and iterate by 2 to skip non-mention content
  // we split by mention expression, so every odd-indexed part is a mention
  for (let i = 0; i < parts.length; i++) {
    if (i % 2 == 0) {
      // for non-mention content, parse for links
      substitutedParts[i] = getRenderedLinks(parts[i], "action");
    } else {
      // otherwise, substitute mentioned username
      const name = getNameById(parts[i], users);
      substitutedParts[i] = (
        <span className={style.mention} key={`comment-${name}-${i}`}>
          {"@" + name}
        </span>
      );
    }
  }

  return substitutedParts;
};

const getNameById = (userId: string, users: Record<string, ICommentDisplayUser>) => {
  if (userId.startsWith("sample_")) {
    return `${userId.replace("sample_", "")}`;
  }

  const user = users[userId];

  if (user) {
    return user.name;
  }

  return "[removed user]";
};

export function Comment({ className, comment, isResolved, onResolve, mentionableUsersByUserId }: IProps) {
  const userName = comment.user_name;

  const user = useMemo(() => {
    return mentionableUsersByUserId[comment.user_id];
  }, [comment.user_id, mentionableUsersByUserId]);

  const commentJsx = useMemo(
    () => generateCommentJsx(comment.text, mentionableUsersByUserId),
    [comment.text, mentionableUsersByUserId]
  );

  if (!user) {
    return <></>;
  }

  return (
    <div className={classNames(style.CommentWrapper, className)}>
      <div className={style.commentHeader}>
        <Avatar
          size="sm"
          picture={user.picture}
          userName={userName}
          style={isResolved ? { filter: "grayscale(100%)" } : undefined}
        />
        <div className={style.commentHeaderDetails}>
          <Text size="small" weight="medium" color={isResolved ? "secondary" : "primary"}>
            {userName}
          </Text>
          <Dot size={2} color="icon-secondary" />
          <TimeAgo
            className={style.date}
            date={comment.createdAt}
            minPeriod={30}
            formatter={getNoSecondsFormatter("< 1 min ago", true)}
          />
        </div>
        {!!onResolve && <ResolveButton isResolved={isResolved} onResolve={onResolve} />}
      </div>
      <div className={style.commentText}>{commentJsx}</div>
    </div>
  );
}

function ResolveButton({ isResolved, onResolve }: Pick<IProps, "isResolved" | "onResolve">) {
  if (isResolved) {
    return (
      <Button level="subtle" size="small" type="icon" iconColor="primary" onClick={onResolve}>
        <CheckCircleIcon className={style.resolveIcon} />
      </Button>
    );
  }

  return (
    <Button level="subtle" size="small" type="icon" iconColor="minimal" onClick={onResolve}>
      <CheckCircleOutlineIcon className={style.resolveIcon} />
    </Button>
  );
}

export default Comment;
