import { useRef } from "react";

/**
 * Creates a ref object that never initializes more than once.
 *
 * ------
 *
 * This code will cause `someFunction()` to run on every render:
 * ```ts
 * const someValue = useRef(someFunction());
 * ```
 *
 * In contrast, by using this hook, `someFunction()` will only run once on mount:
 * ```ts
 * const someValue = useStableRef(() => someFunction());
 * ```
 *
 * @param getDefaultValue A function which returns a default value for the ref
 * @returns A mutable ref
 */
export default function useStableRef<T>(getDefaultValue: () => T) {
  const stableValue = useRef<T | null>(null);
  const stableValueWasSet = useRef(false);
  if (!stableValueWasSet.current) {
    stableValue.current = getDefaultValue();
    stableValueWasSet.current = true;
  }
  return stableValue as React.MutableRefObject<T>;
}
