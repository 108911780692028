import {
  fetchNextProjectCommentsPageActionAtom,
  hasMoreProjectCommentsAtom,
  projectCommentsAtom,
  projectCommentsLoadingAtom,
} from "@/stores/Comments";
import CommentThreadWrapper from "@/views/NS/components/CommentThreadWrapper";
import LoadMore from "@ds/atoms/LoadMore";
import PanelEmptyState from "@ds/molecules/PanelEmptyState";
import Scrollbar from "@ds/molecules/Scrollbar";
import { useAtomValue, useSetAtom } from "jotai";
import React from "react";
import style from "./style.module.css";

function CommentsPanel() {
  const allComments = useAtomValue(projectCommentsAtom);
  const isLoadingMore = useAtomValue(projectCommentsLoadingAtom);
  const hasMore = useAtomValue(hasMoreProjectCommentsAtom);
  const fetchMore = useSetAtom(fetchNextProjectCommentsPageActionAtom);

  if (!allComments.length) {
    return (
      <PanelEmptyState
        data-testid="comments-panel"
        header="No comments"
        description="Comments and suggested edits will appear here"
      />
    );
  }

  return (
    <Scrollbar className={style.scrollArea} data-test-id="comments-panel">
      {allComments.map((commentThreadId, index) => (
        <React.Fragment key={commentThreadId}>
          {index !== 0 && <hr className={style.divider} />}
          <CommentThreadWrapper className={style.commentThread} commentThreadId={commentThreadId} showGoToText />
        </React.Fragment>
      ))}
      <LoadMore hasMore={hasMore} isLoadingMore={isLoadingMore} fetchMore={fetchMore} />
    </Scrollbar>
  );
}

export default CommentsPanel;
