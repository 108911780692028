import { isDiffRichText } from "@shared/lib/text";
import { ILibraryComponentFolder } from "@shared/types/LibraryComponentFolder";
import classNames from "classnames";
import { Atom, atom, useAtomValue } from "jotai";
import React, { Suspense, useCallback, useMemo, useState } from "react";
import Skeleton from "react-loading-skeleton";
import Button from "../../atoms/Button";
import LoadingIcon from "../../atoms/LoadingIcon";
import Text from "../../atoms/Text";
import TextInput from "../../atoms/TextInput";
import VirtualizedList from "../../atoms/VirtualizedList";
import ActionableTextEntity from "../../molecules/ActionableTextEntity";
import ComponentFolderNavButton from "../../molecules/ComponentFolderNavButton";
import FolderBreadcrumbsLabel from "../../molecules/FolderBreadcrumbsLabel";
import TagSearchSuggestion from "../../molecules/TagSearchSuggestion";
import TextItemBlock from "../../molecules/TextItemBlock";
import ComponentTextMatchSuggestions from "../ComponentTextMatchSuggestions";
import FiltersArea from "./FiltersArea";
import style from "./index.module.css";
import {
  EmptyStates,
  IFolderEntity,
  IProps,
  ITextEntity,
  StructuredAtomBlock,
  useCompactTextEntityList,
} from "./useCompactTextEntityList";

// This is the subset of IProps that need to get passed down to TruncatedBlock and Entity
type PassthroughProps = Pick<
  IProps,
  | "className"
  | "actionText"
  | "selectedEntityId"
  | "onComponentActionClick"
  | "handleComponentSelect"
  | "handleComponentDeselect"
  | "originalRichText"
  | "dragAndDrop"
  | "showFolderLabels"
  | "foldersListAtom"
  | "onItemActionClick"
  | "onItemDragEnd"
  | "libraryComponentFoldersBreadcrumbsAtomFamily"
>;

/**
 * Intended to be used alongside the `useCompactTextEntityList` hook.
 *
 * Pass in all the atoms you've created for the component, spread the props, and it will
 * handle the rest.
 */
export function CompactTextEntityList(props: IProps) {
  return (
    <div
      style={props.style}
      className={classNames(style.listWrapper, props.className)}
      data-testid="compact-library-component-list"
    >
      <TextInput
        inputRef={props.searchInputRef}
        autoFocus
        className={style.searchInput}
        placeholder={props.searchPlaceholder ?? "Search..."}
        value={props.searchQuery}
        onChange={props.onSearchQueryChange}
      />

      {props.queryTagMatches.length > 0 && (
        <div className={style.tagSuggestionWrapper}>
          <TagSearchSuggestion tags={props.queryTagMatches} onTagClick={props.handleTagClick} maxSuggestions={1} />
        </div>
      )}

      {props.suggestedComponentIds.length > 0 && (
        <div className={style.suggestedComponentsWrapper}>
          <ComponentTextMatchSuggestions
            libraryComponentFamilyAtom={props.libraryComponentFamilyAtom}
            componentIds={props.suggestedComponentIds}
            usersByIdAtom={props.usersByIdAtom}
            selectedComponentId={props.selectedSuggestedComponentId}
            onComponentSelect={props.handleSuggestedComponentSelect}
            onComponentDeselect={props.handleSuggestedComponentDeselect}
            onComponentActionClick={props.onComponentActionClick}
            originalRichText={props.originalRichText}
          />
        </div>
      )}

      <FiltersArea
        enabledFilters={props.enabledFilters}
        selectedTags={props.selectedTags}
        setSelectedTags={props.setSelectedTags}
        tagsAtom={props.tagsAtom}
        selectedStatuses={props.selectedStatuses}
        setSelectedStatuses={props.setSelectedStatuses}
        selectedProjectId={props.selectedProjectId}
        setSelectedProjectId={props.setSelectedProjectId}
        projectsAtom={props.projectsAtom}
        inFolder={props.inFolder}
        selectedFilters={props.selectedFilters}
        handleClearFilters={props.handleClearFilters}
        handleBackFolderClick={props.handleBackFolderClick}
        headerText={props.headerText}
        formattedSelectedFilters={props.formattedSelectedFilters}
        handleSetSelectedFilters={props.handleSetSelectedFilters}
        handleRemoveFilter={props.handleRemoveFilter}
        title={props.title}
        handleTitleBackClick={props.handleTitleBackClick}
      />

      <Suspense
        fallback={
          <div className={style.loading}>
            <LoadingIcon />
            <Text size="small" color="secondary">
              Loading...
            </Text>
          </div>
        }
      >
        <InnerList {...props} />
      </Suspense>
    </div>
  );
}

type FolderItem = { type: "folder"; id: string; folder: IFolderEntity };
type ListItemEntity = {
  type: "entity";
  id: string;
  entity: ITextEntity & { atom: Atom<ITextEntity | Promise<ITextEntity>> };
};
type ListItemBlock = { type: "block"; id: string; block: StructuredAtomBlock };
type EmptyItem = { type: "empty"; id: "empty" };

type InnerListItem = FolderItem | ListItemEntity | ListItemBlock | EmptyItem;

function InnerList(props: IProps) {
  const { isSearching, onSearchQueryChange } = props;
  const folders = useAtomValue(props.foldersListAtom);
  const entities = useAtomValue(props.entityListAtom);

  const virtualizedListItems: InnerListItem[] = useMemo(() => {
    const blocksWithEntities = entities.blocks.filter((b) => b.entities.length > 0);
    const blocksAllEmpty = blocksWithEntities.length === 0;

    const blockListItems = blocksWithEntities.flatMap<ListItemEntity | ListItemBlock>((block) => {
      if (block._id === null) {
        return block.entities.map((entity) => ({ type: "entity", id: entity._id, entity }));
      } else {
        return [{ type: "block", id: block._id, block }];
      }
    });

    return [
      // render folders first, but don't show folders when searching
      ...(isSearching ? [] : folders.map<FolderItem>((folder) => ({ type: "folder", id: folder._id, folder }))),
      // render blocks after folders
      ...blockListItems,
      // render empty state last if no entities
      ...(blocksAllEmpty ? [{ type: "empty", id: "empty" } as const] : []),
    ];
  }, [isSearching, entities.blocks, folders]);

  const onClearSearch = useCallback(() => onSearchQueryChange(""), [onSearchQueryChange]);

  // The default value for padding to be displayed below the list on scroll to bottom
  const defaultPaddingEnd = 10;

  return (
    <>
      <VirtualizedList
        key="compactList"
        id="compactList"
        items={virtualizedListItems}
        virtualizerAtom={props.virtualizerAtom}
        virtualizeOptions={{
          estimateSize: () => 80,
          overscan: 10,
          getItemKey: (index) => virtualizedListItems[index].id ?? "ROOTBLOCK",
          paddingEnd: (props.extraPaddingEnd ?? 0) + defaultPaddingEnd,
        }}
        className={classNames(style.list, { [style.showFolderLabels]: props.showFolderLabels })}
        itemClassName={style.listItem}
      >
        {({ item, index }) => {
          switch (item.type) {
            case "folder":
              const isLastFolder = virtualizedListItems[index + 1]?.type !== "folder";
              return (
                <ComponentFolderNavButton
                  key={item.id}
                  name={item.folder.name || "Folder"}
                  onClick={() => props.handleFolderClick(item.folder)}
                  className={classNames(style.listItem, style.folderItem, { [style.lastFolder]: isLastFolder })}
                />
              );
            case "block":
              return <TruncatedBlock key={item.id} {...props} block={item.block} className={style.listBlock} />;
            case "entity":
              return <Entity key={item.id} {...props} className={style.listEntity} entityAtom={item.entity.atom} />;
            case "empty":
              return (
                <EmptyState
                  emptyStates={props.emptyStates}
                  isSearching={props.isSearching}
                  isFiltering={props.isFiltering}
                  inFolder={props.inFolder}
                  searchQuery={props.searchQuery}
                  onClearSearch={onClearSearch}
                  onClearFilters={props.handleClearFilters}
                />
              );
          }
        }}
      </VirtualizedList>
    </>
  );
}

const MAX_DISPLAY_SIZE = 3;

function TruncatedBlock(props: PassthroughProps & { block: StructuredAtomBlock }) {
  const { block, className, ...passthroughProps } = props;

  const [isShowingAll, setIsShowingAll] = useState(() => block.entities.length <= MAX_DISPLAY_SIZE);

  const handleClickExpand = useCallback(() => {
    setIsShowingAll(true);
  }, []);

  const entitiesToRender = useMemo(() => {
    if (isShowingAll) {
      return block.entities;
    } else return block.entities.slice(0, MAX_DISPLAY_SIZE);
  }, [block.entities, isShowingAll]);

  const expandButtonText = `${block.entities.length - MAX_DISPLAY_SIZE} other text items in this block`;

  return (
    <TextItemBlock
      footerPadding="var(--spacing-xs) var(--spacing-lg)"
      childrenPadding="0 var(--spacing-md)"
      name={block.name ?? ""}
      onClickExpand={isShowingAll ? undefined : handleClickExpand}
      expandButtonText={isShowingAll ? undefined : expandButtonText}
      className={classNames(className, style.truncatedBlock)}
    >
      {entitiesToRender.map((entity) => (
        <Entity key={entity._id} {...passthroughProps} className={style.listEntityInBlock} entityAtom={entity.atom} />
      ))}
    </TextItemBlock>
  );
}

function UnwrappedEntity(props: PassthroughProps & { entityAtom: Atom<ITextEntity | Promise<ITextEntity>> }) {
  const data = useAtomValue(props.entityAtom);
  const helperText =
    props.originalRichText !== undefined && isDiffRichText(props.originalRichText, data.defaultValue)
      ? "Text will be updated to match"
      : "";

  const handleActionClick = useCallback(() => {
    props.onComponentActionClick(data._id);
    if (props.onItemActionClick) {
      props.onItemActionClick();
    }
  }, [props, data._id]);

  return (
    <ActionableTextEntity
      className={props.className}
      data={data}
      actionText={props.actionText}
      isSelected={data._id === props.selectedEntityId}
      onActionClick={handleActionClick}
      onSelect={props.handleComponentSelect}
      onDeselect={props.handleComponentDeselect}
      helperText={helperText}
      dragAndDrop={props.dragAndDrop}
      onDragEnd={props.onItemDragEnd}
      Header={
        // this is still pretty coupled to components, but going to leave it alone for now since we're going to
        // have to refactor this further later
        props.showFolderLabels && props.foldersListAtom ? (
          <FolderLabel
            folderId={data.component?.folderId ?? null}
            libraryComponentFoldersBreadcrumbsAtomFamily={props.libraryComponentFoldersBreadcrumbsAtomFamily}
          />
        ) : undefined
      }
    />
  );
}

function Entity(props: PassthroughProps & { entityAtom: Atom<ITextEntity | Promise<ITextEntity>> }) {
  return <Suspense fallback={<Skeleton height={36} />}>{<UnwrappedEntity {...props} />}</Suspense>;
}

const fallbackAtoms = {
  projectsAtom: atom([]),
  tagsAtom: atom([]),
};

function CompactTextEntityListFallback(props: { searchPlaceholder?: string }) {
  return (
    <div className={style.listWrapper}>
      <TextInput
        className={style.searchInput}
        placeholder={props.searchPlaceholder ?? "Search..."}
        value={""}
        onChange={() => {}}
      />

      <FiltersArea
        enabledFilters={["status", "tags", "usedInProject"]}
        inFolder={false}
        selectedFilters={[]}
        handleClearFilters={() => {}}
        handleBackFolderClick={() => {}}
        headerText={""}
        formattedSelectedFilters={[]}
        handleSetSelectedFilters={() => {}}
        selectedTags={[]}
        setSelectedTags={() => {}}
        selectedStatuses={[]}
        setSelectedStatuses={() => {}}
        selectedProjectId={null}
        setSelectedProjectId={() => {}}
        projectsAtom={fallbackAtoms.projectsAtom}
        tagsAtom={fallbackAtoms.tagsAtom}
        handleRemoveFilter={() => {}}
      />

      <div className={style.scrollArea}>
        <div className={style.list}>
          <Skeleton height={50} />
          <Skeleton height={50} />
          <Skeleton height={50} />
          <Skeleton height={50} />
        </div>
      </div>
    </div>
  );
}

// re-export the external hook from the index
export { useCompactTextEntityList };

CompactTextEntityList.Fallback = CompactTextEntityListFallback;

export default CompactTextEntityList;

function EmptyState(props: {
  isSearching: boolean;
  isFiltering: boolean;
  inFolder: boolean;
  emptyStates: EmptyStates;
  searchQuery: string;
  onClearSearch: () => void;
  onClearFilters: () => void;
}) {
  if (props.isSearching) {
    return (
      <div className={style.emptyState}>
        <Text textAlign="center" color="secondary" size="small">
          {props.emptyStates.whenSearching?.(props.searchQuery) ?? `Nothing found for "${props.searchQuery}"`}
        </Text>
        <Button level="secondary" size="small" onClick={props.onClearSearch}>
          Clear search
        </Button>
      </div>
    );
  }

  if (props.isFiltering) {
    return (
      <div className={style.emptyState}>
        <Text textAlign="center" color="secondary" size="small">
          {props.emptyStates.whenFiltering ?? "No items found for the selected filters"}
        </Text>
        <Button level="secondary" size="small" onClick={props.onClearFilters}>
          Clear filters
        </Button>
      </div>
    );
  }

  if (props.inFolder) {
    return (
      <div className={style.emptyState}>
        <Text inline textAlign="center" color="secondary" size="small">
          {props.emptyStates.whenInFolder ?? "This folder has no items yet"}
        </Text>
      </div>
    );
  }

  return (
    <div className={style.emptyState}>
      <Text inline textAlign="center" color="secondary" size="small">
        {props.emptyStates.default}
      </Text>
    </div>
  );
}

function FolderLabel(props: {
  folderId: string | null;
  libraryComponentFoldersBreadcrumbsAtomFamily: (
    folderId: string
  ) => Atom<Promise<{ folder: ILibraryComponentFolder; breadcrumbs: string[] }>>;
}) {
  if (!props.folderId) {
    return (
      <Text size="micro" color="secondary">
        All components
      </Text>
    );
  }

  return (
    <FolderBreadcrumbsLabel
      selectedLibraryFolderId={props.folderId}
      libraryComponentFoldersBreadcrumbsAtomFamily={props.libraryComponentFoldersBreadcrumbsAtomFamily}
      maxCrumbs={2}
    />
  );
}
