import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import classNames from "classnames";
import React from "react";
import Icon from "../Icon";
import style from "./index.module.css";

export interface IProps {
  className?: string;
  style?: React.CSSProperties;
  text: string;
  color?: "action" | "secondary";
  hideLinkoutIcon?: boolean;
}

export function URLLink(props: IProps) {
  const { text, color = "action", hideLinkoutIcon = false } = props;
  const href = /https?:\/\//.test(text) ? text : `https://${text}`;
  const iconColor = color === "action" ? "blue" : "secondary";

  return (
    <a
      className={classNames(style.URLLinkWrapper, style[`color-${color}`], props.className)}
      href={href}
      target="_blank"
    >
      {text}
      {!hideLinkoutIcon && <Icon className={style.URLLinkIcon} Icon={<OpenInNewIcon />} size="xs" color={iconColor} />}
    </a>
  );
}

export default URLLink;
