import classNames from "classnames";
import React from "react";
import Text from "../../atoms/Text";
import style from "./index.module.css";

interface IProps {
  className?: string;
  style?: React.CSSProperties;
  preludeText: React.ReactNode;
}

export function MissingFontWarning(props: IProps) {
  return (
    <div
      style={props.style}
      className={classNames(style.MissingFontWarningWrapper, props.className)}
      data-testid="missing-font-warning"
    >
      <Text weight="strong" size="micro" color="warning" inline>
        {props.preludeText}
      </Text>{" "}
      <Text weight="base" size="micro" color="warning" inline>
        - Replace missing fonts in Figma to sync selected layer with Ditto.
      </Text>
    </div>
  );
}

export default MissingFontWarning;
