import {
  mostRecentLibraryComponentCommentFamilyAtom,
  postLibraryComponentCommentActionAtom,
  updateCommentEditsAtom,
} from "@/stores/Comments";
import { derivedOnlySelectedComponentIdAtom, LibraryDetailsEditPanelTabAtom } from "@/stores/Library";
import { deferredUsersAtom } from "@/stores/Workspace";
import CommentThreadWrapper from "@/views/NS/components/CommentThreadWrapper";
import CommentSection from "@ds/organisms/CommentSection";

import { IUser } from "@shared/types/User";
import { useAtomValue, useSetAtom } from "jotai";
import { useCallback } from "react";

function LibraryCommentsSection() {
  const selectedId = useAtomValue(derivedOnlySelectedComponentIdAtom);
  const postCommentAction = useSetAtom(postLibraryComponentCommentActionAtom);
  const mostRecentThreadId = useAtomValue(mostRecentLibraryComponentCommentFamilyAtom(selectedId));
  const setSelectedDetailsTab = useSetAtom(LibraryDetailsEditPanelTabAtom);
  const mentionableUsers = useAtomValue(deferredUsersAtom);
  const updateIsEditingAtom = useSetAtom(updateCommentEditsAtom);

  const onTypeComment = useCallback(
    (contentToSave: string, _mentionedUsersInfo: IUser[]) => {
      updateIsEditingAtom({ commentThreadId: "new", hasUnsavedChanges: !!contentToSave });
    },
    [updateIsEditingAtom]
  );

  const handlePostComment = async (commentText: string, mentionedUsers: IUser[], resetEditorState: () => void) => {
    if (!selectedId) {
      return;
    }

    await postCommentAction({
      libraryComponentId: selectedId,
      commentText,
      mentionedUserIds: mentionedUsers.map((user) => user.userId),
    });

    resetEditorState();
  };

  const handleShowAll = useCallback(() => {
    setSelectedDetailsTab("COMMENTS");
  }, [setSelectedDetailsTab]);

  if (!selectedId) {
    return <></>;
  }

  return (
    <CommentSection
      selectedItemId={selectedId}
      mostRecentThreadId={mostRecentThreadId}
      mentionableUsers={mentionableUsers}
      CommentThreadComponent={CommentThreadWrapper}
      onTypeComment={onTypeComment}
      handlePostComment={handlePostComment}
      handleShowAll={handleShowAll}
    />
  );
}

export default LibraryCommentsSection;
