import { locationAtom } from "@/stores/Location";
import atomWithURLStorage from "@shared/frontend/stores/atomWithURLStorage";
import { atom } from "jotai";
import { atomFamily } from "jotai/utils";

export const LIBRARY_FILTERS = {
  status: {
    value: "status",
    label: "Status",
  },
  assignee: {
    value: "assignee",
    label: "Assignee",
  },
  tags: {
    value: "tags",
    label: "Tags",
  },
} as const;

export type FilterKey = keyof typeof LIBRARY_FILTERS;

// Atom family for storing the values of the filters in the URL. This lets us abstract away the logic of storing
// and syncing the values of the filters in the URL.
export const selectedFiltersAtomFamily = atomFamily((key: FilterKey) => {
  const urlStorageAtom = atomWithURLStorage(key, locationAtom);
  urlStorageAtom.debugLabel = `Selected Library Filters (${key})`;

  return urlStorageAtom;
});

/**
 * Returns true if any filters are active.
 */
export const isFilteringLibraryResultsAtom = atom((get) => {
  const statusFilters = get(selectedFiltersAtomFamily("status"));
  const assigneeFilters = get(selectedFiltersAtomFamily("assignee"));
  const tagFilters = get(selectedFiltersAtomFamily("tags"));
  return Boolean(statusFilters || assigneeFilters || tagFilters);
});

export const clearLibraryFiltersAtom = atom(null, (get, set) => {
  set(selectedFiltersAtomFamily("status"), null);
  set(selectedFiltersAtomFamily("assignee"), null);
  set(selectedFiltersAtomFamily("tags"), null);
});

// Atom which reduces the selected filters into a list of FilterKey values, and allows filters to be added or removed
export const selectedFiltersListAtom = atom(
  // The getter loops over all our possible filter keys and checks if each one has a value stored in the atom family
  // (i.e., if the key has any value in the URL)
  (get) => {
    const selectedFilterKeys: FilterKey[] = [];
    for (const key of Object.keys(LIBRARY_FILTERS) as FilterKey[]) {
      const filterAtom = selectedFiltersAtomFamily(key);
      const filterValue = get(filterAtom);

      if (filterValue !== null && filterValue !== undefined) {
        selectedFilterKeys.push(key);
      }
    }
    return selectedFilterKeys;
  },
  // The setter takes an array of filter keys. We loop over *all* possible filter keys -- if the setter has the key
  // present, we make sure that the atom family at least has a default value. If the setter doesn't have the key
  // present, we remove the key from the atom family (removing it from the URL)
  (get, set, newSelectedFilterKeys: FilterKey[]) => {
    const selectedFilterKeysSet = new Set(newSelectedFilterKeys);
    const allFilterKeys = Object.keys(LIBRARY_FILTERS) as FilterKey[];

    for (const key of allFilterKeys) {
      const filterAtom = selectedFiltersAtomFamily(key);

      if (!selectedFilterKeysSet.has(key)) {
        set(filterAtom, null);
      }

      const filterValue = get(filterAtom);
      if (selectedFilterKeysSet.has(key) && (filterValue === null || filterValue === undefined)) {
        set(filterAtom, []);
      }
    }
  }
);

export const isFilteringAtom = atom((get) => get(selectedFiltersListAtom).length > 0);
