import useAutoScroll, { composeCallbacks } from "@/hooks/useAutoScroll";
import {
  addNewTextItemActionAtom,
  cancelNewTextItemActionAtom,
  isInlineEditingNewTextAtom,
  newTextItemLocationFamilyAtom,
  newTextItemTextValueAtom,
  reorderTextItemsActionAtom,
  saveNewTextItemActionAtom,
} from "@/stores/Editing";
import { searchAtom } from "@/stores/Location";
import {
  forceShowAllTextItemsInBlockAtomFamily,
  moveBlocksActionAtom,
  renameBlockActionAtom,
  shiftBlockOrderActionAtom,
} from "@/stores/Project";
import {
  blockIsSelectedAtomFamily,
  onClickBlockActionAtom,
  selectedItemsAtom,
  selectionTypeAtom,
} from "@/stores/ProjectSelection";
import { blockSelectedVariantIdFamilyAtom, variantTabsForBlockFamilyAtom } from "@/stores/Variants";
import Button from "@ds/atoms/Button";
import DragAndDroppable, { DragLocation } from "@ds/atoms/DragAndDroppable";
import TextItem from "@ds/molecules/TextItem";
import TextItemBlock from "@ds/molecules/TextItemBlock";
import { IVariantTab, VariantTabs } from "@ds/molecules/VariantTabs";
import { IDittoBlockData } from "@shared/types/http/DittoProject";
import { ITipTapRichText } from "@shared/types/TextItem";
import { BASE_VARIANT_ID } from "@shared/types/Variant";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { useAtomCallback } from "jotai/utils";
import { last } from "lodash";
import { memo, useCallback, useState } from "react";
import { DragStartEvent } from "react-aria";
import { z } from "zod";
import { textItemListScrollRefAtom } from "../TextItemList";
import TextItemRow from "../TextItemRow";
import style from "./style.module.css";

const allowedItemKeys = { "ditto/textItem": z.string(), "ditto/blockItem": z.string() };

const TextItemBlockWrapper = memo(function TextItemBlockWrapper(props: {
  textItemBlock: IDittoBlockData;
  index: number;
  maxIndex: number;
  betweenBlocksRowRef?: React.RefObject<HTMLDivElement | null>;
}) {
  const { textItemBlock } = props;

  // variants
  const variantTabs = useAtomValue(variantTabsForBlockFamilyAtom(textItemBlock._id));
  const [blockSelectedVariantId, setBlockSelectedVariantId] = useAtom(
    blockSelectedVariantIdFamilyAtom(textItemBlock._id)
  );

  const selectionType = useAtomValue(selectionTypeAtom);
  const [projectContentSearchQuery] = useAtom(searchAtom);
  const newTextItemLocation = useAtomValue(newTextItemLocationFamilyAtom(textItemBlock._id));
  const isInlineEditingNewText = useAtomValue(isInlineEditingNewTextAtom);
  const getSelectionType = useAtomCallback((get) => get(selectionTypeAtom));
  const textItemScrollContainer = useAtomValue(textItemListScrollRefAtom);

  const addNewTextItemAction = useSetAtom(addNewTextItemActionAtom);
  const saveNewTextItemAction = useSetAtom(saveNewTextItemActionAtom);
  const cancelNewTextItemAction = useSetAtom(cancelNewTextItemActionAtom);

  const isSelected = useAtomValue(blockIsSelectedAtomFamily(textItemBlock._id));
  const onClickBlockAction = useSetAtom(onClickBlockActionAtom);
  const renameBlockAction = useSetAtom(renameBlockActionAtom);
  const shiftBlockOrderAction = useSetAtom(shiftBlockOrderActionAtom);
  const scrollProps = useAutoScroll(textItemScrollContainer);
  const setSelectedItems = useSetAtom(selectedItemsAtom);

  const reorderTextItemsAction = useSetAtom(reorderTextItemsActionAtom);
  const moveBlockItemsAction = useSetAtom(moveBlocksActionAtom);
  const setNewTextItemTextValue = useSetAtom(newTextItemTextValueAtom);

  const [forceShowAllTextItemsInBlock, setForceShowAllTextItemsInBlock] = useAtom(
    forceShowAllTextItemsInBlockAtomFamily(textItemBlock._id || "")
  );
  const [isDragDisabled, setIsDragDisabled] = useState(false);

  const isAddingTextItemToBlock = !!newTextItemLocation;

  const activeVariant = variantTabs.find((v) => v.id === blockSelectedVariantId) ?? { id: "__base__", name: "Base" };

  // filtering
  const numHiddenResultsTextItems = textItemBlock.allTextItems.length - textItemBlock.textItems.length;
  const textItemType = forceShowAllTextItemsInBlock ? "allTextItems" : "textItems";

  const getDraggableItems = useCallback(
    () => [
      {
        "ditto/blockItem": textItemBlock._id,
        "plain/text": textItemBlock._id,
      },
    ],
    [textItemBlock._id]
  );

  const handleRenameBlock = useCallback(
    function _handleRenameBlock(name: string) {
      if (textItemBlock._id) {
        renameBlockAction({ blockId: textItemBlock._id, newName: name });
      }
      setIsDragDisabled(false);
    },
    [renameBlockAction, textItemBlock._id]
  );

  const onTabClick = useCallback(
    function _onTabClick(tab: IVariantTab) {
      setBlockSelectedVariantId(tab.id);
    },
    [setBlockSelectedVariantId]
  );

  const onCancelNewTextItem = useCallback(
    function _onCancelNewTextItem() {
      cancelNewTextItemAction({ skipConfirmation: true });
    },
    [cancelNewTextItemAction]
  );

  const newTextItemOnEscape = useCallback(
    function _newTextItemOnEscape() {
      cancelNewTextItemAction({ skipConfirmation: false });
    },
    [cancelNewTextItemAction]
  );

  const onNewTextItemTextChange = useCallback(
    (richText: ITipTapRichText) => {
      setNewTextItemTextValue(richText);
    },
    [setNewTextItemTextValue]
  );

  const onMoveBlockUp = useCallback(
    function _onMoveBlockUp() {
      shiftBlockOrderAction({ blockId: textItemBlock._id, direction: "up" });
    },
    [shiftBlockOrderAction, textItemBlock._id]
  );

  const onMoveBlockDown = useCallback(
    function _onMoveBlockDown() {
      shiftBlockOrderAction({ blockId: textItemBlock._id, direction: "down" });
    },
    [shiftBlockOrderAction, textItemBlock._id]
  );

  const onClickBlock = useCallback(
    function _onClickBlock() {
      if (textItemBlock._id) {
        onClickBlockAction(textItemBlock._id);
      }
    },
    [onClickBlockAction, textItemBlock._id]
  );

  const onFocusName = useCallback(
    function _onFocusName() {
      setIsDragDisabled(true);

      if (textItemBlock._id) {
        setSelectedItems({ type: "block", id: textItemBlock._id });
      }
    },
    [setSelectedItems, textItemBlock._id]
  );

  const onBlurName = useCallback(function _onBlurName() {
    setIsDragDisabled(false);
  }, []);

  const onAddNewTextItem = useCallback(
    function _onAddNewTextItem() {
      addNewTextItemAction({ blockId: textItemBlock._id });
    },
    [addNewTextItemAction, textItemBlock._id]
  );

  const handleDrop = useCallback(
    function _handleDrop(itemIds: string[], dragLocation: DragLocation) {
      const selectionType = getSelectionType();

      if (selectionType === "text") {
        /**
         * Base reference text item on either the first or last item in the block, dependent on
         * drag location.
         */
        const referenceTextItem =
          dragLocation === "above" ? textItemBlock.allTextItems[0] : last(textItemBlock.allTextItems);

        reorderTextItemsAction([
          {
            textItemIds: itemIds,
            blockId: textItemBlock._id,
            before: dragLocation === "above" ? referenceTextItem?._id : undefined,
            after: dragLocation === "below" ? referenceTextItem?._id : undefined,
          },
        ]);
      } else if (selectionType === "block") {
        if (!textItemBlock._id) {
          throw new Error("Attempted to drop onto the root block, this should never happen");
        }

        moveBlockItemsAction({
          blockIds: itemIds,
          destinationBlockId: textItemBlock._id,
          direction: dragLocation as "above" | "below" | null,
        });
      }
    },
    [getSelectionType, moveBlockItemsAction, reorderTextItemsAction, textItemBlock._id, textItemBlock.allTextItems]
  );

  if (textItemBlock._id === null) {
    const renderTextItemsNotInBlock = textItemBlock.textItems.length > 0 || isAddingTextItemToBlock;

    return (
      <>
        {renderTextItemsNotInBlock && (
          <div className={style.textItemsNotInBlock}>
            {newTextItemLocation === "start" && (
              <TextItem
                autoFocus
                onClickSave={saveNewTextItemAction}
                onClickCancel={onCancelNewTextItem}
                onEscape={newTextItemOnEscape}
                editState="editing"
                onTextChange={onNewTextItemTextChange}
                className={style.textItemNotInBlock}
              />
            )}
            {textItemBlock.textItems.map((textItem) => (
              <TextItemRow
                key={textItem._id}
                textItemId={textItem._id}
                highlightedPhrase={projectContentSearchQuery}
                activeVariantId={activeVariant.id}
                activeVariantName={activeVariant.name}
                className={style.textItemNotInBlock}
              />
            ))}
            {newTextItemLocation === "end" && (
              <TextItem
                autoFocus
                onClickSave={saveNewTextItemAction}
                onClickCancel={onCancelNewTextItem}
                onEscape={newTextItemOnEscape}
                editState="editing"
                onTextChange={onNewTextItemTextChange}
                className={style.textItemNotInBlock}
              />
            )}
          </div>
        )}
      </>
    );
  }

  function setBlockRowToDragging() {
    if (props.betweenBlocksRowRef) {
      props.betweenBlocksRowRef.current?.setAttribute("data-dragging", "true");
    }
  }

  function setBlockRowToNotDragging() {
    if (props.betweenBlocksRowRef) {
      props.betweenBlocksRowRef.current?.removeAttribute("data-dragging");
    }
  }

  function onDragStart(e: DragStartEvent) {
    if (textItemBlock._id) {
      onClickBlockAction(textItemBlock._id);
      setBlockRowToDragging();
    }
  }

  const onDragEnd = setBlockRowToNotDragging;
  const onDragCancel = setBlockRowToNotDragging;

  const dragProps = composeCallbacks([scrollProps, { onDragStart, onDragEnd, onDragCancel }]);

  const isHidingTextItems = numHiddenResultsTextItems > 0 && !forceShowAllTextItemsInBlock;

  return (
    <DragAndDroppable
      getDraggableItems={getDraggableItems}
      allowedItemKeys={allowedItemKeys}
      onDrop={handleDrop}
      isDragDisabled={isDragDisabled}
      selectionType={selectionType}
      {...dragProps}
    >
      <div data-selectiontype="block" data-dragindicatorabove className={style.dropIndicatorWrapper}>
        <div className={style.dropIndicator} />
      </div>
      {variantTabs.length > 0 && (
        <VariantTabs variantTabs={variantTabs} activeVariant={activeVariant} onTabClick={onTabClick} />
      )}
      <TextItemBlock
        isEmpty={!isAddingTextItemToBlock && textItemBlock.allTextItems.length === 0}
        onClickBlock={onClickBlock}
        name={textItemBlock.name ?? "Block"}
        onSaveName={handleRenameBlock}
        // prevent dragging when we're editing block name
        onFocusName={onFocusName}
        onBlurName={onBlurName}
        highlightedPhrase={projectContentSearchQuery}
        state={isSelected ? "focus" : "default"}
        disableAddTextItem={isInlineEditingNewText || activeVariant.id !== BASE_VARIANT_ID}
        onMoveBlockUp={onMoveBlockUp}
        onMoveBlockDown={onMoveBlockDown}
        canMoveDown={props.index < props.maxIndex}
        canMoveUp={props.index > 0}
        onAddNewTextItem={isHidingTextItems ? undefined : onAddNewTextItem}
        onDrop={handleDrop}
      >
        <div data-selectiontype="text" data-dragindicatorabove className={style.dropIndicatorWrapper}>
          <div className={style.dropIndicator} />
        </div>
        <div className={style.textItems}>
          {textItemBlock[textItemType].map((textItem) => (
            <TextItemRow
              key={textItem._id}
              textItemId={textItem._id}
              highlightedPhrase={projectContentSearchQuery}
              activeVariantId={activeVariant.id}
              activeVariantName={activeVariant.name}
            />
          ))}
        </div>
        {isAddingTextItemToBlock && (
          <TextItem
            autoFocus
            onClickSave={saveNewTextItemAction}
            onClickCancel={onCancelNewTextItem}
            onEscape={newTextItemOnEscape}
            editState="editing"
            className={style.newTextItemInBlock}
            onTextChange={onNewTextItemTextChange}
          />
        )}
        <div data-selectiontype="text" data-dragindicatorbelow className={style.dropIndicatorWrapper}>
          <div className={style.dropIndicator} />
        </div>
        {isHidingTextItems && (
          <div className={style.hiddenResultsMessageBlock}>
            <div>
              {numHiddenResultsTextItems} more text {numHiddenResultsTextItems === 1 ? "item" : "items"} in this block
              not shown in search result
            </div>
            <div className={style.hiddenResultsMessageBlockDivider}>•</div>
            <Button level="subtle" size="micro" onClick={() => setForceShowAllTextItemsInBlock(true)}>
              Show all
            </Button>
          </div>
        )}
      </TextItemBlock>
      <div data-selectiontype="block" data-dragindicatorbelow className={style.dropIndicatorWrapper}>
        <div className={style.dropIndicator} />
      </div>
    </DragAndDroppable>
  );
});

export default TextItemBlockWrapper;
