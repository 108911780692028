import { ZMoveLibraryComponentsActionResult } from "@shared/types/http/LibraryComponent";
import { ZObjectId } from "@shared/types/lib";
import { ZLibraryComponentFolder, ZReorderLibraryComponentFolderAction } from "@shared/types/LibraryComponentFolder";
import { z } from "zod";

const LIBRARY_COMPONENT_FOLDER_BASE_PATH = "/library-component-folder";
const LIBRARY_COMPONENT_FOLDER_PATH_WITH_FOLDER_ID = `${LIBRARY_COMPONENT_FOLDER_BASE_PATH}/:folderId`;

export const ZGetLibraryComponentFoldersRequest = {
  query: z.object({
    folderIds: z.array(z.string()).optional(),
    fields: z.string().optional(),
  }),
};
export const ZGetLibraryComponentFoldersResponse = z.object({
  folders: z.array(ZLibraryComponentFolder),
});
export const GetLibraryComponentFoldersMethod = "GET";
export const GetLibraryComponentFoldersPath = LIBRARY_COMPONENT_FOLDER_BASE_PATH;

export const ZCreateLibraryComponentFolderRequest = {
  body: z.object({
    _id: ZObjectId.optional(),
    name: z.string(),
    parentId: ZObjectId.nullable(),
  }),
};

export const ZCreateLibraryComponentFolderResponse = z.object({
  newComponentFolder: ZLibraryComponentFolder,
});
export const CreateLibraryComponentFolderMethod = "POST";
export const CreateLibraryComponentFolderPath = LIBRARY_COMPONENT_FOLDER_BASE_PATH;

export const ZUpdateLibraryComponentFolderRequest = {
  params: z.object({
    folderId: z.string(),
  }),
  body: z.object({
    folderData: ZLibraryComponentFolder.partial(),
  }),
};
export const ZUpdateLibraryComponentFolderResponse = ZLibraryComponentFolder;
export const UpdateLibraryComponentFolderMethod = "PATCH";
export const UpdateLibraryComponentFolderPath = LIBRARY_COMPONENT_FOLDER_PATH_WITH_FOLDER_ID;

export const ZDeleteLibraryComponentFolderRequest = {
  params: z.object({
    folderId: z.string(),
  }),
};
export const ZDeleteLibraryComponentFolderResponse = z.object({
  idToKeyMap: z.record(z.string(), z.string()),
  actionResult: ZMoveLibraryComponentsActionResult,
});
export const DeleteLibraryComponentFolderMethod = "DELETE";
export const DeleteLibraryComponentFolderPath = LIBRARY_COMPONENT_FOLDER_PATH_WITH_FOLDER_ID;

export const ZReorderLibraryComponentFolderRequest = {
  body: z.object({
    action: ZReorderLibraryComponentFolderAction,
  }),
};
export const ZReorderLibraryComponentFolderResponse = z.object({
  idToKeyMap: z.record(z.string(), z.string()),
  folderIds: z.array(ZObjectId),
});
export const ReorderLibraryComponentFolderMethod = "POST";
export const ReorderLibraryComponentFolderPath = `${LIBRARY_COMPONENT_FOLDER_BASE_PATH}/reorder-folder`;
