/**
 * The hooks in this file are used to facilitate passing correct data structures to the `CompactTextEntityList`
 * component; namely, this means..
 * 1. introducing an abstraction called "text entity" which is a shared representation of a text item or a component
 * 2. creating atoms that map between a text item or component and its text entity equivalent
 */
import client from "@shared/frontend/http/httpClient";
import asyncMutableDerivedAtom from "@shared/frontend/stores/asyncMutableDerivedAtom";
import { REFRESH_SILENTLY } from "@shared/frontend/stores/symbols";
import { ILibraryComponent } from "@shared/types/LibraryComponent";
import { ITextItem, ITextItemStatus } from "@shared/types/TextItem";
import { atom, Atom } from "jotai";
import { soon, soonAll } from "jotai-derive";
import { ITextEntity, StructuredAtoms } from "./useCompactTextEntityList";
/**
 * Creates an atom which maps between a text item and its text entity equivalent.
 */
const textItemToTextEntityAtom = (dependencies: {
  textItemAtom: Atom<Promise<ITextItem> | ITextItem>;
  libraryComponentFamilyAtom: (componentId: string) => Atom<Promise<ILibraryComponent> | ILibraryComponent>;
  usersByIdAtom: Atom<
    | Record<string, { name: string; picture?: string | null }>
    | Promise<Record<string, { name: string; picture?: string | null }>>
  >;
}) => {
  return atom<ITextEntity | Promise<ITextEntity>>((get) =>
    soon(soonAll([get(dependencies.usersByIdAtom), get(dependencies.textItemAtom)]), ([users, textItem]) => {
      const componentMaybePromise = textItem.ws_comp
        ? get(dependencies.libraryComponentFamilyAtom(textItem.ws_comp.toString()))
        : undefined;

      return soon(componentMaybePromise, (component) => {
        return {
          _id: textItem._id.toString(),
          assignee: textItem.assignee ? users[textItem.assignee.toString()] : null,
          defaultValue: textItem.rich_text,
          instanceCount: textItem.integrations.figmaV2?.instances?.length ?? 0,
          notes: textItem.notes,
          status: textItem.status,
          tags: textItem.tags,
          component: component?.name ? { name: component.name } : undefined,
        };
      });
    })
  );
};

export const createTextItemEntityListAtom =
  (dependencies: {
    projectIdAtom: Atom<string | null>;
    textItemFamilyAtom: (textItemId: string) => Atom<Promise<ITextItem> | ITextItem>;
    libraryComponentFamilyAtom: (componentId: string) => Atom<Promise<ILibraryComponent> | ILibraryComponent>;
    usersByIdAtom: Atom<
      | Record<string, { name: string; picture?: string | null }>
      | Promise<Record<string, { name: string; picture?: string | null }>>
    >;
  }) =>
  (dependencyAtoms: { queryAtom: Atom<string>; statusesAtom: Atom<ITextItemStatus[]>; tagsAtom: Atom<string[]> }) => {
    const { projectIdAtom } = dependencies;
    const { queryAtom, statusesAtom, tagsAtom } = dependencyAtoms;
    // cache text items by atom id so that we don't have to arbitrarily re-create
    // the atoms each time the data changes
    const textItemAtomById: Record<string, Atom<Promise<ITextEntity> | ITextEntity>> = {};

    const { valueAtom, refreshAtom } = asyncMutableDerivedAtom({
      loadData: async (get) => {
        const projectId = get(projectIdAtom);
        if (!projectId) {
          return {
            blocks: [],
          };
        }

        const query = get(queryAtom);
        const statuses = get(statusesAtom);
        const tags = get(tagsAtom);

        const searchResult = await client.dittoProject.searchDittoProjectTextItems({
          query,
          statuses: statuses,
          tags,
          projectId,
        });

        const structuredAtoms: StructuredAtoms = {
          blocks: searchResult.project.blocks.map((block) => ({
            _id: block._id ? block._id.toString() : null,
            name: block.name,
            entities: block.textItems.map((textItem) => {
              textItemAtomById[textItem._id.toString()] ??= textItemToTextEntityAtom({
                textItemAtom: dependencies.textItemFamilyAtom(textItem._id.toString()),
                libraryComponentFamilyAtom: dependencies.libraryComponentFamilyAtom,
                usersByIdAtom: dependencies.usersByIdAtom,
              });

              return {
                _id: textItem._id.toString(),
                atom: textItemAtomById[textItem._id.toString()],
              };
            }),
          })),
        };

        return structuredAtoms;
      },
    });

    const valueWithRefresh = atom(
      (get) => get(valueAtom),
      (get, set, arg: StructuredAtoms | Promise<StructuredAtoms> | typeof REFRESH_SILENTLY) => {
        if (arg === REFRESH_SILENTLY) {
          set(refreshAtom, arg);
          return;
        }

        set(valueAtom, arg);
      }
    );

    return valueWithRefresh;
  };

/**
 * Creates an atom which maps between a component and its text entity equivalent.
 */
const componentToTextEntityAtom = (dependencies: {
  componentAtom: Atom<Promise<ILibraryComponent> | ILibraryComponent>;
  usersByIdAtom: Atom<
    | Record<string, { name: string; picture?: string | null }>
    | Promise<Record<string, { name: string; picture?: string | null }>>
  >;
}) => {
  return atom<ITextEntity | Promise<ITextEntity>>((get) => {
    const dependenciesSoon = soonAll([get(dependencies.usersByIdAtom), get(dependencies.componentAtom)]);
    return soon(dependenciesSoon, ([users, dataComponent]) => {
      return {
        _id: dataComponent._id.toString(),
        assignee: dataComponent.assignee ? users[dataComponent.assignee.toString()] : null,
        defaultValue: dataComponent.rich_text,
        instanceCount: dataComponent.instances?.length ?? 0,
        notes: dataComponent.notes,
        status: dataComponent.status,
        tags: dataComponent.tags,
        component: {
          name: dataComponent.name,
          folderId: dataComponent.folderId ? dataComponent.folderId.toString() : undefined,
        },
      };
    });
  });
};

export const createComponentEntityListAtom =
  (dependencies: {
    libraryComponentFamilyAtom: (componentId: string) => Atom<Promise<ILibraryComponent> | ILibraryComponent>;
    usersByIdAtom: Atom<
      | Record<string, { name: string; picture?: string | null }>
      | Promise<Record<string, { name: string; picture?: string | null }>>
    >;
  }) =>
  (dependencyAtoms: {
    queryAtom: Atom<string>;
    statusesAtom: Atom<ITextItemStatus[]>;
    tagsAtom: Atom<string[]>;
    folderIdAtom: Atom<string | null>;
    projectIdAtom: Atom<string | null>;
  }) => {
    const { queryAtom, statusesAtom, tagsAtom, folderIdAtom, projectIdAtom } = dependencyAtoms;
    // cache components by atom id so that we don't have to arbitrarily re-create
    // the atoms each time the data changes
    const componentAtomById: Record<string, Atom<Promise<ITextEntity> | ITextEntity>> = {};

    const { valueAtom, refreshAtom } = asyncMutableDerivedAtom({
      loadData: async (get) => {
        const query = get(queryAtom);
        const statuses = get(statusesAtom);
        const tags = get(tagsAtom);
        const folderId = get(folderIdAtom);
        const projectId = get(projectIdAtom);

        // If we're searching from the top level, we want to see results from all folders. If we're
        // searching from within a folder, or if we don't have a search query defined, we only want
        // results from the currently-selected folder.
        let folderIdToSearch: string | undefined = undefined;
        if (folderId !== null) {
          folderIdToSearch = folderId;
        } else {
          if (query) {
            folderIdToSearch = undefined;
          } else {
            folderIdToSearch = "null";
          }
        }

        const searchResult = await client.libraryComponent.searchLibraryComponents({
          query,
          statuses: statuses,
          tags,
          folderId: folderIdToSearch,
          projectId: projectId ?? undefined,
        });

        const structuredAtoms: StructuredAtoms = {
          blocks: [
            {
              _id: null,
              name: "",
              entities: searchResult.componentIds.map((componentId) => {
                componentAtomById[componentId.toString()] ??= componentToTextEntityAtom({
                  componentAtom: dependencies.libraryComponentFamilyAtom(componentId.toString()),
                  usersByIdAtom: dependencies.usersByIdAtom,
                });

                return {
                  _id: componentId.toString(),
                  atom: componentAtomById[componentId.toString()],
                };
              }),
            },
          ],
        };

        return structuredAtoms;
      },
    });

    const valueWithRefresh = atom(
      (get) => get(valueAtom),
      (get, set, arg: StructuredAtoms | Promise<StructuredAtoms> | typeof REFRESH_SILENTLY) => {
        if (arg === REFRESH_SILENTLY) {
          set(refreshAtom, arg);
          return;
        }

        set(valueAtom, arg);
      }
    );

    return valueWithRefresh;
  };
