import useSegment from "@/hooks/useSegment";
import { selectedTextItemDeveloperIdAtom } from "@/stores/EditMetadata";
import { DEV_INTEGRATIONS_V2 } from "@/utils/featureFlags";
import DeveloperIdSection from "@ds/molecules/DeveloperIdSection";
import DeveloperIdSectionComingSoon from "@ds/molecules/DeveloperIdSectionComingSoon";
import {
  DEV_DOCS_LINK_CLICKED,
  DEV_INTEGRATIONS_DEV_ID_COPIED,
  NS_BETA_INTEREST_DEV_ID,
} from "@shared/segment-event-names";
import { useCallback } from "react";

function DeveloperId() {
  const { track } = useSegment();

  function onClickLearnMore() {
    track({ event: NS_BETA_INTEREST_DEV_ID, properties: { version: "NS" } });
  }

  const onCopyDevId = useCallback(() => {
    track({
      event: DEV_INTEGRATIONS_DEV_ID_COPIED,
      properties: { version: "NS", application: "web_app", type: "textItem", location: "edit_panel" },
    });
  }, [track]);

  const onDocsClick = useCallback(() => {
    track({
      event: DEV_DOCS_LINK_CLICKED,
      properties: { version: "NS", application: "web_app", location: "edit_panel" },
    });
  }, [track]);

  if (!DEV_INTEGRATIONS_V2) {
    return <DeveloperIdSectionComingSoon onClickLearnMore={onClickLearnMore} />;
  }
  return (
    <DeveloperIdSection
      developerIdAtom={selectedTextItemDeveloperIdAtom}
      onCopyDevId={onCopyDevId}
      onDocsClick={onDocsClick}
    />
  );
}

export default DeveloperId;
