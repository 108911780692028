import { z } from "zod";
import { ZTipTapRichText } from "./TextItem";

export interface FetchFileDataFailureResponse {
  isError: true;
  code: string;
  data?: any;
}

export interface GetFigmaFileFailurePages {
  isError: true;
  type: "pages";
  failedPages: { pageId: string; data: FetchFileDataFailureResponse }[];
}

export interface GetFigmaFileFailureAutoImport {
  isError: true;
  type: "auto-import";
  failedAutoImports: { pageId: string; data: FetchFileDataFailureResponse }[];
}

interface GetFigmaFileFailureOther {
  isError: true;
  type: "other";
  error?: Error;
  data?: any;
}
export type GetFigmaFileError = GetFigmaFileFailurePages | GetFigmaFileFailureAutoImport | GetFigmaFileFailureOther;

export type GetFigmaDataErrorResponse =
  | GetFigmaFileError
  | {
      isError: true;
      type: "no-children-in-document";
    };

const ZFigmaTextNode = z.object({
  name: z.string(),
  text: z.string(),
  rich_text: ZTipTapRichText,
  id: z.string(),
  isVisible: z.boolean(),
  position: z.object({
    x: z.number(),
    y: z.number(),
    width: z.number(),
    height: z.number(),
  }),
  type: z.enum(["COMPONENT", "INSTANCE", "TEXT"]),
  sortOrder: z.number(),
  boundCharacterVariable: z
    .object({
      type: z.literal("VARIABLE_ALIAS"),
      id: z.string(),
    })
    .optional(),
  // This is only set on the import it seems when we pull data from Figma directly.
  shouldHide: z.boolean().optional(),
  missingFont: z.boolean().optional(),
});

export type IFigmaTextNode = z.infer<typeof ZFigmaTextNode>;

const ZDittoGroup = z.object({
  id: z.string(),
  figma_page_id: z.string(),
  name: z.string(),
  comps: z.array(z.string()),
  position: z.object({
    x: z.number(),
    y: z.number(),
    width: z.number(),
    height: z.number(),
  }),
  isComponentizedFrame: z.boolean(),
  cachedNodeId: z.string().optional(),
  applied_variant_id: z.string().nullable().optional(),
  isBeingPreviewed: z.boolean(),
  integrations: z.object({
    figma: z.object({
      page_id: z.string(),
      frame_name: z.string(),
      applied_variant_id: z.string().nullable().optional(),
      frame_name_synced_with_group_name: z.boolean().optional(),
      position: z.object({
        x: z.number(),
        y: z.number(),
        width: z.number(),
        height: z.number(),
      }),
    }),
  }),
});

export type IDittoGroup = z.infer<typeof ZDittoGroup>;

export const ZGetFigmaDataSuccessResponse = z.object({
  isError: z.literal(false),
  name: z.string(),
  comps: z.record(ZFigmaTextNode.optional()),
  groupIds: z.record(z.string()),
  groups: z.record(ZDittoGroup),
  groupsByPage: z.record(z.array(z.object({ id: z.string(), name: z.string() }))),
  selectedPagesInfo: z.array(z.object({ name: z.string(), figma_id: z.string() })),
});

export type GetFigmaDataSuccessResponse = z.infer<typeof ZGetFigmaDataSuccessResponse>;

export const TrackedFigmaNodeTypesForDeletion = ["FRAME", "TEXT", "SECTION", "GROUP", "INSTANCE"] as const;
export type ITrackedFigmaNodeTypesForDeletion = "FRAME" | "TEXT" | "SECTION" | "GROUP" | "INSTANCE";
