import { Atom, atom } from "jotai";
import { unwrap } from "jotai/utils";

/**
 * Takes an asynchronous Atom which will return a Promise<T>, and returns a cached version.
 * This means that the return value will evaluate to a Promise<T> on *initial load*, but on subsequent accesses, will
 * refresh quietly in the background. This lets us easily write behavior of the form "only suspend this data when you
 * absolutely have to".
 */
export const cacheAtom = <T>(atomToCache: Atom<Promise<T> | T>): Atom<T | Promise<T>> => {
  const cacheAtom = unwrap(atomToCache, (prev) => prev);
  cacheAtom.debugLabel = atomToCache.debugLabel + " (cached)";
  const valueAtom = atom((get) => {
    const value = get(atomToCache);
    const cacheValue = get(cacheAtom);
    return cacheValue ?? value;
  });
  valueAtom.debugLabel = atomToCache.debugLabel + " (cached value)";

  return valueAtom;
};
