import InviteUsersInput from "@/components/permissions/InviteUsersInput";
import { UserInvitation } from "@/components/permissions/InviteUsersInput/types";
import http, { API } from "@/http";
import { inviteUsersToProject } from "@/http/dittoProject";
import { userRoleAtom } from "@/stores/Auth";
import { projectIdAtom } from "@/stores/Project";
import { usersBasicInfoAtom } from "@/stores/Workspace";
import Button from "@ds/atoms/Button";
import Text from "@ds/atoms/Text";
import TextAreaInput from "@ds/atoms/TextAreaInput";
import Modal from "@ds/molecules/Modal";
import { showToastActionAtom } from "@shared/frontend/stores/Toast";
import logger from "@shared/utils/logger";
import { useAtomValue, useSetAtom } from "jotai";
import React, { useCallback, useMemo, useState } from "react";
import style from "./style.module.css";

interface IShareModalProps {
  open: boolean;
  onOpenChange: (isOpen: boolean) => void;
}

function ShareModal({ open, onOpenChange }: IShareModalProps) {
  const projectId = useAtomValue(projectIdAtom);
  const userRole = useAtomValue(userRoleAtom);
  const workspaceUsers = useAtomValue(usersBasicInfoAtom);
  const showToast = useSetAtom(showToastActionAtom);
  const [invitedUsers, setInvitedUsers] = useState<UserInvitation[]>([]);
  const [isSending, setIsSending] = useState<boolean>(false);
  const [messageInput, setMessageInput] = useState<string>("");

  const canSend = useMemo(() => {
    // Disable submit if already submitting, no users invited, or there is an invalid user (which won't have a type)
    return !isSending && invitedUsers.length > 0 && invitedUsers.every((user) => user.type);
  }, [invitedUsers, isSending]);

  const closeAndClearModal = useCallback(() => {
    onOpenChange(false);
    setInvitedUsers([]);
    setMessageInput("");
  }, [onOpenChange]);

  const handleOpenChange = useCallback(
    (isOpen: boolean) => {
      if (isOpen) {
        onOpenChange(true);
      } else {
        closeAndClearModal();
      }
    },
    [onOpenChange, closeAndClearModal]
  );

  const handleSendInvite = useCallback(async () => {
    if (!canSend || !projectId) return;

    try {
      setIsSending(true);

      // Account for non-standardized data
      // This is caused by an implementation error somewhere in the InviteUsersInput component,
      // but since that's in use in the legacy app, it's safer to fix here for now
      const invitations = invitedUsers.map((user) => ({
        ...user,
        permissionGroups: ["editor"],
      }));

      const [request] = inviteUsersToProject({
        projectId,
        invitations,
        message: messageInput,
      });
      await request;
      closeAndClearModal();
      showToast({ message: `Invite${invitedUsers.length === 1 ? "" : "s"} sent! 🎉` });
    } catch (error) {
      logger.error("Error inviting users to project", { context: { projectId, usersToInvite: invitedUsers } }, error);
      showToast({ message: "Something went wrong, please try again later." });
    }
    setIsSending(false);
  }, [projectId, invitedUsers, messageInput, closeAndClearModal, showToast, canSend]);

  const onMessageInputChange = useCallback((value: string) => {
    setMessageInput(value);
  }, []);

  const isEmailInUse = useCallback(
    async (email: string) => {
      try {
        const { url, body } = API.user.post.checkExists;
        const { data: userExists } = await http.post(url, body({ email }));
        return userExists.exists && !userExists.sameWorkspace;
      } catch (error) {
        logger.error("Error checking if email exists", { context: { projectId, email } }, error);
        return false;
      }
    },
    [projectId]
  );

  const onUserInvited = useCallback(
    async (newInvite: UserInvitation) => {
      const isAlreadyInvited = newInvite.type !== "new" ? false : await isEmailInUse(newInvite.email);

      setInvitedUsers((prev) => {
        if (prev.some((invite) => invite.email === newInvite.email)) return prev;
        const update = prev.concat({ ...newInvite, isAlreadyInvited });
        return update;
      });
    },
    [isEmailInUse, setInvitedUsers]
  );

  const onUserRemoved = useCallback(
    (index: number) => {
      setInvitedUsers((prev) => prev.toSpliced(index, 1));
    },
    [setInvitedUsers]
  );

  const handleClickCopy = useCallback(() => {
    navigator.clipboard.writeText(window.location.href);
    showToast({ message: "Link copied to clipboard!" });
  }, [showToast]);

  return (
    <Modal
      headline="Share with collaborator"
      description="Invite teammates to collaborate on this project."
      open={open}
      onOpenChange={handleOpenChange}
      showCloseButton
    >
      <div className={style.contentContainer}>
        <InviteUsersInput
          disabled={isSending}
          placeholder="Search for a teammate or enter email..."
          defaultRole="editor"
          currentUserRole={userRole || "editor"}
          invitedUserList={invitedUsers}
          userList={workspaceUsers}
          onUserInvited={onUserInvited}
          onUserRemoved={onUserRemoved}
        />
        <div className={style.messageContainer}>
          <div>
            <Text color="primary" size="small" weight="strong" inline>
              Message
            </Text>{" "}
            <Text color="tertiary" size="small">
              (Optional)
            </Text>
          </div>
          <TextAreaInput
            placeholder="Add a message to your invite..."
            content={messageInput}
            disabled={isSending}
            minRows={4}
            onChange={onMessageInputChange}
          />
        </div>
        <div className={style.footer}>
          <Button level="subtleAction" onClick={handleClickCopy}>
            Copy link
          </Button>
          <Button level="primary" disabled={!canSend} onClick={handleSendInvite}>
            Invite
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default ShareModal;
