import { routes } from "@/defs";
import useRedirectToNSPage from "@/hooks/useRedirectToNSPage";
import useSegment from "@/hooks/useSegment";
import Dot from "@ds/atoms/Dot";
import Text from "@ds/atoms/Text";
import { LEARN_MORE_URL } from "@shared/frontend/learnMoreLinks";
import { NS_BETA_LIBRARY_INTEREST } from "@shared/segment-event-names";
import React, { useCallback } from "react";
import style from "./style.module.css";

/**
 * Renders a banner at the top of the Components page indicating that the Beta
 * Component library is coming soon and allows users to sign up for updates.
 *
 * Should only be rendered with the North Star feature flag enabled.
 */
function BetaBanner() {
  const navigateToUrl = useRedirectToNSPage();
  const { track } = useSegment();

  const handleClickLearnMore = useCallback(() => {
    window.open(LEARN_MORE_URL.LIBRARY, "_blank");
    track({
      event: NS_BETA_LIBRARY_INTEREST,
      properties: {
        action: "learn_more",
        location: "legacy-library-page",
        version: "NS",
      },
    });
  }, [track]);

  const goToLibrary = useCallback(() => {
    navigateToUrl(routes.nonNavRoutes.library.getPath());
  }, [navigateToUrl]);

  return (
    <div className={style.banner}>
      <div className={style.bannerLeft}>
        <Text color="primary" size="small" weight="medium">
          You're working in the current Ditto components library
        </Text>
        <Dot size={2} color="icon-secondary" />
        <Text color="action" asLink size="small" onClick={goToLibrary}>
          Toggle to the new Ditto library beta
        </Text>
      </div>
      <div>
        <Text color="secondary" size="small" asLink onClick={handleClickLearnMore}>
          Learn more about the New Ditto beta
        </Text>
      </div>
    </div>
  );
}

export default BetaBanner;
