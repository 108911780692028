import { textItemFamilyAtom } from "@/stores/TextItem";
import { IVariantTab } from "@ds/molecules/VariantTabs";
import client from "@shared/frontend/http/httpClient";
import { createEmptyRichText } from "@shared/frontend/richText/plainTextToRichText";
import { extractVariableMetadataFromRichText, serializeTipTapRichText } from "@shared/frontend/richText/serializer";
import { showToastActionAtom } from "@shared/frontend/stores/Toast";
import { isDiffRichText } from "@shared/lib/text";
import { ITextItem, ITextItemVariantUpdate } from "@shared/types/TextItem";
import { AddVariantData, AddVariantUpdateType, BASE_VARIANT_ID, IVariant } from "@shared/types/Variant";
import logger from "@shared/utils/logger";
import ObjectID from "bson-objectid";
import { atom } from "jotai";
import { soon, soonAll } from "jotai-derive";
import { atomFamily } from "jotai/utils";
import uniq from "lodash/uniq";
import { updateLibraryComponentActionAtom } from "./Components";
import {
  editableHasChangesAtom,
  formattedTextItemVariantsWithChangesAtom,
  hasInlineEditingChangesAtom,
  hasNewVariantChangesAtom,
  hasTextItemVariantChangesAtom,
  stopInlineEditingActionAtom,
} from "./Editing";
import { discardChangesActionAtom, modalAtom } from "./Modals";
import {
  projectAtom,
  projectBlocksFamilyAtom,
  projectIdAtom,
  updateTextItemsActionAtom,
  workspaceIdAtom,
} from "./Project";
import { detailsPanelPropsAtom } from "./ProjectSelection";
import { deferredVariantsAtom, variantsAtom } from "./Workspace";
type _TType = [ITextItem | Promise<ITextItem>, ...(ITextItem | Promise<ITextItem>)[]];
/**
 * Atom family for getting variant tabs for a block by its variantIds.
 * @param variantIds - The set of variantIds that exist in the block.
 */
export const variantTabsForBlockFamilyAtom = atomFamily((blockId: string | null) => {
  const variantTabsForBlockFamilyAtomAtom = atom((get) => {
    return soon(get(projectBlocksFamilyAtom(blockId)), (block) => {
      if (block) {
        const soonTextItems = soonAll(
          block.textItems.map((textItem) => get(textItemFamilyAtom(textItem._id))) as _TType
        );

        return soon(soonAll([soonTextItems, get(deferredVariantsAtom)]), ([textItems, variants]) => {
          const variantIds = textItems.flatMap((textItem) => textItem.variants.map((variant) => variant.variantId));
          const variantMap = new Map<string, IVariantTab>(variants.map((v) => [v._id, { id: v._id, name: v.name }]));
          const blockVariants = uniq(variantIds)
            .flatMap((variantId) => variantMap.get(variantId) ?? [])
            .sort((a, b) => a.name.localeCompare(b.name, undefined, { sensitivity: "base" }));
          if (blockVariants.length > 0) {
            blockVariants.unshift({ name: "Base", id: BASE_VARIANT_ID });
          }
          return blockVariants;
        });
      }
      return [];
    });
  });
  variantTabsForBlockFamilyAtomAtom.debugLabel = `variantTabsForBlockFamilyAtom (${blockId})`;

  return variantTabsForBlockFamilyAtomAtom;
});

/**
 * Atom family that returns an atom containing the selected variantId for each block.
 * Defaults to the base variant (BASE_VARIANT_ID) if blockId is null (block with null id pointing to text items)
 * @param blockId - The id of the block to get the selected variant for.
 */
export const blockSelectedVariantIdFamilyAtom = atomFamily((blockId: string | null) => {
  // Create a base atom for this block's selected variant
  const variantIdAtom = atom(BASE_VARIANT_ID);

  // Create a derived atom that validates the selected variant exists in the block
  const blockSelectedVariantIdValueAtom = atom(
    (get) => {
      // For non-block text items, we always select the base variant
      if (blockId === null) return BASE_VARIANT_ID;
      const currentVariantId = get(variantIdAtom);

      // Get the valid variants for this block
      return soon(get(variantTabsForBlockFamilyAtom(blockId)), (variantTabs) => {
        // Check if the current variant is valid for this block
        const isValidVariant = variantTabs.some((tab) => tab.id === currentVariantId);

        // Return current variant if valid, otherwise return base variant
        return isValidVariant ? currentVariantId : BASE_VARIANT_ID;
      });
    },
    (get, set, newVariantId: string) => {
      const hasTextItemChanges = get(editableHasChangesAtom);
      const hasTextItemVariantChanges = get(hasTextItemVariantChangesAtom);
      const hasInlineEditingChanges = get(hasInlineEditingChangesAtom);
      const hasNewVariantChanges = get(hasNewVariantChangesAtom);
      const variantId = get(variantIdAtom);

      // Exit early if user selected same variant
      if (newVariantId === variantId) return;

      const hasVariantChanges =
        hasTextItemVariantChanges || hasNewVariantChanges || (hasInlineEditingChanges && variantId !== BASE_VARIANT_ID);

      function _handleVariantTabChange() {
        if (blockId !== null) set(variantIdAtom, newVariantId);

        // Clear Variants panel props state when we switch between variants
        // and resets form state in Variants panel
        set(detailsPanelPropsAtom, (props) => ({
          ...props,
          variants: undefined,
          resetFormState: true,
        }));

        // Clear inline edit state if we choose the "Discard" option when switching variant tabs
        if (hasInlineEditingChanges) {
          set(stopInlineEditingActionAtom, { skipConfirmation: true });
        }
      }

      // TODO: Refactor this to use discardChangesModalActionAtom
      if (hasTextItemChanges || hasTextItemVariantChanges || hasNewVariantChanges || hasInlineEditingChanges) {
        let headline = `Discard unsaved text item ${hasVariantChanges ? "variant " : ""}changes?`;
        let content = "Your changes will be discarded. This can't be undone.";

        if (hasTextItemVariantChanges) {
          const formattedVariantsWithChanges = get(formattedTextItemVariantsWithChangesAtom);
          headline = "Discard changes to variants?";
          content = formattedVariantsWithChanges;
        }
        let activeElement = document.activeElement;

        set(modalAtom, {
          headline,
          content,
          actionText: "Discard",
          onAction: () => {
            set(discardChangesActionAtom, { ignoreInlineChanges: false });

            _handleVariantTabChange();
          },
          onOpenChange: (open) => {
            if (open) return;
            set(modalAtom, null);
          },
          onCancel: () => {
            if (activeElement) {
              // check if active element has the focus method and call it if so
              (activeElement as HTMLElement).focus?.();
            }
          },
        });
      } else {
        _handleVariantTabChange();
      }
    }
  );

  return blockSelectedVariantIdValueAtom;
});

/**
 * Action atom for attaching a variant to a text item.
 * If the variant doesn't exist yet, we create an id for it optimistically and save it to the backend.
 * If the variant already exists, we attach its metadata to the text item.
 * @param variant - The metadata for the variant being attached.
 * @param updateType - The type of update to perform -- CREATE if we're creating and attaching a new variant, otherwise UPDATE for only attaching an existing variant.
 * @param textItemId - The id of the text item to attach the variant to.
 */
export const attachVariantActionAtom = atom(
  null,
  async (get, set, props: { variant: AddVariantData; updateType: AddVariantUpdateType; itemId: string }) => {
    const projectId = get(projectIdAtom);
    if (!projectId) throw new Error("projectIdAtom is not set");

    const workspaceId = get(workspaceIdAtom);
    if (!workspaceId) throw new Error("workspaceIdAtom is not set");

    // update text item optimistically
    const textItemAtom = textItemFamilyAtom(props.itemId);
    const textItem = await get(textItemAtom);
    const textItemBlockId = textItem.blockId;
    const originalTextItemVariants = (await get(textItemAtom)).variants;
    const originalWorkspaceVariants = await get(variantsAtom);

    // If we're attaching an existing variant, use its ID
    // Otherwise, we're creating a new variant, so generate an optimistic ID for Jotai and to save to backend
    const variantId = props.variant.variantId ? props.variant.variantId : new ObjectID().toString();

    // If update type is CREATE, we're creating a new variant
    // Optimistically add variant to store of workspace variants
    if (props.updateType === "CREATE") {
      const newVariant = {
        name: props.variant.name,
        _id: variantId,
        workspace_ID: workspaceId,
        description: "",
        apiID: "",
        docs: [],
        components: [],
        folder_id: null,
        isSample: false,
      } as IVariant;

      // Update the workspace variants to include the new variant
      set(variantsAtom, [...originalWorkspaceVariants, newVariant]);
    }

    // Extract variable metadata for optimistic update
    const variantVariables = extractVariableMetadataFromRichText(props.variant.rich_text);

    // Update the text item to include the new variant in Jotai
    set(textItemAtom, (prev) => ({
      ...prev,
      variants: [
        // Push new variant to the front of the array
        {
          variantId: variantId,
          text: serializeTipTapRichText(props.variant.rich_text).text,
          rich_text: props.variant.rich_text,
          status: props.variant.status,
          lastSync: null,
          lastSyncRichText: null,
          variables: variantVariables,
          plurals: [],
          text_last_modified_at: new Date(),
        },
        ...prev.variants,
      ],
    }));

    // Update project atom to reflect the new variant
    // Find the block that contains the updated text item and update its variantIds field
    const project = await get(projectAtom);
    const variants = await get(variantsAtom);
    const updatedBlocks = project.blocks.map((block) => {
      if (block._id !== textItemBlockId) return block;

      // Spread existing IDs first, then add new one
      const existingVariantIds = block.variantIds ?? [];
      const uniqueVariantIds = [...new Set([...existingVariantIds, variantId])];

      // Sort variant IDs by their associated names
      const sortedVariantIds = uniqueVariantIds.sort((a, b) => {
        // Handle BASE_VARIANT_ID (should always be first)
        if (a === BASE_VARIANT_ID) return -1;
        if (b === BASE_VARIANT_ID) return 1;

        // Find variant names
        const variantA = variants.find((v) => v._id === a);
        const variantB = variants.find((v) => v._id === b);

        // Use case sensitive comparison (match mongo sort order)
        return (variantA?.name ?? "") < (variantB?.name ?? "") ? -1 : 1;
      });
      const selectedVariant = get(blockSelectedVariantIdFamilyAtom(block._id));
      if (selectedVariant !== BASE_VARIANT_ID) {
        set(blockSelectedVariantIdFamilyAtom(block._id), variantId);
      }
      return {
        ...block,
        variantIds: sortedVariantIds,
      };
    });
    set(projectAtom, { ...project, blocks: updatedBlocks });

    try {
      await client.dittoProject.updateTextItems({
        projectId,
        updates: [
          {
            textItemIds: [props.itemId],
            richText: props.variant.rich_text,
            status: props.variant.status,
          },
        ],
        ...(props.updateType === "CREATE"
          ? {
              newVariant: {
                name: props.variant.name ?? "",
                variantId,
              },
            }
          : {}),
        ...(props.updateType === "ATTACH" ? { variantId } : {}),
      });
    } catch (e) {
      set(showToastActionAtom, { message: "Something went wrong attaching the variant to this text item" });
      logger.error(`Error updating text item with id ${props.itemId}}`, { context: { projectId } }, e);

      // revert optimistic update
      set(variantsAtom, originalWorkspaceVariants);
      set(textItemAtom, (prev) => ({ ...prev, variants: originalTextItemVariants }));
      set(projectAtom, project);
    }
  }
);

/**
 * Action atom for editing a text item's variant data.
 * Optimistically updates the variant data of the text item atom (from textItemFamilyAtom) for the given textItemId.
 * @param textItemId - The id of the text item to update.
 * @param update - The update object for the text item's variant, requires variantId as a property.
 */
export const updateTextItemVariantActionAtom = atom(
  null,
  async (get, set, props: { itemId: string; update: ITextItemVariantUpdate }) => {
    const projectId = get(projectIdAtom);
    if (!projectId) throw new Error("projectIdAtom is not set");

    // Update the text item's variant data in Jotai
    const textItemAtom = textItemFamilyAtom(props.itemId);
    const textItem = await get(textItemAtom);

    // Find the variant index to update (if index not found, attach the variant)
    const variantToUpdateIndex = textItem.variants.findIndex((v) => v.variantId === props.update.variantId);

    // Make sure something actually changed before continuing
    let hasDiff = false;
    const oldVariant = textItem.variants[variantToUpdateIndex];
    if (!oldVariant) {
      hasDiff = true;
    } else if ("status" in props.update && oldVariant.status !== props.update.status) {
      hasDiff = true;
    } else if ("richText" in props.update && isDiffRichText(oldVariant.rich_text, props.update.richText)) {
      hasDiff = true;
    }

    if (!hasDiff) return;

    const originalTextItemVariants = [...textItem.variants];
    let textItemVariantsUpdated = [...textItem.variants];

    if (variantToUpdateIndex === -1) {
      // If the variant doesn't exist on the text item, create and push to front
      const newVariant = {
        variantId: props.update.variantId,
        text: props.update.richText ? serializeTipTapRichText(props.update.richText).text : "",
        rich_text: props.update.richText ?? createEmptyRichText(),
        status: props.update.status ?? "NONE",
        lastSync: null,
        lastSyncRichText: null,
        variables: [],
        plurals: [],
        text_last_modified_at: new Date(),
      };
      textItemVariantsUpdated = [newVariant, ...textItemVariantsUpdated];
    } else {
      // Create a deep copy of the variant to update, so we can revert if the update fails
      const updatedTextItemVariant = structuredClone(textItem.variants[variantToUpdateIndex]);
      textItemVariantsUpdated[variantToUpdateIndex] = {
        ...updatedTextItemVariant,
        ...(props.update.richText
          ? {
              rich_text: props.update.richText,
              text: serializeTipTapRichText(props.update.richText).text,
            }
          : {}),
        ...(props.update.status ? { status: props.update.status } : {}),
      };
    }

    set(textItemAtom, (prev) => ({
      ...prev,
      variants: textItemVariantsUpdated,
    }));

    try {
      const result = await client.dittoProject.updateTextItems({
        projectId,
        updates: [
          {
            textItemIds: [props.itemId],
            ...(props.update.status ? { status: props.update.status } : {}),
            ...(props.update.richText ? { richText: props.update.richText } : {}),
          },
        ],
        variantId: props.update.variantId,
      });

      const updatedTextItems: ITextItem[] = Object.values(result.updatedTextItems);
      const updatedLibraryComponents = Object.values(result.updatedLibraryComponents);

      // Update any library-component attached text item variants
      set(updateTextItemsActionAtom, updatedTextItems);
      for (const updatedLibraryComponent of updatedLibraryComponents) {
        set(updateLibraryComponentActionAtom, { _id: updatedLibraryComponent._id, update: updatedLibraryComponent });
      }
    } catch (e) {
      set(showToastActionAtom, { message: "Something went wrong updating the variant for this text item" });
      logger.error(`Error updating text item with id ${props.itemId}`, { context: { projectId } }, e);

      // revert optimistic update
      set(textItemAtom, (prev) => ({ ...prev, variants: originalTextItemVariants }));
    }
  }
);
