import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { isDiffRichText } from "@shared/lib/text";
import { ITipTapRichText } from "@shared/types/TextItem";
import classNames from "classnames";
import { Atom, useAtomValue } from "jotai";
import React, { useCallback, useEffect, useRef, useState } from "react";
import batchedAsyncAtomFamily from "../../../../shared/frontend/stores/batchedAsyncAtomFamily";
import { ILibraryComponent } from "../../../../shared/types/LibraryComponent";
import { IUser } from "../../../../shared/types/User";
import Button from "../../atoms/Button";
import Icon from "../../atoms/Icon";
import { BoltIcon } from "../../atoms/Icon/icons/BoltIcon";
import Label from "../../atoms/Label";
import Text from "../../atoms/Text";
import ActionableTextEntity, { ITextEntity } from "../../molecules/ActionableTextEntity";
import style from "./index.module.css";

interface IProps {
  className?: string;
  style?: React.CSSProperties;
  libraryComponentFamilyAtom: (
    id: string | null
  ) => ReturnType<ReturnType<typeof batchedAsyncAtomFamily<ILibraryComponent>>["familyAtom"]>;
  usersByIdAtom: Atom<Record<string, IUser> | Promise<Record<string, IUser>>>;
  componentIds: string[];
  /**
   * ID of the currently selected component suggestion, null if no component is selected.
   */
  selectedComponentId: string | null;
  /**
   * Callback for when user selects a component suggestion.
   * @param componentId - ID of the component that will be selected
   */
  onComponentSelect: (componentId: string) => void;
  /**
   * Callback for when user deselects a component suggestion.
   */
  onComponentDeselect: () => void;
  /**
   * Callback for when the action button is clicked.
   * @param componentId - ID of the component that will be acted upon
   * @returns void
   */
  onComponentActionClick: (componentId: string) => void;
  /**
   * Helper text to display next to the action button.
   */
  originalRichText?: ITipTapRichText;
}

export function ComponentTextMatchSuggestions(props: IProps) {
  const expandedContentRef = useRef<HTMLDivElement>(null);
  const [expandedContentState, setExpandedContentState] = useState<"open" | "closed" | "unset">("unset");
  const [currComponentIdx, setCurrComponentIdx] = useState<number>(0);

  const { componentIds, onComponentActionClick, selectedComponentId, onComponentSelect, onComponentDeselect } = props;

  // We need to recalculate the content of the expanded content on mount and when the height of the content changes.
  const computeExpandedContentHeight = useCallback(
    function recalculateExpandedContentHeight() {
      // Wrap in a timeout to ensure DOM has fully painted, to avoid error "ResizeObserver loop completed with undelivered notifications"
      setTimeout(() => {
        if (!expandedContentRef.current) return;
        const expandedContentHeight = expandedContentRef.current.offsetHeight;
        document.documentElement.style.setProperty("--expanded-content-height", `${expandedContentHeight}px`);
      }, 0);
    },
    [expandedContentRef]
  );

  useEffect(
    function observeExpandedContentHeight() {
      const expandedContentResizeObserver = new ResizeObserver(computeExpandedContentHeight);
      if (expandedContentRef.current) {
        expandedContentResizeObserver.observe(expandedContentRef.current);
      }
      return () => {
        expandedContentResizeObserver.disconnect();
      };
    },
    [computeExpandedContentHeight]
  );

  if (componentIds.length === 0) return <></>;

  function handleToggleExpand() {
    setExpandedContentState(expandedContentState !== "closed" ? "closed" : "open");
    if (selectedComponentId) onComponentDeselect();
  }

  function handleNextSuggestionClick() {
    setCurrComponentIdx(currComponentIdx + 1);
    if (selectedComponentId) onComponentDeselect();
    if (expandedContentState === "closed") setExpandedContentState("open");
  }

  function handlePreviousSuggestionClick() {
    setCurrComponentIdx(currComponentIdx - 1);
    if (selectedComponentId) onComponentDeselect();
    if (expandedContentState === "closed") setExpandedContentState("open");
  }

  const componentSuggestionId = props.componentIds[currComponentIdx];

  const selectPreviousSuggestionDisabled = currComponentIdx === 0;
  const selectNextSuggestionDisabled = currComponentIdx === componentIds.length - 1;

  const paginationText = `${currComponentIdx + 1} of ${componentIds.length}`;

  const suggestionHeader =
    componentIds.length > 1
      ? "Your library has existing components that have the same value as this text item."
      : "Your library has an existing component that has the same value as this text item.";

  return (
    <div className={style.ComponentTextMatchSuggestionsWrapper}>
      <div className={style.header}>
        <div className={style.headerLabel}>
          <Icon Icon={<BoltIcon />} size="xxs" color="purple" />
          <Label size="small" weight="medium">
            Search suggestion
          </Label>
          {componentIds.length > 1 && (
            <div className={style.navButtons}>
              <Button
                type="icon"
                level="subtle"
                size="small"
                disabled={selectPreviousSuggestionDisabled}
                onClick={handlePreviousSuggestionClick}
              >
                <KeyboardArrowLeftIcon />
              </Button>
              <Text className={style.paginationText} color="tertiary" size="small" onClick={(e) => e.stopPropagation()}>
                {paginationText}
              </Text>
              <Button
                type="icon"
                level="subtle"
                size="small"
                disabled={selectNextSuggestionDisabled}
                onClick={handleNextSuggestionClick}
              >
                <KeyboardArrowRightIcon />
              </Button>
            </div>
          )}
        </div>
        <div onClick={handleToggleExpand}>
          <Icon
            className={classNames(style.expandIcon, {
              [style.closed]: expandedContentState === "closed",
            })}
            Icon={<ExpandMoreIcon />}
            size="xs"
            color="secondary"
          />
        </div>
      </div>
      <div className={classNames(style.expandedContentWrapper)} data-state={expandedContentState}>
        <SuggestionContent
          ref={expandedContentRef}
          componentId={componentSuggestionId}
          suggestionHeader={suggestionHeader}
          libraryComponentFamilyAtom={props.libraryComponentFamilyAtom}
          usersByIdAtom={props.usersByIdAtom}
          selectedComponentId={selectedComponentId}
          onComponentSelect={onComponentSelect}
          onComponentDeselect={onComponentDeselect}
          onComponentActionClick={onComponentActionClick}
          originalRichText={props.originalRichText}
        />
      </div>
    </div>
  );
}

interface ISuggestionContentProps {
  componentId: string;
  suggestionHeader: string;
  libraryComponentFamilyAtom: (
    id: string | null
  ) => ReturnType<ReturnType<typeof batchedAsyncAtomFamily<ILibraryComponent>>["familyAtom"]>;
  usersByIdAtom: Atom<Record<string, IUser> | Promise<Record<string, IUser>>>;
  selectedComponentId: string | null;
  onComponentSelect: (componentId: string) => void;
  onComponentDeselect: () => void;
  onComponentActionClick: (componentId: string) => void;
  originalRichText?: ITipTapRichText;
}

const SuggestionContent = React.forwardRef<HTMLDivElement, ISuggestionContentProps>(function SuggestionContent(
  props,
  ref
) {
  const {
    componentId,
    suggestionHeader,
    selectedComponentId,
    libraryComponentFamilyAtom,
    usersByIdAtom,
    onComponentSelect,
    onComponentDeselect,
    onComponentActionClick,
  } = props;

  const handleComponentActionClick = useCallback(() => {
    onComponentActionClick(componentId);
  }, [componentId, onComponentActionClick]);

  const handleComponentSelect = useCallback(() => {
    onComponentSelect(componentId);
  }, [componentId, onComponentSelect]);

  const handleComponentDeselect = useCallback(() => {
    onComponentDeselect();
  }, [onComponentDeselect]);

  const component = useAtomValue(libraryComponentFamilyAtom(componentId));
  const users = useAtomValue(usersByIdAtom);

  const data: ITextEntity = {
    _id: componentId,
    assignee: component?.assignee ? users[component.assignee] : null,
    instanceCount: component?.instances.length,
    defaultValue: component?.rich_text,
    component: { name: component.name },
    notes: component?.notes,
    status: component?.status,
    tags: component?.tags,
  };

  const helperText =
    props.originalRichText !== undefined && isDiffRichText(props.originalRichText, data.defaultValue)
      ? "Text will be updated to match"
      : "";

  return (
    <div className={style.suggestionContentWrapper} ref={ref}>
      <Text className={style.suggestionHeaderText} color="secondary" size="small">
        {suggestionHeader}
      </Text>
      <ActionableTextEntity
        data={data}
        isSelected={selectedComponentId === componentId}
        onSelect={handleComponentSelect}
        onDeselect={handleComponentDeselect}
        onActionClick={handleComponentActionClick}
        actionText="Link"
        helperText={helperText}
      />
    </div>
  );
});

export default ComponentTextMatchSuggestions;
