import { createRoot, Root } from "react-dom/client";

import { useMemo, useRef } from "react";

/**
 * Use this hook to create a preview image for a native HTML5 drag n drop component.
 * @param getContentToRender - A function that returns the React node to be rendered for the drag preview.
 * @example
 * ```tsx
 * const dragPreview = useHTML5DragPreview(() => <div>I'm being dragged!</div>);
 *
 * const onDragStart = (event) => dragPreview.handleDragStart(event);
 * const onDragEnd = () => dragPreview.handleDragEnd();
 *
 * return <div draggable={true}>Drag me!</div>
 * ```
 */
export function useHTML5DragPreview(getContentToRender: () => React.ReactNode) {
  const dragPreviewRef = useRef<HTMLDivElement | null>(null);
  const dragRootRef = useRef<Root | null>(null);

  return useMemo(
    () => ({
      handleDragStart: (event: React.DragEvent<HTMLDivElement>) => {
        // The native drag preview requires an HTML element mounted in the DOM
        if (!dragPreviewRef.current) {
          const rootHtml = document.createElement("div");
          // Ensure the element used for preview is not visible on-screen
          rootHtml.style.position = "fixed";
          rootHtml.style.top = "-10000px";
          dragPreviewRef.current = document.body.appendChild(rootHtml);
        }

        // Creating a React root is the recommended way of converting React nodes to HTML elements
        // in the browser
        if (!dragRootRef.current) {
          dragRootRef.current = createRoot(dragPreviewRef.current);
        }

        dragRootRef.current.render(getContentToRender());

        event.dataTransfer.setDragImage(dragPreviewRef.current, 0, 0);
      },
      handleDragEnd: () => {
        if (dragPreviewRef.current) {
          document.body.removeChild(dragPreviewRef.current);
          dragPreviewRef.current = null;
        }
        if (dragRootRef.current) {
          dragRootRef.current.unmount();
          dragRootRef.current = null;
        }
      },
    }),
    [getContentToRender]
  );
}
