import useRedirectToNSPage from "@/hooks/useRedirectToNSPage";
import Icon from "@ds/atoms/Icon";
import LibraryComponentIcon from "@ds/icons/LibraryComponentIcon";
import DescriptionIcon from "@mui/icons-material/Description";
import ImportContactsIcon from "@mui/icons-material/ImportContacts";
import React from "react";
import { useHistory } from "react-router-dom";
import { routes } from "../../../../defs";
import { VariableInstanceData } from "../../types";
import style from "./style.module.css";

interface Props {
  instanceData: VariableInstanceData;
}
const VariableInstances = ({ instanceData: { projects, wsComps, libraryComponents } }: Props) => {
  const history = useHistory();
  const handleRedirectToNSPage = useRedirectToNSPage();
  const goToDoc = (id: string, version: 1 | 2) => {
    history.push(routes.nonNavRoutes.project.getPath(id, version));
  };

  const goToWsComp = (id: string) => {
    history.push(routes.nonNavRoutes.component.getPath(id));
  };

  const goToLibraryComp = (folderId: string | null, id: string) => {
    const { pathname, searchParams } = routes.nonNavRoutes.library.getUrlParts({
      folderId,
      selectedComponentIds: [id],
    });

    handleRedirectToNSPage(pathname, searchParams);
  };

  const noInstances = projects.length === 0 && wsComps.length === 0 && libraryComponents.length === 0;

  return (
    <div className={style.instances}>
      {noInstances && <div className={style.draft}>Variable not used in any projects or components.</div>}
      {projects &&
        projects.map((project, index) => (
          <div key={index} className={style.docLink} onClick={() => goToDoc(project._id, project.version)}>
            <DescriptionIcon className={style.icon} />
            <span className={style.docName}>{project.projectName}</span>
          </div>
        ))}
      {wsComps &&
        wsComps.map((wsComp, index) => (
          <div key={index} className={style.componentInstance} onClick={() => goToWsComp(wsComp?._id)}>
            <ImportContactsIcon className={style.componentIcon} />
            <span className={style.componentName}>{wsComp.name}</span>
          </div>
        ))}
      {libraryComponents &&
        libraryComponents.map((libraryComp, index) => (
          <div
            key={index}
            className={style.componentInstance}
            onClick={() => goToLibraryComp(libraryComp.folderId, libraryComp._id)}
          >
            <Icon Icon={<LibraryComponentIcon />} className={style.libraryComponentIcon} />
            <span className={style.componentName}>{libraryComp.name}</span>
          </div>
        ))}
    </div>
  );
};

export default VariableInstances;
