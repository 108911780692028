import { routes } from "@/defs";
import useRedirectToNSPage from "@/hooks/useRedirectToNSPage";
import Dot from "@ds/atoms/Dot";
import Text from "@ds/atoms/Text";
import React, { useCallback } from "react";
import style from "./style.module.css";

/**
 * Renders a banner at the top of the new Library page allowing users to go back to the legacy route.
 */
function LegacyBanner() {
  const navigateToUrl = useRedirectToNSPage();

  const goToLegacy = useCallback(() => {
    navigateToUrl(routes.nonNavRoutes.component.getPath(null));
  }, [navigateToUrl]);

  return (
    <div className={style.banner}>
      <div className={style.bannerLeft}>
        <Text color="primary" size="small" weight="medium">
          You're working in the new Ditto library beta
        </Text>
        <Dot size={2} color="icon-secondary" />
        <Text color="action" asLink size="small" onClick={goToLegacy}>
          Toggle to the legacy components library
        </Text>
      </div>
    </div>
  );
}

export default LegacyBanner;
