import useSegment from "@/hooks/useSegment";
import { projectDeveloperIdAtom } from "@/stores/Project";
import Text from "@ds/atoms/Text";
import Callout from "@ds/molecules/Callout";
import Modal from "@ds/molecules/Modal";
import EditableDeveloperId from "@ds/organisms/EditableDeveloperId";
import { LEARN_MORE_URL } from "@shared/frontend/learnMoreLinks";
import { DEV_DOCS_LINK_CLICKED, DEV_INTEGRATIONS_DEV_ID_COPIED } from "@shared/segment-event-names";
import React, { useCallback } from "react";
import style from "./style.module.css";

interface IProjectDevIntegrationsModalProps {
  open: boolean;
  onOpenChange: (isOpen: boolean) => void;
}

function ProjectDevIntegrationsModal({ open, onOpenChange }: IProjectDevIntegrationsModalProps) {
  const { track } = useSegment();

  const onCopy = useCallback(() => {
    track({
      event: DEV_INTEGRATIONS_DEV_ID_COPIED,
      properties: {
        version: "NS",
        application: "web_app",
        type: "project",
        location: "project_developer_integrations_modal",
      },
    });
  }, [track]);

  const handleDocsClick = useCallback(() => {
    window.open(LEARN_MORE_URL.DEVELOPER_HELP_DOCS, "_blank");
    track({
      event: DEV_DOCS_LINK_CLICKED,
      properties: { version: "NS", application: "web_app", location: "project_developer_integrations_modal" },
    });
  }, [track]);

  return (
    <Modal
      headline="Integrate into development"
      description="Integration into development"
      showCloseButton
      open={open}
      onOpenChange={onOpenChange}
      className={style.modal}
    >
      <Callout
        variant="secondary"
        content="Integrate Ditto with your codebase to sync edits directly into development."
        actionText="View developer docs"
        onActionClick={handleDocsClick}
      />
      <div className={style.developerIdContainer}>
        <Text size="small" weight="strong">
          Project ID
        </Text>
        <EditableDeveloperId developerIdAtom={projectDeveloperIdAtom} onCopy={onCopy} />
      </div>
    </Modal>
  );
}

export default ProjectDevIntegrationsModal;
