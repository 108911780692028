import {
  clearFiltersActionAtom,
  resetComponentNameAtom,
  selectedComponentIdAtom as selectedComponentIdForLinkingAtom,
  selectedLibraryFolderIdAtom,
} from "@/stores/ComponentLinkingFlow";
import { libraryComponentFamilyAtom } from "@/stores/Components";
import { textItemFamilyAtom } from "@/stores/TextItem";
import { DetailsPanelProps } from "@ds/organisms/VariantsPanel";
import { EMPTY_RICH_TEXT } from "@shared/frontend/constants";
import atomWithURLStorage from "@shared/frontend/stores/atomWithURLStorage";
import { getPriorSelections } from "@shared/frontend/stores/selection";
import { showToastActionAtom } from "@shared/frontend/stores/Toast";
import { ITextItem, ITextItemVariant, ITipTapRichText } from "@shared/types/TextItem";
import { BASE_VARIANT_ID } from "@shared/types/Variant";
import { isNonEmptyArray } from "@shared/utils/array";
import { Atom, atom, WritableAtom } from "jotai";
import { derive, soon, soonAll } from "jotai-derive";
import { focusAtom } from "jotai-optics";
import { atomFamily } from "jotai/utils";
import { SetStateAction } from "react";
import { DragStartEvent } from "react-aria";
import { inlineEditingAtom, stopInlineEditingActionAtom } from "./Editing";
import {
  blockIdsAtom,
  COMMENT_THREAD_SELECTION_PARAM,
  DETAILS_PANEL_PARAM,
  locationAtom,
  textItemIdsAtom,
} from "./Location";
import { discardChangesModalActionAtom } from "./Modals";
import { allBlockIdsAtom, allTextItemIdsAtom, blockFamilyAtom, projectBlocksSplitAtom } from "./Project";
import { blockSelectedVariantIdFamilyAtom } from "./Variants";
import { deferredVariantsAtom } from "./Workspace";

// MARK: - Source Atoms

const _selectedCommentAtom = atomWithURLStorage(COMMENT_THREAD_SELECTION_PARAM, locationAtom);
export const selectedCommentAtom = atom(
  (get) => {
    const commentSelections = get(_selectedCommentAtom);

    return commentSelections && commentSelections.length > 0 ? commentSelections[0] : null;
  },
  (_get, set, value: string[] | null) => {
    set(_selectedCommentAtom, value);
  }
);

export const setSelectedCommentThreadAtom = atom(null, (_get, set, value: string) => {
  set(_selectedCommentAtom, [value]);
});

export const isCommentSelectedAtomFamily = atomFamily((id: string) => {
  const isCommentSelectedAtom = atom((get) => get(selectedCommentAtom) === id);
  isCommentSelectedAtom.debugLabel = `isCommentSelectedAtom: ${id}`;
  return isCommentSelectedAtom;
});

// Most selection and filtering states should keep their source of truth in the URL.

export type Selection =
  | {
      type: "none";
    }
  | {
      type: "text";
      ids: string[];
    }
  | {
      type: "block";
      id: string;
    };

/**
 * This atom contains a set of comments being edited:
 * - If writing a new comment, will contain the text "new"
 * - If writing a reply, will contain the comment_thread_id of the comment being replied to
 */
export const commentEditsAtom = atom<Set<string>>(new Set<string>());

// Resets comment edits state
export const resetCommentEditsActionAtom = atom(null, (get, set) => {
  set(commentEditsAtom, new Set<string>());
});

/**
 * Special atom type for use only in this file.
 * Stores the current selection state for the project.
 * These selection states are mutually exclusive -- we can have text items selected, or blocks selected, but not both.
 * Any other kind of selection logic should be enforced here as well.
 */
function pageSelectionAtom(): WritableAtom<Selection, [SetStateAction<Selection>], void> {
  const selectionAtom = atom(
    (get) => {
      const textItemIds = get(textItemIdsAtom);
      const blockIds = get(blockIdsAtom);
      // Intentionally throwing a hard error here -- this should never happen, and if it does we should figure out why.
      if (textItemIds && blockIds) {
        throw new Error("Page selection should be mutually exclusive");
      }

      /**
       * Check that the text item IDs and block ID exists in the project.
       */
      const projectBlockIds = get(allBlockIdsAtom);
      const projectTextItemIds = get(allTextItemIdsAtom);

      const filteredBlockIds = blockIds?.filter((id) => projectBlockIds.includes(id));
      const filteredTextItemIds = textItemIds?.filter((id) => projectTextItemIds.includes(id));

      // It's possible for the URL to have an empty value for the selection URL keys. This means the atom returns a value
      // of an empty array. As of the time of this comment, we treat this case as if the selection is empty. If this ever
      // changes, we should make sure that every consumer of `selectedTextItems` and `selectedBlocks` knows they might
      // receive an empty array.
      if (filteredTextItemIds && filteredTextItemIds.length > 0) {
        return { type: "text", ids: filteredTextItemIds } as Selection;
      } else if (filteredBlockIds && filteredBlockIds.length > 0) {
        return { type: "block", id: filteredBlockIds[0] } as Selection;
      } else {
        return { type: "none" } as Selection;
      }
    },
    (get, set, newSelection: Selection) => {
      // Detect if there is actually a change in selection
      function hasSelectionChanged() {
        const currentSelection = get(selectionAtom);
        if (currentSelection.type !== newSelection.type) {
          return true;
        }

        switch (newSelection.type) {
          case "none":
            return false;
          case "block":
            const currentBlockId = "id" in currentSelection && currentSelection.id; // Even though we already know both selection types are "block", TS doesn't
            return newSelection.id !== currentBlockId;
          case "text":
            const currentSelectedItems = "ids" in currentSelection ? currentSelection.ids : []; // Even though we already know both selection types are "text", TS doesn't
            if (newSelection.ids.length !== currentSelectedItems.length) {
              return true;
            }

            return newSelection.ids.toSorted().join(",") !== currentSelectedItems.toSorted().join(",");
        }
      }

      if (!hasSelectionChanged()) {
        return;
      }

      const clearLibraryInteractionView = () => set(clearLibraryInteractionViewActionAtom);

      function _handleSelectionChange() {
        if (newSelection.type === "none") {
          clearLibraryInteractionView();

          set(textItemIdsAtom, null);
          set(blockIdsAtom, null);
          set(selectedCommentAtom, null);
        } else if (newSelection.type === "text") {
          const newSelectionIds = new Set(newSelection.ids);
          clearLibraryInteractionView();

          set(textItemIdsAtom, newSelection.ids);
          set(blockIdsAtom, null);
          set(selectedCommentAtom, null);
        } else if (newSelection.type === "block") {
          clearLibraryInteractionView();

          set(textItemIdsAtom, null);
          set(blockIdsAtom, [newSelection.id]);
          set(selectedCommentAtom, null);
        }
      }

      // Either immediately change selection or show a discard changes confirmation modal
      set(discardChangesModalActionAtom, {
        onConfirm: _handleSelectionChange,
        ignoreInlineChanges: false,
        activeElement: document.activeElement,
      });
    }
  );

  return selectionAtom;
}
export const selectedItemsAtom = pageSelectionAtom();

// MARK: - Derived Atoms

/**
 * Warning: This atom can contain duplicates if the user
 * selects non-consecutive text items.
 */
export const selectedTextItemIdsAtom = atom((get) => {
  const selection = get(selectedItemsAtom);
  if (selection.type === "text") {
    return selection.ids;
  } else {
    return [];
  }
});

// Note: A selected text item may appear in this list more than once to support multi-selection, so using Set to dedupe
export const selectedTextItemsCountAtom = derive([selectedTextItemIdsAtom], (ids) => new Set(ids).size);
export const onlySelectedTextItemIdAtom = derive([selectedTextItemIdsAtom], (ids) =>
  new Set(ids).size === 1 ? ids[0] : null
);

const _selectedTextItemsAtom: Atom<ITextItem[] | Promise<ITextItem[]>> = atom((get) => {
  const ids = get(selectedTextItemIdsAtom);
  const valuesArray = ids.map((id) => get(textItemFamilyAtom(id)));
  if (!isNonEmptyArray(valuesArray)) return [];
  const textItems = soonAll(valuesArray);
  return textItems;
});

export const derivedSelectedTextItemsAtom = derive([_selectedTextItemsAtom], (items) => items);

/**
 * If exactly one text item is selected, returns the full data for that text item.
 * Otherwise, returns null.
 */
export const derivedOnlySelectedTextItemAtom = derive(
  [_selectedTextItemsAtom, onlySelectedTextItemIdAtom],
  (selectedItems, onlySelectedId) => {
    if (!onlySelectedId) return null;
    return selectedItems.find((item) => item._id === onlySelectedId) ?? null;
  }
);

/**
 * Returns the variants that exist on the only selected text item, with their variant names from the derived workspace variants atom.
 */
export const derivedOnlySelectedTextItemVariantsAtom = derive(
  [derivedOnlySelectedTextItemAtom, deferredVariantsAtom],
  (selectedTextItem, wsVariants) => {
    if (!selectedTextItem) return [];
    return selectedTextItem.variants.map((textItemVariant) => ({
      ...textItemVariant,
      name: wsVariants.find((wsVariant) => wsVariant._id === textItemVariant.variantId)?.name ?? "",
    }));
  }
);

/**
 * If one text item is selected, returns its id and ws_comp value.
 * Otherwise return null.
 */
export const singleSelectedTextItemComponentDataAtom = derive([derivedOnlySelectedTextItemAtom], (item) => {
  return item ? { _id: item._id, ws_comp: item.ws_comp } : null;
});

/**
 * If exactly one text item is selected, and it is linked to a library component,
 * returns id of the linked library component. Otherwise returns null.
 */
export const singleSelectedComponentIdAtom = derive([derivedOnlySelectedTextItemAtom], (selectedTextItem) => {
  return selectedTextItem?.ws_comp || null;
});

/**
 * If exactly one text item is selected, and it is linked to a library component,
 * returns the full data of the linked library component. Otherwise returns null.
 */
export const singleSelectedComponentAtom = atom((get) => {
  return soon(get(singleSelectedComponentIdAtom), (id) => {
    return id ? get(libraryComponentFamilyAtom(id)) : null;
  });
});

/**
 * If exactly one text item is selected, and it is linked to a library component,
 * returns the name of the library component. Otherwise returns null.
 */
export const singleSelectedComponentNameAtom: Atom<string | null | Promise<string | null>> = derive(
  [singleSelectedComponentAtom],
  (component) => {
    return component?.name || null;
  }
);

/**
 * Family atom that returns the data of a text item's variant.
 * @param key The unique key for the text item - variant pair (in format `{textItemId}-{variantId}`).
 * @returns The variant data for the given text item - variant pair, including variant name..
 */
export const textItemVariantsFamilyAtom = atomFamily((key: string) => {
  const [textItemId, variantId] = key.split("-");
  const textItemVariantAtom = derive([textItemFamilyAtom(textItemId), deferredVariantsAtom], (textItem, wsVariants) => {
    const variant = textItem.variants.find((variant) => variant.variantId === variantId);
    if (!variant) return null;

    return {
      ...variant,
      name: wsVariants.find((wsVariant) => wsVariant._id === variant.variantId)?.name ?? "",
      placeholder: textItem.text,
    };
  });
  textItemVariantAtom.debugLabel = `textItemVariantAtom: ${textItemId}-${variantId}`;
  return textItemVariantAtom;
});

/**
 * Derived atom that returns the mapping of variant IDs on the text item
 * to their metadata, including the variant's name.
 */
export const selectedItemsVariantsAtom = derive(
  [derivedOnlySelectedTextItemAtom, deferredVariantsAtom],
  (selectedTextItem, variants) => {
    // Multiselection not currently supported for variants
    if (!selectedTextItem) return {};

    const variantIdsToNameMap = new Map<string, string>();
    variants?.forEach((v) => variantIdsToNameMap.set(v._id, v.name));

    const variantIdsToData: Record<string, ITextItemVariant & { name: string }> = {};

    for (const variant of selectedTextItem.variants) {
      variantIdsToData[variant.variantId] = { ...variant, name: variantIdsToNameMap.get(variant.variantId) ?? "" };
    }

    return variantIdsToData;
  }
);

// hit text item family atom for a given variant id
export const selectedBlockIdAtom = atom((get) => {
  const selection = get(selectedItemsAtom);
  if (selection.type === "block") {
    return selection.id;
  } else {
    return null;
  }
});

export const selectedBlockAtom = atom((get) => {
  const selection = get(selectedItemsAtom);
  if (selection.type === "block") {
    return get(blockFamilyAtom(selection.id));
  } else {
    return null;
  }
});

export const selectionTypeAtom = focusAtom(selectedItemsAtom, (optic) => optic.prop("type"));

export const editPanelTitleAtom = atom((get) => {
  const libraryInteractionView = get(libraryInteractionViewAtom);
  if (libraryInteractionView !== null) {
    switch (libraryInteractionView) {
      case "PUBLISH":
        return "Publish to library";
      case "LINK":
        return "Link to existing component";
      case "SWAP":
        return "Swap component";
      default:
        const _never: never = libraryInteractionView;
        break;
    }
  }

  return `Selected ${get(selectionTypeAtom)}`;
});

const editPanelCloseActionAtomAtom = atom((get) => {
  const libraryInteractionView = get(libraryInteractionViewAtom);
  if (libraryInteractionView !== null) {
    return clearLibraryInteractionViewActionAtom;
  }

  return clearSelectionActionAtom;
});

export const editPanelCloseActionAtom = atom(null, (get, set) => {
  const actionAtom = get(editPanelCloseActionAtomAtom);
  set(actionAtom);
});

// MARK: - Actions

/**
 * An action for selecting the text item(s) with the provided IDs, only if they are still present in the project.
 * Use this when working with things like comments or activity which may reference text items that have since been removed.
 */
export const selectTextItemsThatExistActionAtom = atom(null, (get, set, textItemIds: string[]) => {
  const existingTextItemIds = get(allTextItemIdsAtom);
  const validTextItemIds = textItemIds.filter((id) => existingTextItemIds.includes(id));

  if (validTextItemIds.length) {
    set(setSelectedTextIdsActionAtom, validTextItemIds);
  } else {
    const message = textItemIds.length === 1 ? "That text item was deleted" : "Those text items were deleted";
    set(showToastActionAtom, { message });
  }
});

/**
 * This atom is used to determine which items are draggable for a given text item.
 *
 * If the text item primarily being dragged isn't selected, then only that text item
 * is draggable. Otherwise, the entire set of selected text items is draggable.
 */
export const draggableItemsForTextItemAtom = atomFamily((id: string) =>
  atom((get) => {
    const selectedTextItemIds = new Set(get(selectedTextItemIdsAtom));
    const isSelected = get(textItemIsSelectedAtomFamily(id));

    return Array.from(isSelected ? selectedTextItemIds : [id]).map((item) => ({
      "ditto/textItem": item,
      "plain/text": item,
    }));
  })
);

export const textItemIsSelectedAtomFamily = atomFamily((id: string) => {
  const textItemIsSelectedAtom = atom((get) => get(selectedTextItemIdsAtom).includes(id));
  textItemIsSelectedAtom.debugLabel = `textItemIsSelectedAtom: ${id}`;
  return textItemIsSelectedAtom;
});

export const blockIsSelectedAtomFamily = atomFamily((id: string | null) => {
  const blockIsSelectedAtom = atom((get) => get(selectedBlockIdAtom) === id);
  blockIsSelectedAtom.debugLabel = `blockIsSelectedAtom: ${id}`;
  return blockIsSelectedAtom;
});

export const onTextItemClickActionAtomFamily = atomFamily((textItemId: string) =>
  atom(
    null,
    async (
      get,
      set,
      {
        e,
        skipInlineEdit,
        ...textItem
      }: { richText: ITipTapRichText; e: React.MouseEvent<HTMLElement> | DragStartEvent; skipInlineEdit?: boolean }
    ) => {
      const isSelected = get(textItemIsSelectedAtomFamily(textItemId));
      const isInlineEditing = !!get(inlineEditingAtom);
      const selectedTextItemIds = get(selectedTextItemIdsAtom);

      if ("shiftKey" in e && e.shiftKey) {
        // We need the ordered list of all text items to determine the range of elements to select.
        const allItems = get(projectBlocksSplitAtom).flatMap((projectBlockAtom) => get(projectBlockAtom).textItems);

        // If we press SHIFT+click, select the range of elements between the most recently selected element and the current one.
        const mostRecentlySelectedIndex = allItems.findIndex(
          (item) => item._id === selectedTextItemIds[selectedTextItemIds.length - 1]
        );

        // If the last selected element is not found (should never happen), select the current element.
        if (mostRecentlySelectedIndex === -1) {
          set(stopInlineEditingActionAtom, {
            onConfirm: () => {
              set(setSelectedTextIdsActionAtom, [textItemId]);
            },
          });
          return;
        }

        const currentIndex = allItems.findIndex((item) => item._id === textItemId);
        const startIndex = Math.min(mostRecentlySelectedIndex, currentIndex);
        const endIndex = Math.max(mostRecentlySelectedIndex, currentIndex);

        const alreadyPresentSelections = getPriorSelections(
          selectedTextItemIds,
          allItems,
          currentIndex,
          mostRecentlySelectedIndex,
          startIndex,
          endIndex
        );

        // Create the new selections array and ensure the most recently selected element is preserved at the end.
        const newSelections = [
          ...alreadyPresentSelections,
          ...allItems.slice(startIndex, mostRecentlySelectedIndex).map((item) => item._id),
          ...allItems
            .slice(mostRecentlySelectedIndex, endIndex + 1)
            .map((item) => item._id)
            .reverse(),
        ];

        set(stopInlineEditingActionAtom, {
          onConfirm: () => {
            set(setSelectedTextIdsActionAtom, newSelections);
          },
        });
      } else if ("metaKey" in e && e.metaKey) {
        // If we press CMD+click, toggle selection of the element, without affecting other selections.
        set(stopInlineEditingActionAtom, {
          onConfirm: () => {
            if (isSelected) {
              set(
                setSelectedTextIdsActionAtom,
                selectedTextItemIds.filter((id) => id !== textItemId)
              );
            } else {
              // Place the text item twice in the selections array to properly handle both pivot and pointer state (relevant for arrow-shift selection)
              set(setSelectedTextIdsActionAtom, [textItemId, ...selectedTextItemIds, textItemId]);
            }
          },
        });
      } else {
        // If we plain-select a selected element, turn on inline editing and remove other selections.
        if (isSelected && !isInlineEditing) {
          if (!skipInlineEdit) {
            // Check if a variant tab is selected
            const fullTextItem = await get(textItemFamilyAtom(textItemId));
            if (!fullTextItem.blockId) {
              set(inlineEditingAtom, { type: "existing", id: textItemId, richText: textItem.richText });
            } else {
              const currentlySelectedVariant = await get(blockSelectedVariantIdFamilyAtom(fullTextItem.blockId));
              if (currentlySelectedVariant === BASE_VARIANT_ID) {
                set(inlineEditingAtom, { type: "existing", id: textItemId, richText: textItem.richText });
              } else {
                // set the value to whatever the current variant value is
                const variantValue =
                  fullTextItem.variants.find((variant) => variant.variantId === currentlySelectedVariant)?.rich_text ||
                  EMPTY_RICH_TEXT;
                set(inlineEditingAtom, {
                  type: "existing",
                  id: textItemId,
                  richText: variantValue,
                  variantId: currentlySelectedVariant,
                });
              }
            }
          }
          // Clear any other selections so only this item is now selected
          set(setSelectedTextIdsActionAtom, [textItemId]);
          return;
        }

        // If we plain-select a new element, remove all other selections.
        if (!isSelected) {
          if (isInlineEditing) {
            set(stopInlineEditingActionAtom, {
              onConfirm: () => {
                set(setSelectedTextIdsActionAtom, [textItemId]);
              },
            });
          } else {
            set(setSelectedTextIdsActionAtom, [textItemId]);
          }
          return;
        }
      }
    }
  )
);

export const onTextItemKeyDownActionAtom = atom(null, async (get, set, event: React.KeyboardEvent<HTMLDivElement>) => {
  const inlineEditing = get(inlineEditingAtom);

  // Ignore key events if we're currently inline editing - they should go to the editor.
  if (inlineEditing !== null) {
    return;
  }

  switch (event.key) {
    case "ArrowUp":
    case "ArrowDown":
    case "Enter":
      event.preventDefault();
      break;
    default:
      return;
  }

  const currentSelection = get(selectedItemsAtom);
  const selectedTextItemIds = currentSelection.type === "text" ? currentSelection.ids : [];
  const selectedBlockId = currentSelection.type === "block" ? currentSelection.id : null;

  // If we press ENTER, and a single item was selected, start editing the selected item.
  if (event.key === "Enter") {
    const dedupedSelection = new Set(selectedTextItemIds);
    if (dedupedSelection.size !== 1) {
      return;
    }

    const textItemId = selectedTextItemIds[0];
    const textItem = await get(textItemFamilyAtom(textItemId));

    // Check if a variant tab is selected
    if (!textItem.blockId) {
      set(inlineEditingAtom, { type: "existing", id: textItemId, richText: textItem.rich_text });
    } else {
      const currentlySelectedVariant = await get(blockSelectedVariantIdFamilyAtom(textItem.blockId));
      if (currentlySelectedVariant === BASE_VARIANT_ID) {
        set(inlineEditingAtom, { type: "existing", id: textItemId, richText: textItem.rich_text });
      } else {
        // set the value to whatever the current variant value is
        const variantValue =
          textItem.variants.find((variant) => variant.variantId === currentlySelectedVariant)?.rich_text ||
          EMPTY_RICH_TEXT;
        set(inlineEditingAtom, {
          type: "existing",
          id: textItemId,
          richText: variantValue,
          variantId: currentlySelectedVariant,
        });
      }
    }

    // Clear any other selections so only this item is now selected
    set(setSelectedTextIdsActionAtom, [textItemId]);
    return;
  }

  const projectBlocks = get(projectBlocksSplitAtom);

  // If a block is selected, select the closest text item above (ArrowUp) or below (ArrowDown) the block
  if (selectedBlockId) {
    const blockIndex = projectBlocks.findIndex((blockAtom) => get(blockAtom)._id === selectedBlockId);
    if (event.key === "ArrowUp") {
      if (blockIndex !== 0) {
        const previousBlockAtom = projectBlocks[blockIndex - 1];
        const previousBlock = get(previousBlockAtom);
        const lastTextItem = previousBlock.textItems.at(-1);
        if (lastTextItem) {
          set(setSelectedTextIdsActionAtom, [lastTextItem._id]);
        } else {
          // If the block above this one has no text items, select the block itself
          set(setSelectedBlockIdsActionAtom, previousBlock._id);
        }
      }
    } else {
      // Arrow Down
      const nextBlockAtom = projectBlocks[blockIndex + 1];
      const nextBlock = get(nextBlockAtom);
      const nextTextItem = nextBlock.textItems[0];
      if (nextTextItem) {
        set(setSelectedTextIdsActionAtom, [nextTextItem._id]);
      } else if (nextBlock._id !== null) {
        // If the block after this is real and has no text items, select the block itself
        set(setSelectedBlockIdsActionAtom, nextBlock._id);
      }
    }

    return;
  }

  const allTextItems = projectBlocks.flatMap((projectBlockAtom) => get(projectBlockAtom).textItems);

  // If nothing is selected, select the first text item on the page
  if (currentSelection.type === "none") {
    set(setSelectedTextIdsActionAtom, [allTextItems[0]._id]);
    return;
  }

  // If we press a plain-arrow key, select the next/previous item in the list and discard all other selections.
  if (!event.shiftKey) {
    const currentIndex = allTextItems.findIndex(
      (item) => item._id === selectedTextItemIds[selectedTextItemIds.length - 1]
    );
    const newIndex = event.key === "ArrowUp" ? currentIndex - 1 : currentIndex + 1;

    // If the new index is out of bounds, set the selection to only the most recently selected item.
    if (newIndex < 0 || newIndex >= allTextItems.length) {
      set(setSelectedTextIdsActionAtom, [selectedTextItemIds[selectedTextItemIds.length - 1]]);
      return;
    }

    set(setSelectedTextIdsActionAtom, [allTextItems[newIndex]._id]);
  } else {
    // If we press SHIFT+arrow-up/down, select the range of elements between the most recently selected element and the current one.
    const mostRecentlySelectedIndex = allTextItems.findIndex(
      (item) => item._id === selectedTextItemIds[selectedTextItemIds.length - 1]
    );

    // Always store the "pointer" index as the first element in the selection array. The resultant array will be between the
    // new pointer index and the most recently selected index.
    const currentIndex = allTextItems.findIndex((item) => item._id === selectedTextItemIds[0]);

    // The new current index should be the index of the item that is currently selected, plus or minus the direction of the arrow key,
    // excluding bounds, and excluding the most recently selected index.
    const newCurrentIndex = (() => {
      const initialNewIndex = event.key === "ArrowUp" ? currentIndex - 1 : currentIndex + 1;

      if (initialNewIndex < 0 || initialNewIndex >= allTextItems.length) {
        return currentIndex;
      }

      if (initialNewIndex === mostRecentlySelectedIndex) {
        const skippedIndex = event.key === "ArrowUp" ? currentIndex + 1 : currentIndex - 1;

        if (skippedIndex < 0 || skippedIndex >= allTextItems.length) {
          return currentIndex;
        }
      }

      return initialNewIndex;
    })();

    const startIndex = Math.min(mostRecentlySelectedIndex, newCurrentIndex);
    const endIndex = Math.max(mostRecentlySelectedIndex, newCurrentIndex);

    const alreadyPresentSelections = getPriorSelections(
      selectedTextItemIds,
      allTextItems,
      currentIndex,
      mostRecentlySelectedIndex,
      startIndex,
      endIndex
    );

    const newPointerId = allTextItems[newCurrentIndex]._id;

    // Create the new selections array and ensure the most recently selected element is preserved at the end.
    set(setSelectedTextIdsActionAtom, [
      newPointerId,
      ...alreadyPresentSelections,
      ...allTextItems.slice(startIndex, mostRecentlySelectedIndex).map((item) => item._id),
      ...allTextItems
        .slice(mostRecentlySelectedIndex, endIndex + 1)
        .map((item) => item._id)
        .reverse(),
    ]);
  }
});

export const onTextItemTextChangeActionAtom = atom(
  null,
  (get, set, textItem: { id: string; richText: ITipTapRichText }) => {
    const inlineEditingState = get(inlineEditingAtom);
    if (inlineEditingState?.type !== "existing" || inlineEditingState.id !== textItem.id) {
      throw new Error("Invalid state: cannot update text for a text item that is not being edited");
    }

    set(inlineEditingAtom, { type: "existing", id: textItem.id, richText: textItem.richText });
  }
);

export const onTextItemCancelEditActionAtom = atom(
  null,
  async (get, set, { textItemId, skipConfirm = false }: { textItemId: string; skipConfirm?: boolean }) => {
    const inlineEditingState = get(inlineEditingAtom);
    if (inlineEditingState?.type !== "existing" || inlineEditingState.id !== textItemId) {
      throw new Error("Invalid state: cannot cancel edit for a text item that is not being edited");
    }

    set(stopInlineEditingActionAtom, { skipConfirmation: skipConfirm });
  }
);

export const clearSelectionActionAtom = atom(null, (_, set) => {
  set(stopInlineEditingActionAtom, {
    onConfirm: () => set(selectedItemsAtom, { type: "none" }),
  });
});

export const setSelectedTextIdsActionAtom = atom(null, (_, set, textItemIds: string[]) => {
  set(selectedItemsAtom, { type: "text", ids: textItemIds });
});

export const setSelectedBlockIdsActionAtom = atom(null, (_, set, blockId: string) => {
  set(selectedItemsAtom, { type: "block", id: blockId });
});

export const onClickBlockActionAtom = atom(null, (_, set, blockId: string) => {
  set(stopInlineEditingActionAtom, {
    onConfirm: () => set(selectedItemsAtom, { type: "block", id: blockId }),
  });
});

// MARK: - Details Panel

const DetailsPanelContexts = ["PROJECT", "EDIT"] as const;
export type DetailsPanelContext = (typeof DetailsPanelContexts)[number];

export const detailsPanelContextAtom = atom<DetailsPanelContext>((get) => {
  const block = get(selectedBlockIdAtom);
  const textItems = get(selectedTextItemIdsAtom);

  if (textItems.length > 0) {
    return "EDIT";
  }

  if (block) {
    return "EDIT";
  }

  return "PROJECT";
});

const ComponentInteractionStates = ["LINKING_COMPONENT", "SWAPPING_COMPONENT", "PUBLISHING_COMPONENT"] as const;
export type ComponentInteractionStates = (typeof ComponentInteractionStates)[number];

const DetailsPanelEditStates = ["EDIT", "ACTIVITY", "COMMENTS", "VARIANTS", ...ComponentInteractionStates] as const;
export type DetailsPanelSelectionStates = (typeof DetailsPanelEditStates)[number];

// Atom to store props to pass to the current details panel
export const detailsPanelPropsAtom = atom<DetailsPanelProps>({});

export const detailsPanelSelectionStateAtom = atom(
  (get) => {
    const location = get(locationAtom);

    if (!location.searchParams) {
      return "EDIT";
    }

    const state = location.searchParams.get(DETAILS_PANEL_PARAM) as DetailsPanelSelectionStates;

    if (state === null || !DetailsPanelEditStates.includes(state)) {
      return "EDIT";
    }

    return state;
  },
  (get, set, newPanel: DetailsPanelSelectionStates) => {
    function handleChangePanel() {
      const location = get(locationAtom);
      const newSearchParams = new URLSearchParams(location.searchParams);

      if (newPanel) {
        newSearchParams.set(DETAILS_PANEL_PARAM, newPanel);

        // Clear panel props state for variants when we're navigating between tabs normally
        set(detailsPanelPropsAtom, (props) => ({ ...props, variants: undefined }));
      } else {
        newSearchParams.delete(DETAILS_PANEL_PARAM);
      }

      set(locationAtom, {
        ...location,
        searchParams: newSearchParams,
      });
      set(selectedCommentAtom, null);
    }

    set(discardChangesModalActionAtom, {
      onConfirm: handleChangePanel,
      ignoreInlineChanges: true,
      activeElement: document.activeElement,
    });
  }
);

// should only be able to access these states from the edit view
const _libraryInteractionViewAtom = atom<null | "LINK" | "SWAP" | "PUBLISH">(null);
export const libraryInteractionViewAtom = atom(
  (get) => {
    const value = get(_libraryInteractionViewAtom);

    const detailsPanelEditState = get(detailsPanelSelectionStateAtom);
    if (detailsPanelEditState !== "EDIT") {
      return null;
    }

    return value;
  },
  (get, set, value: null | "LINK" | "SWAP" | "PUBLISH") => {
    const detailsPanelEditState = get(detailsPanelSelectionStateAtom);
    if (detailsPanelEditState !== "EDIT") {
      set(_libraryInteractionViewAtom, null);
      return;
    }

    set(_libraryInteractionViewAtom, value);
  }
);

export const clearLibraryInteractionViewActionAtom = atom(null, (_get, set) => {
  // reset any library interaction data
  set(selectedComponentIdForLinkingAtom, null);
  set(selectedLibraryFolderIdAtom, null);
  set(resetComponentNameAtom);
  set(clearFiltersActionAtom);

  // reset the view state
  set(libraryInteractionViewAtom, null);
});

const DetailsPanelProjectStates = ["ACTIVITY", "COMMENTS"] as const;
export type DetailsPanelProjectStates = (typeof DetailsPanelProjectStates)[number];

export const detailsPanelProjectStateAtom = atom(
  (get) => {
    const location = get(locationAtom);

    if (!location.searchParams) {
      return "ACTIVITY";
    }

    const state = location.searchParams.get(DETAILS_PANEL_PARAM) as DetailsPanelProjectStates;

    if (state === null || !DetailsPanelProjectStates.includes(state)) {
      return "ACTIVITY";
    }

    return state;
  },
  (get, set, newPanel: DetailsPanelProjectStates) => {
    function handleChangePanel() {
      const location = get(locationAtom);
      const newSearchParams = new URLSearchParams(location.searchParams);

      if (newPanel) {
        newSearchParams.set(DETAILS_PANEL_PARAM, newPanel);
      } else {
        newSearchParams.delete(DETAILS_PANEL_PARAM);
      }

      set(locationAtom, {
        ...location,
        searchParams: newSearchParams,
      });
    }

    set(discardChangesModalActionAtom, {
      onConfirm: handleChangePanel,
      ignoreInlineChanges: true,
      activeElement: document.activeElement,
    });
  }
);
