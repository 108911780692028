import useAutoScroll from "@/hooks/useAutoScroll";
import { libraryComponentFamilyAtom } from "@/stores/Components";
import { libraryHiddenFieldsAtom } from "@/stores/HiddenFields";
import {
  componentIsSelectedAtomFamily,
  draggableItemsForComponentItemAtom,
  handleComponentClickActionAtom,
  libraryDraggedSelectionAtom,
  reorderLibraryComponentsActionAtom,
  scrollToComponentIdActionAtom,
} from "@/stores/Library";
import { searchAtom } from "@/stores/Location";
import { deferredVariantsNamesMapAtom, usersByIdAtom } from "@/stores/Workspace";
import DragAndDroppable, { DragLocation } from "@ds/atoms/DragAndDroppable";
import { MultiDragPreview } from "@ds/atoms/MultiDragPreview";
import TextItem, { TextItemStatic } from "@ds/molecules/TextItem";
import { VariantBadgeData } from "@ds/molecules/VariantBadge";
import { ILibraryComponentItem } from "@shared/types/Library";
import classNames from "classnames";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { useAtomCallback } from "jotai/utils";
import { ForwardedRef, forwardRef, memo, useCallback, useMemo, useRef } from "react";
import { DragPreview, DragPreviewRenderer, DragStartEvent, DragTypes } from "react-aria";
import { z } from "zod";
import { libraryComponentListScrollRefAtom } from "../LibraryItemsList";
import style from "./style.module.css";

interface ILibraryComponentListItemProps {
  item: ILibraryComponentItem;
  isScrolling: boolean;
  disableDropBetweenItems?: boolean;
  className?: string;
  isDragging?: boolean;
  isDragPreview?: boolean;
  style?: React.CSSProperties;
  containerStyle?: React.CSSProperties;
}

const LibraryComponentListItem = memo(
  forwardRef(function LibraryComponentListItem(
    props: ILibraryComponentListItemProps,
    forwardedRef: ForwardedRef<HTMLDivElement>
  ) {
    const component = useAtomValue(libraryComponentFamilyAtom(props.item._id));
    const usersById = useAtomValue(usersByIdAtom);
    const hiddenFields = useAtomValue(libraryHiddenFieldsAtom);
    const variantNameLookup = useAtomValue(deferredVariantsNamesMapAtom);
    const searchQuery = useAtomValue(searchAtom);

    const isStatusHidden = hiddenFields.status.isHidden;
    const isTagsHidden = hiddenFields.tags.isHidden;
    const isNotesHidden = hiddenFields.notes.isHidden;
    const isCommentsHidden = hiddenFields.comments.isHidden;
    const isInstancesHidden = hiddenFields.instances.isHidden;
    const isVariantsHidden = hiddenFields.variants.isHidden;
    const isAssigneeHidden = hiddenFields.assigned.isHidden;

    const isSelected = useAtomValue(componentIsSelectedAtomFamily(props.item._id));

    function getState() {
      if (props.isDragPreview) return "drag-preview";
      if (isSelected) return "focus";
      return "default";
    }

    const badgeVariants: VariantBadgeData[] = useMemo(
      () =>
        component.variants.map((variant) => ({
          variantId: variant.variantId,
          variantName: variantNameLookup[variant.variantId],
          status: variant.status ?? "NONE",
        })),
      [component.variants, variantNameLookup]
    );

    if (!component) return null;

    return (
      <div className={style.libraryComponentTextItemContainer} style={{ ...props.containerStyle }}>
        <TextItem
          style={props.style}
          ref={forwardedRef}
          renderDragGhost={props.isDragging}
          disableAutoscroll
          component={component ?? undefined}
          defaultText={component.text}
          defaultValue={component.rich_text}
          status={isStatusHidden ? undefined : component.status}
          assignee={isAssigneeHidden ? undefined : usersById[component.assignee ?? ""]}
          instanceCount={isInstancesHidden ? undefined : component.instances.length}
          tags={isTagsHidden ? undefined : component.tags}
          notes={isNotesHidden ? undefined : component.notes}
          variants={badgeVariants}
          hideVariantsBadge={isVariantsHidden}
          state={getState()}
          numComments={isCommentsHidden ? undefined : component.commentThreads.length}
          highlightedPhrase={searchQuery}
        />
      </div>
    );
  })
);

const allowedItemKeys = { "ditto/componentItem": z.string() };

const LibraryComponentListItemDragWrapper = memo(function LibraryComponentListItemDragWrapper(
  props: ILibraryComponentListItemProps
) {
  const { disableDropBetweenItems } = props;
  const previewRef = useRef<DragPreviewRenderer | null>(null);
  const textItemRef = useRef<HTMLDivElement | null>(null);

  // it looks weird that this is a ref, but if we use state, the drag preview is always one
  // render cycle behind the actual dimensions -- and we don't need these values to be
  // reactive (i.e. cause re-renders), because the drag preview is always recreated when a user starts dragging
  const textItemDimensions = useRef<{ width: number; height: number } | null>(null);

  const libraryComponentListScrollContainer = useAtomValue(libraryComponentListScrollRefAtom);
  const isSelected = useAtomValue(componentIsSelectedAtomFamily(props.item._id));
  const component = useAtomValue(libraryComponentFamilyAtom(props.item._id));

  const handleComponentClickAction = useSetAtom(handleComponentClickActionAtom);
  const reorderLibraryComponentsAction = useSetAtom(reorderLibraryComponentsActionAtom);
  const setScrollToComponentIdAction = useSetAtom(scrollToComponentIdActionAtom);
  const [libraryDraggedSelection, setLibraryDraggedSelection] = useAtom(libraryDraggedSelectionAtom);

  const scrollProps = useAutoScroll(libraryComponentListScrollContainer);

  const getDraggableItems = useAtomCallback(
    useCallback((get) => get(draggableItemsForComponentItemAtom(props.item._id)), [props.item._id])
  );

  // True if this item is in the current selected items, and we're dragging *something*, but not *this* item.
  const inDragSelection = useMemo(() => {
    return (
      isSelected && libraryDraggedSelection?.origin === "main-list" && props.item._id !== libraryDraggedSelection._id
    );
  }, [isSelected, libraryDraggedSelection, props.item._id]);

  const handleDrop = useCallback(
    function _handleDrop(componentIds: string[], dragLocation: DragLocation) {
      if (disableDropBetweenItems) return;

      reorderLibraryComponentsAction([
        {
          componentIds: componentIds,
          before: dragLocation === "above" ? props.item._id : undefined,
          after: dragLocation === "below" ? props.item._id : undefined,
          folderId: component?.folderId,
        },
      ]);

      // request animation frame to ensure we scroll to newly updated component location
      requestAnimationFrame(() => setScrollToComponentIdAction(componentIds[0]));
    },
    [
      component?.folderId,
      props.item._id,
      reorderLibraryComponentsAction,
      setScrollToComponentIdAction,
      disableDropBetweenItems,
    ]
  );

  const handleDragStart = useCallback(
    function _handleDragStart(event: DragStartEvent) {
      // measure the dimensions of the text item as soon as we start dragging
      if (textItemRef.current) {
        textItemDimensions.current = {
          width: textItemRef.current.offsetWidth,
          height: textItemRef.current.offsetHeight,
        };
      }

      if (!isSelected) {
        handleComponentClickAction({
          event,
          componentId: props.item._id,
          skipInlineEditing: true,
        });
      }

      setLibraryDraggedSelection({ _id: props.item._id, type: "component", origin: "main-list" });
    },
    [handleComponentClickAction, setLibraryDraggedSelection, isSelected, props.item._id]
  );

  function getDropOperation(types: DragTypes) {
    return types.has("ditto/componentItem") ? "move" : "cancel";
  }

  const handleDragEnd = useCallback(() => setLibraryDraggedSelection(null), [setLibraryDraggedSelection]);

  const handleComponentClick = useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      // Must stop event propagation to prevent global deselect handler from clearing selection
      event.stopPropagation();

      handleComponentClickAction({
        event,
        componentId: props.item._id,
        skipInlineEditing: true,
      });
    },
    [props.item._id, handleComponentClickAction]
  );

  const selectionType = libraryDraggedSelection?.type ?? "none";

  return (
    <DragAndDroppable
      getDraggableItems={getDraggableItems}
      allowedItemKeys={allowedItemKeys}
      onDrop={handleDrop}
      onDragStart={handleDragStart}
      {...scrollProps}
      onDragEnd={() => {
        if ("onDragEnd" in scrollProps) {
          scrollProps.onDragEnd?.();
        }
        handleDragEnd();
      }}
      getDropOperation={getDropOperation}
      selectionType={selectionType}
      className={style.rowPadding}
      preview={previewRef}
    >
      {({ isDragging, isDropTarget, dragLocation }) => {
        const dropIndicatorsEnabled = !props.disableDropBetweenItems && isDropTarget;
        const isDroppingBelow = dropIndicatorsEnabled && dragLocation === "below";
        const isDroppingAbove = dropIndicatorsEnabled && dragLocation === "above";

        return (
          <>
            {isDroppingAbove && (
              <div
                data-selectiontype="text"
                data-dragindicatorabove
                className={classNames(style.dropIndicatorWrapper, style.above)}
              >
                <div className={style.dropIndicator} />
              </div>
            )}

            <div className={classNames(style.dragWrapper, props.className)} onClick={handleComponentClick}>
              <LibraryComponentListItem
                {...props}
                isDragging={isDragging}
                ref={textItemRef}
                style={{ opacity: inDragSelection ? 0.3 : 1 }}
              />
            </div>

            <DragPreview ref={previewRef}>
              {(items) => (
                <MultiDragPreview count={items.length}>
                  <LibraryComponentListItem
                    isDragPreview
                    style={{
                      width: textItemDimensions.current?.width,
                      height: textItemDimensions.current?.height,
                    }}
                    containerStyle={{
                      paddingBottom: 0,
                    }}
                    {...props}
                  />
                </MultiDragPreview>
              )}
            </DragPreview>

            {isDroppingBelow && (
              <div
                data-selectiontype="text"
                data-dragindicatorbelow
                className={classNames(style.dropIndicatorWrapper, style.below)}
              >
                <div className={style.dropIndicator} />
              </div>
            )}
          </>
        );
      }}
    </DragAndDroppable>
  );
});

export function LibraryComponentListItemFallback() {
  return (
    <div className={style.libraryComponentTextItemContainer}>
      <TextItemStatic
        disableAutoscroll
        component={{ name: "Loading..." }}
        defaultText="Loading..."
        defaultValue={{
          type: "doc",
          content: [
            {
              type: "paragraph",
              content: [
                {
                  type: "text",
                  text: "Loading...",
                },
              ],
            },
          ],
        }}
      />
    </div>
  );
}

export default LibraryComponentListItemDragWrapper;
