import { instanceDataForSelectedComponentAtom, selectedLibraryInstanceCountAtom } from "@/stores/Library";
import InstancesBanner from "@ds/molecules/InstancesBanner";
import { useAtomValue } from "jotai";
import React, { Suspense } from "react";

function LibraryComponentInstancesBanner(props: { onActionClick?: () => void }) {
  const libraryInstancesPanelListItems = useAtomValue(instanceDataForSelectedComponentAtom);
  const instanceCount = useAtomValue(selectedLibraryInstanceCountAtom);

  if (instanceCount === 0) return null;

  return (
    <Suspense fallback={<InstancesBanner content={`Loading instances...`} />}>
      <InstancesBanner
        content={`${instanceCount} design instance${instanceCount === 1 ? "" : "s"} across ${
          libraryInstancesPanelListItems.length
        } project${libraryInstancesPanelListItems.length === 1 ? "" : "s"}`}
        actionText="Preview"
        onActionClick={props.onActionClick}
      />
    </Suspense>
  );
}

export default LibraryComponentInstancesBanner;
