import { routes } from "@/defs";
import useRedirectToNSPage from "@/hooks/useRedirectToNSPage";
import { nullableLibraryComponentFamilyAtom } from "@/stores/Components";
import { ILibraryComponent } from "@shared/types/LibraryComponent";
import { useAtomCallback } from "jotai/utils";
import { useCallback } from "react";

/**
 * Use this hook to redirect to a specific library component in North Star.
 * This hook will look up the library component by id in order to find the folder it's in.
 * Then, it will redirect to the library page with the component selected, and if provided, a comment thread selected.
 */
function useRedirectToNSLibraryComponent() {
  const redirectToNSPage = useRedirectToNSPage();

  const goToLibraryComponent = useAtomCallback(
    useCallback(
      async (get, _set, libraryComponentId: string | null, commentThreadId?: string | null) => {
        let libraryComponent: ILibraryComponent | null = null;

        try {
          libraryComponent = await get(nullableLibraryComponentFamilyAtom(libraryComponentId || null));
        } catch (e) {
          // The component no longer exists. This is ok, just go to library root and select the comment
        }

        if (!libraryComponentId || !libraryComponent) {
          const { pathname, searchParams } = routes.nonNavRoutes.library.getUrlParts({ commentThreadId });
          redirectToNSPage(pathname, searchParams);
          return;
        }

        const folderId = libraryComponent.folderId;
        const { pathname, searchParams } = routes.nonNavRoutes.library.getUrlParts({
          selectedComponentIds: [libraryComponentId],
          commentThreadId,
          folderId,
        });
        redirectToNSPage(pathname, searchParams);
      },
      [nullableLibraryComponentFamilyAtom, redirectToNSPage]
    )
  );

  return goToLibraryComponent;
}

export default useRedirectToNSLibraryComponent;
