import { flattenedProjectItemsAtom } from "@/stores/Project";
import NavItem from "@ds/molecules/NavigatorRow";
import { useAtomValue } from "jotai";
import style from "./style.module.css";

export function LoadingNavItem() {
  return <NavItem state="loading" pressed={false} />;
}

export function HiddenProjectItemsNavItem() {
  const { hiddenProjectItemsMessage } = useAtomValue(flattenedProjectItemsAtom);

  if (!hiddenProjectItemsMessage) {
    return null;
  }

  return <div className={style.hiddenResultsMessageNavItem}>{hiddenProjectItemsMessage}</div>;
}
