import classNames from "classnames";
import React, { useMemo } from "react";
import { ENTRY_TYPES, IChangeItem } from "../../../../shared/types/ActualChange";
import { TrackFn } from "../../../../shared/types/analytics";
import ChangeItemContent from "../../atoms/ChangeItemContent";
import ChangeItemHeader from "../../atoms/ChangeItemHeader";
import ChangeItemCountBadge, { ChangeItemBadgeData } from "../ChangeItemCountBadge";
import style from "./index.module.css";

interface IProps {
  changeItem: IChangeItem;
  onClick?: () => void | Promise<void>;
  /**
   * A method that allows us to call our Segment `track` method
   * with the version and application properties already set.
   */
  sendTrackingEvent: TrackFn;
}

export function ChangeItem({ changeItem, onClick, sendTrackingEvent }: IProps) {
  const badge: ChangeItemBadgeData | null = useMemo(() => {
    switch (changeItem.entry_type) {
      case ENTRY_TYPES.EDIT:
        return { type: "instance", count: changeItem.data.figma_node_ids.length };
      case ENTRY_TYPES.DUPES_STATUS:
        return { type: "textItem", count: changeItem.dupe_comp_ids.length };
      default:
        return null;
    }
  }, [changeItem]);

  return (
    <div
      className={classNames(style.ChangeItemWrapper, { [style.clickable]: !!onClick })}
      data-testid="change-item"
      onClick={onClick}
    >
      <ChangeItemHeader changeItem={changeItem} />
      <div className={style.ChangeItemContentArea}>
        <ChangeItemContent changeItem={changeItem} sendTrackingEvent={sendTrackingEvent} />
        {!!badge && <ChangeItemCountBadge type={badge.type} count={badge.count} />}
      </div>
    </div>
  );
}

export default ChangeItem;
