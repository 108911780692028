import React, { useCallback, useEffect, useState } from "react";
import LibraryComponentIcon from "../../atoms/Icon/icons/LibraryComponentIcon";
import Text from "../../atoms/Text";
import TextInput from "../../atoms/TextInput";
import style from "./index.module.css";

interface IProps {
  componentName: string | undefined;
  onChange?: ((componentName: string) => void) | undefined;
}

function EditableComponentName(props: IProps) {
  const [localName, setLocalName] = useState(props.componentName);
  const { onChange } = props;

  useEffect(
    function updateLocalStateOnPropChange() {
      setLocalName(props.componentName);
    },
    [props.componentName]
  );

  const handleChange = useCallback(
    (value: string) => {
      setLocalName(value); // Optimistic UI update
      onChange?.(value); // Alert the parent of the change
    },
    [onChange]
  );

  if (typeof props.componentName !== "string") {
    return null;
  }

  return (
    <div className={style.componentName}>
      <Text size="small" weight="strong">
        Component name
      </Text>
      <TextInput leadingIcon={<LibraryComponentIcon />} iconSize="xxs" value={localName} onChange={handleChange} />
    </div>
  );
}

export default EditableComponentName;
