import { useAtomRef } from "@/hooks/useAtomRef";
import { flattenedProjectItemsAtom, isEmptyProjectAtom } from "@/stores/Project";
import Text from "@ds/atoms/Text";
import NavItem from "@ds/molecules/NavItem";
import Scrollbar from "@ds/molecules/Scrollbar";
import { IS_SAFARI } from "@shared/frontend/constants";
import { atom, useAtomValue } from "jotai";
import { Suspense } from "react";
import BlockNavItem from "./BlockNavItem";
import LeftSidebarContentSkeleton from "./LoadingSkeleton";
import MessageNavItem from "./MessageNavItem";
import { HiddenProjectItemsNavItem } from "./SimpleNavItems";
import style from "./style.module.css";
import TextNavItem from "./TextNavItem";

export const leftSidebarScrollRefAtom = atom<HTMLDivElement | null>(null);

function TextPanel() {
  const { flattenedProjectItems } = useAtomValue(flattenedProjectItemsAtom);
  const isEmptyProject = useAtomValue(isEmptyProjectAtom);

  if (isEmptyProject) {
    return (
      <>
        <Text color="tertiary" size="small" className={style.emptyStateMessage}>
          Blocks and text in your project will appear here
        </Text>
        <HiddenProjectItemsNavItem />
      </>
    );
  }

  // Custom previews for dragged items are invisible in Safari, so we disable them. See ticket for context:
  // [DIT-9582](https://linear.app/dittowords/issue/DIT-9582/investigate-visual-bugs-in-safari-with-react-aria-dnd-tanstack-virtual)
  return (
    <>
      {flattenedProjectItems.map((item) => {
        if (item.type === "block") {
          return (
            <Suspense key={item._id} fallback={<NavItem.Fallback />}>
              <BlockNavItem key={item._id} item={item} disableCustomPreview={IS_SAFARI} />
            </Suspense>
          );
        } else if (item.type === "text") {
          return (
            <Suspense key={item._id} fallback={<NavItem.Fallback />}>
              <TextNavItem key={item._id} item={item} disableCustomPreview={IS_SAFARI} />
            </Suspense>
          );
        } else if (item.type === "message") {
          return (
            <Suspense key={item._id} fallback={<NavItem.Fallback />}>
              <MessageNavItem item={item} />
            </Suspense>
          );
        }
        return null;
      })}
      <HiddenProjectItemsNavItem />
    </>
  );
}

function TextPanelWrapper() {
  const scrollContentRef = useAtomRef(leftSidebarScrollRefAtom);

  return (
    <Scrollbar disableScrollX className={style.scrollWrapper} {...{ scrollContentRef }}>
      <div className={style.textPanelWrapper}>
        <Suspense fallback={<LeftSidebarContentSkeleton />}>
          <TextPanel />
        </Suspense>
      </div>
    </Scrollbar>
  );
}

export default TextPanelWrapper;
