import { deselectLibraryComponentsActionAtom } from "@/stores/Library";
import client from "@shared/frontend/http/httpClient";
import { createLibraryComponentsStore } from "@shared/frontend/stores/LibraryComponents";
import { REFRESH_SILENTLY, REFRESH_WITH_SUSPENSE } from "@shared/frontend/stores/symbols";
import { atom } from "jotai";
import { LibraryListItem, LibrarySelection, libraryListAtomFamily, librarySelectedItemsAtom } from "./Library";
import { textItemsUpdatedActionAtom } from "./Project";
import { textItemFamilyAtom } from "./TextItem";

export const {
  libraryComponentFamilyAtom,
  nullableLibraryComponentFamilyAtom,
  handleLibraryComponentsUpdatedActionAtom,
  updateLibraryComponentActionAtom: _updateLibraryComponentActionAtom,
  deleteLibraryComponentsActionAtom: _deleteLibraryComponentsActionAtom,
  resetLibraryComponentFamilyActionAtom,
  handleLibraryComponentCreatedActionAtom,
  handleLibraryComponentsMovedActionAtom,
} = createLibraryComponentsStore(client);

export const updateLibraryComponentActionAtom: typeof _updateLibraryComponentActionAtom = atom(
  null,
  async (get, set, data) => {
    set(_updateLibraryComponentActionAtom, data);

    if (data.update !== REFRESH_WITH_SUSPENSE && data.update !== REFRESH_SILENTLY) {
      set(textItemsUpdatedActionAtom, {
        textItemIds: data.update.instances,
      });
    }
  }
);

/**
 * Action atom that handles a websocket event for component deletion.
 */
export const handleLibraryComponentsDeletedActionAtom = atom(
  null,
  async (get, set, data: { componentIds: string[] }) => {
    for (const componentId of data.componentIds) {
      const component = await get(libraryComponentFamilyAtom(componentId));

      // remove component from family store
      libraryComponentFamilyAtom.remove(componentId);

      const libraryStructureNode = libraryListAtomFamily(component.folderId || undefined);
      const libraryStructure = await get(libraryStructureNode);

      set(deselectLibraryComponentsActionAtom, [componentId]);
      set(
        libraryStructureNode,
        libraryStructure.filter((item) => item._id !== componentId)
      );
    }
  }
);

/**
 * Action atom for deleting the library component, including sending the backend request to delete the component.
 * NOTE!!! All components are deleted from the same folder.
 */
export const deleteLibraryComponentsStructureAtom = atom(null, async (get, set, data: { ids: string[] }) => {
  let previousLibraryStructure: LibraryListItem[] | undefined;
  let libraryStructure: LibraryListItem[] = [];
  let previousSelection: LibrarySelection;

  set(_deleteLibraryComponentsActionAtom, {
    ids: data.ids,
    onDelete: async (components) => {
      const componentIds = components.map((component) => component._id);

      const foldersAllSame = components.every((component) => component.folderId === components[0].folderId);

      if (!foldersAllSame) {
        throw new Error("All components must be in the same folder for deletion");
      }

      // NOTE!!! All components are deleted from the same folder.
      const libraryStructureNode = libraryListAtomFamily(components[0].folderId || undefined);
      libraryStructure = await get(libraryStructureNode);
      previousLibraryStructure = { ...libraryStructure };
      previousSelection = await get(librarySelectedItemsAtom);

      set(deselectLibraryComponentsActionAtom, data.ids);
      set(
        libraryStructureNode,
        libraryStructure.filter((item) => !componentIds.includes(item._id))
      );

      const allInstances = components.flatMap((component) => component.instances);

      for (const textItemId of allInstances) {
        set(textItemFamilyAtom(textItemId), (prev) => ({ ...prev, ws_comp: null }));
      }
    },
    onRollback: async (components) => {
      if (previousLibraryStructure) {
        const libraryStructureNode = libraryListAtomFamily(components[0].folderId || undefined);

        set(libraryStructureNode, previousLibraryStructure);
        set(librarySelectedItemsAtom, previousSelection);
      }
    },
  });
});
