import { useWorkspace } from "@/store/workspaceContext";
import {
  editableTextItemVariantChangesAtom,
  resetAddVariantFormChangesActionAtom,
  setAddVariantFormChangesActionAtom,
} from "@/stores/Editing";
import {
  derivedOnlySelectedComponentAtom,
  derivedOnlySelectedComponentVariantsAtom,
  libraryAttachVariantActionAtom,
  libraryComponentVariantsFamilyAtom,
  libraryDetailsPanelPropsAtom,
  libraryRemoveVariantFromItemsActionAtom,
  libraryUpdateItemVariantActionAtom,
} from "@/stores/Library";
import { blockSelectedVariantIdFamilyAtom } from "@/stores/Variants";
import { deferredVariantsAtom } from "@/stores/Workspace";
import Scrollbar from "@ds/molecules/Scrollbar";
import VariantsPanel from "@ds/organisms/VariantsPanel";
import React from "react";
import style from "./style.module.css";

function LibraryEditVariantsPanel() {
  const workspace = useWorkspace();

  return (
    <Scrollbar className={style.scrollArea} disableScrollX>
      <VariantsPanel
        workspaceId={workspace.workspaceInfo._id}
        atoms={{
          onlySelectedItemAtom: derivedOnlySelectedComponentAtom,
          workspaceVariantsAtom: deferredVariantsAtom,
          onlySelectedItemVariantsAtom: derivedOnlySelectedComponentVariantsAtom,
          blockSelectedVariantIdFamilyAtom: blockSelectedVariantIdFamilyAtom,
          detailsPanelPropsAtom: libraryDetailsPanelPropsAtom,
          setAddVariantFormChangesActionAtom: setAddVariantFormChangesActionAtom,
          resetAddVariantFormChangesActionAtom: resetAddVariantFormChangesActionAtom,
          attachVariantActionAtom: libraryAttachVariantActionAtom,
          updateItemVariantActionAtom: libraryUpdateItemVariantActionAtom,
          editableItemVariantChangesAtom: editableTextItemVariantChangesAtom,
          removeVariantFromItemsActionAtom: libraryRemoveVariantFromItemsActionAtom,
          itemVariantsFamilyAtom: libraryComponentVariantsFamilyAtom,
        }}
      />
    </Scrollbar>
  );
}

export default LibraryEditVariantsPanel;
