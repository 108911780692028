import AddIcon from "@mui/icons-material/Add";
import VisibilityIcon from "@mui/icons-material/VisibilityOutlined";
import React, { useCallback, useMemo } from "react";
import Text from "../../atoms/Text";
import DropdownCombobox from "../../molecules/DropdownCombobox";
import style from "./index.module.css";

export const GROUPING_OPTIONS = {
  tag: {
    value: "tag",
    label: "Tag",
  },
  // TODO: Add status back in when implementing status for library components grouping
  // status: {
  //   value: "status",
  //   label: "Status",
  // },
} as const;
export type GroupingType = keyof typeof GROUPING_OPTIONS;

interface IOption {
  value: GroupingType;
  label: string;
}

interface IProps {
  selectedGrouping: GroupingType | null;
  setSelectedGrouping: (grouping: GroupingType | null) => void;
  removeFilter?: () => void;

  size?: "base" | "small";
  Icon?: React.ReactNode;
  className?: string;
  popoverClassName?: string;
  style?: React.CSSProperties;
  hideToggledState?: boolean;
  alignPopover?: "start" | "end" | "center";
}

export function AddGroupingsDropdown(props: IProps) {
  const { selectedGrouping, setSelectedGrouping } = props;

  const selectedGroupingOption = useMemo(() => {
    return selectedGrouping ? GROUPING_OPTIONS[selectedGrouping] : null;
  }, [selectedGrouping]);

  const hasSelectedGrouping = !!selectedGrouping;

  const removeGrouping = useCallback(() => {
    setSelectedGrouping(null);
  }, [setSelectedGrouping]);

  const onChange = useCallback(
    (option: IOption[]) => {
      const value = option[0]?.value ?? null;
      if (value && selectedGrouping === value) {
        setSelectedGrouping(null);
      } else {
        setSelectedGrouping(value);
      }
    },
    [selectedGrouping, setSelectedGrouping]
  );

  return (
    <DropdownCombobox
      size={props.size}
      asToggle
      closeOnSelect
      multiple={false}
      showTextInput={false}
      hideToggledState={props.hideToggledState}
      forceToggledDisplay={hasSelectedGrouping}
      options={Object.values(GROUPING_OPTIONS)}
      selectedOptions={selectedGroupingOption ? [selectedGroupingOption] : []}
      onChange={onChange}
      OptionComponent={OptionComponent}
      TriggerComponent={TriggerComponent}
      onRemoveFilter={hasSelectedGrouping ? removeGrouping : undefined}
      removeFilterText="Remove grouping"
      triggerLeadingIcon={hasSelectedGrouping ? <VisibilityIcon /> : <AddIcon />}
      alignPopover={props.alignPopover}
      popoverClassName={props.popoverClassName}
    />
  );
}

interface ITriggerProps {
  selectedOptions: IOption[];
  size?: "base" | "small";
  hideToggledState?: boolean;
}
function TriggerComponent(props: ITriggerProps) {
  const selectedOption: IOption | undefined = props.selectedOptions[0];

  const selectedText = selectedOption ? `Grouped by ${selectedOption.value}` : "Group";

  if (props.hideToggledState) {
    return (
      <Text size={props.size === "small" ? "micro" : "small"} color="primary">
        Group
      </Text>
    );
  }

  return (
    <Text size={props.size === "small" ? "micro" : "small"} color="primary">
      {selectedText}
    </Text>
  );
}

interface IOptionProps {
  option: IOption;
  query: string;
  selected: boolean;
}
function OptionComponent(props: IOptionProps) {
  return (
    <Text color="primary" size="small" className={style.label}>
      <span>{props.option.label}</span>
    </Text>
  );
}

export default AddGroupingsDropdown;
