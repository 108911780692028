import { useAtomRef } from "@/hooks/useAtomRef";
import { moveLibraryComponentFolderActionAtom } from "@/stores/ComponentFolders";
import {
  libraryCurrentFolderNameAtom,
  libraryCurrentFolderParentIdAtom,
  libraryDraggedSelectionAtom,
  libraryNavItemsAtom,
  libraryNavItemsVirtualizerAtom,
  navigateToLibraryFolderActionAtom,
  reorderLibraryComponentsActionAtom,
} from "@/stores/Library";
import DragAndDroppable, { DragLocation } from "@ds/atoms/DragAndDroppable";
import Text from "@ds/atoms/Text";
import VirtualizedList from "@ds/atoms/VirtualizedList";
import NavItem from "@ds/molecules/NavItem";
import Scrollbar from "@ds/molecules/Scrollbar";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { IS_SAFARI } from "@shared/frontend/constants";
import logger from "@shared/utils/logger";
import classNames from "classnames";
import { atom, useAtomValue, useSetAtom } from "jotai";
import { Suspense, useCallback } from "react";
import Skeleton from "react-loading-skeleton";
import { z } from "zod";
import LibraryComponentFolderNavItem from "../LibraryComponentFolderNavItem";
import LibraryComponentNavItem from "../LibraryComponentNavItem";
import LibraryComponentsNavHeader from "../LibraryComponentsNavHeader";
import style from "./style.module.css";

function LibraryLeftSidebar() {
  return (
    <div className={style.libraryLeftSidebarContainer} data-clear-library-selection>
      <Suspense fallback={<LeftNavSidebarFallback />}>
        <LibraryComponentsNavHeader />
        <FolderBackButton />
        <LibraryLeftSidebarItems />
      </Suspense>
    </div>
  );
}

function LeftNavSidebarFallback() {
  return (
    <div className={style.libraryNavFallback}>
      <Skeleton count={25} className={style.libraryNavFallbackSkeleton} height={28} />
    </div>
  );
}

export const libraryLeftSidebarScrollRefAtom = atom<HTMLDivElement | null>(null);

/**
 * The height percentage threshold when dropping for moving a folder into another vs. reordering
 * them.
 */
const EDGE_THRESHOLD = 0.3;

function LibraryLeftSidebarItems() {
  const libraryNavItems = useAtomValue(libraryNavItemsAtom);
  const libraryDraggedSelection = useAtomValue(libraryDraggedSelectionAtom);
  const scrollContentRef = useAtomRef(libraryLeftSidebarScrollRefAtom);
  const shouldDisableScrollTo = libraryDraggedSelection?.origin === "left-sidebar";

  if (libraryNavItems.length === 0) {
    return (
      <div className={style.noItemsContainer}>
        <Text color="tertiary" size="small" textAlign="center">
          Components and folders in your library will appear here
        </Text>
      </div>
    );
  }

  // Draggable list items rendered w/tanstack virtualizer render incorrectly on Safari.
  // Temporary workaround is to render the list of items w/out virtualization in Safari until we address in followup investigation.
  // Additionally, custom previews for dragged items are invisible in Safari, so we disable them. See ticket for context:
  // [DIT-9582](https://linear.app/dittowords/issue/DIT-9582/investigate-visual-bugs-in-safari-with-react-aria-dnd-tanstack-virtual)
  if (IS_SAFARI) {
    return (
      <Scrollbar scrollContentRef={scrollContentRef} className={style.scrollArea}>
        {libraryNavItems.map((item) => {
          switch (item.type) {
            case "component":
              return (
                <Suspense key={item._id} fallback={<NavItem.Fallback />}>
                  <LibraryComponentNavItem item={item} disableCustomPreview />
                </Suspense>
              );
            case "componentFolder":
              return (
                <Suspense key={item._id} fallback={<NavItem.Fallback />}>
                  <LibraryComponentFolderNavItem item={item} disableCustomPreview edgeThreshold={EDGE_THRESHOLD} />
                </Suspense>
              );
            default:
              logger.error("Unknown library item type", { context: { item } }, new Error("Unknown library item type"));
              return null;
          }
        })}
      </Scrollbar>
    );
  }

  return (
    <VirtualizedList
      id={"LIBRARY_NAV"}
      items={libraryNavItems}
      virtualizerAtom={libraryNavItemsVirtualizerAtom}
      virtualizeOptions={{
        estimateSize: () => 28,
        overscan: 50,
        getItemKey: (index) => libraryNavItems[index]._id,
      }}
      ref={scrollContentRef}
      shouldDisableScrollTo={shouldDisableScrollTo}
      className={style.libraryNavItemsContainer}
    >
      {({ item }) => {
        switch (item.type) {
          case "component":
            return (
              <Suspense fallback={<NavItem.Fallback />}>
                <LibraryComponentNavItem item={item} />
              </Suspense>
            );
          case "componentFolder":
            return (
              <Suspense fallback={<NavItem.Fallback />}>
                <LibraryComponentFolderNavItem item={item} edgeThreshold={EDGE_THRESHOLD} />
              </Suspense>
            );
          default:
            logger.error("Unknown library item type", { context: { item } }, new Error("Unknown library item type"));
            return null;
        }
      }}
    </VirtualizedList>
  );
}

const allowedItemKeys = { "ditto/componentItem": z.string(), "ditto/folder": z.string() };

function FolderBackButton() {
  const currentFolderParentId = useAtomValue(libraryCurrentFolderParentIdAtom);
  const currentFolderName = useAtomValue(libraryCurrentFolderNameAtom);
  const navigateToFolder = useSetAtom(navigateToLibraryFolderActionAtom);
  const reorderLibraryComponentsAction = useSetAtom(reorderLibraryComponentsActionAtom);
  const moveFolder = useSetAtom(moveLibraryComponentFolderActionAtom);

  const handleFolderBack = useCallback(
    function _handleFolderBack() {
      navigateToFolder(currentFolderParentId);
    },
    [currentFolderParentId, navigateToFolder]
  );

  const handleDrop = useCallback(
    function _handleDrop(itemIds: string[], _dragLocation: DragLocation, dropKey: keyof typeof allowedItemKeys) {
      if (dropKey === "ditto/folder") {
        // Handle folder drop - move the folder up one level in the hierarchy
        const droppedFolderId = itemIds[0];

        // When moving to the root level (currentFolderParentId is null),
        // we pass an empty string which will be normalized to null in the action atom
        moveFolder({
          folderId: droppedFolderId,
          targetFolderId: currentFolderParentId || "",
        });
      } else {
        // Handle component drop - move components to parent folder
        reorderLibraryComponentsAction([
          {
            componentIds: itemIds,
            folderId: currentFolderParentId,
          },
        ]);
      }
    },
    [currentFolderParentId, reorderLibraryComponentsAction, moveFolder]
  );

  if (!currentFolderName) return null;

  return (
    <DragAndDroppable
      allowedItemKeys={allowedItemKeys}
      onDrop={handleDrop}
      isDragDisabled
      getDraggableItems={() => []}
      selectionType="text"
      className={style.dragAndDropWrapper}
    >
      {({ isDropTarget }) => {
        return (
          <div
            className={classNames(style.libraryFolderNavBar, { [style.dropTargetHighlight]: isDropTarget })}
            onClick={handleFolderBack}
          >
            <ChevronLeftIcon className={style.libraryFolderNavBarIcon} />
            <Text size="small" className={style.folderName} truncate>
              {currentFolderName}
            </Text>
          </div>
        );
      }}
    </DragAndDroppable>
  );
}

export default LibraryLeftSidebar;
