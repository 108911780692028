import { libraryComponentCommentsFamilyAtom } from "@/stores/Comments";
import { derivedOnlySelectedComponentIdAtom } from "@/stores/Library";
import CommentThreadWrapper from "@/views/NS/components/CommentThreadWrapper";
import PanelEmptyState from "@ds/molecules/PanelEmptyState";
import { Scrollbar } from "@ds/molecules/Scrollbar";
import { useAtomValue } from "jotai";
import React, { Suspense } from "react";
import Skeleton from "react-loading-skeleton";
import style from "./style.module.css";

function LibraryEditCommentsPanel() {
  const selectedComponentId = useAtomValue(derivedOnlySelectedComponentIdAtom);
  const commentIds = useAtomValue(libraryComponentCommentsFamilyAtom(selectedComponentId));

  if (!commentIds.length) {
    return (
      <PanelEmptyState
        data-testid="selected-comments-panel"
        header="No comments"
        description="Comments and suggested edits will appear here"
      />
    );
  }

  return (
    <Scrollbar className={style.scrollArea} data-testid="selected-comments-panel">
      {commentIds.map((commentThreadId, index) => (
        <React.Fragment key={commentThreadId}>
          {index !== 0 && <hr className={style.divider} />}
          <CommentThreadWrapper className={style.commentThread} commentThreadId={commentThreadId} />
        </React.Fragment>
      ))}
    </Scrollbar>
  );
}

function LibraryComponentCommentsWithSuspense() {
  return (
    <Suspense fallback={<Skeleton height={61} baseColor="white" />}>
      <LibraryEditCommentsPanel />
    </Suspense>
  );
}

export default LibraryComponentCommentsWithSuspense;
