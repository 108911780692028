import * as Tab from "@radix-ui/react-tabs";
import classNames from "classnames";
import React from "react";
import Label from "../../atoms/Label";
import style from "./index.module.css";

export interface ITab {
  id: string;
  label: string;
  leadingIcon?: React.ReactNode | React.ReactSVGElement;
  trailingIcon?: React.ReactNode | React.ReactSVGElement;
  disabled?: boolean;
  /**
   * Use this prop if you want to render something other than a tab trigger (e.g. a divider or back button) in the Tab list.
   * If this prop is present, all other props will be ignored and this content will be rendered as provided.
   */
  nonTabContent?: React.ReactNode | React.ReactSVGElement;
  /**
   * If provided, this will be wrapped around the tab label.
   */
  labelWrapper?: (props: { children: React.ReactNode }) => React.ReactNode;
}

interface IProps {
  tabs: Array<ITab>;
  defaultTabIndex?: number; // defaults to 0
  className?: string;
  listClassName?: string;
  triggerClassName?: string;
  style?: React.CSSProperties;
  onValueChange?: (value: string) => void;
  value?: string;
  size?: "small" | "default";
}

export function Tabs(props: IProps) {
  const tabIds = props.tabs.map((tab) => tab.id);
  const size = props.size || "default";

  const defaultTabId = props.defaultTabIndex ? tabIds[props.defaultTabIndex] : tabIds[0];

  return (
    <Tab.Root
      value={props.value}
      defaultValue={defaultTabId}
      className={classNames(style.tabRoot, props.className)}
      data-testid="tabs"
      onValueChange={props.onValueChange}
    >
      <Tab.List className={classNames(style.tabList, props.listClassName)}>
        {props.tabs.map((tab) => {
          if (tab.nonTabContent) {
            return (
              <Label
                key={tab.id}
                size={size}
                leadingIcon={tab.leadingIcon}
                trailingIcon={tab.trailingIcon}
                className={style.tabLabel}
              >
                {tab.nonTabContent}
              </Label>
            );
          }
          return (
            <Tab.Trigger
              key={tab.id}
              value={tab.id}
              className={classNames(props.triggerClassName, style.tabTrigger, {
                [style.disabled]: tab.disabled,
              })}
              disabled={tab.disabled}
              data-testid={`tabs-tab-${tab.id}`}
            >
              {(tab.labelWrapper ?? ((c) => c.children))({
                children: (
                  <Label
                    size={size}
                    leadingIcon={tab.leadingIcon}
                    trailingIcon={tab.trailingIcon}
                    className={style.tabLabel}
                  >
                    {tab.label}
                  </Label>
                ),
              })}
            </Tab.Trigger>
          );
        })}
      </Tab.List>
    </Tab.Root>
  );
}

export default Tabs;
