import useSegment from "@/hooks/useSegment";
import { allBlockIdsAtom, allTextItemIdsAtom } from "@/stores/Project";
import { setSelectedBlockIdsActionAtom, setSelectedTextIdsActionAtom } from "@/stores/ProjectSelection";
import { textItemFamilyAtom } from "@/stores/TextItem";
import { blockSelectedVariantIdFamilyAtom } from "@/stores/Variants";
import ChangeItem from "@ds/molecules/ChangeItem";
import { CustomChangeItemComponentProps } from "@ds/organisms/ChangeItemList";
import { showToastActionAtom } from "@shared/frontend/stores/Toast";
import useAtomEffect, { GetterWithPeek } from "@shared/frontend/stores/useAtomEffect";
import { ENTRY_TYPES, extractBlockId, extractTextItemIds } from "@shared/types/ActualChange";
import { TrackArgs } from "@shared/types/analytics";
import { Setter } from "jotai";
import React, { useCallback, useState } from "react";

const TEXT_ITEM_DELETED_MESSAGE = "That text item was deleted";
const MULTI_TEXT_ITEMS_DELETED_MESSAGE = "Those text items were deleted";
const BLOCK_DELETED_MESSAGE = "That block was deleted";

function ProjectActivityItem({ changeItem }: CustomChangeItemComponentProps) {
  const [handlers, setHandlers] = useState<{ onClick: () => void } | undefined>();

  const { track } = useSegment();

  const sendTrackingEvent = useCallback(
    ({ event, properties }: TrackArgs) => {
      track({ event, properties: { version: "NS", application: "web_app", ...properties } });
    },
    [track]
  );

  const getOnClickFn = useCallback(
    (get: GetterWithPeek, set: Setter) => {
      if (
        changeItem.entry_type === ENTRY_TYPES.TEXT_ITEM_DELETED ||
        changeItem.entry_type === ENTRY_TYPES.DITTO_BLOCK_DELETED
      ) {
        setHandlers(undefined);
        return;
      }

      const textItemIds = extractTextItemIds(changeItem);
      if (textItemIds.length) {
        // Filter to just the text items that still exist in the project
        const existingTextIds = get.peek(allTextItemIdsAtom);
        const textItemsToSelect = textItemIds.filter((id) => existingTextIds.includes(id));
        if (textItemsToSelect.length) {
          // Select the text items that still exist
          setHandlers({
            onClick: async () => {
              if ("variantId" in changeItem && changeItem.variantId) {
                let textItem = await get.peek(textItemFamilyAtom(textItemsToSelect[0]));
                if (textItem && textItem.blockId) {
                  set(blockSelectedVariantIdFamilyAtom(textItem.blockId), changeItem.variantId);
                }
              }
              set(setSelectedTextIdsActionAtom, textItemsToSelect);
            },
          });
          return;
        } else {
          // All of these text items were deleted, so show a toast message instead
          const message = textItemIds.length === 1 ? TEXT_ITEM_DELETED_MESSAGE : MULTI_TEXT_ITEMS_DELETED_MESSAGE;
          setHandlers({ onClick: () => set(showToastActionAtom, { message }) });
          return;
        }
      }
      const blockId = extractBlockId(changeItem);
      if (blockId) {
        // Determine if the block still exists
        const existingBlockIds = get.peek(allBlockIdsAtom);
        if (existingBlockIds.includes(blockId)) {
          // Select the block if it still exists
          setHandlers({ onClick: () => set(setSelectedBlockIdsActionAtom, blockId) });
          return;
        } else {
          // Show a toast message that the block was deleted
          setHandlers({ onClick: () => set(showToastActionAtom, { message: BLOCK_DELETED_MESSAGE }) });
          return;
        }
      }

      setHandlers(undefined);
      return;
    },
    [changeItem]
  );

  useAtomEffect(
    useCallback(
      (get, set) => {
        getOnClickFn(get, set);
      },
      [getOnClickFn]
    )
  );

  return <ChangeItem changeItem={changeItem} onClick={handlers?.onClick} sendTrackingEvent={sendTrackingEvent} />;
}

export default ProjectActivityItem;
