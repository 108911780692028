import { textItemFamilyAtom } from "@/stores/TextItem";
import client from "@shared/frontend/http/httpClient";
import asyncMutableDerivedAtom from "@shared/frontend/stores/asyncMutableDerivedAtom";
import batchedAsyncAtomFamily from "@shared/frontend/stores/batchedAsyncAtomFamily";
import optimisticUpdateActionAtom from "@shared/frontend/stores/optimisticUpdateActionAtom";
import paginatedAtom from "@shared/frontend/stores/paginatedAtom";
import { REFRESH_SILENTLY } from "@shared/frontend/stores/symbols";
import { ICommentThread } from "@shared/types/CommentThread";
import logger from "@shared/utils/logger";
import { atom } from "jotai";
import { atomFamily, splitAtom, unwrap } from "jotai/utils";
import { libraryComponentFamilyAtom } from "./Components";
import { projectIdAtom } from "./Project";
import { commentEditsAtom } from "./ProjectSelection";

/**
 * A family atom that maps comment_thread_id to ICommentThread.
 */
export const { familyAtom: commentFamilyAtom, resetAtom: resetCommentFamilyAtomActionAtom } =
  batchedAsyncAtomFamily<ICommentThread>({
    asyncFetchRequest: async (get, ids) => {
      const projectId = get(projectIdAtom) || undefined;

      return client.comments.getCommentsByCommentIds({
        commentIds: ids,
        projectId,
        type: "standard",
      });
    },
    getId: (item) => item._id,
    debugPrefix: "Comment",
  });

/**
 * Stores the list of comment_thread_ids to render the Project Comments panel. This is a paginated atom.
 */
const {
  valueAtom: _projectCommentsAtom,
  fetchNextPageActionAtom: _fetchNextProjectCommentsPageActionAtom,
  hasMoreAtom: _hasMoreProjectCommentsAtom,
  loadingAtom: _projectCommentsLoadingAtom,
} = paginatedAtom<string, string | null>({
  dependencyAtom: projectIdAtom,
  pageSize: 20,
  async pageRequest({ page, pageSize }, projectId) {
    try {
      if (!projectId) return [];

      return client.comments.getProjectCommentIds({
        projectId,
        type: "standard",
        limit: `${pageSize}`,
        skip: `${page * pageSize}`,
      });
    } catch (error) {
      logger.error("Failed to fetch project comments", { context: { projectId, page, pageSize } }, error);
      return [];
    }
  },
  debugPrefix: "Project Comments",
});

export const projectCommentsSplitAtom = splitAtom(unwrap(_projectCommentsAtom, (prev) => prev ?? []));

export const projectCommentsAtom = _projectCommentsAtom;
export const fetchNextProjectCommentsPageActionAtom = _fetchNextProjectCommentsPageActionAtom;
export const hasMoreProjectCommentsAtom = _hasMoreProjectCommentsAtom;
export const projectCommentsLoadingAtom = _projectCommentsLoadingAtom;

/**
 * Stores the list of comment_thread_ids to render the Library Comments panel. This is a paginated atom.
 */
const {
  valueAtom: _libraryCommentsAtom,
  fetchNextPageActionAtom: _fetchNextLibraryCommentsPageActionAtom,
  hasMoreAtom: _hasMoreLibraryCommentsAtom,
  loadingAtom: _libraryCommentsLoadingAtom,
} = paginatedAtom<string, string | null>({
  dependencyAtom: atom(null),
  pageSize: 20,
  async pageRequest({ page, pageSize }) {
    try {
      return client.comments.getLibraryCommentIds({
        type: "standard",
        limit: `${pageSize}`,
        skip: `${page * pageSize}`,
      });
    } catch (error) {
      logger.error("Failed to fetch library comments", { context: { page, pageSize } }, error);
      return [];
    }
  },
  debugPrefix: "Library Comments",
});

export const libraryCommentsSplitAtom = splitAtom(unwrap(_libraryCommentsAtom, (prev) => prev ?? []));

export const libraryCommentsAtom = _libraryCommentsAtom;
export const fetchNextLibraryCommentsPageActionAtom = _fetchNextLibraryCommentsPageActionAtom;
export const hasMoreLibraryCommentsAtom = _hasMoreLibraryCommentsAtom;
export const libraryCommentsLoadingAtom = _libraryCommentsLoadingAtom;

/**
 * This atom keeps track of the comments (just thread ids) for a given text item.
 * It is an atomFamily keyed by textItemId.
 */
export const textItemCommentsFamilyAtom = atomFamily((textItemId: string | null) => {
  const { valueAtom: familyAtom } = asyncMutableDerivedAtom<string[]>({
    loadData: async () => {
      if (!textItemId) return [];

      return client.comments.getTextItemCommentIds({ textItemId, type: "standard" });
    },
  });

  familyAtom.debugLabel = `Text Item Comment Family ${textItemId}`;

  return familyAtom;
});

/**
 * This atom keeps track of the comments (just thread ids) for a given library component.
 * It is an atomFamily keyed by libraryComponentId.
 */
export const libraryComponentCommentsFamilyAtom = atomFamily((libraryComponentId: string | null) => {
  const { valueAtom: familyAtom } = asyncMutableDerivedAtom<string[]>({
    loadData: async () => {
      if (!libraryComponentId) return [];

      return client.comments.getLibraryComponentCommentIds({ libraryComponentId, type: "standard" });
    },
  });

  familyAtom.debugLabel = `Library Component Comment Family ${libraryComponentId}`;

  return familyAtom;
});

/**
 * This atom represents the most recent comment for a given text item.
 * It is an atomFamily that maps textItemId to comment_thread_id.
 */
export const mostRecentTextItemCommentFamilyAtom = atomFamily((textItemId: string | null) => {
  const { valueAtom, refreshAtom } = asyncMutableDerivedAtom<string | null>({
    loadData: async (get) => {
      if (!textItemId) return null;
      const projectId = get(projectIdAtom);
      if (!projectId) return null;

      const result = await client.dittoProject.getMostRecentCommentThreadIdByTextItemId({
        projectId,
        textItemId,
        type: "standard",
      });

      return result?._id ?? null;
    },
  });

  // This allows us to call set(atomFamily(id), REFRESH_SILENTLY) to force a re-fetch
  const familyAtom = atom(
    (get) => get(valueAtom),
    (get, set, newValue: string | null | typeof REFRESH_SILENTLY) => {
      if (newValue === REFRESH_SILENTLY) {
        set(refreshAtom);
      } else {
        set(valueAtom, newValue);
      }
    }
  );

  familyAtom.debugLabel = `Most Recent Text Item Comment Family ${textItemId}`;

  return familyAtom;
});

/**
 * This atom represents the most recent comment for a given library component.
 * It is an atomFamily that maps libraryComponentId to comment_thread_id.
 */
export const mostRecentLibraryComponentCommentFamilyAtom = atomFamily((componentId: string | null) => {
  const { valueAtom, refreshAtom } = asyncMutableDerivedAtom<string | null>({
    loadData: async (get) => {
      if (!componentId) return null;

      const result = await client.libraryComponent.getMostRecentCommentThreadIdByLibraryComponentId({
        componentId,
        type: "standard",
      });

      return result?._id ?? null;
    },
  });

  // This allows us to call set(atomFamily(id), REFRESH_SILENTLY) to force a re-fetch
  const familyAtom = atom(
    (get) => get(valueAtom),
    (get, set, newValue: string | null | typeof REFRESH_SILENTLY) => {
      if (newValue === REFRESH_SILENTLY) {
        set(refreshAtom);
      } else {
        set(valueAtom, newValue);
      }
    }
  );

  familyAtom.debugLabel = `Most Recent Library Component Comment Family ${componentId}`;

  return familyAtom;
});

// MARK: Actions

/**
 * An action to add or update a comment in the commentFamilyAtom with the provided comment data.
 */
export const updateCommentActionAtom = atom(null, (_get, set, comment: ICommentThread) => {
  set(commentFamilyAtom(comment._id), comment);
});

/**
 * An action to update the commentEditsAtom with the provided commentId.
 * If typing a new comment, pass "new" as the commentThreadId.
 */
export const updateCommentEditsAtom = atom(
  null,
  (get, set, { commentThreadId, hasUnsavedChanges }: { commentThreadId: string; hasUnsavedChanges: boolean }) => {
    const prevEdits = get(commentEditsAtom);
    const updatedEdits = new Set(prevEdits);

    if (hasUnsavedChanges) {
      set(commentEditsAtom, updatedEdits.add(commentThreadId));
    } else {
      updatedEdits.delete(commentThreadId);
      set(commentEditsAtom, updatedEdits);
    }
  }
);

/**
 * An action to update jotai after we've detected a new comment thread was created.
 */
export const handleCommentCreatedActionAtom = atom(null, async (get, set, newComment: ICommentThread) => {
  // Add the new comment's data to the commentFamilyAtom
  set(updateCommentActionAtom, newComment);

  // Add the new comment to projectCommentsAtom if it's related to a project (text item)
  if (newComment.doc_id) {
    const projectId = get(projectIdAtom);
    if (projectId === newComment.doc_id) {
      set(projectCommentsAtom, (prev) => {
        if (!prev) return prev;
        if (prev.includes(newComment._id)) return prev;
        return [newComment._id, ...prev];
      });
    }
  }

  // If the comment was on a text item, update the related text item comment atoms
  if (newComment.comp_id) {
    // Update the most recent comment for the text item
    set(mostRecentTextItemCommentFamilyAtom(newComment.comp_id), newComment._id);

    // If this text item's comment family is already loaded, add the new comment to it
    const idsInCache = [...textItemCommentsFamilyAtom.getParams()];
    if (idsInCache.includes(newComment.comp_id)) {
      const existingComments = await get(textItemCommentsFamilyAtom(newComment.comp_id));
      if (!existingComments.includes(newComment._id)) {
        set(textItemCommentsFamilyAtom(newComment.comp_id), [newComment._id, ...existingComments]);
      }
    }

    // Add the id to textItem.comment_threads, which renders the comment count badge on each text item
    const textItem = await get(textItemFamilyAtom(newComment.comp_id));
    if (!textItem.comment_threads.includes(newComment._id)) {
      set(textItemFamilyAtom(newComment.comp_id), {
        ...textItem,
        comment_threads: [newComment._id, ...textItem.comment_threads],
      });
    }
  }
  // If the comment was on a library component, update the related library comment atoms
  else if (newComment.library_comp_id) {
    // Update the overall comments list for library components
    set(libraryCommentsAtom, (prev) => {
      if (!prev) return prev;
      if (prev.includes(newComment._id)) return prev;
      return [newComment._id, ...prev];
    });

    // Update the most recent comment for the library component
    set(mostRecentLibraryComponentCommentFamilyAtom(newComment.library_comp_id), newComment._id);

    // If this library component's comment family is already loaded, add the new comment to it
    const idsInCache = [...libraryComponentCommentsFamilyAtom.getParams()];
    if (idsInCache.includes(newComment.library_comp_id)) {
      const existingComments = await get(libraryComponentCommentsFamilyAtom(newComment.library_comp_id));
      if (!existingComments.includes(newComment._id)) {
        set(libraryComponentCommentsFamilyAtom(newComment.library_comp_id), [newComment._id, ...existingComments]);
      }
    }

    // Add the id to libraryComponent.comment_threads, which renders the comment count badge on each library component
    const libraryComponent = await get(libraryComponentFamilyAtom(newComment.library_comp_id));
    if (!libraryComponent.commentThreads.includes(newComment._id)) {
      set(libraryComponentFamilyAtom(newComment.library_comp_id), {
        ...libraryComponent,
        commentThreads: [newComment._id, ...libraryComponent.commentThreads],
      });
    }
  }
});

/**
 * An action to update jotai after we've detected a comment thread was updated.
 */
export const handleCommentUpdatedActionAtom = atom(null, async (get, set, newComment: ICommentThread) => {
  // Add the new comment's data to the commentFamilyAtom
  set(updateCommentActionAtom, newComment);
});

export const resolveUnresolveCommentActionAtom = optimisticUpdateActionAtom<
  { commentThreadId: string; newResolvedValue: boolean },
  ICommentThread
>({
  getOriginalData: async (get, { commentThreadId }) => await get(commentFamilyAtom(commentThreadId)),
  updateDataInMemory: async (get, set, { commentThreadId, newResolvedValue }, localOnly) => {
    const existingComment = await get(commentFamilyAtom(commentThreadId));
    if (!existingComment) {
      return;
    }

    // Update the comment data in the commentFamilyAtom
    if (existingComment.is_resolved !== newResolvedValue) {
      set(updateCommentActionAtom, { ...existingComment, is_resolved: newResolvedValue });

      if (existingComment.comp_id) {
        // Changing the resolution could affect which comment is the most recent unresolved, so re-fetch it
        // Only doing this if localOnly, because otherwise we have to wait until the backend update is done
        if (localOnly) {
          set(mostRecentTextItemCommentFamilyAtom(existingComment.comp_id), REFRESH_SILENTLY);
        }

        // Update the comment_threads array in the text item, which affects the comment count badge
        const textItem = await get(textItemFamilyAtom(existingComment.comp_id));
        // If the comment got resolved, remove it from the text item's comment_threads array
        if (newResolvedValue && textItem.comment_threads.includes(commentThreadId)) {
          set(textItemFamilyAtom(existingComment.comp_id), {
            ...textItem,
            comment_threads: textItem.comment_threads.filter((id) => id !== commentThreadId),
          });
        }
        // If the comment got unresolved, add it to the text item's comment_threads array
        else if (!newResolvedValue && !textItem.comment_threads.includes(commentThreadId)) {
          set(textItemFamilyAtom(existingComment.comp_id), {
            ...textItem,
            comment_threads: [commentThreadId, ...textItem.comment_threads],
          });
        }
      } else if (existingComment.library_comp_id) {
        // Changing the resolution could affect which comment is the most recent unresolved, so re-fetch it
        // Only doing this if localOnly, because otherwise we have to wait until the backend update is done
        if (localOnly) {
          set(mostRecentLibraryComponentCommentFamilyAtom(existingComment.library_comp_id), REFRESH_SILENTLY);
        }

        // Update the commentThreads array in the library component, which affects the comment count badge
        const libraryComponent = await get(libraryComponentFamilyAtom(existingComment.library_comp_id));
        // If the comment got resolved, remove it from the library component's comment_threads array
        if (newResolvedValue && libraryComponent.commentThreads.includes(commentThreadId)) {
          set(libraryComponentFamilyAtom(existingComment.library_comp_id), {
            ...libraryComponent,
            commentThreads: libraryComponent.commentThreads.filter((id) => id !== commentThreadId),
          });
        }
        // If the comment got unresolved, add it to the text item's comment_threads array
        else if (!newResolvedValue && !libraryComponent.commentThreads.includes(commentThreadId)) {
          set(libraryComponentFamilyAtom(existingComment.library_comp_id), {
            ...libraryComponent,
            commentThreads: [commentThreadId, ...libraryComponent.commentThreads],
          });
        }
      }
    }
  },
  persistUpdate: async (get, set, { commentThreadId, newResolvedValue }) => {
    const updatedCommentThread = await client.comments.resolveThread({
      threadId: commentThreadId,
      is_resolved: newResolvedValue,
      from: "web_app",
    });

    // Since the list of resolved threads just changed, re-fetch the most recent unresolved thread
    if (updatedCommentThread.comp_id) {
      set(mostRecentTextItemCommentFamilyAtom(updatedCommentThread.comp_id), REFRESH_SILENTLY);
    } else if (updatedCommentThread.library_comp_id) {
      set(mostRecentLibraryComponentCommentFamilyAtom(updatedCommentThread.library_comp_id), REFRESH_SILENTLY);
    }
  },
  rollbackDataInMemory: async (get, set, { input, originalData, updateDataInMemory }) => {
    updateDataInMemory(get, set, {
      commentThreadId: input.commentThreadId,
      newResolvedValue: originalData.is_resolved,
    });
  },
  errorText: (input) => `Failed to ${input.newResolvedValue ? "resolve" : "unresolve"} comment thread`,
  debugPrefix: "Toggle Comment Resolved",
});

/**
 * An action to post a new comment (start a new thread) on a text item
 */
export const postTextItemCommentActionAtom = atom(
  null,
  async (
    get,
    set,
    {
      textItemId,
      commentText,
      mentionedUserIds,
    }: { textItemId: string; commentText: string; mentionedUserIds: string[] }
  ) => {
    const projectId = get(projectIdAtom);
    if (!projectId) return; // Should never happen

    if (!commentText) return;

    const newCommentThread = await client.comments.createCommentThread({
      projectId,
      first_comment: commentText,
      mentionedUserIds,
      comp_id: textItemId,
      from: "web_app",
    });

    set(handleCommentCreatedActionAtom, newCommentThread);
  }
);

/**
 * An action to post a new comment (start a new thread) on a library component
 */
export const postLibraryComponentCommentActionAtom = atom(
  null,
  async (
    get,
    set,
    {
      libraryComponentId,
      commentText,
      mentionedUserIds,
    }: { libraryComponentId: string; commentText: string; mentionedUserIds: string[] }
  ) => {
    if (!commentText) return;

    const newCommentThread = await client.comments.createCommentThread({
      first_comment: commentText,
      mentionedUserIds,
      library_comp_id: libraryComponentId,
      from: "web_app",
    });

    set(handleCommentCreatedActionAtom, newCommentThread);
  }
);

/**
 *  An action for posting a reply to a comment thread.
 */
export const postCommentReplyActionAtom = atom(
  null,
  async (
    get,
    set,
    {
      commentThreadId,
      commentText,
      mentionedUserIds,
    }: { commentThreadId: string; commentText: string; mentionedUserIds: string[] }
  ) => {
    if (!commentText) return;

    const newReplyCommentThread = await client.comments.postReply({
      threadId: commentThreadId,
      text: commentText,
      mentionedUserIds,
      from: "web_app",
    });

    set(handleCommentUpdatedActionAtom, newReplyCommentThread);
  }
);
