import client from "@shared/frontend/http/httpClient";
import { createLibraryComponentFoldersStore } from "@shared/frontend/stores/LibraryComponentFolders";
import { REFRESH_SILENTLY } from "@shared/frontend/stores/symbols";
import { showToastActionAtom } from "@shared/frontend/stores/Toast";
import logger from "@shared/utils/logger";
import { atom } from "jotai";
import { soon } from "jotai-derive";
import { observe } from "jotai-effect";
import { atomFamily } from "jotai/utils";
import { libraryComponentFamilyAtom } from "./Components";
import {
  libraryListAtomFamily,
  LibraryListItem,
  preloadLibraryListAtomFamilyActionAtom,
  selectedLibraryFolderIdAtom,
} from "./Library";
import { locationAtom } from "./Location";
export const {
  libraryComponentFolderFamilyAtom,
  resetLibraryComponentFolderFamilyActionAtom,
  libraryComponentFoldersListAtom,
  removeFolderFromListActionAtom,
  refreshLibraryComponentFoldersListAtom: _refreshLibraryComponentFoldersListAtom,
  libraryComponentFoldersBreadcrumbsAtomFamily,
  handleLibraryComponentFolderCreatedActionAtom: _handleLibraryComponentFolderCreatedActionAtom,
  handleLibraryComponentFolderUpdatedActionAtom: _handleLibraryComponentFolderUpdatedActionAtom,
  saveLibraryComponentFolderNameActionAtom: _saveLibraryComponentFolderNameActionAtom,
} = createLibraryComponentFoldersStore(client);

export const nullableLibraryComponentFolderFamilyAtom = atomFamily((folderId: string | null) => {
  return atom((get) => {
    if (!folderId) return null;
    return get(libraryComponentFolderFamilyAtom(folderId));
  });
});

observe(function preloadComponentLibraryFolders(get, set) {
  // don't preload component library folders if we're not on the library page
  const location = get(locationAtom);
  if (!location.pathname?.includes("/library")) return;

  // run every time the component folders list is updated, which happens if a folder is created
  get(libraryComponentFoldersListAtom);

  set(preloadLibraryListAtomFamilyActionAtom);
});

export const refreshLibraryComponentFoldersListAtom: typeof _refreshLibraryComponentFoldersListAtom = atom(
  null,
  async (get, set) => {
    await set(_refreshLibraryComponentFoldersListAtom);
    const selectedFolderId = await get(selectedLibraryFolderIdAtom);
    set(libraryListAtomFamily(selectedFolderId ?? undefined), REFRESH_SILENTLY);
  }
);

export const handleLibraryComponentFolderCreatedActionAtom: typeof _handleLibraryComponentFolderCreatedActionAtom =
  atom(null, async (_, set, arg) => {
    // update the front-end respository of component folders
    set(_handleLibraryComponentFolderCreatedActionAtom, arg);

    // update the component library structure
    const parentFolderAtom = libraryListAtomFamily(arg.newFolderParentId ?? undefined);
    // NOTE: refresh rather than manually setting data to avoid having to re-implement sorting logic
    set(parentFolderAtom, REFRESH_SILENTLY);
  });

export const handleLibraryComponentFolderUpdatedActionAtom: typeof _handleLibraryComponentFolderUpdatedActionAtom =
  atom(null, async (get, set, args) => {
    // update the front-end repository of component folders
    set(_handleLibraryComponentFolderUpdatedActionAtom, args);

    // If parentId is included in the args, we need to update the library structure
    if (args.parentId !== undefined) {
      // Get the current folder data to check what changed
      const folder = await get(libraryComponentFolderFamilyAtom(args.folderId));
      if (!folder) return;

      // Get the original parent ID
      const originalParentId = folder.parentId;

      // Only proceed if the parentId actually changed
      if (originalParentId !== args.parentId) {
        // Remove the folder from its original parent's list
        const originalParentListAtom = libraryListAtomFamily(originalParentId ?? undefined);
        const originalParentList = await get(originalParentListAtom);
        set(
          originalParentListAtom,
          originalParentList.filter((item) => item._id !== args.folderId)
        );

        // Add the folder to its new parent's list
        const newParentListAtom = libraryListAtomFamily(args.parentId ?? undefined);
        const newParentList = await get(newParentListAtom);

        // Create the folder item with the correct type
        const folderItem = { type: "componentFolder" as const, _id: args.folderId };

        // Find the index to insert at (before the first component)
        const indexOfFirstComponent = newParentList.findIndex((item) => item.type === "component");

        // Create a new array only if we need to modify it
        if (indexOfFirstComponent === -1) {
          // No components, add to the end
          set(newParentListAtom, [...newParentList, folderItem]);
        } else {
          // Insert before the first component
          const updatedList = [
            ...newParentList.slice(0, indexOfFirstComponent),
            folderItem,
            ...newParentList.slice(indexOfFirstComponent),
          ];
          set(newParentListAtom, updatedList);
        }
      }
    }
  });

/**
 * Handler that's called when a folder deletion event is received via websocket.
 */
export const handleLibraryComponentFolderDeletedActionAtom = atom(
  null,
  async (get, set, args: { folderId: string; folderName: string; idToKeyMap: Record<string, string> }) => {
    // get folder id before we update it to the parent folder's id
    const selectedFolderId = await get(selectedLibraryFolderIdAtom);

    // update library list atoms to reflect state after folder deletion
    await set(_deleteLibraryComponentFolderActionAtom, args);

    // notify user if the deleted folder was the currently selected folder
    if (selectedFolderId === args.folderId) {
      set(showToastActionAtom, {
        message: `Folder "${args.folderName}" was deleted`,
      });
    }

    // update sort keys
    for (const entry of Object.entries(args.idToKeyMap)) {
      const [componentId, sortKey] = entry;
      const componentAtom = libraryComponentFamilyAtom(componentId);
      set(componentAtom, (prev) => ({ ...prev, sortKey }));
    }
  }
);

/**
 * Handler that's called when a folder reordering event is received via websocket.
 */
export const handleLibraryComponentFolderReorderedActionAtom = atom(
  null,
  async (get, set, args: { folderId: string; idToKeyMap: Record<string, string> }) => {
    // Update the folder's sort key
    const folderAtom = libraryComponentFolderFamilyAtom(args.folderId);
    const folder = await get(folderAtom);

    if (folder) {
      // Update the sort keys for all affected items
      for (const entry of Object.entries(args.idToKeyMap)) {
        const [folderId, sortKey] = entry;
        const itemAtom = libraryComponentFolderFamilyAtom(folderId);
        set(itemAtom, (prev) => ({ ...prev, sortKey }));
      }

      // Refresh the parent folder's list to reflect the new order
      const parentFolderAtom = libraryListAtomFamily(folder.parentId ?? undefined);
      set(parentFolderAtom, REFRESH_SILENTLY);
    }
  }
);

/**
 * Internal action atom that handles updating Jotai state after folder deletion.
 */
const _deleteLibraryComponentFolderActionAtom = atom(null, async (get, set, args: { folderId: string }) => {
  const folderToDelete = await get(libraryComponentFolderFamilyAtom(args.folderId));

  const folderChildren = await get(libraryListAtomFamily(args.folderId));
  const childFolderIds = folderChildren.filter((child) => child.type === "componentFolder").map((child) => child._id);
  const childComponents = folderChildren.filter((child) => child.type === "component");
  const parentFolderListAtom = libraryListAtomFamily(folderToDelete.parentId ?? undefined);
  const originalParentItems = await get(parentFolderListAtom);

  // remove this folder from its parent folder list
  const filteredList = originalParentItems.filter((item) => item._id !== args.folderId);
  set(parentFolderListAtom, filteredList);

  // update parentId of child folders
  for (const childFolderId of childFolderIds) {
    const childFolderAtom = libraryComponentFolderFamilyAtom(childFolderId);
    const childFolder = await get(childFolderAtom);
    set(childFolderAtom, { ...childFolder, parentId: folderToDelete.parentId });

    // move any child folders to the end of the parent's folder list
    const parentFolderList = await get(parentFolderListAtom);

    const lastFolderIndex = parentFolderList.findLastIndex((item) => item.type === "componentFolder");
    const insertIndex = lastFolderIndex === -1 ? 0 : lastFolderIndex + 1;

    parentFolderList.splice(insertIndex, 0, { _id: childFolderId, type: "componentFolder" });
    set(parentFolderListAtom, parentFolderList);
  }

  // update folderId of child components
  for (const childComponent of childComponents) {
    const componentAtom = libraryComponentFamilyAtom(childComponent._id);
    set(componentAtom, (prev) => ({ ...prev, folderId: folderToDelete.parentId ?? null }));
  }

  // push child components on to parent folder
  const newParentList = [...(await get(libraryListAtomFamily(folderToDelete.parentId ?? undefined)))];
  set(libraryListAtomFamily(folderToDelete.parentId ?? undefined), [...newParentList, ...childComponents]);

  // delete current folder
  set(libraryListAtomFamily(args.folderId), []);

  const selectedFolderId = await get(selectedLibraryFolderIdAtom);

  // update selected folder id to parent folder if it's being deleted
  if (selectedFolderId === args.folderId) {
    set(selectedLibraryFolderIdAtom, folderToDelete.parentId ?? null);
  }

  // delete current folder from family and list atoms
  set(removeFolderFromListActionAtom, args.folderId);
  libraryComponentFolderFamilyAtom.remove(args.folderId);
  libraryListAtomFamily.remove(args.folderId);

  return {
    folderChildren,
    childFolderIds,
    originalParentItems,
  };
});

export const saveLibraryComponentFolderNameActionAtom: typeof _saveLibraryComponentFolderNameActionAtom = atom(
  null,
  async (_, set, arg) => {
    set(_saveLibraryComponentFolderNameActionAtom, arg);
  }
);

/**
 * Given a folderId, returns the name of its parent folder
 */
export const folderParentNameAtomFamily = atomFamily((folderId: string) => {
  return atom((get) => {
    const folderDataPromise = get(libraryComponentFolderFamilyAtom(folderId));
    const folderDataParentId = soon(folderDataPromise, (folderData) => folderData.parentId);
    return soon(folderDataParentId, (parentId) => {
      if (!parentId) return "All Components";
      const parentFolderData = get(libraryComponentFolderFamilyAtom(parentId));
      return soon(parentFolderData, (parentFolderData) => parentFolderData.name);
    });
  });
});

export const deleteLibraryComponentFolderActionAtom = atom(null, async (get, set, args: { folderId: string }) => {
  const { folderChildren, childFolderIds, originalParentItems } = await set(_deleteLibraryComponentFolderActionAtom, {
    folderId: args.folderId,
  });

  try {
    const { idToKeyMap, actionResult } = await client.libraryComponentFolder.deleteLibraryComponentFolder({
      folderId: args.folderId,
    });

    // update sort keys
    for (const entry of Object.entries(idToKeyMap)) {
      const [componentId, sortKey] = entry;
      const componentAtom = libraryComponentFamilyAtom(componentId);
      set(componentAtom, (prev) => ({ ...prev, sortKey }));
    }

    // emit toast if components were moved from deleted folder to new parent
    if (actionResult.destinationFolderId !== undefined && actionResult.componentIds.length > 0) {
      const folderName = actionResult.destinationFolderId
        ? (await get(libraryComponentFolderFamilyAtom(actionResult.destinationFolderId))).name
        : "All components";
      set(showToastActionAtom, {
        message: `Moved ${actionResult.componentIds.length} component${
          actionResult.componentIds.length > 1 ? "s" : ""
        } to "${folderName}"`,
      });
    }
  } catch (error) {
    logger.error(`Failed to delete folder with folderId: ${args.folderId}`, {}, error);

    // revert optimistic updates
    // revert library structure of parent folder
    const folderToDelete = await get(libraryComponentFolderFamilyAtom(args.folderId));
    const parentFolderListAtom = libraryListAtomFamily(folderToDelete.parentId ?? undefined);
    set(parentFolderListAtom, originalParentItems);

    // revert child folders parent ids
    for (const childFolderId of childFolderIds) {
      const childFolderAtom = libraryComponentFolderFamilyAtom(childFolderId);
      const childFolder = await get(childFolderAtom);
      set(childFolderAtom, { ...childFolder, parentId: folderToDelete._id });
    }

    set(libraryListAtomFamily(args.folderId), folderChildren);
  }
});

/**
 * Action atom that handles moving a folder to another folder.
 * This updates the folder's parentId and handles the UI updates optimistically.
 */
export const moveLibraryComponentFolderActionAtom = atom(
  null,
  async (get, set, args: { folderId: string; targetFolderId: string | null }) => {
    const { folderId, targetFolderId } = args;

    if (targetFolderId === folderId) {
      return;
    }

    // Convert empty string to null for the root level
    const normalizedTargetFolderId = targetFolderId === "" ? null : targetFolderId;

    // Get the current folder data
    const folderToMove = await get(libraryComponentFolderFamilyAtom(folderId));
    if (!folderToMove) {
      logger.error("Cannot find folder to move", { context: { folderId } });
      return;
    }

    // Store original parent ID for potential rollback
    const originalParentId = folderToMove.parentId;

    // Get the original parent's list and target folder's list
    const originalParentListAtom = libraryListAtomFamily(originalParentId ?? undefined);
    const targetFolderListAtom = libraryListAtomFamily(normalizedTargetFolderId ?? undefined);

    const originalParentList = await get(originalParentListAtom);
    const targetFolderList = await get(targetFolderListAtom);

    // Make copies for optimistic updates
    const updatedOriginalParentList = originalParentList.filter((item) => item._id !== folderId);
    const updatedTargetFolderList = [...targetFolderList];

    // Find the index to insert at (before the first component)
    const indexOfFirstComponent = updatedTargetFolderList.findIndex((item) => item.type === "component");

    if (indexOfFirstComponent === -1) {
      // No components, add to the end
      updatedTargetFolderList.push({ type: "componentFolder", _id: folderId });
    } else {
      // Insert before the first component
      updatedTargetFolderList.splice(indexOfFirstComponent, 0, { type: "componentFolder", _id: folderId });
    }

    try {
      // Optimistically update the UI
      set(libraryComponentFolderFamilyAtom(folderId), { ...folderToMove, parentId: normalizedTargetFolderId });
      set(originalParentListAtom, updatedOriginalParentList);
      set(targetFolderListAtom, updatedTargetFolderList);

      // Update the folder on the server
      await client.libraryComponentFolder.updateLibraryComponentFolder({
        folderId,
        folderData: {
          parentId: normalizedTargetFolderId,
        },
      });

      // Get the target folder name for the toast
      const targetFolderName = normalizedTargetFolderId
        ? (await get(libraryComponentFolderFamilyAtom(normalizedTargetFolderId))).name
        : "All Components";

      // Show a success toast
      set(showToastActionAtom, {
        message: `Moved folder "${folderToMove.name}" to "${targetFolderName}"`,
      });
    } catch (error) {
      logger.error("Failed to move folder", { context: { folderId, targetFolderId: normalizedTargetFolderId } }, error);

      // Revert optimistic updates
      set(libraryComponentFolderFamilyAtom(folderId), { ...folderToMove, parentId: originalParentId });
      set(originalParentListAtom, originalParentList);
      set(targetFolderListAtom, targetFolderList);

      // Show error toast
      set(showToastActionAtom, {
        message: `Failed to move folder "${folderToMove.name}". Please try again.`,
      });
    }
  }
);

/**
 * Action atom that handles reordering folders within the same parent.
 * This is used when dropping a folder at the top or bottom edge of another folder.
 */
export const reorderLibraryComponentFoldersActionAtom = atom(
  null,
  async (get, set, action: { folderId: string; before?: string; after?: string }) => {
    // Variables for rollback
    let originalListCopy: LibraryListItem[] = [];
    let parentId: string | undefined;

    try {
      // Get the folder for optimistically updating its parent's list
      const folder = await get(libraryComponentFolderFamilyAtom(action.folderId));
      if (!folder) {
        throw new Error(`Folder not found: ${action.folderId}`);
      }

      // Get the parent folder's list
      parentId = folder.parentId ?? undefined;
      const parentFolderAtom = libraryListAtomFamily(parentId);
      const originalList = [...(await get(parentFolderAtom))];

      // Store the original list for rollback
      originalListCopy = [...originalList];

      // Find the folder in the list
      const folderIndex = originalList.findIndex((item) => item._id === action.folderId);
      if (folderIndex === -1) {
        throw new Error(`Folder not found in parent's list: ${action.folderId}`);
      }

      // Remove the folder from its current position
      const folderItem = originalList.splice(folderIndex, 1)[0];

      // Find the new position
      let newIndex: number;
      if (action.before) {
        newIndex = originalList.findIndex((item) => item._id === action.before);
        if (newIndex === -1) {
          // If target not found, don't move
          newIndex = folderIndex;
        }
      } else if (action.after) {
        newIndex = originalList.findIndex((item) => item._id === action.after);
        if (newIndex === -1) {
          // If target not found, don't move
          newIndex = folderIndex;
        } else {
          // Insert after the target
          newIndex += 1;
        }
      } else {
        // If no before or after, put at the end
        newIndex = originalList.length;
      }

      // Insert the folder at the new position
      originalList.splice(newIndex, 0, folderItem);

      // Apply optimistic update to the list
      set(parentFolderAtom, originalList);

      // Make the actual API call
      const result = await client.libraryComponentFolder.reorderLibraryComponentFolder({
        action: {
          folderId: action.folderId,
          before: action.before ?? null,
          after: action.after ?? null,
        },
      });

      // Update the sort keys from the server response
      const { idToKeyMap } = result;
      for (const entry of Object.entries(idToKeyMap)) {
        const [folderId, sortKey] = entry;
        const folderAtom = libraryComponentFolderFamilyAtom(folderId);
        set(folderAtom, (prev) => ({ ...prev, sortKey }));
      }
    } catch (error) {
      logger.error("Error reordering folders", { context: { action } }, error);

      // Rollback optimistic update by restoring the original list
      if (parentId !== undefined && originalListCopy.length > 0) {
        const parentFolderAtom = libraryListAtomFamily(parentId);
        set(parentFolderAtom, originalListCopy);
      }

      // Show error toast
      set(showToastActionAtom, {
        message: "Failed to reorder folders. Please try again.",
      });
    }
  }
);
