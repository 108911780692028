import { useAtomRef } from "@/hooks/useAtomRef";
import { libraryComponentFolderFamilyAtom, moveLibraryComponentFolderActionAtom } from "@/stores/ComponentFolders";
import { libraryComponentFamilyAtom } from "@/stores/Components";
import {
  folderChildFoldersCountAtomFamily,
  folderComponentChildrenCountAtomFamily,
  libraryCreateComponentModalIsOpenAtom,
  libraryCreateFolderModalIsOpenAtom,
  libraryCurrentFolderNameAtom,
  libraryDraggedSelectionAtom,
  libraryFolderItemsAtom,
  libraryFolderItemsCountAtom,
  LibraryItem,
  libraryItemsAtom,
  libraryItemsCountAtom,
  libraryItemsVirtualizerAtom,
  libraryItemsWithTagHeadersAtom,
  navigateToLibraryFolderActionAtom,
  reorderLibraryComponentsActionAtom,
  scrollToComponentIdActionAtom,
  selectedComponentIdsAtom,
  selectedGroupingAtom,
} from "@/stores/Library";
import Button from "@ds/atoms/Button";
import DragAndDroppable, { DragLocation } from "@ds/atoms/DragAndDroppable";
import Text from "@ds/atoms/Text";
import VirtualizedList, { createStickyRangeExtractor } from "@ds/atoms/VirtualizedList";
import DropdownMenu, { DropdownMenuItemType } from "@ds/molecules/DropdownMenu";
import LibraryFolderCard from "@ds/molecules/LibraryFolderCard";
import Scrollbar from "@ds/molecules/Scrollbar";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { IS_SAFARI } from "@shared/frontend/constants";
import logger from "@shared/utils/logger";
import { Range } from "@tanstack/react-virtual";
import { atom, useAtom, useAtomValue, useSetAtom } from "jotai";
import { atomEffect } from "jotai-effect";
import { Suspense, useCallback, useMemo, useRef, useState } from "react";
import { DragPreview, DragPreviewRenderer, DragStartEvent } from "react-aria";
import Skeleton from "react-loading-skeleton";
import { z } from "zod";
import LibraryComponentListItem, { LibraryComponentListItemFallback } from "../LibraryComponentListItem";
import { LibraryItemsTagGroupHeader, LibraryItemsTitleHeader } from "../LibraryItemsHeader";
import LibraryItemsListEmptyState from "../LibraryItemsListEmptyState";
import style from "./style.module.css";

const LIBRARY_CONTENT_VIRTUALIZER_ID = "LIBRARY_CONTENT";

function LibraryItemsList() {
  return (
    <div className={style.libraryItemsListContainer} data-clear-library-selection>
      <Suspense fallback={<LibraryItemsListFallback />}>
        <LibraryListItemsContainer />
      </Suspense>
    </div>
  );
}

function LibraryItemsListFallback() {
  return (
    <div className={style.libraryListFallback}>
      <Skeleton count={20} height={80} borderRadius={8} />
    </div>
  );
}

const isScrolledAtom = atom(false);

function LibraryListItemsContainer() {
  const libraryItemsCount = useAtomValue(libraryItemsCountAtom);
  const libraryFolderItemsCount = useAtomValue(libraryFolderItemsCountAtom);
  const grouping = useAtomValue(selectedGroupingAtom);
  const [isScrolled, setIsScrolled] = useAtom(isScrolledAtom);
  const setLibraryCreateComponentModalIsOpen = useSetAtom(libraryCreateComponentModalIsOpenAtom);
  const libraryItemsVirtualizer = useAtomValue(libraryItemsVirtualizerAtom);
  const virtualizer = libraryItemsVirtualizer[LIBRARY_CONTENT_VIRTUALIZER_ID];
  const libraryItems = useAtomValue(libraryItemsAtom);
  const libraryItemsWithTagHeaders = useAtomValue(libraryItemsWithTagHeadersAtom);
  const libraryFolderItems = useAtomValue(libraryFolderItemsAtom);

  const openCreateComponentModal = useCallback(() => {
    setLibraryCreateComponentModalIsOpen(true);
  }, [setLibraryCreateComponentModalIsOpen]);

  const scrollToTop = useCallback(() => {
    virtualizer.scrollToIndex(0);
  }, [virtualizer]);

  const itemsToUse = useMemo(() => {
    if (grouping === "tag") {
      return [LIBRARY_HEADER, ...libraryItemsWithTagHeaders];
    }
    return [LIBRARY_HEADER, ...libraryItems];
  }, [grouping, libraryItems, libraryItemsWithTagHeaders]);

  if (libraryItemsCount === 0 && libraryFolderItemsCount === 0) {
    return <LibraryItemsListEmptyState />;
  } else {
    return (
      <Suspense fallback={<LibraryItemsListFallback />}>
        <div className={style.stretchCenterWrapper}>
          <TitleRow isScrolled={isScrolled} scrollToTop={scrollToTop} onClickNewComponent={openCreateComponentModal} />
        </div>

        {/* TODO: this functionally disables virtualization in the ListContent component; revisit in DIT-9817*/}
        <Scrollbar className={style.topScrollArea}>
          {libraryFolderItems.length > 0 && (
            <div className={style.stretchCenterWrapper}>
              <LibraryFoldersArea />
            </div>
          )}

          <LibraryItemsListContent libraryItems={itemsToUse} />
        </Scrollbar>
      </Suspense>
    );
  }
}

function LibraryFoldersArea() {
  const libraryFolders = useAtomValue(libraryFolderItemsAtom);
  return (
    <div className={style.foldersArea}>
      {libraryFolders.map((folder) => (
        <LibraryFolderWrapper key={folder._id} folderId={folder._id} />
      ))}
    </div>
  );
}

function LibraryFolderWrapper(props: { folderId: string }) {
  const previewRef = useRef<DragPreviewRenderer | null>(null);
  const folderCardDimensions = useRef<{ width: number; height: number } | null>(null);
  const folderCardRef = useRef<HTMLDivElement>(null);
  const folder = useAtomValue(libraryComponentFolderFamilyAtom(props.folderId));
  const componentCount = useAtomValue(folderComponentChildrenCountAtomFamily(props.folderId));
  const folderCount = useAtomValue(folderChildFoldersCountAtomFamily(props.folderId));
  const navigateToFolder = useSetAtom(navigateToLibraryFolderActionAtom);
  const reorderLibraryComponentsAction = useSetAtom(reorderLibraryComponentsActionAtom);
  const moveFolder = useSetAtom(moveLibraryComponentFolderActionAtom);

  const handleDrop = useCallback(
    (droppedItemIds: string[], dragLocation: DragLocation, dropKey: string) => {
      if (dropKey === "ditto/componentItem") {
        reorderLibraryComponentsAction([
          {
            componentIds: droppedItemIds,
            folderId: props.folderId,
          },
        ]);
      } else if (dropKey === "ditto/folder") {
        moveFolder({
          folderId: droppedItemIds[0],
          targetFolderId: props.folderId,
        });
      }
    },
    [props.folderId, reorderLibraryComponentsAction, moveFolder]
  );

  const handleDragStart = useCallback((event: DragStartEvent) => {
    if (folderCardRef.current) {
      folderCardDimensions.current = {
        width: folderCardRef.current.offsetWidth,
        height: folderCardRef.current.offsetHeight,
      };
    }
  }, []);

  return (
    <DragAndDroppable
      allowedItemKeys={{
        "ditto/folder": z.string(),
        "ditto/componentItem": z.string(),
      }}
      getDraggableItems={() => [{ "ditto/folder": props.folderId }]}
      onDrop={handleDrop}
      onDragStart={handleDragStart}
      selectionType="block"
      preview={previewRef}
    >
      {({ isDropTarget, isDragging }) => (
        <>
          <LibraryFolderCard
            ref={folderCardRef}
            className={style.folderCard}
            name={folder.name}
            componentCount={componentCount}
            folderCount={folderCount}
            onClick={() => navigateToFolder(props.folderId)}
            renderDragGhost={isDragging}
            highlightDropTarget={isDropTarget}
          />
          <DragPreview ref={previewRef}>
            {(items) => (
              <LibraryFolderCard
                style={{
                  width: folderCardDimensions.current?.width,
                  height: folderCardDimensions.current?.height,
                }}
                className={style.folderCard}
                name={folder.name}
                componentCount={componentCount}
                folderCount={folderCount}
                onClick={() => navigateToFolder(props.folderId)}
                highlightFocused
              />
            )}
          </DragPreview>
        </>
      )}
    </DragAndDroppable>
  );
}

function TitleRow(props: { isScrolled: boolean; scrollToTop: () => void; onClickNewComponent: () => void }) {
  const currentFolderName = useAtomValue(libraryCurrentFolderNameAtom);
  return (
    <div className={style.headerRow}>
      <Text size="large" weight="strong">
        {currentFolderName ?? "All components"}
      </Text>
      <div className={style.actionButtons}>
        {props.isScrolled && (
          <Button level="subtle" size="micro" onClick={props.scrollToTop}>
            Top
          </Button>
        )}
        <NewButtonDropdown />
      </div>
    </div>
  );
}

function NewButtonDropdown() {
  const [isOpen, setIsOpen] = useState(false);
  const setLibraryCreateComponentModalIsOpen = useSetAtom(libraryCreateComponentModalIsOpenAtom);
  const setLibraryCreateFolderModalIsOpen = useSetAtom(libraryCreateFolderModalIsOpenAtom);

  const openCreateComponentModal = useCallback(() => {
    setLibraryCreateComponentModalIsOpen(true);
  }, [setLibraryCreateComponentModalIsOpen]);

  const openCreateFolderModal = useCallback(() => {
    setLibraryCreateFolderModalIsOpen(true);
  }, [setLibraryCreateFolderModalIsOpen]);

  const DROPDOWN_ITEMS: DropdownMenuItemType[] = useMemo(
    () => [
      {
        type: "option",
        text: "New component",
        onClick: openCreateComponentModal,
      },
      {
        type: "option",
        text: "New folder",
        onClick: openCreateFolderModal,
      },
    ],
    [openCreateComponentModal, openCreateFolderModal]
  );

  return (
    <DropdownMenu
      items={DROPDOWN_ITEMS}
      DropdownTrigger={
        <Button
          level="outline"
          size="small"
          trailingIcon={<KeyboardArrowDownIcon style={{ transform: isOpen ? "rotate(180deg)" : "rotate(0deg)" }} />}
          iconColor="secondary"
        >
          New
        </Button>
      }
      RDropdownMenuContentProps={{ align: "end" }}
      onOpenChange={setIsOpen}
    />
  );
}

const LIBRARY_HEADER = {
  type: "header",
  _id: "header",
  label: "Components",
} as const;

export const libraryComponentListScrollRefAtom = atom<HTMLDivElement | null>(null);
export const libraryItemBeingDraggedAtom = atom<string | null>(null);

// Create an effect atom that will handle the scrolling after data is loaded
const scrollAfterLoadEffect = atomEffect((get, set) => {
  const handleScroll = async () => {
    // Wait for the selected IDs to resolve
    const selectedIds = await get(selectedComponentIdsAtom);

    // Only proceed if we have exactly one selected ID
    if (selectedIds.length !== 1) return;

    // Wait for the selected component's data to load
    const componentId = selectedIds[0];
    const componentData = get(libraryComponentFamilyAtom(componentId));

    // If the component data is not a promise, we don't need to scroll
    // because it was already loaded and scrolled to
    if ("then" in componentData) {
      await componentData;
      // TODO: This is all pretty messy just to get scrolling on load
      // to work with the virtualized list. We should revisit this.
      // https://linear.app/dittowords/issue/DIT-9639/revisit-scroll-to-behavior-for-onload-with-virtualized-lists
      setTimeout(() => {
        set(scrollToComponentIdActionAtom, componentId);
      }, 500);
    }
  };

  handleScroll();
});

function LibraryItemsListContent(props: { libraryItems: LibraryItem[] }) {
  const { libraryItems } = props;

  const activeStickyIndexRef = useRef(null);
  const scrollContentRef = useAtomRef(libraryComponentListScrollRefAtom);
  const [isScrolled, setIsScrolled] = useAtom(isScrolledAtom);
  const grouping = useAtomValue(selectedGroupingAtom);
  const libraryDraggedSelection = useAtomValue(libraryDraggedSelectionAtom);
  useAtomValue(scrollAfterLoadEffect);

  const shouldDisableScrollTo = libraryDraggedSelection?.origin === "main-list";

  // When we have a grouping, we can't drag items to reorder in the list, but we can still drop them
  // on the sidebar items.
  const disableDropBetweenItems = grouping !== null;

  // Get the indices of all the items that we want to render as sticky headers in the virtual list.
  const headerIndices = useMemo(() => {
    const indices: number[] = [];
    for (const [index, item] of libraryItems.entries()) {
      if (item.type === "header" || item.type === "tag-header") {
        indices.push(index);
      }
    }
    return indices;
  }, [libraryItems]);

  const rangeExtractor = useCallback(
    (range: Range) => createStickyRangeExtractor(activeStickyIndexRef, headerIndices)(range),
    [headerIndices]
  );

  const getItemKey = useCallback(
    (index: number) => {
      const item = libraryItems[index];
      switch (item.type) {
        case "header":
        case "tag-header":
        case "separator":
          return item._id;
        case "component":
          if (item.tag) {
            return `${item._id}-${item.tag}`;
          }
          return item._id;
      }
    },
    [libraryItems]
  );

  // Draggable list items rendered w/tanstack virtualizer render incorrectly on Safari.
  // Temporary workaround is to render the list of items w/out virtualization in Safari until we address in followup investigation.
  // Additionally, custom previews for dragged items are invisible in Safari, so we disable them. See ticket for context:
  // [DIT-9582](https://linear.app/dittowords/issue/DIT-9582/investigate-visual-bugs-in-safari-with-react-aria-dnd-tanstack-virtual)
  if (IS_SAFARI) {
    return (
      <>
        <LibraryItemsTitleHeader isScrolled />
        <Scrollbar scrollContentRef={scrollContentRef} className={style.scrollArea}>
          {libraryItems
            .filter((item) => item.type === "component")
            .map((item) => (
              <Suspense key={item._id} fallback={<LibraryComponentListItemFallback />}>
                <LibraryComponentListItem className={style.virtualItem} item={item} isScrolling={false} />
              </Suspense>
            ))}
        </Scrollbar>
      </>
    );
  }

  return (
    <VirtualizedList
      className={style.libraryVirtualList}
      itemClassName={style.virtualItem}
      id={LIBRARY_CONTENT_VIRTUALIZER_ID}
      items={libraryItems}
      virtualizerAtom={libraryItemsVirtualizerAtom}
      virtualizeOptions={{
        onChange: (instance) => {
          setIsScrolled((instance.scrollElement?.scrollTop || 0) > 0);
        },
        estimateSize: () => 300,
        overscan: 10,
        paddingEnd: 24,
        getItemKey,
        rangeExtractor,
      }}
      getIsSticky={(index) => headerIndices.includes(index)}
      getIsActiveSticky={(index) => index === activeStickyIndexRef.current}
      ref={scrollContentRef}
      shouldDisableScrollTo={shouldDisableScrollTo}
    >
      {({ index, item, isScrolling }) => {
        switch (item.type) {
          case "header":
            return (
              <LibraryItemsTitleHeader
                isScrolled={isScrolled}
                isActiveSticky={index === activeStickyIndexRef.current}
              />
            );
          case "tag-header":
            return (
              <LibraryItemsTagGroupHeader
                // main components header is always index 0
                isFirst={index === 1}
                isActiveSticky={index === activeStickyIndexRef.current}
                isScrolled={isScrolled}
                label={item.label}
                count={item.count}
              />
            );
          case "component":
            return (
              <Suspense fallback={<LibraryComponentListItemFallback />}>
                <LibraryComponentListItem
                  item={item}
                  isScrolling={isScrolling}
                  disableDropBetweenItems={disableDropBetweenItems}
                />
              </Suspense>
            );
          case "separator":
            return <div className={style.groupSeparator} />;
          default:
            logger.error("Unknown library item type", { context: { item } }, new Error("Unknown library item type"));
            return null;
        }
      }}
    </VirtualizedList>
  );
}
export default LibraryItemsList;
