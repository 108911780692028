import { useAtom, WritableAtom } from "jotai";
import React, { useCallback, useEffect, useState } from "react";
import { DEFAULT_COMPONENTS_CONFIG } from "../../../../shared/utils/apiId/lib";
import { validateDeveloperId } from "../../../../shared/utils/developerId";
import Button from "../../atoms/Button";
import TextInput, { IProps as TextInputProps } from "../../atoms/TextInput";
import CopyableBadge from "../../molecules/CopyableBadge";
import style from "./index.module.css";

export type UpdateDeveloperIdResponse =
  | { success: true }
  | { success: false; errorMessage: string; errorMessageAction?: TextInputProps["errorMessageAction"] };

export interface EditableDeveloperIdProps {
  className?: string;
  style?: React.CSSProperties;
  developerIdAtom: WritableAtom<string | Promise<string>, [string], Promise<UpdateDeveloperIdResponse>>;
  onToggleEditMode?: (isEditing: boolean) => void;
  onCopy: () => void;
}

export function EditableDeveloperId(props: EditableDeveloperIdProps) {
  const [persistedValue, setPersistedValue] = useAtom(props.developerIdAtom);
  const [localValue, setLocalValue] = useState(persistedValue);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [errorMessageAction, setErrorMessageAction] = useState<TextInputProps["errorMessageAction"]>();

  // DIT-10001: Pull config from workspace via atom/prop
  const config = DEFAULT_COMPONENTS_CONFIG;

  const isSaveDisabled = localValue === persistedValue || isSaving || !!errorMessage;

  const { onToggleEditMode } = props;
  const setIsEditing = useCallback(
    (isEditing: boolean) => {
      // Update local state
      setIsEditMode(isEditing);
      // Alert the parent of the change if needed
      onToggleEditMode?.(isEditing);
    },
    [onToggleEditMode]
  );

  useEffect(
    function updateLocalOnPersistedValueChange() {
      setLocalValue(persistedValue);
      setIsEditing(false);
    },
    [persistedValue, setIsEditing]
  );

  const handleChange = useCallback(
    (newValue: string) => {
      setLocalValue(newValue);

      const validationResponse = validateDeveloperId(newValue, config);
      if (!validationResponse.isValid) {
        setErrorMessage(`Developer ID ${validationResponse.message}`);
      } else {
        setErrorMessage(undefined);
      }
      setErrorMessageAction(undefined);
    },
    [config]
  );

  const handleClickEdit = useCallback(() => {
    setIsEditing(true);
  }, [setIsEditing]);

  const handleClickCancel = useCallback(() => {
    setLocalValue(persistedValue);
    setIsEditing(false);
    setErrorMessage(undefined);
    setErrorMessageAction(undefined);
  }, [persistedValue, setIsEditing]);

  const handleClickSave = useCallback(async () => {
    setIsSaving(true);
    const result = await setPersistedValue(localValue);
    setIsSaving(false);
    if (result.success) {
      setIsEditing(false);
      setErrorMessage(undefined);
      setErrorMessageAction(undefined);
    } else {
      setErrorMessage(result.errorMessage);
      setErrorMessageAction(result.errorMessageAction);
    }
  }, [localValue, setPersistedValue, setIsEditing]);

  // Save on Enter, cancel on Escape
  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === "Enter" && !isSaveDisabled) {
        event.preventDefault();
        handleClickSave();
      } else if (event.key === "Escape") {
        event.preventDefault();
        handleClickCancel();
      }
    },
    [handleClickSave, handleClickCancel, isSaveDisabled]
  );

  if (isEditMode) {
    return (
      <div className={style.inputContainer}>
        <TextInput
          value={localValue}
          autoFocus
          fontStyle="code"
          errorLevel="warning"
          errorMessage={errorMessage}
          errorMessageAction={errorMessageAction}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
        />
        <div className={style.buttonsContainer}>
          <Button level="secondary" onClick={handleClickCancel}>
            Cancel
          </Button>
          <Button level="primary" disabled={isSaveDisabled} onClick={handleClickSave}>
            Save
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className={style.readOnlyContainer}>
      <CopyableBadge fontStyle="code" color="blue" borderRadius="md" text={persistedValue} onCopy={props.onCopy} />
      <Button level="subtleAction" size="micro" onClick={handleClickEdit}>
        Edit
      </Button>
    </div>
  );
}

export default EditableDeveloperId;
