import { z } from "zod";
import { ZDFVariable, ZDFVariableCollection } from "./FigmaVariables";
import { ZTextItem } from "./TextItem";
import { ZObjectId } from "./lib";

const ZProjectSelectedPageInfo = z.object({
  name: z.string(),
  figma_id: z.string(),
});

const ZProjectIntegrations = z.object({
  figma: z.object({
    file_id: z.string().optional(),
    file_name: z.string().optional(),
    branch_id: z.string().nullable().optional(),
    selected_pages: z.array(ZProjectSelectedPageInfo).optional(),
    resynced_at: z.date().optional(),
    previews_updated_at: z.date().optional(),
    ditto_component_key: z.string().optional(),
    text_layer_rules: z
      .object({
        show_component_icon: z.boolean(),
        show_status_icon: z.boolean(),
        show_api_id: z.boolean(),
      })
      .optional(),
    variableCollections: z.array(ZDFVariableCollection).optional(),
    variables: z.array(ZDFVariable).optional(),
  }),
  slack: z.object({
    channel_id: z.string().nullable(),
    channel_name: z.string().nullable(),
    webhook_url: z.string().nullable(),
    configuration_url: z.string().nullable(),
    notif_types: z.object({
      textEdited: z.boolean(),
      textAssigned: z.boolean(),
      statusChanged: z.boolean(),
      commentActivity: z.boolean(),
      componentAttached: z.boolean(),
      draftItems: z.boolean(),
    }),
  }),
});

const ZProjectBlock = z.object({
  name: z.string(),
  apiID: z.string().nullable(),
  comps: z.array(ZTextItem).or(z.array(ZObjectId)),
  _id: ZObjectId,
});

const ZProjectGroupIntegration = z.object({
  figma: z.object({
    frame_id: z.string().optional(),
    frame_name: z.string().optional(),
    frame_name_synced_with_group_name: z.boolean().optional(),
    page_id: z.string().optional(),
    previews: z
      .object({
        fullsize: z.string().optional(),
      })
      .optional(),
    applied_variant_id: ZObjectId.nullable().optional(),
    position: z
      .object({
        x: z.number(),
        y: z.number(),
        width: z.number(),
        height: z.number(),
      })
      .optional(),
  }),
});

const ZProjectGroup = z.object({
  _id: ZObjectId,
  name: z.string(),
  apiID: z.string().nullable(),
  applied_variant_id: ZObjectId.nullable(),
  figma_artboard_ID: z.string(),
  figma_page_id: z.string().nullable(),
  state: z.enum(["ERROR", "NONE"]),
  blocks: z.array(ZProjectBlock),
  comps: z.array(ZTextItem).or(z.array(ZObjectId)),
  pinned: z.boolean(),
  hidden: z.boolean(),
  nonblocks_collapsed: z.boolean(),
  linking_enabled: z.boolean(),
  integrations: ZProjectGroupIntegration,
  dontShowSetupSuggestions: z.boolean().optional(),
});

export const ZFeatureFlags = z.object({
  rich_text: z.boolean().optional(),
  figmaVariables: z.boolean().optional(),
  autoConnectDrafts: z.boolean().optional(),
  autoAttachComponents: z.discriminatedUnion("enabled", [
    z.object({ enabled: z.literal(false) }),
    z.object({
      enabled: z.literal(true),
      componentFolderId: z.string().optional(),
    }),
  ]),
  autoImportFrames: z.discriminatedUnion("enabled", [
    z.object({ enabled: z.literal(false) }),
    z.object({ enabled: z.literal(true), selectedPages: z.record(z.boolean()).optional() }),
  ]),
});
export type IFeatureFlags = z.infer<typeof ZFeatureFlags>;

export const ZProject = z.object({
  _id: ZObjectId,
  workspace_ID: ZObjectId,
  developer_mode_enabled: z.boolean(),
  integrations: ZProjectIntegrations,
  feature_flags: ZFeatureFlags,
  doc_name: z.string(),
  tags: z.array(z.string()),
  groups: z.array(ZProjectGroup),
  graveyard: z.array(ZTextItem).or(z.array(ZObjectId)),
  date_time_created: z.date(),
  time_last_resync: z.date(),
  edited_at: z.date(),
  previewsUpdatedAt: z.date().optional(),
  folder_id: ZObjectId.nullable().optional(),
  is_locked: z.boolean(),
  isSample: z.boolean(),
});
export type IProject = z.infer<typeof ZProject>;
