import { z } from "zod";
import { ZCreatableId, ZObjectId } from "../lib";

// MARK: - Moving Components

export const ZMoveLibraryComponentsAction = z.object({
  componentIds: z.array(ZObjectId),
  folderId: ZObjectId.optional().nullable(),
  /**
   * ID of component to insert before
   */
  before: ZObjectId.optional(),
  /**
   * ID of component to insert after
   */
  after: ZObjectId.optional(),
  moveToStart: z.boolean().optional(),
});

export type IMoveLibraryComponentsAction = z.infer<typeof ZMoveLibraryComponentsAction>;

export const ZMoveLibraryComponentsProps = z.object({
  actions: z.array(ZMoveLibraryComponentsAction),
});

export type IMoveLibraryComponentsProps = z.infer<typeof ZMoveLibraryComponentsProps>;

export const ZMoveLibraryComponentsActionResult = z.object({
  destinationFolderId: ZCreatableId.nullable().optional(),
  componentIds: z.array(ZCreatableId),
});

export type IMoveLibraryComponentsActionResult = z.infer<typeof ZMoveLibraryComponentsActionResult>;
