import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Virtualizer } from "@tanstack/react-virtual";
import classNames from "classnames";
import { atom, PrimitiveAtom, useAtom } from "jotai";
import React, { useCallback, useMemo, useRef } from "react";
import { DragPreview, DragPreviewRenderer, DragStartEvent } from "react-aria";
import Button from "../../atoms/Button";
import DragAndDroppable, { IDragAndDroppableProps, SelectionType } from "../../atoms/DragAndDroppable";
import LoadingIcon from "../../atoms/LoadingIcon";
import MultiDragPreview from "../../atoms/MultiDragPreview";
import NavItemNestedIndicator from "../../atoms/NavItemNestedIndicator";
import Text, { ITextProps } from "../../atoms/Text";
import style from "./index.module.css";

const DEFAULT_DND_PROPS: Omit<IDragAndDroppableProps<unknown, unknown>, "children"> = {
  isDragDisabled: true,
  selectionType: "none" as const,
  getDraggableItems: () => [],
  allowedItemKeys: {},
};

interface BaseProps<DraggableDataType, DroppableDataType> {
  /**
   * Unique identifier for the item.
   */
  id: string;

  /**
   * The icon to display for the item.
   */
  icon?: React.ReactNode;

  /**
   * Trailing UI to display for the item.
   */
  trailingUI?: React.ReactNode;

  /**
   * The label to display for the item.
   */
  label?: string;

  /**
   * The children to display for the item.
   */
  children?: React.ReactNode;

  /**
   * The options to pass to the label component.
   */
  labelOptions?: Partial<ITextProps>;

  /**
   * Whether the item is selected.
   */
  selected?: boolean;

  /**
   * Whether the parent of the current child NavItem is selected.
   */
  parentSelected?: boolean;

  /**
   * Whether the item is disabled (used for styling).
   */
  disabled?: boolean;

  /**
   * Whether the item is nested within a group.
   */
  isNested?: boolean;

  /**
   * Whether the item is highlighted as a drop target.
   */
  highlightDropTarget?: boolean;

  /**
   * Props for enabling drag and drop functionality. See `DragAndDroppable` for more details.
   */
  dragAndDrop?: Omit<IDragAndDroppableProps<DraggableDataType, DroppableDataType>, "children">;

  /**
   * Whether to show drop indicators above and below the droppable target.
   */
  showDropIndicators?: boolean;

  /**
   * This item's drop target type.
   * E.g. a text item is type "text", component item is type "component", etc
   * Used to determine when drop targets above and below the NavItem are shown.
   */
  dropTargetType?: SelectionType;

  /**
   * Whether to disable rendering a custom preview for the drag and drop component.
   */
  disableCustomPreview?: boolean;

  /**
   * The percentage of the edge area for edge drop targets above and below the NavItem. Between 0 and 0.5.
   */
  edgeThreshold?: number;

  /**
   * Callback for when the item is clicked.
   */
  onClick?: React.MouseEventHandler<HTMLElement>;

  /**
   * Atom used to store all virtualizers in this virtual list. It is primarily used for scrolling an element into view.
   */
  virtualizerAtom?: PrimitiveAtom<Record<string, Virtualizer<Element, Element>>>;

  /**
   * Atom that manages the state for whether the group is collapsed (children are hidden).
   */
  isCollapsedAtom?: PrimitiveAtom<boolean>;

  className?: string;
  style?: React.CSSProperties;
}

interface GroupItemProps<DraggableDataType, DroppableDataType> extends BaseProps<DraggableDataType, DroppableDataType> {
  type: "group";

  /**
   * Optional children to render for the group item.
   */
  groupChildren?: React.ReactNode;

  /**
   * Optionally render the group children using an array of NavItem props. Use `groupChildrenProps` if you don't need to customize the children of the group nav item
   * (will render each child as a NavItem with the props passed in).
   * `groupChildren` must not be defined in order to render using `groupChildrenProps`.
   */
  groupChildrenProps?: INavItemProps<DraggableDataType, DroppableDataType>[];

  virtualizerAtom?: PrimitiveAtom<Record<string, Virtualizer<Element, Element>>>;

  /**
   * Atom that manages the state for whether the group is collapsed (children are hidden).
   */
  isCollapsedAtom: PrimitiveAtom<boolean>;

  /**
   * Optional flag for whether clicking anywhere on the entire group header element will toggle collapsed state.
   * Default is false-- only clicking on toggle icon collapses the group.
   */
  toggleOnGroupHeaderClick?: boolean;

  /**
   * Callback for when the group's collapse state is toggled.
   */
  onToggleCollapse?: () => void;
}

export interface TextNavItemProps<DraggableDataType, DroppableDataType>
  extends BaseProps<DraggableDataType, DroppableDataType> {
  type: "item";
}

export type INavItemProps<DraggableDataType, DroppableDataType> =
  | TextNavItemProps<DraggableDataType, DroppableDataType>
  | GroupItemProps<DraggableDataType, DroppableDataType>;

export function NavItem<DraggableDataType extends unknown, DroppableDataType extends unknown>(
  props: INavItemProps<DraggableDataType, DroppableDataType>
) {
  const dragPreviewRef = useRef<DragPreviewRenderer>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const containerDimensions = useRef<{ width: number; height: number } | null>(null);

  const { onClick, dragAndDrop = DEFAULT_DND_PROPS, dropTargetType = "text" } = props;

  const isCollapsedAtom = useMemo(
    () => (props.isCollapsedAtom ? props.isCollapsedAtom : atom(false)),
    [props.isCollapsedAtom]
  );
  const [isCollapsed, setIsCollapsed] = useAtom(isCollapsedAtom);

  const toggleOnGroupHeaderClick = props.type === "group" ? props.toggleOnGroupHeaderClick : undefined;
  const onToggleCollapse = props.type === "group" ? props.onToggleCollapse : undefined;

  const handleToggleCollapse = useCallback(
    (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e?.preventDefault();
      e?.stopPropagation();
      setIsCollapsed((prev) => !prev);
      onToggleCollapse?.();
    },
    [onToggleCollapse, setIsCollapsed]
  );

  const handleOnClick = useCallback(
    function _handleOnClick(e: React.MouseEvent<HTMLElement>) {
      if (toggleOnGroupHeaderClick) {
        setIsCollapsed((prev) => !prev);
        onToggleCollapse?.();
      }
      onClick?.(e);
    },
    [onToggleCollapse, onClick, setIsCollapsed, toggleOnGroupHeaderClick]
  );

  const handleStartDrag = useCallback(
    (e: DragStartEvent) => {
      dragAndDrop.onDragStart?.(e);
      if (containerRef.current) {
        containerDimensions.current = {
          width: containerRef.current.offsetWidth,
          height: containerRef.current.offsetHeight,
        };
      }
    },
    [dragAndDrop]
  );

  const showGroupChildren = props.type === "group" && !isCollapsed;

  return (
    <div
      ref={containerRef}
      style={props.style}
      className={classNames(style.NavItemWrapper, props.className)}
      data-testid="nav-item"
    >
      <div className={style.navItemRow}>
        {props.isNested && <NavItemNestedIndicator />}
        <div className={classNames(style.navItemContainer, { [style.nested]: props.isNested })}>
          <DragAndDroppable
            className={style.dragAndDropWrapper}
            // @ts-ignore
            onDrop={() => {}}
            {...dragAndDrop}
            onDragStart={handleStartDrag}
            preview={props.disableCustomPreview ? undefined : dragPreviewRef}
            edgeThreshold={props.edgeThreshold}
          >
            {(dragAndDropProps) => {
              const dropDisabledAbove =
                !props.showDropIndicators ||
                (props.highlightDropTarget &&
                  props.edgeThreshold !== undefined &&
                  dragAndDropProps.dragLocation !== "above-edge");

              const dropDisabledBelow =
                !props.showDropIndicators ||
                (props.highlightDropTarget &&
                  props.edgeThreshold !== undefined &&
                  dragAndDropProps.dragLocation !== "below-edge");

              const isDroppingOnMiddle =
                dragAndDropProps.dragLocation === "below" || dragAndDropProps.dragLocation === "above";

              return (
                <>
                  {!dropDisabledAbove && (
                    <div
                      data-selectiontype={dropTargetType}
                      data-dragindicatorabove
                      className={classNames(style.dropIndicatorWrapper, style.above)}
                    >
                      <div className={style.dropIndicator} />
                    </div>
                  )}
                  <div className={style.navItemSpacer}>
                    <NavItemContent
                      props={props}
                      dragAndDropProps={dragAndDropProps}
                      isCollapsed={isCollapsed}
                      handleToggleCollapse={handleToggleCollapse}
                      handleOnClick={handleOnClick}
                      dropTargetHighlighted={
                        props.highlightDropTarget && dragAndDropProps.isDropTarget && isDroppingOnMiddle
                      }
                    />
                    <DragPreview ref={dragPreviewRef}>
                      {(items) => (
                        <MultiDragPreview count={items.length}>
                          <NavItemContent
                            style={{
                              width: containerDimensions.current?.width,
                            }}
                            props={props}
                            dragAndDropProps={dragAndDropProps}
                            isCollapsed={isCollapsed}
                            handleToggleCollapse={handleToggleCollapse}
                            isDragPreview
                            useDisabledColor
                          >
                            <div className={style.dragIcon}></div>
                          </NavItemContent>
                        </MultiDragPreview>
                      )}
                    </DragPreview>
                  </div>
                  {!dropDisabledBelow && (
                    <div
                      data-selectiontype={dropTargetType}
                      data-dragindicatorbelow
                      className={classNames(style.dropIndicatorWrapper, style.below)}
                    >
                      <div className={style.dropIndicator} />
                    </div>
                  )}
                </>
              );
            }}
          </DragAndDroppable>
          {showGroupChildren && (
            <div className={classNames(style.groupChildren)}>
              {props.groupChildren}
              {!props.groupChildren &&
                props.groupChildrenProps?.map((groupChildProps) => {
                  return (
                    <NavItem
                      key={groupChildProps.id}
                      {...groupChildProps}
                      isNested
                      parentSelected={props.selected}
                      className={style.groupItems}
                    />
                  );
                })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

interface NavItemContentProps<DraggableDataType, DroppableDataType> {
  props: INavItemProps<DraggableDataType, DroppableDataType>;
  dragAndDropProps: { isDragging: boolean };
  isCollapsed: boolean;
  handleToggleCollapse: (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  handleOnClick?: React.MouseEventHandler<HTMLElement>;
  dropTargetHighlighted?: boolean;
  isDragPreview?: boolean;
  useDisabledColor?: boolean;
  style?: React.CSSProperties;
  children?: React.ReactNode;
}

/**
 * A component that renders the content of a nav item
 */
function NavItemContent<DraggableDataType, DroppableDataType>({
  props,
  dragAndDropProps,
  isCollapsed,
  handleToggleCollapse,
  handleOnClick,
  dropTargetHighlighted,
  isDragPreview,
  useDisabledColor,
  style: customStyle,
}: NavItemContentProps<DraggableDataType, DroppableDataType>) {
  return (
    <div
      onClick={handleOnClick}
      style={customStyle}
      className={classNames(style.navItemContent, {
        [style.selected]: props.selected,
        [style.parentSelected]: props.parentSelected,
        [style.dragged]: dragAndDropProps.isDragging,
        [style.disabledContent]: props.disabled,
        [style.dropTargetHighlighted]: dropTargetHighlighted,
        [style.dragPreview]: isDragPreview,
      })}
    >
      {props.type === "group" && (
        <Button type="icon" level="subtle" size="small" onClick={handleToggleCollapse}>
          <KeyboardArrowDownIcon
            className={classNames(style.groupDropDownIndicator, { [style.collapse]: isCollapsed })}
          />
        </Button>
      )}
      {!dragAndDropProps.isDragging && props.icon}
      {props.label && (
        <Text
          className={style.label}
          truncate
          size="small"
          color={useDisabledColor && props.disabled ? "secondary" : "primary"}
          weight={props.type === "group" ? "base" : "light"}
          {...props.labelOptions}
        >
          {props.label}
        </Text>
      )}
      {props.children}
      {props.trailingUI}
    </div>
  );
}

NavItem.Fallback = function NavItemFallback() {
  return (
    <NavItem
      id={crypto.randomUUID()}
      type="item"
      label="Loading…"
      icon={<LoadingIcon size="xxs" />}
      dragAndDrop={{
        selectionType: "none",
        getDraggableItems: () => [],
        allowedItemKeys: {},
      }}
    />
  );
};

export default NavItem;
