import Button from "@ds/atoms/Button";
import React from "react";

interface IDeleteTextItemProps {
  text: string;
  onClick: () => void;
}

function DeleteItem(props: IDeleteTextItemProps) {
  return (
    <Button size="micro" level="subtle" onClick={props.onClick}>
      {props.text}
    </Button>
  );
}

export default DeleteItem;
