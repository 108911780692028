import { useAtom, useAtomValue } from "jotai";
import React, { useCallback, useEffect, useState } from "react";
import { EditMetadataAtom } from ".";
import { MergedAssigneeMetadata } from "../../../../shared/frontend/lib/metadataHelpers";
import { SoonAtom } from "../../../../shared/frontend/stores/types";
import { IUser } from "../../../../shared/types/User";
import LabelWrapper from "../../molecules/LabelWrapper";
import Select, { SelectOption } from "../../molecules/Select";
import { AvatarIcon } from "./icons";
import style from "./index.module.css";

interface IProps {
  assigneeAtom: EditMetadataAtom<MergedAssigneeMetadata, string | null>;
  usersByIdAtom: SoonAtom<Record<string, IUser>>;
  inputSize: "base" | "small";
  labelHeight: number;
}

function EditAssignee(props: IProps) {
  const [persistedAssignee, savePersistedAssignee] = useAtom(props.assigneeAtom);
  const [localAssignee, setLocalAssignee] = useState(persistedAssignee);
  const usersById = useAtomValue(props.usersByIdAtom);

  const handleSelectAssignee = useCallback(
    (value: string) => {
      if (!value || value === "UNASSIGNED" || !usersById[value]?.name) {
        setLocalAssignee(null); // Optimistic local UI update
        savePersistedAssignee(null); // Alert the parent of the change
      } else {
        setLocalAssignee({ value, label: usersById[value].name }); // Optimistic local UI update
        savePersistedAssignee(value); // Alert the parent of the change
      }
    },
    [savePersistedAssignee, usersById]
  );

  useEffect(
    function updateLocalStateOnPropChange() {
      setLocalAssignee(persistedAssignee);
    },
    [persistedAssignee]
  );

  return (
    <LabelWrapper
      className={style.labelWrapper}
      labelFlexStart
      labelHeight={props.labelHeight}
      label="Assign"
      leadingIcon={<AvatarIcon />}
    >
      <div className={style.inputWidthWrapper}>
        <Select
          size={props.inputSize}
          value={localAssignee?.value ?? ""}
          placeholder="Add teammate..."
          onChange={handleSelectAssignee}
        >
          {[
            <SelectOption key="UNASSIGNED" label="No assignee" value="UNASSIGNED" />,
            ...Object.entries(usersById).map(([id, user]) => <SelectOption key={id} label={user.name} value={id} />),
            <SelectOption key="MIXED" hidden label="Mixed Assignees" value="MIXED" />,
          ]}
        </Select>
      </div>
    </LabelWrapper>
  );
}

export default EditAssignee;
