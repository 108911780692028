import { useAtom } from "jotai";
import React, { useCallback, useEffect, useState } from "react";
import { EditMetadataAtom } from ".";
import { MergedStatusMetadata } from "../../../../shared/frontend/lib/metadataHelpers";
import { ITextItemStatus } from "../../../../shared/types/TextItem";
import LabelWrapper from "../../molecules/LabelWrapper";
import StatusSelect from "../StatusSelect";
import { FlagIcon } from "./icons";
import style from "./index.module.css";

interface IProps {
  statusAtom: EditMetadataAtom<MergedStatusMetadata, ITextItemStatus>;
  inputSize: "base" | "small";
  labelHeight: number;
}

function EditStatus(props: IProps) {
  const [persistedStatus, savePersistedStatus] = useAtom(props.statusAtom);
  const [localStatus, setLocalStatus] = useState(persistedStatus);

  const handleStatusChange = useCallback(
    (newStatus: ITextItemStatus) => {
      setLocalStatus(newStatus); // Optimistic local UI update
      savePersistedStatus(newStatus); // Alert the parent of the change
    },
    [savePersistedStatus]
  );

  useEffect(
    function updateLocalStateOnPropChange() {
      setLocalStatus(persistedStatus);
    },
    [persistedStatus]
  );

  return (
    <LabelWrapper
      className={style.labelWrapper}
      labelFlexStart
      labelHeight={props.labelHeight}
      label="Status"
      leadingIcon={<FlagIcon />}
    >
      <div className={style.inputWidthWrapper}>
        <StatusSelect status={localStatus} setStatus={handleStatusChange} size={props.inputSize} />
      </div>
    </LabelWrapper>
  );
}

export default EditStatus;
