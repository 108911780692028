import FolderIcon from "@mui/icons-material/FolderOpenOutlined";
import { ILibraryComponentFolder } from "@shared/types/LibraryComponentFolder";
import classNames from "classnames";
import { Atom, useAtomValue } from "jotai";
import React, { Suspense, useMemo } from "react";
import Icon from "../../atoms/Icon";
import Text from "../../atoms/Text";
import style from "./index.module.css";

export default function FolderBreadcrumbsLabel(props: IProps) {
  return (
    <Suspense fallback={<React.Fragment />}>
      <FolderBreadcrumbsLabelInner {...props} />
    </Suspense>
  );
}

interface IProps {
  maxCrumbs?: number;
  selectedLibraryFolderId: string;
  libraryComponentFoldersBreadcrumbsAtomFamily: (
    folderId: string
  ) => Atom<Promise<{ folder: ILibraryComponentFolder; breadcrumbs: string[] }>>;
}

function FolderBreadcrumbsLabelInner(props: IProps) {
  const { maxCrumbs = 4 } = props;

  const { folder, breadcrumbs } = useAtomValue(
    props.libraryComponentFoldersBreadcrumbsAtomFamily(props.selectedLibraryFolderId)
  );

  const crumbs = useMemo(() => {
    let crumbs: string[] = [];
    crumbs.push(...breadcrumbs);
    crumbs.push(folder.name);

    if (crumbs.length > maxCrumbs) {
      crumbs = [crumbs[0], "...", ...crumbs.slice(crumbs.length - 1)];
    }

    return crumbs;
  }, [breadcrumbs, folder.name, maxCrumbs]);

  return (
    <div className={style.folderBreadcrumbsLabel}>
      <Icon Icon={<FolderIcon />} size="xxs" color="secondary" />
      <div className={style.crumbs}>
        {crumbs.map((crumb, index) => {
          const isLast = index === crumbs.length - 1;
          const isJustEllipsis = crumb === "...";

          return (
            <React.Fragment key={crumb}>
              <Text
                truncate
                className={classNames(style.crumb, {
                  [style.lastCrumb]: isLast,
                  [style.justEllipsis]: isJustEllipsis,
                })}
                size="micro"
                color="secondary"
              >
                {crumb}
              </Text>
              {!isLast && <Separator />}
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
}

function Separator() {
  return (
    <Text size="micro" color="secondary">
      /
    </Text>
  );
}
