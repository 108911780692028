import useInviteModal from "@/hooks/useInviteModal";
import { useAuthenticatedAuth } from "@/store/AuthenticatedAuthContext";
import { WorkspacePlan, useWorkspace } from "@/store/workspaceContext";
import {
  DEV_DOCS_LINK_CLICKED,
  ENABLE_DEV_TOOLS_MODAL_CLICKED,
  INVITE_DEVELOPER_CLICKED,
} from "@shared/segment-event-names";
import { useDittoComponent } from "ditto-react";
import { useContext, useState } from "react";
import { useOverlayToast } from "../../components/OverlayToast/useOverlayToast";
import useSegment from "../../hooks/useSegment";
import http, { API } from "../../http";
import billingApi from "../../http/billing";
import { BillingContext } from "../../store/billingContext";

export const DAYS_IN_MS = 1000 * 60 * 60 * 24;

export function useDevelopersPage() {
  const segment = useSegment();
  const { overlayToastProps, showToast } = useOverlayToast();
  const inviteModal = useInviteModal();
  const { workspaceInfo, updateWorkspaceInfo } = useWorkspace();
  const { user } = useAuthenticatedAuth();
  const { showBillingModal, validateBilling, billingStatus, seatsState } = useContext(BillingContext);
  const [devToolsModalOpen, setDevToolsModalOpen] = useState<boolean>(false);

  const viewerIsDeveloper = user.job === "ENGINEER";
  const devToolsEnabled = workspaceInfo?.devTools;

  function getDevTrialStartDate() {
    if (workspaceInfo?.devToolsTrial) {
      return new Date(workspaceInfo.devToolsTrial).toISOString();
    }
    return "";
  }

  function getWorkspaceTrialStartDate() {
    if (workspaceInfo?.ws_trial) {
      return new Date(workspaceInfo.ws_trial).toISOString();
    }
    return "";
  }

  let devToolsBilled = false;
  if (!billingStatus.loading && billingStatus.success) {
    devToolsBilled = billingStatus.data.devTools;
  }

  async function onClickEnable() {
    segment.track({ event: ENABLE_DEV_TOOLS_MODAL_CLICKED });
    setDevToolsModalOpen(true);
  }

  function openInviteModal() {
    segment.track({ event: INVITE_DEVELOPER_CLICKED });
    inviteModal.openInviteModal();
  }

  function openDevDocs() {
    segment.track({
      event: DEV_DOCS_LINK_CLICKED,
      properties: { version: "legacy", application: "web_app", location: "developers_page" },
    });
    window.open("https://developer.dittowords.com", "_blank");
  }

  const devToolsEnabledText = useDittoComponent({ componentId: "integration-enabled" });
  const devToolsTrialEnabledText = useDittoComponent({
    componentId: "dev-integrations-trial-started",
  });

  async function handleEnableDevTools() {
    // If dev tools are not currently being billed, add them to the bill first
    // (devToolsBilled will always be true for free and trial users)
    if (!devToolsBilled) {
      // get current seats
      const [seatsRequest, _] = billingApi.getSeats();
      const { data } = await seatsRequest;

      // update subscription with the same seats as currently, but enable dev tools
      const [request, cancelRequest] = billingApi.updateSubscriptions({
        monthly: {
          editors: data.numMonthlyEditorSeats,
          commenters: data.numMonthlyCommenterSeats,
          devTools: true,
        },
        plan: data.plan as WorkspacePlan,
      });
      await request;
    }

    // the validation logic takes care of actually turning on dev tools in the
    // workspace unless we're on a free plan -- then we need to do it manually
    await validateBilling();
    if (workspaceInfo?.plan === "free") {
      // Actually enable dev tools across the workspace
      const { url, body } = API.workspace.put.devTools;
      await http.put(url, body({ devToolsEnabled: true }));
      updateWorkspaceInfo((prev) => ({ ...prev, devTools: true }));
    }

    // close the modal
    setDevToolsModalOpen(false);

    showToast(devToolsEnabledText!, 5000);
  }

  async function handleStartTrial() {
    const [request, cancelRequest] = billingApi.startDevToolsTrial();
    await request;

    // the validation logic takes care of actually turning on dev tools in the workspace
    await validateBilling();

    setDevToolsModalOpen(false);

    showToast(devToolsTrialEnabledText!, 5000);
  }

  return {
    segment,
    devToolsModalOpen,
    setDevToolsModalOpen,
    viewerIsDeveloper,
    devToolsEnabled,
    onTeamTrial: getWorkspaceTrialStartDate() !== "",
    onDevToolsTrial: getDevTrialStartDate() !== "",
    onClickEnable,
    openInviteModal,
    openDevDocs,
    handleEnableDevTools,
    handleStartTrial,
    devTrialStartDate: getDevTrialStartDate(),
    workspaceTrialStartDate: getWorkspaceTrialStartDate(),
    overlayToastProps,
  };
}
