import { libraryItemsCountAtom, UNTAGGED_GROUP_NAME } from "@/stores/Library";
import Badge from "@ds/atoms/Badge";
import Dot from "@ds/atoms/Dot";
import Text from "@ds/atoms/Text";
import classNames from "classnames";
import { useAtomValue } from "jotai";
import style from "../LibraryItemsList/style.module.css";

function LibraryItemsHeader(props: {
  children: React.ReactNode;
  count: number;

  isScrolled?: boolean;
  isFirst?: boolean;
  isActiveSticky?: boolean;
  className?: string;
}) {
  return (
    <div
      className={classNames(
        style.libraryItemsHeaderContainer,
        {
          [style.isScrolled]: props.isScrolled,
          [style.isFirst]: props.isFirst,
          [style.isActiveSticky]: props.isActiveSticky,
        },
        props.className
      )}
    >
      <div className={style.content}>
        {props.children}
        <Dot size={2} color="icon-secondary" />
        <Text size="small" color="tertiary">
          {props.count}
        </Text>
      </div>
    </div>
  );
}

export function LibraryItemsTitleHeader(props: { isScrolled: boolean; isActiveSticky?: boolean }) {
  const libraryItemsCount = useAtomValue(libraryItemsCountAtom);
  return (
    <LibraryItemsHeader isScrolled={props.isScrolled} isActiveSticky={props.isActiveSticky} count={libraryItemsCount}>
      <Text>Components</Text>
    </LibraryItemsHeader>
  );
}

export function LibraryItemsTagGroupHeader(props: {
  isScrolled: boolean;
  label: string;
  count: number;
  isFirst: boolean;
  isActiveSticky: boolean;
}) {
  const isUntagged = props.label === UNTAGGED_GROUP_NAME;
  return (
    <LibraryItemsHeader
      isScrolled={props.isScrolled}
      count={props.count}
      isFirst={props.isFirst}
      isActiveSticky={props.isActiveSticky}
    >
      <Badge size="micro" color={isUntagged ? "neutral" : "black"}>
        {props.label}
      </Badge>
    </LibraryItemsHeader>
  );
}

export default LibraryItemsHeader;
