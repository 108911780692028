import { Editor } from "@tiptap/react";
import { useCallback, useRef } from "react";
import { RichTextInputProps } from "../../../../shared/types/RichText";
import { ITextItem, ITipTapRichText } from "../../../../shared/types/TextItem";

// This is the type that's used in the webapp - notice the casing of value.richText
type FieldStateCamelCase = {
  label: string | undefined;
  form: string | undefined;
  value: { text: string; richText: ITipTapRichText; variables: ITextItem["variables"] };
};

// This is the type that's used in the plugin - notice the casing of value.rich_text
type FieldStateUnderScore = {
  label: string | undefined;
  form: string | undefined;
  value: { text: string; rich_text: ITipTapRichText; variables: ITextItem["variables"] };
};

type FieldState = FieldStateCamelCase | FieldStateUnderScore;

const getRichTextFromFieldState = (fieldState: FieldState): ITipTapRichText => {
  if ("richText" in fieldState.value) {
    return fieldState.value.richText;
  } else {
    return fieldState.value.rich_text;
  }
};

/**
 * Takes the props that are provided to RichTextInput and returns a set of common, calculated props
 * that will be passed into TextitemTextInputs in each of the different contexts.
 */
export default function useRichTextInputProps(props: RichTextInputProps) {
  const { initialVariableRichValue, setBaseText, setCharacterLimit } = props;

  // Ref for the TipTap editor instance
  const editorRef = useRef<Editor | null>(null);

  const handleCharacterLimitChange = useCallback(
    (newCharacterLimit: number | null) => {
      setCharacterLimit?.(newCharacterLimit);
    },
    [setCharacterLimit]
  );

  const legacyHandleTextChange = useCallback(
    function _legacyHandleTextChange(fieldStates: FieldState[]): void {
      const base = fieldStates.find((fieldState) => fieldState.form === undefined);
      const nonBases = fieldStates.filter((fieldState) => fieldState.form !== undefined);

      if (nonBases.length === 0 && base) {
        setBaseText(getRichTextFromFieldState(base));
      } else if (nonBases[0]) {
        setBaseText(getRichTextFromFieldState(nonBases[0]));
      }

      // TODO: Restore when implementing plurals
      // setPlurals(
      //   nonBases.map((fieldState) => ({
      //     rich_text: getRichTextFromFieldState(fieldState),
      //     form: fieldState.form as TextItemPluralType,
      //   }))
      // );
    },
    [setBaseText]
  );

  /**
   * Callback for resetting the TipTap editor with initial values
   * TODO: Handle resetting plurals
   */
  const reset = useCallback(() => {
    if (editorRef.current) {
      editorRef.current.commands.setContent(initialVariableRichValue.rich_text);
    }
  }, [initialVariableRichValue.rich_text]);

  const accessEditorInstance = useCallback((editor: Editor) => {
    editorRef.current = editor;
  }, []);

  return { handleCharacterLimitChange, legacyHandleTextChange, reset, accessEditorInstance };
}
